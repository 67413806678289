import React from "react";
import {Container, Row, Col} from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import feature1 from "../../../assets/images/crypto/features-img/img-1.png";
import feature2 from "../../../assets/images/crypto/features-img/img-2.png";

const Features = () => {
    const features1 = [
        {
            id: 1,
            desc: "Gestión de Pacientes: \"Registra y accede a los historiales médicos de tus pacientes de manera rápida y segura.\""
        },
        {
            id: 2,
            desc: "Recetas Electrónicas: \"Genera y envía recetas electrónicas cumpliendo con las regulaciones de FarmabitsCaydeem.\""
        },
    ];
    const features2 = [
        {id: 1, desc: "Donec pede justo vel aliquet"},
        {id: 2, desc: "Aenean et nisl sagittis"},
    ];

    return (
        <React.Fragment>
            <section className="section" id="features">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <div className="small-title">Software</div>
                                <h4>Detalles del producto</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center pt-4">
                        <Col md="6" sm="8">
                            <div>
                                <img
                                    src={feature1}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                        <Col md="5" className="ms-auto">
                            <div className="mt-4 mt-md-auto">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="features-number fw-semibold display-4 me-3">
                                        01
                                    </div>
                                    <h4 className="mb-0">Características principales</h4>
                                </div>
                                <div className="text-muted mt-4">
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        <b>Gestión de Pacientes:</b> "Registra y accede a los historiales médicos de tus
                                        pacientes de manera rápida y segura."
                                    </p>
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        <b>Recetas Electrónicas:</b> "Genera y envía recetas electrónicas cumpliendo con las regulaciones de FarmabitsCaydeem."
                                    </p>
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        <b>Citas y Agenda:</b> "Organiza tus citas y reduce los tiempos de espera con una agenda médica integrada."
                                    </p>
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        <b>Seguridad y Privacidad:</b> "Protege la información de tus pacientes con cifrado de datos y cumplimiento normativo."
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center mt-5 pt-md-5">
                        <Col md="5">
                            <div className="mt-4 mt-md-auto">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="features-number fw-semibold display-4 me-3">
                                        02
                                    </div>
                                    <h4 className="mb-0">Beneficios</h4>
                                </div>
                                <div className="text-muted mt-4">
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        "Ahorra tiempo en la gestión administrativa de tu consulta."
                                    </p>
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        "Mejora la atención a tus pacientes con un historial médico completo y accesible."
                                    </p>
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        "Cumple con las normativas legales sin complicaciones."
                                    </p>
                                    <p className="mb-2">
                                        <i className="mdi mdi-circle-medium text-success me-1"/>
                                        "Accede a la información desde cualquier dispositivo con conexión a Internet."
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" sm="8" className="ms-md-auto">
                            <div className="mt-4 me-md-0">
                                <img
                                    src={feature2}
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Features;

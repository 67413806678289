import React, {useState} from 'react';
import {Button, Card, Col, Container, Row, Toast, ToastBody, ToastHeader} from 'reactstrap';
import {cleanErrors, getAdminPatientFilesThunk, deletePatientFile} from "../../slices/admin/thunk";
import {Patient, PatientFile, Doctor, Assistant} from "../../entities/types";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";

import {Link, useLocation, useNavigate, useParams} from "react-router-dom"
import {
    Table,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
} from "reactstrap"
import Spinners from "Components/Common/Spinner";
import {toast} from "react-toastify";
import loading = toast.loading;
import DeleteModal from "../Page_Admin_Agenda/DeleteModal";
import {getAdminProfileThunk} from "../../slices/profile/thunk";
import {getAdminAssistantsThunk} from "../../slices/assistant/thunk";
import AdminTabs from "../../Layouts/HorizontalLayout/Tabs";

interface selectState {
    admin: {
        adminPatient: Patient;
        doctor: Doctor;
        loading: false;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
    doctor: {
        loading: false;
        adminProfile: Doctor;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
    assistant: {
        loading: false;
        assistant: Assistant;
        AssistantList: Assistant[];
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminPatientFiles = (props: any) => {
    const dispatch: any = useDispatch();
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    document.title = "Archivos";

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (state: selectState) => state.doctor,
        (state: selectState) => state.assistant.AssistantList,
        (item, doctor, assistants) => ({
            adminPatient: item.adminPatient,
            adminProfile: doctor.adminProfile,
            assistants,
            loading: item.loading,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const { loading, adminPatient, adminProfile, assistants, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage!=="") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    const isActiveTab = (tabPath: string) => {
        return location.pathname === tabPath;
    };

    React.useEffect(() => {
        if (id) { // Agrega una condición para asegurarte de que 'id' esté definido
            dispatch(getAdminPatientFilesThunk(id));
        }
    }, [id]); // Solo dispara la acción cuando 'id' cambie

    function getIconClass(fileUrl: string): string {
        const extension = fileUrl.split('.').pop(); // Obtener la extensión del archivo
        switch (extension) {
            case 'pdf':
                return 'mdi mdi-file-pdf font-size-16 align-middle text-danger me-2';
            case 'jpg':
            case 'jpeg':
            case 'png':
                return 'mdi mdi-file-image font-size-16 align-middle text-primary me-2';
            case 'mp4':
                return 'mdi mdi-file-video font-size-16 align-middle text-success me-2';
            default:
                return 'mdi mdi-file font-size-16 align-middle text-muted me-2';
        }
    }

    const openFileInNewTab = (fileUrl: string) => {
        window.open(fileUrl, '_blank');
    };

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);


    const handleDeletePatient = () => {
        if (deleteId !== null) {
            dispatch(deletePatientFile(deleteId.toString()))
                .then(() => {
                    dispatch(getAdminPatientFilesThunk(id));  // Recarga la lista de archivos después de eliminar
                })
                .catch(error => {
                    console.error('Error al eliminar el archivo:', error);
                });
        }
        setDeleteModal(false);
    };

    const calculatePosition = (element) => {
        const rect = element.getBoundingClientRect();
        return {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX
        };
    };

    React.useEffect(() => {
        dispatch(getAdminProfileThunk());
    }, []);

        React.useEffect(() => {
            dispatch(getAdminAssistantsThunk());
        }, []);

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletePatient}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            {loading ? (
                                <Spinners/>
                            ) : (
                                <Card>
                                    <AdminTabs id={id as string} isActiveTab={isActiveTab} />
                                    <div className="mt-4">
                                        <div className="d-flex flex-wrap">
                                            <h5 className="font-size-16 me-3" style={{marginLeft: '20px'}}>Archivos del
                                                paciente {adminPatient ? (`${adminPatient.first_name} ${adminPatient.last_name}`) : ""}</h5>
                                            <div className="ms-auto d-flex align-items-center" style={{ marginRight: '20px' }}>
                                                <Button
                                                    type="button"
                                                    color="success"
                                                    className="btn-md btn-rounded"
                                                    onClick={() => navigate(`/admin/patients/file/add/${id}`)}
                                                >
                                                    + Agregar archivo
                                                </Button>
                                            </div>
                                        </div>
                                        <hr className="mt-2"/>

                                        <div className="table-responsive">
                                            <Table className="table align-middle table-nowrap table-hover mb-0">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Subido por</th>
                                                    <th scope="col">Fecha</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {adminPatient.patient_files?.map((item: PatientFile, key: number) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <Link to="#" className="text-dark fw-medium"
                                                                  onClick={() => openFileInNewTab(item.file)}>
                                                                <i className={getIconClass(item.file)}></i> {item.file.split('/').pop()}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            {item.assistant_id && item.assistant_id !== 0 ? (
                                                                (() => {
                                                                    const assistant = assistants.find(ass => ass.id === item.assistant_id);
                                                                    return assistant ?
                                                                        `${assistant.first_name} ${assistant.last_name}` :
                                                                        `${adminProfile.first_name} ${adminProfile.last_name}`;
                                                                })()
                                                            ) : (
                                                                `${adminProfile.first_name} ${adminProfile.last_name}`
                                                            )}
                                                        </td>
                                                        <td>{new Intl.DateTimeFormat('es-ES', {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit'
                                                        }).format(new Date(item.created_at))}</td>
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn-sm btn-rounded me-2"
                                                                onClick={() => openFileInNewTab(item.file)}
                                                            >
                                                                Abrir
                                                            </Button>
                                                            <Button
                                                                type="button"
                                                                color="danger"
                                                                className="btn-sm btn-rounded"
                                                                onClick={() => {
                                                                    setDeleteId(item.id);
                                                                    setDeleteModal(true);
                                                                }}
                                                            >
                                                                Eliminar
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AdminPatientFiles;

import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody } from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"

import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {isEmpty} from "lodash";
import {Doctor} from "../../entities/types";
import {adminProfileUpdateThunk, cleanErrors, getAdminProfileThunk} from "../../slices/profile/thunk";

interface selectState {
    doctor: {
        loading: false;
        adminProfile: Doctor;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminProfileEdit = (props: any) => {
    const dispatch: any = useDispatch();

    //meta title
    document.title = "Editar Perfil";

    const selectProperties = createSelector(
        (state: selectState) => state.doctor,
        (item) => ({
            adminProfile: item.adminProfile,
            loading: item.loading,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {adminProfile, loading, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");


    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        dispatch(getAdminProfileThunk());
    }, [dispatch]);


    React.useEffect(() => {
        if (!isEmpty(adminProfile)) {
            console.log("adminProfile", adminProfile)
            const selectedGender = GenderOptions.find(option => option.val === adminProfile.gender);

            formik.setValues({
                academic_title: adminProfile.academic_title || '',
                first_name: adminProfile.first_name || '',
                last_name: adminProfile.last_name || '',
                specialty: adminProfile.specialty || '',
                professional_id_card: adminProfile.professional_id_card || '',
                gender: selectedGender || { val: '', label: '' },
                educations: (adminProfile.info.educations?.length > 0
                        ? adminProfile.info.educations.map(education => ({
                            title: education.title,
                            school_name: education.school_name,
                            school_logo: education.school_logo,
                            admission_date: education.admission_date,
                            graduation_date: education.graduation_date,
                        }))
                        : [{ title: '', school_name: '', school_logo: '', admission_date: '', graduation_date: '' }]
                ),
                subspecialties: (adminProfile.info.subspecialties?.length > 0
                        ? adminProfile.info.subspecialties.map(subspecialty => ({
                            subspecialty: subspecialty.subspecialty,
                        }))
                        : [{ subspecialty: '' }]
                ),
                courses: (adminProfile.info.courses?.length > 0
                        ? adminProfile.info.courses.map(course => ({
                            course: course.course,
                            start_date: course.start_date,
                            end_date: course.end_date,
                        }))
                        : [{ course: '', start_date: '', end_date: '' }]
                ),
                trainings: (adminProfile.info.trainings?.length > 0
                        ? adminProfile.info.trainings.map(training => ({
                            training: training.training,
                        }))
                        : [{ training: ''}]
                ),
            });
        }

        if (reducerToastMessage!=="") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage, adminProfile, dispatch]);

    // Contantes para mostrar en las opciones de un box
    const GenderOptions = [
        { val: 'male', label: 'Masculino' },
        { val: 'female', label: 'Femenino' },
    ]

    //Basic Information
    const formik: any = useFormik({
        initialValues: {
            academic_title: '',
            first_name: '',
            last_name: '',
            specialty: '',
            professional_id_card: '',
            gender: { val: '', label: '' },
            educations: [{ title: '', school_name: '', school_logo: '', admission_date: '', graduation_date: ''  }],
            subspecialties: [{ subspecialty: ''}],
            courses: [{ course: '', start_date: '', end_date: ''}],
            trainings: [{ training: ''}],

        },
        validationSchema: yup.object().shape({
            first_name: yup.string().required('El campo es requerido'),
            last_name: yup.string().required('El campo es requerido'),
        }),
        onSubmit: (values: any) => {
            console.log('doctor update', values);
            dispatch(adminProfileUpdateThunk(values, props.router.navigate));
        },
    });


    const handleEducationChange = (e, index, field) => {
        console.log("handleEducationChange e", e);
        const { value } = e.target;
        console.log("handleEducationChange value", value);
        const newEducations = formik.values.educations.map((education, i) =>
            i === index ? { ...education, [field]: value } : education
        );
        formik.setFieldValue('educations', newEducations);
    };

    const addEducation = () => {
        const newEducations = [...formik.values.educations, { academic_title: '' }];
        formik.setFieldValue('educations', newEducations);
    };

    const removeEducation = index => {
        const educations = formik.values.educations;

        if (educations.length === 1) {
            formik.setFieldValue('educations', []);
            return;
        }

        const newEducations = educations.filter((_, i) => i !== index);
        formik.setFieldValue('educations', newEducations);
    };

    const handleSubspecialtyChange = (e, index, field) => {
        console.log("handleSubspecialtyChange e", e);
        const { value } = e.target;
        console.log("handleSubspecialtyChange value", value);
        const newSubspecialities = formik.values.subspecialties.map((subspecialty, i) =>
            i === index ? { ...subspecialty, [field]: value } : subspecialty
        );
        formik.setFieldValue('subspecialties', newSubspecialities);
    };

    const addSubspecialty = () => {
        const newsubspecialties = [...formik.values.subspecialties, { subspecialty: '' }];
        formik.setFieldValue('subspecialties', newsubspecialties);
    };

    const removeSubspecialty = index => {
        const subspecialties = formik.values.subspecialties;

        if (subspecialties.length === 1) {
            formik.setFieldValue('subspecialties', []);
            return;
        }

        const newsubspecialties = subspecialties.filter((_, i) => i !== index);
        formik.setFieldValue('subspecialties', newsubspecialties);
    };


    const handleCourseChange = (e, index, field) => {
        console.log("handleCourseChange e", e);
        const { value } = e.target;
        console.log("handleCourseChange value", value);
        const newCourses = formik.values.courses.map((course, i) =>
            i === index ? { ...course, [field]: value } : course
        );
        formik.setFieldValue('courses', newCourses);
    };

    const addCourse = () => {
        const newCourses = [...formik.values.courses, { academic_title: '' }];
        formik.setFieldValue('courses', newCourses);
    };

    const removeCourse = index => {
        const courses = formik.values.courses;

        if (courses.length === 1) {
            formik.setFieldValue('courses', []);
            return;
        }

        const newCourses = courses.filter((_, i) => i !== index);
        formik.setFieldValue('courses', newCourses);
    };


    const handleTrainingChange = (e, index, field) => {
        console.log("handleTrainingChange e", e);
        const { value } = e.target;
        console.log("handleTrainingChange value", value);
        const newTrainings = formik.values.trainings.map((training, i) =>
            i === index ? { ...training, [field]: value } : training
        );
        formik.setFieldValue('trainings', newTrainings);
    };

    const addTraining = () => {
        const newTrainings = [...formik.values.trainings, { academic_title: '' }];
        formik.setFieldValue('trainings', newTrainings);
    };

    const removeTraining = index => {
        const trainings = formik.values.trainings;

        if (trainings.length === 1) {
            formik.setFieldValue('trainings', []);
            return;
        }

        const newTrainings = trainings.filter((_, i) => i !== index);
        formik.setFieldValue('trainings', newTrainings);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row >
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h4">Información de perfil
                                    </CardTitle>
                                    <p className="card-title-desc">Ingresa tu información.</p>
                                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="academic_title">Título académico</Label>
                                                    <Input
                                                        id="academic_title"
                                                        name="academic_title"
                                                        type="text"
                                                        placeholder="Título académico (Dr, Dra)"
                                                        value={formik.values.academic_title}
                                                        onChange={formik.handleChange}
                                                        invalid={formik.touched.academic_title && formik.errors.academic_title ? true : false}
                                                    />
                                                    {formik.errors.academic_title && formik.touched.academic_title ? (
                                                        <FormFeedback type="invalid">{formik.errors.academic_title}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="first_name">Nombre</Label>
                                                    <Input
                                                        id="first_name"
                                                        name="first_name"
                                                        type="text"
                                                        placeholder="Nombre"
                                                        value={formik.values.first_name}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.first_name && formik.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {formik.errors.first_name && formik.touched.first_name ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.first_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="last_name"> Apellidos </Label>
                                                    <Input
                                                        id="last_name"
                                                        name="last_name"
                                                        type="text"
                                                        placeholder="Apellidos"
                                                        value={formik.values.last_name}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.last_name && formik.errors.last_name ? true : false
                                                        }
                                                    />
                                                    {formik.errors.last_name && formik.touched.last_name ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.last_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="specialty">Especialidad</Label>
                                                    <Input
                                                        id="specialty"
                                                        name="specialty"
                                                        type="text"
                                                        placeholder="Especialidad"
                                                        value={formik.values.specialty}
                                                        onChange={formik.handleChange}
                                                        invalid={formik.touched.specialty && formik.errors.specialty ? true : false}
                                                    />
                                                    {formik.errors.specialty && formik.touched.specialty ? (
                                                        <FormFeedback type="invalid">{formik.errors.specialty}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="professional_id_card">Cédula Profesional</Label>
                                                    <Input
                                                        id="professional_id_card"
                                                        name="professional_id_card"
                                                        type="text"
                                                        placeholder="Cédula Profesional"
                                                        value={formik.values.professional_id_card}
                                                        onChange={formik.handleChange}
                                                        invalid={formik.touched.professional_id_card && formik.errors.professional_id_card ? true : false}
                                                    />
                                                    {formik.errors.professional_id_card && formik.touched.professional_id_card ? (
                                                        <FormFeedback type="invalid">{formik.errors.professional_id_card}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <div className="control-label"
                                                         style={{marginBottom: "0.5rem"}}>Género
                                                    </div>
                                                    <Select
                                                        id="gender"
                                                        name="gender"
                                                        options={GenderOptions}
                                                        className="select2"
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue('gender', selectedOption);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.gender}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <CardTitle tag="h4" className="mt-5">Estudios
                                        </CardTitle>
                                        <Row>
                                            <Col sm="12">
                                                <div className="mb-3 mt-3">

                                                        {formik.values.educations?.map((education, index) => (
                                                        <div key={index} className="mb-3 d-flex flex-column">

                                                                <Row>
                                                                    <Col sm="2" className="text-end">
                                                                        <Label htmlFor={`educations[${index}].title`}>Título adquirido</Label>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Input
                                                                            id={`education-${index}`}
                                                                            name={`educations[${index}].title`}
                                                                            type="text"
                                                                            placeholder="Título adquirido"
                                                                            value={education.title}
                                                                            onChange={e => handleEducationChange(e, index, 'title')}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="2" className="text-end">
                                                                        <Label htmlFor={`educations[${index}].school_name`}>Nombre de la Escuela</Label>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Input
                                                                            id={`education-${index}`}
                                                                            name={`educations[${index}].school_name`}
                                                                            type="text"
                                                                            placeholder="Nombre de la Escuela"
                                                                            value={education.school_name}
                                                                            onChange={e => handleEducationChange(e, index, 'school_name')}
                                                                            className="flex-grow-1"
                                                                            style={{marginRight: 10}}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="2" className="text-end">
                                                                        <Label htmlFor={`educations[${index}].admission_date`}>Fecha de adminisión</Label>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                    <Input
                                                                        id={`education-${index}`}
                                                                        name={`educations[${index}].admission_date`}
                                                                        type="text"
                                                                        placeholder="Fecha de adminisión"
                                                                        value={education.admission_date}
                                                                        onChange={e => handleEducationChange(e, index, 'admission_date')}
                                                                        className="flex-grow-1"
                                                                        style={{marginRight: 10}}
                                                                    />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="2" className="text-end">
                                                                        <Label htmlFor={`educations[${index}].graduation_date`}>Fecha de graduación</Label>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Input
                                                                            id={`education-${index}`}
                                                                            name={`educations[${index}].graduation_date`}
                                                                            type="text"
                                                                            placeholder="Fecha de graduación"
                                                                            value={education.graduation_date}
                                                                            onChange={e => handleEducationChange(e, index, 'graduation_date')}
                                                                            className="flex-grow-1"
                                                                            style={{marginRight: 10}}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="2" className="text-end">
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Button type="button"
                                                                        onClick={() => removeEducation(index)}>Eliminar</Button>
                                                                    </Col>
                                                                </Row>
                                                            {formik.errors[`educations[${index}].education`] && (
                                                                <div className="text-danger">Campo requerido*</div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="light" className="btn-rounded mt-3" onClick={addEducation}>
                                            Agregar otro Estudio
                                        </Button>

                                        <CardTitle tag="h4" className="mt-5">Subespecialidades
                                        </CardTitle>
                                        <Row>
                                            <Col sm="12">
                                                <div className="mb-3 mt-3">
                                                        {formik.values.subspecialties?.map((subspecialty, index) => (
                                                        <div key={index} className="mb-3 d-flex flex-column">

                                                                <Row>
                                                                    <Col sm="2" className="text-end">
                                                                        <Label htmlFor={`subspecialties[${index}].subspecialty`}>Subespecialidad</Label>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Input
                                                                            id={`subspecialty-${index}`}
                                                                            name={`subspecialties[${index}].subspecialty`}
                                                                            type="text"
                                                                            placeholder="Subespecialidad"
                                                                            value={subspecialty.subspecialty}
                                                                            onChange={e => handleSubspecialtyChange(e, index, 'subspecialty')}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col sm="2" className="text-end">
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <Button type="button"
                                                                        onClick={() => removeSubspecialty(index)}>Eliminar</Button>
                                                                    </Col>
                                                                </Row>
                                                            {formik.errors[`subspecialties[${index}].subspecialty`] && (
                                                                <div className="text-danger">Campo requerido*</div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="light" className="btn-rounded mt-3" onClick={addSubspecialty}>
                                            Agregar otra Subespecialidad
                                        </Button>

                                        <CardTitle tag="h4" className="mt-5">Cursos
                                        </CardTitle>
                                        <Row>
                                            <Col sm="12">
                                                <div className="mb-3 mt-3">

                                                    {formik.values.courses?.map((course, index) => (
                                                        <div key={index} className="mb-3 d-flex flex-column">

                                                            <Row>
                                                                <Col sm="2" className="text-end">
                                                                    <Label htmlFor={`courses[${index}].course`}>Curso</Label>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Input
                                                                        id={`course-${index}`}
                                                                        name={`courses[${index}].course`}
                                                                        type="text"
                                                                        placeholder="Curso"
                                                                        value={course.course}
                                                                        onChange={e => handleCourseChange(e, index, 'course')}
                                                                        className="flex-grow-1"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="2" className="text-end">
                                                                    <Label htmlFor={`courses[${index}].school_name`}>Fecha de inicio</Label>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Input
                                                                        id={`course-${index}`}
                                                                        name={`courses[${index}].start_date`}
                                                                        type="text"
                                                                        placeholder="Fecha de inicio"
                                                                        value={course.start_date}
                                                                        onChange={e => handleCourseChange(e, index, 'start_date')}
                                                                        className="flex-grow-1"
                                                                        style={{marginRight: 10}}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="2" className="text-end">
                                                                    <Label htmlFor={`courses[${index}].school_logo`}>Fecha de finalización</Label>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Input
                                                                        id={`course-${index}`}
                                                                        name={`courses[${index}].end_date`}
                                                                        type="text"
                                                                        placeholder="Fecha de finalización"
                                                                        value={course.end_date}
                                                                        onChange={e => handleCourseChange(e, index, 'end_date')}
                                                                        className="flex-grow-1"
                                                                        style={{marginRight: 10}}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="2" className="text-end">
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Button type="button"
                                                                            onClick={() => removeCourse(index)}>Eliminar</Button>
                                                                </Col>
                                                            </Row>
                                                            {formik.errors[`courses[${index}].course`] && (
                                                                <div className="text-danger">Campo requerido*</div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="light" className="btn-rounded mt-3" onClick={addCourse}>
                                            Agregar otro Curso
                                        </Button>

                                        <CardTitle tag="h4" className="mt-5">Entrenamientos
                                        </CardTitle>
                                        <Row>
                                            <Col sm="12">
                                                <div className="mb-3 mt-3">

                                                    {formik.values.trainings?.map((training, index) => (
                                                        <div key={index} className="mb-3 d-flex flex-column">

                                                            <Row>
                                                                <Col sm="2" className="text-end">
                                                                    <Label htmlFor={`trainings[${index}].training`}>Entrenamiento</Label>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Input
                                                                        id={`training-${index}`}
                                                                        name={`trainings[${index}].training`}
                                                                        type="text"
                                                                        placeholder="Entrenamiento"
                                                                        value={training.training}
                                                                        onChange={e => handleTrainingChange(e, index, 'training')}
                                                                        className="flex-grow-1"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="2" className="text-end">
                                                                </Col>
                                                                <Col sm="6">
                                                                    <Button type="button"
                                                                            onClick={() => removeTraining(index)}>Eliminar</Button>
                                                                </Col>
                                                            </Row>
                                                            {formik.errors[`trainings[${index}].training`] && (
                                                                <div className="text-danger">Campo requerido*</div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="light" className="btn-rounded mt-3" onClick={addTraining}>
                                            Agregar otro Entrenamiento
                                        </Button>

                                        {
                                            loading ? <Spinners/>
                                                :
                                                <div className="d-flex flex-wrap justify-content-end gap-2">
                                                    <Button type="submit" color="primary"> Guardar </Button>
                                                    <div className="position-fixed top-0 end-0 p-3"
                                                         style={{zIndex: "1005"}}>
                                                        <Toast isOpen={toast}>
                                                            <ToastHeader toggle={closeToast}>
                                                                {toastTitle}
                                                            </ToastHeader>
                                                            <ToastBody>
                                                                {toastMessage}
                                                            </ToastBody>
                                                        </Toast>
                                                    </div>
                                                </div>
                                        }

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminProfileEdit)
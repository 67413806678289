import {
    apiError, loadingTrue, registerUserSuccessful, resetRegisterFlag
} from "./reducer";

import {APIClient} from "../../../helpers/api_helper";

const api = new APIClient();


export const registerUserThunk = (user: any, history: any) => async (dispatch: any) => {
    console.log("in registerUserThunk, user", user);
    dispatch(loadingTrue());
    try {
        const response = await api.Post(`/doctor/register`, {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            password: user.password,
        });

        console.log("loginUserThunk response", response);

        localStorage.setItem("token", response.token);
        localStorage.setItem("user", response.user);
        dispatch(registerUserSuccessful(response.token));
        history('/admin');
    } catch (error) {
        console.error("Error in consultingOfficeCreateThunk:", error);
        dispatch(apiError(error));
    }
}

export const resetRegisterMsgFlagThunk = () => {
    const response = resetRegisterFlag();
    return response;
};
import {
    loadingTrue,
    apiError,
    cleanApiError,
    appointmentCreateSuccess,
    adminCalendarEventsSuccess,
    adminSearchPatientsSuccess, adminDeleteEventSuccess, adminConsultingOfficesSuccess
}
    from "./reducer";


import {APIClient} from "../../helpers/api_helper";

const api = new APIClient();
export const cleanErrors = () => {
    return cleanApiError();
}
export const getEvents = (from: string, to: string, timezone: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/consultations?from=${from}&to=${to}&timezone=${timezone}`, null);
        console.log("without error, getEvents response", response);
        dispatch(adminCalendarEventsSuccess(response.calendar_events));
    } catch (error) {
        console.error("Error in getEvents:", error);
        dispatch(apiError(error));
    }
}
export const searchPatients = (name: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/patient/search?name=${name}`, null);
        console.log("without error, searchUsers response", response);
        dispatch(adminSearchPatientsSuccess(response.patients));
    } catch (error) {
        console.error("Error in searchUsers:", error);
        dispatch(apiError(error));
    }
}

export const deleteEvent = (id: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Delete(`/doctor/consultation/${id}`, null);
        dispatch(adminDeleteEventSuccess(response));
    } catch (error) {
        console.error("Error in deleteEvent:", error);
        dispatch(apiError(error));
    }
}

export const updateEvent = (user: any) => async (dispatch: any) => {
    console.log("in updateEventThunk, user", user);
    dispatch(loadingTrue());

    try {
        const response = await api.Patch(`/doctor/consultation`, {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            birth_place: user.birth_place,
            state: user.state,
            address: user.address,
        });

        console.log("without error, updateEvent response", response);
        dispatch(appointmentCreateSuccess());
    } catch (error) {
        console.error("Error in updateEvent:", error);
        dispatch(apiError(error));
    }
}
export const addNewEvent = (event: any, history: any) => async (dispatch: any) => {
    console.log("in addNewEventThunk, event", event);
    dispatch(loadingTrue());
    try {
        const response = await api.Post(`/doctor/consultation`, {
            patient_id: event.patient_id,
            date: event.event_date,
            duration: event.duration,
            consulting_office_id: event.consulting_office_id,
            assistant_id: event.assistant_id,
            force: event.force,
        });

        console.log("without error, addNewEvent response", response);
        dispatch(appointmentCreateSuccess());
        if (event.force) {
            history(`/admin/patients/consultation/edit/${response.consultation.id}`);
        } else {
            history(`/admin`);
        }
    } catch (error) {
        console.error("Error in addNewEvent:", error);
        dispatch(apiError(error));
    }
}

export const getAdminConsultingOfficesThunk = () => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/consulting-offices`, null);
        console.log("without error, response", response);
        console.log("without error, response.consulting_offices", response.consulting_offices);
        dispatch(adminConsultingOfficesSuccess(response.consulting_offices));
    } catch (error) {
        console.error("Error in getAdminConsultingOfficesThunk:", error);
        dispatch(apiError(error));
    }
}


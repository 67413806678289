import {Link, useLocation, useParams} from "react-router-dom";
import React, {useMemo} from "react";
import withRouter from "Components/Common/withRouter";

interface AdminTabsProps {
    id: string;
    isActiveTab: (tabPath: string) => boolean;
}

const usePatientId = () => {
    const { id } = useParams();
    const location = useLocation();

    const patientId = useMemo(() => {
        if (location.pathname.includes('/admin/patient/consultation/') ||
            location.pathname.includes('/admin/patients/consultation/edit/')) {
            const pathSegments = location.pathname.split('/');
            return pathSegments[pathSegments.length - 1];
        }
        return id;
    }, [id, location.pathname]);

    return patientId;
};

const AdminTabs: React.FC<AdminTabsProps> = ({ id, isActiveTab }) => {

    const patientId = usePatientId();

    return (
        <div className="tabs">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link
                        to={`/admin/patients/${patientId}`}
                        className={`nav-link ${isActiveTab(`/admin/patients/${patientId}`) ? "active" : ""}`}
                        style={isActiveTab(`/admin/patients/${patientId}`) ? { color: 'blue' } : {}}
                    >
                        Perfil
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        to={`/admin/patients/medical-history/${patientId}`}
                        className={`nav-link ${isActiveTab(`/admin/patients/medical-history/${patientId}`) ? "active" : ""}`}
                        style={isActiveTab(`/admin/patients/medical-history/${patientId}`) ? { color: 'blue' } : {}}
                    >
                        Antecedentes
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        to={`/admin/patient/consultations/${patientId}`}
                        className={`nav-link ${isActiveTab(`/admin/patient/consultations/${patientId}`) ? "active" : ""}`}
                        style={isActiveTab(`/admin/patient/consultations/${patientId}`) ? { color: 'blue' } : {}}
                    >
                        Consultas
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        to={`/admin/patient/files/${patientId}`}
                        className={`nav-link ${isActiveTab(`/admin/patient/files/${patientId}`) ? "active" : ""}`}
                        style={isActiveTab(`/admin/patient/files/${patientId}`) ? { color: 'blue' } : {}}
                    >
                        Laboratorio y gabinete
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default withRouter(AdminTabs)
import React, {useMemo, useEffect, useState} from "react";
import withRouter from "../../Components/Common/withRouter";

import { Button, Card, CardBody, CardTitle } from "reactstrap";

import TableContainer from "../../Components/Common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Assistant } from "../../entities/types";
import { useNavigate } from "react-router-dom";
import { deleteAssistant, getAdminAssistantsThunk } from "../../slices/assistant/thunk";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "../Page_Admin_Agenda/DeleteModal";
import {getAdminPatientsThunk} from "../../slices/admin/thunk";
import {deleteOffice} from "../../slices/consulting_office/thunk";

interface selectState {
    assistant: {
        AssistantList: Assistant[];
        loading: true;
        message: "";
        error: false;
    };
};

const AssistantList: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const selectProperties = createSelector(
        (state: selectState) => state.assistant,
        (item) => ({
            assistantList: item.AssistantList,
            loading: item.loading,
            error: item.error,
            message: item.message,
        })
    );

    const {assistantList, loading} = useSelector(selectProperties);

    const columns = useMemo(
        () => [
            {
                header: "Nombre",
                accessorKey: "first_name",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/assistants/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black"
                            }}>
                                {cellProps.row.original.first_name}
                            </a>
                        </div>
                    )
                }
            },
            {
                header: "Apellidos",
                accessorKey: "last_name",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/assistants/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black"
                            }}>
                                {cellProps.row.original.last_name}
                            </a>
                        </div>
                    )
                }
            },
            {
                header: "Email",
                accessorKey: "email",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/assistants/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black",
                                overflow:"hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}>
                                {cellProps.row.original.email}
                            </a>
                        </div>
                    )
                }
            },
            {
                header: "",
                accessorKey: "actions",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const handleDeleteClick = () => {
                        setDeleteModal(true);
                        setDeleteId(cellProps.row.original.id);
                    };

                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => navigate(`/admin/assistants/${cellProps.row.original.id}`)}
                            >
                                Editar
                            </Button>
                            <span
                                style={{ fontSize: '1.5em', paddingLeft: '15px', paddingTop: '25px', cursor: 'pointer' }}
                                id="btn-delete-event"
                                onClick={handleDeleteClick}
                            >
                                <i className="bx bx-trash"></i>
                            </span>
                        </div>
                    );
                },
            },
        ],
        [navigate]
    );

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState();

    const handleDeleteAssistant = () => {
        console.log("deleteId", deleteId);
        if (deleteId) {
            dispatch(deleteAssistant(deleteId));
        }
        setDeleteModal(false);
    };

    useEffect(() => {
        dispatch(getAdminAssistantsThunk());
    }, [dispatch])

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteAssistant}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Card>
                <CardBody>
                    <CardTitle tag="h4" className="mb-4 d-flex justify-content-between align-items-center">
                        <span>Asistentes</span>
                        <Button type="button" color="success" className="btn-md btn-rounded"
                                onClick={() => navigate('/admin/new-assistant')}>
                            + Agregar asistente
                        </Button>
                    </CardTitle>
                    {
                        loading ? <Spinners/>
                            :
                            <TableContainer
                                columns={columns}
                                data={assistantList}
                                isGlobalFilter={false}
                                isPagination={false}
                                SearchPlaceholder="Search..."
                                isCustomPageSize={false}
                                isAddButton={false}
                                buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                buttonName="Add new"
                                tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                theadClass="table-light"
                            />
                    }
                </CardBody>
            </Card>

        </React.Fragment>
    );
}

export default withRouter(AssistantList);
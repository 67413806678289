import React, {useState} from 'react';
import {Col, Container, Row, Toast, ToastBody, ToastHeader} from 'reactstrap';
import PatientConsultationList from "../PatientsList/PatientConsultationList";
import {cleanErrors} from "../../slices/admin/thunk";
import {Consultation} from "../../entities/types";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";

interface selectState {
    admin: {
        patientConsultationList: Consultation[];
        loading: true;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminPatientConsultations = () => {

    document.title = "Consultas";
    const dispatch = useDispatch<any>();

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (item) => ({
            patientConsultationList: item.patientConsultationList,
            loading: item.loading,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const { reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage!=="") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);


    return (
        <React.Fragment>
            <div className="position-fixed top-0 end-0 p-3" style={{zIndex: "1005"}}>
                <Toast isOpen={toast}>
                    <ToastHeader toggle={closeToast}>
                        {toastTitle}
                    </ToastHeader>
                    <ToastBody>
                        {toastMessage}
                    </ToastBody>
                </Toast>
            </div>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <PatientConsultationList/>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default AdminPatientConsultations;

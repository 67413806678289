import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    user: "",
    reducerToastMessage: "",
    reducerToastTitle: "",
    loading: false,
    isUserLogout: false,
};

const loginSlice = createSlice({
    name: "forgotpwd",
    initialState,
    reducers: {
        pwdRecoverSuccess(state, action) {
            console.log("reducers pwdRecoverSuccess loading false");
            state.user = action.payload
            state.loading = false;
        },
        pwdResetSuccess(state, action) {
            console.log("reducers pwdResetSuccess loading false");
            state.user = action.payload
            state.loading = false;
        },
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
            state.isUserLogout = true;
            console.log("reducers apiError");
        },
        cleanErrors(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
    }
});
export const {
    pwdRecoverSuccess,
    pwdResetSuccess,
    apiError,
    loadingTrue,
} = loginSlice.actions;
export default loginSlice.reducer;
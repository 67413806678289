import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  reducerToastMessage: "",
  reducerToastTitle: "",
  loading: false,
  user: null,
  success: false,
  error: false,
  isUserLogout: true
};

const registerSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    registerUserSuccessful(state, action) {
      console.log("reducers registerUserSuccessful loading false");
      state.user = action.payload
      state.loading = false;
    },
    resetRegisterFlag(state) {
      console.log("reducers resetLoginFlag loading false");
      state.reducerToastTitle = "";
      state.reducerToastMessage = "";
      state.loading = false;
    },
    apiError(state, action) {
      state.reducerToastTitle = "Error";
      state.reducerToastMessage = action.payload;
      state.loading = false;
      state.isUserLogout = true;
      console.log("reducers apiError");
    },
    loadingTrue(state) {
      state.loading = true;
    },
  }
});

export const {
  registerUserSuccessful,
  resetRegisterFlag,
  apiError,
  loadingTrue,
} = registerSlice.actions;

export default registerSlice.reducer;
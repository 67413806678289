import {
    apiError,
    cleanApiError,
    loadingTrue,
    adminAssistantsSuccess,
    adminAssistantSuccess,
    adminEditAssistantSuccess,
    adminCreateAssistantSuccess,
    adminDeleteAssistantSuccess,
} from "./reducer";
import { APIClient } from "../../helpers/api_helper";
import {adminCreateOfficeSuccess, adminDeleteOfficeSuccess} from "../consulting_office/reducer";
import {getAdminConsultingOfficesThunk} from "../consulting_office/thunk";

const api = new APIClient();
export const cleanErrors = () => {
    return cleanApiError();
}

export const getAdminAssistantsThunk = () => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/assistants`, null);
        console.log("without error, response", response);
        console.log("without error, response.assistants", response.assistants);
        dispatch(adminAssistantsSuccess(response.assistants));
    } catch (error) {
        console.error("Error in getAdminAssistantsThunk", error);
        dispatch(apiError(error));
    }
}

export const getAdminAssistantThunk = (id: string | undefined) =>async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/assistant/${id}`, null);
        console.log("without error, response", response);
        console.log("without error, response.assistant", response.assistant);
        dispatch(adminAssistantSuccess(response.assistant));
    } catch (error) {
        console.error("Error in getAdminAssistantThunk:", error);
        dispatch(apiError(error));
    }
}

export const assistantUpdateThunk = (assistant: any, history: any) => async (dispatch: any) => {
    console.log("in assistantUpdateThunk, user", assistant);
    dispatch(loadingTrue());
    try {
        const response = await api.Put(`/doctor/assistant`, {
            id: assistant.id,
            first_name: assistant.first_name,
            last_name: assistant.last_name,
            email: assistant.email,
        });

        console.log("without error, assistantUpdateThunk response", response);
        dispatch(adminEditAssistantSuccess(response));
        history('/admin/assistants');
    } catch (error) {
        console.error("Error in adminEditAssistantSuccess:", error);
        dispatch(apiError(error));
    }
}

export const assistantCreateThunk = (assistant: any, history: any) => async (dispatch: any) => {
    console.log("in assistantCreateThunk, user", assistant);
    dispatch(loadingTrue());
    try {
        const response = await api.Post(`/doctor/assistant`, {
            first_name: assistant.first_name,
            last_name: assistant.last_name,
            email: assistant.email,
        });

        console.log("without error, assistantCreateThunk response", response);
        dispatch(adminCreateAssistantSuccess());
        history('/admin/assistants');
    } catch (error) {
        console.error("Error in assistantCreateThunk:", error);
        dispatch(apiError(error));
    }
}

export const deleteAssistant = (id: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Delete(`/doctor/assistant/${id}`, null);
        dispatch(adminDeleteAssistantSuccess(response));
        dispatch(getAdminAssistantsThunk());
    } catch (error) {
        console.error("Error in deleteEvent:", error);
        dispatch(apiError(error));
    }
}
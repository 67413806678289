import {
    adminPatientsSuccess,
    loadingTrue,
    apiError,
    cleanApiError,
    patientCreateSuccess,
    adminPatientConsultationsSuccess,
    adminPatientMedicalHistorySuccess,
    adminSearchPatientsSuccess,
    adminPatientSuccess,
    adminPatientFilesSuccess,
    patientEditSuccess,
    patientMedicalHistoryEditSuccess,
    adminDeletePatientSuccess,
    adminDeletePatientFileSuccess,
} from "./reducer";
import { APIClient } from "../../helpers/api_helper";
import {adminDeleteAssistantSuccess} from "../assistant/reducer";
import {getAdminAssistantsThunk} from "../assistant/thunk";
import {
    Alcohol,
    Allergy,
    ConsultationPrescription,
    ConsultationPrescriptionNumb, CustomizedQuestion,
    Doctor,
    Drug,
    EmergencyContact,
    FamilyPathology,
    FamilyPathologyMember,
    FemaleBarrierMethod, FemaleCytology,
    FemaleInjectableMethod,
    FemaleIntrauterineMethod, FemaleMammogram, FemaleMultiplePregnancy,
    FemaleNaturalMethod,
    FemaleOralMethod,
    FemaleOtherMethod, FemalePregnancy,
    FemaleSubdermalMethod,
    FemaleSurgicalMethod,
    GeneralInfo,
    HistoryInfo,
    Identification,
    Immunization,
    Info,
    InsuranceOrPlan,
    Pathology,
    PathologyTreatment,
    Patient,
    Phone,
    PhysicalActivity,
    Subspecialty,
    Surgery
} from "../../entities/types";
import {getAdminConsultingOfficesThunk} from "../consulting_office/thunk";
import {loadingFalse} from "../config_recipe/reducer";

const api = new APIClient();
export const cleanErrors = () => {
    return cleanApiError();
}

const defaultInfo: GeneralInfo = {
    phones: [],
    identifications: [],
    insurance_or_plans: [],
    emergency_contacts: [],
};

const historyInfo: HistoryInfo = {
    medical_history: {
        family_pathology_present: '',
        family_pathology_notes: '',
        pathology_present: '',
        pathology_notes: '',
        allergy_present: '',
        allergy_notes: '',
        surgery_present: '',
        surgery_notes: '',
        immunization_present: '',
        up_to_date_immunization: false,
        immunization_notes: '',
        tobacco_present: '',
        tobacco_suspension_time: '',
        cigarettes_per_day: 0,
        smoking_years: 0,
        smoking_index: 0,
        tobacco_notes: '',
        alcohol_present: '',
        alcohol_suspension_time: '',
        alcohol_consumed_years: '',
        alcohol_notes: '',
        drug_present: '',
        drug_notes: '',
        physical_activity_present: '',
        physical_activity_notes: '',
        contraceptive_present: '',
        intrauterine: false,
        injectable: false,
        barrier_method: false,
        natural_method: false,
        surgical_method: false,
        oral: false,
        subdermal: false,
        other: false,
        method_notes: '',
        menstrual_cycle_present: '',
        cycle_frequency: '',
        dysmenorrhea_present: '',
        menstrual_cycle_notes: '',
        pregnancy_present: '',
        pregnancies: 0,
        births: 0,
        abortions: 0,
        deaths: 0,
        cesarean: 0,
        alive: 0,
        dead: 0,
        pregnancy_notes: '',
        cytology_mammogram_present: '',
        cytology_mammogram_notes: '',
        general_observations: '',
    },
    family_pathologies: [],
    pathologies: [],
    allergies: [],
    surgeries: [],
    immunizations: [],
    alcohols: [],
    drugs: [],
    physical_activities: [],
    intrauterines: [],
    injectables: [],
    barriers: [],
    naturals: [],
    surgicals: [],
    orals: [],
    subdermals: [],
    others: [],
    pregnancies: [],
    cytologies: [],
    mammograms: [],
    questions: [],
};

const mapToGeneralPatient = (data: any): Patient => {
    return {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        birthday: data.birthday,
        gender: data.gender,
        birth_place: data.birth_place,
        ethnic_group: data.ethnic_group,
        marital_status: data.marital_status,
        blood_type: data.blood_type,
        state: data.state,
        city: data.city,
        address: data.address,
        occupation: data.occupation,
        private_note: data.private_note,
        socioeconomic_status: data.socioeconomic_status,
        housing: data.housing,
        disability: data.disability,
        religion: data.religion,
        next_consultation: data.next_consultation,
        general_info: data.general_info || defaultInfo,
        history_info: data.history_info || historyInfo,
        patient_phones: data.patient_phones || [],
        patient_identifications: data.patient_identifications || [],
        patient_insurance_or_plans: data.patient_insurance_or_plans || [],
        patient_emergency_contacts: data.patient_emergency_contacts || [],
        patient_medical_history: data.patient_medical_history || [],
        patient_family_pathologies: data.patient_family_pathologies || [],
        patient_pathologies: data.patient_pathologies || [],
        patient_allergies: data.patient_allergies || [],
        patient_surgeries: data.patient_surgeries || [],
        patient_immunizations: data.patient_immunizations || [],
        patient_alcohols: data.patient_alcohols || [],
        patient_drugs: data.patient_drugs || [],
        patient_physical_activities: data.patient_physical_activities || [],
        patient_female_intrauterine_methods: data.patient_female_intrauterine_methods || [],
        patient_female_injectable_methods: data.patient_female_injectable_methods || [],
        patient_female_barrier_methods: data.patient_female_barrier_methods || [],
        patient_female_natural_methods: data.patient_female_natural_methods || [],
        patient_female_surgical_methods: data.patient_female_surgical_methods || [],
        patient_female_oral_methods: data.patient_female_oral_methods || [],
        patient_female_subdermal_methods: data.patient_female_subdermal_methods || [],
        patient_female_pregnancies: data.patient_female_pregnancies || [],
        patient_female_cytologies: data.patient_female_cytologies || [],
        patient_female_mammograms: data.patient_female_mammograms || [],
        patient_customized_questions: data.patient_customized_questions || [],
        patient_files: data.patient_files || [],
    };
};

export const getAdminPatientsThunk = () =>async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/patients`, null);
        console.log("without error, response", response);
        console.log("without error, response.patients", response.patients);
        dispatch(adminPatientsSuccess(response.patients));
    } catch (error) {
        console.error("Error in patientCreateThunk:", error);
        dispatch(apiError(error));
    }
}

export const getAdminPatientThunk = (id: string | undefined) =>async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/patient/${id}`, null);
        console.log("without error, response", response);
        console.log("without error, response.patient", response.patient);
        const patient: Patient = mapToGeneralPatient(response.patient);
        dispatch(adminPatientSuccess(patient));
    } catch (error) {
        console.error("Error in getAdminPatientThunk:", error);
        dispatch(apiError(error));
    }
}

export const getAdminPatientConsultationsThunk = (id: string | undefined, page?: number) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/consultation/history/${id}?page=${page || 1}`, null);
        console.log("Response from backend:", response);

        // Verifica la estructura de la respuesta y asegúrate de acceder correctamente a los datos
        const consultations = response?.consultations || []; // Acceder al array de consultas, asegurándote de manejar si es nulo o undefined

        dispatch(adminPatientConsultationsSuccess(consultations));
    } catch (error) {
        console.error("Error in getAdminPatientConsultationThunk:", error);
        dispatch(apiError(error));
    }
};

export const getAdminPatientFilesThunk = (id: string | undefined) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/patient/files/${id}`, null);
        console.log("Response from getAdminPatientFilesThunk:", response);
        const patient: Patient = mapToGeneralPatient(response.patient);
        dispatch(adminPatientFilesSuccess(patient));
    } catch (error) {
        console.error("Error in getAdminPatientFilesThunk:", error);
        dispatch(apiError(error));
    }
};

export const getAdminPatientMedicalHistoryThunk = (id: string | undefined) => async (dispatch: any) => {
    dispatch(loadingTrue());
    console.log("Dispatching loading true");
    try {
        const response = await api.Get(`/doctor/patient/history/${id}`, null);
        console.log("API Response:", response);
        const patient: Patient = mapToGeneralPatient(response.patient);
        dispatch(adminPatientMedicalHistorySuccess(patient));
        console.log("Dispatching patient medical history success", response.patient);
    } catch (error) {
        console.error("Error in getAdminPatientMedicalHistoryThunk:", error);
        dispatch(apiError(error));
    }
}

export const searchPatients = (name: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/patient/search?name=${name}`, null);
        console.log("without error, searchUsers response", response);
        dispatch(adminSearchPatientsSuccess(response.patients));
    } catch (error) {
        console.error("Error in searchUsers:", error);
        dispatch(apiError(error));
    }
}

export const patientCreateThunk = (user: any, history: any) => async (dispatch: any) => {
    console.log("in patientCreateThunk, user", user);
    dispatch(loadingTrue());
    try {
        let phones = user.phones.map((phone: Phone) => {
            return {
                phone_type: phone.phone_type,
                phone: phone.phone,
            }
        });

        let identifications = user.identifications.map((identification: Identification) => {
            return {
                identification_type: identification.identification_type,
                identification: identification.identification,
            }
        });

        let insurance_or_plans = user.insurance_or_plans.map((insurance: InsuranceOrPlan) => {
            return {
                name: insurance.name,
                policy: insurance.policy,
                note: insurance.note,
            }
        });

        let emergency_contacts = user.emergency_contacts.map((contact: EmergencyContact) => {
            return {
                full_name: contact.full_name,
                relationship: contact.relationship,
                email: contact.email,
                note: contact.note,
                phones: contact.phones.map((phone: Phone) => ({
                    phone_type: phone.phone_type,
                    phone: phone.phone,
                })),
            }
        });

        const response = await api.Post(`/doctor/patient`, {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            birthday: user.birthday,
            gender: user.gender.val,
            birth_place: user.birth_place,
            ethnic_group: user.ethnic_group.val,
            marital_status: user.marital_status.val,
            occupation: user.occupation,
            blood_type: user.blood_type.val,
            private_note: user.private_note,
            state: user.state,
            address: user.address,
            email: user.email,
            city: user.city,
            socioeconomic_status: user.socioeconomic_status,
            housing: user.housing,
            disability: user.disability,
            religion: user.religion,
            phones: phones,
            identifications: identifications,
            insurance_or_plans: insurance_or_plans,
            emergency_contacts: emergency_contacts,
        });

        console.log("without error, patientCreateThunk response", JSON.stringify(response));
        dispatch(patientCreateSuccess());
        history(`/admin/patients/${response.patient.id}`);
    } catch (error) {
        console.error("Error in patientCreateThunk:", error);
        dispatch(apiError(error));
    }
}

export const patientEditThunk = (user: any, history: any) => async (dispatch: any) => {
    console.log("in patientEditThunk, user", user);
    dispatch(loadingTrue());
    try {
        let phones = user.phones.map((phone: Phone) => {
            return {
                phone_type: phone.phone_type,
                phone: phone.phone,
            }
        });

        let identifications = user.identifications.map((identification: Identification) => {
            return {
                identification_type: identification.identification_type,
                identification: identification.identification,
            }
        });

        let insurance_or_plans = user.insurance_or_plans.map((insurance: InsuranceOrPlan) => {
            return {
                name: insurance.name,
                policy: insurance.policy,
                note: insurance.note,
            }
        });

        let emergency_contacts = user.emergency_contacts.map((contact: EmergencyContact) => {
            return {
                full_name: contact.full_name,
                relationship: contact.relationship,
                email: contact.email,
                note: contact.note,
                phones: contact.phones.map((phone: Phone) => ({
                    phone_type: phone.phone_type,
                    phone: phone.phone,
                })),
            }
        });

        const response = await api.Patch(`/doctor/patient`, {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            birthday: user.birthday,
            gender: user.gender.val,
            birth_place: user.birth_place,
            ethnic_group: user.ethnic_group.val,
            marital_status: user.marital_status.val,
            occupation: user.occupation,
            blood_type: user.blood_type.val,
            private_note: user.private_note,
            state: user.state,
            address: user.address,
            email: user.email,
            city: user.city,
            socioeconomic_status: user.socioeconomic_status,
            housing: user.housing,
            disability: user.disability,
            religion: user.religion,
            phones: phones,
            identifications: identifications,
            insurance_or_plans: insurance_or_plans,
            emergency_contacts: emergency_contacts,
        });

        console.log("without error, patientEditThunk response", JSON.stringify(response));
        dispatch(patientEditSuccess());
        history(`/admin/patients/${response.patient.id}`);
    } catch (error) {
        console.error("Error in patientEditThunk:", error);
        dispatch(apiError(error));
    }
}

export const patientMedicalHistoryEditThunk = (user: any, history: any) => async (dispatch: any) => {
    console.log("in patientMedicalHistoryEditThunk, user", user);
    dispatch(loadingTrue());

    const sanitizeToInt = (val) => {
        if (val === "") {
            return null;
        }
        const myInt = parseInt(val, 10);
        return isNaN(myInt) ? null : myInt;
    };

    try {
        let medical_history = {
            family_pathology_present: user.medical_history.family_pathology_present,
            family_pathology_notes: user.medical_history.family_pathology_notes,
            pathology_present: user.medical_history.pathology_present,
            pathology_notes: user.medical_history.pathology_notes,
            allergy_present: user.medical_history.allergy_present,
            allergy_notes: user.medical_history.allergy_notes,
            surgery_present: user.medical_history.surgery_present,
            surgery_notes: user.medical_history.surgery_notes,
            immunization_present: user.medical_history.immunization_present,
            up_to_date_immunization: user.medical_history.up_to_date_immunization,
            immunization_notes: user.medical_history.immunization_notes,
            tobacco_present: user.medical_history.tobacco_present,
            tobacco_suspension_time: user.medical_history.tobacco_suspension_time,
            cigarettes_per_day: sanitizeToInt(user.medical_history.cigarettes_per_day),
            smoking_years: sanitizeToInt(user.medical_history.smoking_years),
            smoking_index: sanitizeToInt(user.medical_history.smoking_index),
            tobacco_notes: user.medical_history.tobacco_notes,
            alcohol_present: user.medical_history.alcohol_present,
            alcohol_suspension_time: user.medical_history.alcohol_suspension_time,
            alcohol_consumed_years: user.medical_history.alcohol_consumed_years,
            alcohol_notes: user.medical_history.alcohol_notes,
            drug_present: user.medical_history.drug_present,
            drug_notes: user.medical_history.drug_notes,
            physical_activity_present: user.medical_history.physical_activity_present,
            physical_activity_notes: user.medical_history.physical_activity_notes,
            contraceptive_present: user.medical_history.contraceptive_present,
            intrauterine: user.medical_history.intrauterine,
            injectable: user.medical_history.injectable,
            barrier_method: user.medical_history.barrier_method,
            natural_method: user.medical_history.natural_method,
            surgical_method: user.medical_history.surgical_method,
            oral: user.medical_history.oral,
            subdermal: user.medical_history.subdermal,
            other: user.medical_history.other,
            method_notes: user.medical_history.method_notes,
            menstrual_cycle_present: user.medical_history.menstrual_cycle_present,
            cycle_frequency: user.medical_history.cycle_frequency,
            dysmenorrhea_present: user.medical_history.dysmenorrhea_present,
            menstrual_cycle_notes: user.medical_history.menstrual_cycle_notes,
            pregnancy_present: user.medical_history.pregnancy_present,
            pregnancies: sanitizeToInt(user.medical_history.pregnancies),
            births: sanitizeToInt(user.medical_history.births),
            abortions: sanitizeToInt(user.medical_history.abortions),
            deaths: sanitizeToInt(user.medical_history.deaths),
            cesarean: sanitizeToInt(user.medical_history.cesarean),
            alive: sanitizeToInt(user.medical_history.alive),
            dead: sanitizeToInt(user.medical_history.dead),
            pregnancy_notes: user.medical_history.pregnancy_notes,
            cytology_mammogram_present: user.medical_history.cytology_mammogram_present,
            cytology_mammogram_notes: user.medical_history.cytology_mammogram_notes,
            general_observations: user.medical_history.general_observations,
        };

        let family_pathologies = user.family_pathologies.map((family: FamilyPathology) => {
            return {
                pathology: family.pathology,
                members: family.members.map((member: FamilyPathologyMember) => ({
                    member: member.member,
                })),
            }
        });

        let pathologies = user.pathologies.map((pathology: Pathology) => {
            return {
                pathology: pathology.pathology,
                treatments: pathology.treatments.map((treatment: PathologyTreatment) => ({
                    treatment: treatment.treatment,
                }))
            }
        });

        let allergies = user.allergies.map((allergy: Allergy) => {
            return {
                allergy: allergy.allergy,
                reaction: allergy.reaction,
            }
        });

        let surgeries = user.surgeries.map((surgery: Surgery) => {
            return {
                surgery: surgery.surgery,
                date: surgery.date,
                complications: surgery.complications,
            }
        });

        let alcohols = user.alcohols.map((alcohol: Alcohol) => {
            return {
                alcohol: alcohol.alcohol,
                quantity_and_frequency: alcohol.quantity_and_frequency,
            }
        });

        let immunizations = user.immunizations.map((immunization: Immunization) => {
            return {
                immunization: immunization.immunization,
                application_year: immunization.application_year,
            }
        });

        let drugs = user.drugs.map((drug: Drug) => {
            return {
                drug: drug.drug,
                dose: drug.dose,
                consumed_frequency: drug.consumed_frequency,
                consumed_years: drug.consumed_years,
                suspended: drug.suspended,
                suspension_years: drug.suspension_years,
            }
        });

        let physical_activities = user.physical_activities.map((activity: PhysicalActivity) => {
            return {
                activity: activity.activity,
                time: activity.time,
                frequency: activity.frequency,
            }
        });

        let intrauterines = user.intrauterines.map((intrauterine: FemaleIntrauterineMethod) => {
            return {
                method: intrauterine.method,
                time: intrauterine.time,
                actual_method: intrauterine.actual_method,
            }
        });

        let injectables = user.injectables.map((injectable: FemaleInjectableMethod) => {
            return {
                medicine: injectable.medicine,
                time: injectable.time,
                actual_method: injectable.actual_method,
            }
        });

        let barriers = user.barriers.map((barrier: FemaleBarrierMethod) => {
            return {
                method: barrier.method,
                time: barrier.time,
                actual_method: barrier.actual_method,
            }
        });

        let naturals = user.naturals.map((natural: FemaleNaturalMethod) => {
            return {
                method: natural.method,
                time: natural.time,
                actual_method: natural.actual_method,
            }
        });

        let surgicals = user.surgicals.map((surgical: FemaleSurgicalMethod) => {
            return {
                method: surgical.method,
                time: surgical.time,
                actual_method: surgical.actual_method,
            }
        });

        let orals = user.orals.map((oral: FemaleOralMethod) => {
            return {
                medicine: oral.medicine,
                time: oral.time,
                actual_method: oral.actual_method,
            }
        });

        let subdermals = user.subdermals.map((subdermal: FemaleSubdermalMethod) => {
            return {
                method: subdermal.method,
                time: subdermal.time,
                actual_method: subdermal.actual_method,
            }
        });

        let others = user.others.map((other: FemaleOtherMethod) => {
            return {
                method: other.method,
                time: other.time,
                actual_method: other.actual_method,
            }
        });

        let pregnancies = user.pregnancies.map((pregnancy: FemalePregnancy) => {
            return {
                due_date: pregnancy.due_date,
                labor: pregnancy.labor,
                gestational_age: pregnancy.gestational_age,
                multiple_birth: pregnancy.multiple_birth,
                pregnancy_observations: pregnancy.pregnancy_observations,
                pregnancies: pregnancy.pregnancies.map((multiple: FemaleMultiplePregnancy) => ({
                    weight: multiple.weight,
                    gender: multiple.gender,
                    birth_method: multiple.birth_method,
                    neonate: multiple.neonate,
                }))
            }
        });

        let cytologies = user.cytologies.map((cytology: FemaleCytology) => {
            return {
                date: cytology.date,
                result: cytology.result,
                observations: cytology.observations,
            }
        });

        let mammograms = user.mammograms.map((mammogram: FemaleMammogram) => {
            return {
                date: mammogram.date,
                result: mammogram.result,
                observations: mammogram.observations,
            }
        });

        let questions = user.questions.map((question: CustomizedQuestion) => {
            return {
                question: question.question,
                answer: question.answer,
            }
        });

        const response = await api.Patch(`/doctor/patient/medical-history`, {
            id: user.id,
            medical_history: medical_history,
            family_pathologies: family_pathologies,
            pathologies: pathologies,
            allergies: allergies,
            surgeries: surgeries,
            immunizations: immunizations,
            alcohols: alcohols,
            drugs: drugs,
            physical_activities: physical_activities,
            intrauterines: intrauterines,
            injectables: injectables,
            barriers: barriers,
            naturals: naturals,
            surgicals: surgicals,
            orals: orals,
            subdermals: subdermals,
            others: others,
            pregnancies: pregnancies,
            cytologies: cytologies,
            mammograms: mammograms,
            questions: questions,
        });

        console.log("without error, patientMedicalHistoryEditThunk response", JSON.stringify(response));
        dispatch(patientMedicalHistoryEditSuccess());
        history(`/admin/patients/medical-history/${response.medical_history.id}`);
    } catch (error) {
        console.error("Error in patientMedicalHistoryEditThunk:", error);
        dispatch(apiError(error));
    }
}

export const deletePatient = (id: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Delete(`/doctor/patient/${id}`, null);
        dispatch(adminDeletePatientSuccess(response));
        dispatch(getAdminPatientsThunk());
    } catch (error) {
        console.error("Error in adminDeletePatientSuccess:", error);
        dispatch(apiError(error));
    }
}

export const deletePatientFile = (id: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Delete(`/doctor/patient/delete-file/${id}`, null);
        dispatch(adminDeletePatientFileSuccess(response));
        dispatch(getAdminPatientFilesThunk(id));
    } catch (error) {
        console.error("Error in adminDeletePatientFileSuccess:", error);
        dispatch(apiError(error));
    }
}

export const addPatientFilesThunk = (files: any, patientId: string, history: any) => async (dispatch: any) => {
    console.log("in addPatientFilesThunk files", files);
    dispatch(loadingTrue());
    try {
        const formData = new FormData();
        files.forEach((file: any) => {
            formData.append('files', file, file.name); // Añadimos cada archivo
        });

        // Añadir el ID del paciente al FormData
        formData.append('id', patientId);

        const response = await api.PostFile('/doctor/patient/upload/file', formData);

        console.log("without error, addPatientFilesThunk response", response);
        dispatch(loadingFalse());
        history(`/admin/patient/files/${patientId}`);  // Usar patientId para redirigir
    } catch (error) {
        console.error("Error in addPatientFilesThunk:", error);
        dispatch(apiError(error));
    }
};
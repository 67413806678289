import {
    loadingTrue,
    apiError,
    cleanApiError,
    adminProfileSuccess, adminPlaceSuccess,
} from "./reducer";
import {APIClient} from "../../helpers/api_helper";
import {adminPatientSuccess} from "../admin/reducer";
import {adminEditOfficeSuccess} from "../consulting_office/reducer";
import {Doctor, Education, Info, PaymentIntent, Subspecialty, Training} from "../../entities/types";
import {adminConsultationSuccess} from "../consultation/reducer";

const api = new APIClient();
export const cleanErrors = () => {
    return cleanApiError();
}

const defaultInfo: Info = {
    educations: [],
    subspecialties: [],
    courses: [],
    trainings: [],
};

// Función de mapeo para convertir el objeto de la API en una instancia de Doctor
const mapToDoctor = (data: any): Doctor => {
    return {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        specialty: data.specialty,
        email: data.email,
        password: data.password,
        gender: data.gender,
        academic_title: data.academic_title,
        professional_id_card: data.professional_id_card,
        signature: data.signature,
        logo: data.logo,
        info: data.info || defaultInfo,
    };
};
// Función de mapeo para convertir el objeto de la API en una instancia de PaymentIntent
const mapToPaymentIntent = (data: any): PaymentIntent => {
    return {
        id: data.id,
        client_secret: data.client_secret,
    };
};

export const getAdminProfileThunk = () => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/profile`, null);
        console.log("without error, response", response);
        console.log("without error, response.doctor", response.doctor);
        const doctor: Doctor = mapToDoctor(response.doctor);
        dispatch(adminProfileSuccess(doctor));

    } catch (error) {
        console.error("Error in getAdminProfileThunk:", error);
        dispatch(apiError(error));
    }
}

export const adminProfileUpdateThunk = (doctor: any, history: any) => async (dispatch: any) => {
    console.log("in adminProfileUpdateThunk, user", doctor);
    dispatch(loadingTrue());
    try {

        let educations = doctor.educations.map((education: Education) => {
            return {
                title: education.title,
                school_name: education.school_name,
                school_logo: education.school_logo,
                admission_date: education.admission_date,
                graduation_date: education.graduation_date,
            }
        });
        let subspecialties = doctor.subspecialties.map((subspecialty: Subspecialty) => {
            return {
                subspecialty: subspecialty.subspecialty,
            }
        });
        let courses = doctor.courses.map((course: any) => {
            return {
                course: course.course,
                start_date: course.start_date,
                end_date: course.end_date,
            }
        });
        let trainings = doctor.trainings.map((training: Training) => {
            return {
                training: training.training,
            }
        });

        console.log("in adminProfileUpdateThunk, doctor", doctor);

        const response = await api.Patch(`/doctor/profile`, {
            id: doctor.id,
            first_name: doctor.first_name,
            last_name: doctor.last_name,
            specialty: doctor.specialty,
            professional_id_card: doctor.professional_id_card,
            academic_title: doctor.academic_title,
            signature: doctor.signature,
            gender: doctor.gender.val,
            educations: educations,
            subspecialties: subspecialties,
            courses: courses,
            trainings: trainings,
        });

        console.log("without error, adminProfileUpdateThunk response", response);
        history('/admin/profile');
    } catch (error) {
        console.error("Error in doctorCreateThunk:", error);
        dispatch(apiError(error));
    }
}

export const getAdminPlace = () => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Post(`/doctor/order/place`, {
            months: 12,
        });
        console.log("without error, response", response);
        console.log("without error, response.payment_intent", response.payment_intent);
        const doctor: Doctor = mapToDoctor(response.doctor);
        const paymentIntent: PaymentIntent = mapToPaymentIntent(response.payment_intent);
        dispatch(adminPlaceSuccess({doctor, paymentIntent}));
    } catch (error) {
        console.error("Error in getAdminPlace:", error);
        dispatch(apiError(error));
    }
}
import React, {useMemo, useEffect, useState} from "react";
import withRouter from "../../Components/Common/withRouter";

import {Button, Card, CardBody, CardTitle, Input} from "reactstrap";

import TableContainer from "../../Components/Common/TableContainer";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from 'reselect';
import {Patient, Phone} from "../../entities/types";
import {useLocation, useNavigate} from 'react-router-dom';
import {deletePatient, getAdminPatientsThunk, searchPatients} from "../../slices/admin/thunk";
import Spinners from "Components/Common/Spinner";
import {deleteOffice} from "../../slices/consulting_office/thunk";
import DeleteModal from "../Page_Admin_Agenda/DeleteModal";

// Este selectState se comunica indirectamente con el state del reducer en /slices/admin/reducer
// y esa unión se hace a través del slices/index.ts, donde une el state "admin" con el archivo del reducer
interface selectState {
    admin: {
        adminPatientList: Patient[];
        loading: true;
        message: "";
        error: false;
    };
};


const PatientList: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (item) => ({
            patientList: item.adminPatientList,
            loading: item.loading,
            error: item.error,
            message: item.message,
        })
    );

    const PhoneOptions = [
        { val: "cel", label: 'Celular' },
        { val: "home", label: 'Casa' },
        { val: "work", label: 'Trabajo' },
        { val: "other", label: 'Otro' },
    ];

    const {patientList, loading} = useSelector(selectProperties);
    const [inputValue, setInputValue] = useState<string>('');

    const columns = useMemo(
        () => [
            {
                header: "Nombre completo",
                accessorKey: "name",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const { first_name, last_name } = cellProps.row.original;
                    return (
                        <div className="flex-grow-1" style={{ width: "200px" }}>
                            <a href={`/admin/patients/${cellProps.row.original.id}`} style={{ display: "block", width: "100%", height: "100%", textDecoration: "none", color:"black", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap" }} >
                                {`${first_name} ${last_name}`}
                            </a>
                        </div>
                    );
                },
            },
            {
                header: "Contacto",
                accessorKey: "contact",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const { email, general_info } = cellProps.row.original;
                    const phones = general_info?.phones;

                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            {email && (
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <span style={{fontWeight: 'bold'}}>
                                        <i className="fas fa-envelope" style={{marginRight: '5px'}}></i> {/* Ícono de correo de FontAwesome */}
                                    </span>
                                    <a href={`/admin/patients/${cellProps.row.original.id}`}
                                       style={{textDecoration: "none", color: "black", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>
                                        {email}
                                    </a>
                                </div>
                            )}
                            {phones && phones.length > 0 && (
                                <div style={{display: 'flex', alignItems: 'center', marginTop: email ? '10px' : '0'}}>
                                    <span style={{fontWeight: 'bold'}}>
                                        <i className="fas fa-phone-alt"
                                           style={{marginRight: '5px'}}></i> {/* Ícono de teléfono de FontAwesome */}
                                    </span>
                                    <div style={{marginLeft: '5px'}}>
                                        {phones.map((phone: Phone, index: number) => (
                                            <div key={index}>
                                                <a href={`/admin/patients/${cellProps.row.original.id}`}
                                                   style={{textDecoration: "none", color: "black", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>
                                                    {phone.phone}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {!email && (!phones || phones.length === 0) && (
                                <span>-</span>
                            )}
                        </div>
                    );
                },
            },
            {
                header: "Próxima consulta",
                accessorKey: "next_consultation",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const nextConsultation = cellProps.row.original.next_consultation;
                    return (
                        <div className="flex-grow-1" style={{width: "150px"}}>
                            {nextConsultation ? (
                                <a href={`/admin/patient/consultations/${cellProps.row.original.id}`}
                                   style={{textDecoration: "none", color: "black", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>
                                    {new Date(nextConsultation).toLocaleString('es-MX', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    })}
                                </a>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    );
                },
            },
            {
                header: "",
                accessorKey: "actions",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const handleDeleteClick = () => {
                        setDeleteModal(true);
                        setDeleteId(cellProps.row.original.id);
                    };

                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => navigate(`/admin/patients/${cellProps.row.original.id}`)}
                            >
                                Ver
                            </Button>
                            &nbsp;
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => navigate(`/admin/new-appointment?patient=${cellProps.row.original.id}`)}
                            >
                                Agendar consulta
                            </Button>
                            <span
                                style={{fontSize: '1.5em', paddingLeft: '15px', paddingTop: '25px', cursor: 'pointer'}}
                                id="btn-delete-event"
                                onClick={handleDeleteClick}
                            >
                    <i className="bx bx-trash"></i>
                </span>
                        </div>
                    );
                },
            },
        ],
        [navigate]
    );

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState();

    const handleDeletePatient = () => {
        console.log("deleteId", deleteId);
        if (deleteId) {
            dispatch(deletePatient(deleteId));
        }
        setDeleteModal(false);
    };

    useEffect(() => {
        dispatch(getAdminPatientsThunk());
    }, [dispatch]);

    const handleSearch = () => {
        if (inputValue.trim() !== '') {
            dispatch(searchPatients(inputValue.trim()));
            console.log(`Input letters: ${inputValue}`);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletePatient}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <CardTitle tag="h4" className="mb-0">
                            Pacientes
                        </CardTitle>
                        <div className="ms-auto d-flex align-items-center">
                            <div className="me-5 flex-grow-1">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Buscar paciente..."
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={handleSearch}
                                    >
                                        <i className="mdi mdi-magnify"></i> {/* Ícono de lupa de Material Design Icons */}
                                    </button>
                                </div>
                            </div>
                            <Button
                                type="button"
                                color="success"
                                className="btn-md btn-rounded"
                                onClick={() => navigate('/admin/new-patient')}
                            >
                                + Agregar paciente
                            </Button>
                        </div>
                    </div>
                    {loading ? <Spinners/> :
                        <React.Fragment>
                            <TableContainer
                                columns={columns}
                                data={patientList}
                                isGlobalFilter={false}
                                isPagination={false} // Desactiva la paginación interna de TableContainer
                                SearchPlaceholder="Buscar paciente..."
                                isCustomPageSize={false}
                                isAddButton={false}
                                buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                buttonName="Agregar nuevo"
                                tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                theadClass="table-light"
                            />
                        </React.Fragment>
                    }
                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default withRouter(PatientList);

import axios from "axios";
import {loginSuccess, apiError, logoutUserSuccess, resetLoginFlag, loadingTrue} from "./reducer";
import {APIClient} from "../../../helpers/api_helper";

const api = new APIClient();
export const loginUserThunk = (user: any, history: any) => async (dispatch: any) => {
    console.log("in loginUserThunk, user", user);
    dispatch(loadingTrue());
    try {
        const response = await api.Post(`/doctor/login`, {
            email: user.email,
            password: user.password,
        });

        console.log("loginUserThunk response", response);

        localStorage.setItem("token", response.token);
        localStorage.setItem("user", response.user);
        dispatch(loginSuccess(response.token));
        history('/admin');
    } catch (error) {
        console.error("Error in consultingOfficeCreateThunk:", error);
        dispatch(apiError(error));
    }
}

export const logoutUserThunk = () => async (dispatch: any) => {
    localStorage.removeItem("token");
    dispatch(logoutUserSuccess(true));
};

export const resetLoginMsgFlagThunk = () => {
    const response = resetLoginFlag();
    return response;
};


import React, {useState} from "react"
import { Link, useLocation } from "react-router-dom";

import {
    Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody, Table
} from "reactstrap";
import * as yup from "yup";
import {useFormik} from "formik";
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import {cleanErrors, getAdminConsultationThunk} from "../../slices/consultation/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {useParams} from "react-router-dom";
import {Consultation, Doctor} from "../../entities/types";
import {isEmpty, map} from "lodash";
import "../../assets/scss/consultation.scss";

interface selectState {
    consultation: {
        loading: false;
        consultation: Consultation;
        doctor: Doctor;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminConsultationHistoryPrint = (props: any) => {
    const dispatch: any = useDispatch();
    const { id } = useParams<{ id: string }>();

    const location = useLocation();

    //meta title
    document.title = "Consulta";

    const selectProperties = createSelector(
        (state: selectState) => state.consultation,
        (item) => ({
            loading: item.loading,
            consultation: item.consultation,
            doctor: item.doctor,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, consultation, doctor, reducerToastTitle,
        reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);


    React.useEffect(() => {
        if (id) {
            dispatch(getAdminConsultationThunk(id));
        }
        if (!isEmpty(consultation)) {
            printRecipe();
        }
    }, [id]);

    const printRecipe = () => {
        window.print();
    };

    const calculateAge = (birthday: string | Date): number => {
        const birthDate = typeof birthday === 'string' ? new Date(birthday) : birthday;

        if (!(birthDate instanceof Date) || isNaN(birthDate.getTime())) {
            throw new Error('Invalid date');
        }

        const now = new Date();
        let age = now.getFullYear() - birthDate.getFullYear();
        const monthDifference = now.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            {loading ? (
                                <Spinners/>
                            ) : (
                                <Card>
                                    <CardBody className="d-flex justify-content-between align-items-center">
                                        <CardTitle className="mb-0">
                                            <span className={`fw-bold fs-4`}>
                                                Detalle de la consulta <br/>
                                            </span>
                                            Paciente: &nbsp;
                                                <span className={`fw-normal fs-4`}>
                                            {consultation.patient ?
                                                (`${consultation.patient.first_name} ${consultation.patient.last_name}`) : ""
                                            }<br/>
                                            </span>Fecha: &nbsp;
                                                <span className={`fw-normal`}>
                                            {consultation.patient ?
                                                (`${new Date(consultation.date).toLocaleString('es-ES', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true
                                                })}`) : ""
                                            }<br/>
                                            </span>
                                            Consultorio: &nbsp;
                                                <span className={`fw-normal`}>
                                            {consultation.office ?
                                                (`${consultation.office ? consultation.office.name : '-'}`) : ""
                                            }
                                            </span>
                                        </CardTitle>

                                        <CardTitle>
                                            <span className={`fw-bold fs-4`}>
                                            {doctor ?
                                                (`${doctor.academic_title}. ${doctor.first_name} ${doctor.last_name}`) : ""
                                            }<br/>
                                            </span>

                                            <span className={`fw-normal fs-5`}>
                                            {doctor ?
                                                (`${doctor.specialty}`) : ""
                                            }<br/>
                                            </span>

                                            <span className={`fw-bold fs-6`}>Email:</span>&nbsp;
                                            <span className={`fw-normal fs-6`}>
                                            {doctor ?
                                                (`${doctor.email}`) : ""
                                            }<br/>
                                            </span>

                                            <span className={`fw-bold fs-6`}>Teléfono:</span>&nbsp;
                                            <span className={`fw-normal fs-6`}>
                                            {consultation.office ?
                                                (`${consultation.office ? consultation.office.phone : '-'}`) : ""
                                            }<br/>
                                            </span>
                                        </CardTitle>
                                    </CardBody>

                                    <CardTitle style={{ marginLeft: 30, marginTop: 40 }} className={`fw-bold fs-5`}>
                                        Información del paciente
                                    </CardTitle>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                {
                                                    consultation.patient && consultation.patient.birthday && (
                                                        <tr>
                                                            <th scope="row" style={{ width: '200px' }}>Fecha de nacimiento:</th>
                                                            <td>{new Date(consultation.patient.birthday).toLocaleDateString('es-ES', { timeZone: 'UTC' })}</td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    consultation.patient && consultation.patient.birthday && (
                                                        <tr>
                                                            <th scope="row">Edad:</th>
                                                            <td>{calculateAge(consultation.patient.birthday)} años</td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    consultation.patient && consultation.patient.gender &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Género:</th>
                                                        <td>
                                                            {
                                                                consultation.patient.gender === 'male' ? 'Masculino' :
                                                                    consultation.patient.gender === 'fem' ? 'Femenino' :
                                                                        'Sin registro'
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.birth_place &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Lugar de nacimiento:</th>
                                                        <td>{consultation.patient.birth_place}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.ethnic_group &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Grupo étnico:</th>
                                                        <td>
                                                            {
                                                                consultation.patient.ethnic_group === 'afr' ? 'Afrodescenciente (Africano)' :
                                                                    consultation.patient.ethnic_group === 'ame' ? 'Amerindio (Indígena)' :
                                                                        consultation.patient.ethnic_group === 'arb' ? 'Arabigo (Árabe)' :
                                                                            consultation.patient.ethnic_group === 'cau' ? 'Caucásico (Blanco)' :
                                                                                consultation.patient.ethnic_group === 'lat' ? 'Latino (Latinoamericano)' :
                                                                                    consultation.patient.ethnic_group === 'mzo' ? 'Mestizo' :
                                                                                        consultation.patient.ethnic_group === 'mto' ? 'Mulato' :
                                                                                            consultation.patient.ethnic_group === 'otal' ? 'Oriental (Asiático)' :
                                                                                                consultation.patient.ethnic_group === 'other' ? 'Otro' :
                                                                                                    'Sin registro'
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.marital_status &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Estado civil:</th>
                                                        <td>
                                                            {
                                                                consultation.patient.marital_status === 'sgl' ? 'Soltero(a)' :
                                                                    consultation.patient.marital_status === 'mry' ? 'Casado(a)' :
                                                                        consultation.patient.marital_status === 'wdw' ? 'Viudo(a)' :
                                                                            consultation.patient.marital_status === 'sep' ? 'Separado(a)' :
                                                                                consultation.patient.marital_status === 'div' ? 'Divorciado(a)' :
                                                                                    consultation.patient.marital_status === 'prt' ? 'Concubino(a)' :
                                                                                        consultation.patient.marital_status === 'other' ? 'Otro' :
                                                                                            'Sin registro'
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.blood_type &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Tipo de sangre:</th>
                                                        <td>
                                                            {
                                                                consultation.patient.blood_type === 'o+' ? 'O+' :
                                                                    consultation.patient.blood_type === 'o-' ? 'O-' :
                                                                        consultation.patient.blood_type === 'a+' ? 'A+' :
                                                                            consultation.patient.blood_type === 'a-' ? 'A-' :
                                                                                consultation.patient.blood_type === 'b+' ? 'B+' :
                                                                                    consultation.patient.blood_type === 'b-' ? 'B-' :
                                                                                        consultation.patient.blood_type === 'ab+' ? 'AB+' :
                                                                                            consultation.patient.blood_type === 'ab-' ? 'AB-' :
                                                                                                consultation.patient.blood_type === 'unkn' ? 'Desconocido' :
                                                                                                    'Sin registro'
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.state &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Estado de residencia:</th>
                                                        <td>{consultation.patient.state}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.city &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Ciudad de residencia:</th>
                                                        <td>{consultation.patient.city}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.address &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Domicilio:</th>
                                                        <td>{consultation.patient.address}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.occupation &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Ocupación:</th>
                                                        <td>{consultation.patient.occupation}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.socioeconomic_status &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Nivel Socioeconómico:</th>
                                                        <td>{consultation.patient.socioeconomic_status}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.housing &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Vivienda:</th>
                                                        <td>{consultation.patient.housing}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.disability &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Discapacidad:</th>
                                                        <td>{consultation.patient.disability}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.religion &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Religión:</th>
                                                        <td>{consultation.patient.religion}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.private_note &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Notas privadas:</th>
                                                        <td>{consultation.patient.private_note}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.email &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Email:</th>
                                                        <td>{consultation.patient.email}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.general_info?.phones && consultation.patient.general_info.phones.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Teléfonos:</th>
                                                        <td>
                                                            <div style={{marginBottom: '20px'}}>
                                                                {consultation.patient.general_info.phones.map((phone, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>{phone.phone_type ? `${phone.phone_type}: ` : "Sin registro :"}</span>
                                                                            <span>{phone.phone ? phone.phone : "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.general_info?.identifications && consultation.patient.general_info.identifications.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Identificaciones:</th>
                                                        <td>
                                                            <div style={{marginBottom: '20px'}}>
                                                                {consultation.patient.general_info.identifications.map((identification, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>{identification.identification_type ? `${identification.identification_type}: ` : "Sin registro :"}</span>
                                                                            <span>{identification.identification ? identification.identification : "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.general_info?.insurance_or_plans && consultation.patient.general_info.insurance_or_plans.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Seguros y planes médicos:</th>
                                                        <td>
                                                            <div style={{marginBottom: '20px'}}>
                                                                {consultation.patient.general_info.insurance_or_plans.map((insurance, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>Aseguradora o plan médico: {insurance.name ? insurance.name : "Sin registro :"}</span><br/>
                                                                            <span>Póliza o número de afiliado: {insurance.policy ? insurance.policy : "Sin registro"}</span><br/>
                                                                            {insurance.note && (
                                                                                <div>
                                                                                    <span>Nota: {insurance.note ? insurance.note : "Sin registro"}</span>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.patient && consultation.patient.general_info?.emergency_contacts && consultation.patient.general_info.emergency_contacts.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Contactos de emergencia:</th>
                                                        <td>
                                                            <div style={{marginBottom: '10px'}}>
                                                                {consultation.patient.general_info.emergency_contacts.map((contact, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '30px',
                                                                        marginLeft: '30px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span style={{marginTop: '10px'}}>Nombre completo: {contact.full_name ? contact.full_name : "Sin registro"}</span><br/>
                                                                            {contact.relationship && (
                                                                                <div>
                                                                                    <span>Parentesco: {contact.relationship}</span>
                                                                                </div>
                                                                            )}
                                                                            {contact.email && (
                                                                                <div>
                                                                                    <span
                                                                                        style={{marginTop: '10px'}}>Email: {contact.email ? contact.email : "Sin registro"}</span>
                                                                                </div>
                                                                            )}
                                                                            {contact.note && (
                                                                                <div>
                                                                                    <span
                                                                                        style={{marginTop: '10px'}}>Notas: {contact.note ? contact.note : "Sin registro"}</span>
                                                                                </div>
                                                                            )}
                                                                            {contact.phones && contact.phones.length > 0 && contact.phones.some(phone => phone.phone_type || phone.phone) && (
                                                                                <div>
                                                                                    <span>Teléfonos:</span>
                                                                                    {contact.phones.map((phone, phoneIndex) => (
                                                                                        (phone.phone_type || phone.phone) && (
                                                                                            <div key={phoneIndex}
                                                                                                 style={{
                                                                                                     marginLeft: '20px',
                                                                                                     marginBottom: '10px'
                                                                                                 }}>
                                                                                                <strong>{phoneIndex + 1}.&nbsp;&nbsp;&nbsp;</strong>
                                                                                                {phone.phone_type &&
                                                                                                    <span>{phone.phone_type}</span>}
                                                                                                {phone.phone &&
                                                                                                    <span>: {phone.phone}</span>}
                                                                                            </div>
                                                                                        )
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>

                                    <CardTitle style={{marginLeft: 30, marginTop: 40}} className={`fw-bold fs-5`}>
                                        Detalle de la consulta
                                    </CardTitle>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                {
                                                    (consultation.vital_sign_notes ||
                                                        (consultation.systolic_blood_pressure && consultation.systolic_blood_pressure !== 0) ||
                                                        (consultation.heart_rate && consultation.heart_rate !== 0) ||
                                                        (consultation.blood_oxygen_saturation && consultation.blood_oxygen_saturation !== 0) ||
                                                        (consultation.temperature && consultation.temperature !== 0) ||
                                                        (consultation.respiratory_rate && consultation.respiratory_rate !== 0) ||
                                                        (consultation.glucose && consultation.glucose !== 0) ||
                                                        (consultation.height && consultation.height !== 0) ||
                                                        (consultation.weight && consultation.weight !== 0) ||
                                                        (consultation.body_mass_index && consultation.body_mass_index !== 0) ||
                                                        consultation.menstrual_cycle_date) &&
                                                    <tr>
                                                        <th scope="row" style={{width: '200px'}}>Signos vitales:</th>
                                                        <td>
                                                            {consultation.vital_sign_notes && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Observaciones:</span> {consultation.vital_sign_notes}
                                                                </div>
                                                            )}
                                                            {consultation.systolic_blood_pressure !== 0 && consultation.diastolic_blood_pressure !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Presión arterial (PA):</span> {`${consultation.systolic_blood_pressure} / ${consultation.diastolic_blood_pressure} mmHg`}
                                                                </div>
                                                            )}
                                                            {consultation.heart_rate !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Frecuencia cardiaca (FC):</span> {`${consultation.heart_rate} lpm`}
                                                                </div>
                                                            )}
                                                            {consultation.blood_oxygen_saturation !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Saturación de oxígeno (SpO2):</span> {`${consultation.blood_oxygen_saturation} %`}
                                                                </div>
                                                            )}
                                                            {consultation.temperature !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Temperatura:</span> {`${consultation.temperature} ºC`}
                                                                </div>
                                                            )}
                                                            {consultation.respiratory_rate !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Frecuencia respiratoria:</span> {`${consultation.respiratory_rate} rpm`}
                                                                </div>
                                                            )}
                                                            {consultation.glucose !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Glucosa:</span> {`${consultation.glucose} mg/dl`}
                                                                </div>
                                                            )}
                                                            {consultation.height !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Altura:</span> {`${consultation.height} cm`}
                                                                </div>
                                                            )}
                                                            {consultation.weight !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Peso:</span> {`${consultation.weight} Kg`}
                                                                </div>
                                                            )}
                                                            {consultation.body_mass_index !== 0 && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Índice de masa corporal (IMC):</span> {`${consultation.body_mass_index}`}
                                                                </div>
                                                            )}
                                                            {consultation.menstrual_cycle_date && (
                                                                <div style={{ marginBottom: '10px' }}>
                                                                    <span>Fecha último ciclo menstrual:</span> {consultation.menstrual_cycle_date}
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_reason &&
                                                    <tr>
                                                        <th scope="row" style={{width: '200px'}}>Motivo de consulta:
                                                        </th>
                                                        <td>{consultation.consultation_reason}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.questions && consultation.consultation_info.questions.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Preguntas personalizadas:</th>
                                                        <td>
                                                            <div style={{marginBottom: '20px'}}>
                                                                {consultation.consultation_info.questions.map((question, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>{question.question || "Sin registro"}</span><br/>
                                                                            <span>{question.answer || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.systems && consultation.consultation_info.systems.some(system => system.system || system.observations || system.normal !== null) &&
                                                    <tr>
                                                        <th scope="row">Interrogatorio por aparatos y sistemas:</th>
                                                        <td>
                                                            <div style={{ marginBottom: '20px' }}>
                                                                {consultation.consultation_info.systems.map((system, index) => (
                                                                    (system.system || system.observations || system.normal !== null) && (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div style={{ marginRight: '10px' }}>{index + 1}.</div>
                                                                            <div>
                                                                                <span>Aparato o sistema: {system.system || "Sin registro"}</span><br />
                                                                                <span>{system.normal !== null ? (system.normal ? "Normal" : "Anormal") : "Sin registro"}</span><br />
                                                                                {system.observations && (
                                                                                    <div>
                                                                                        <span>Observaciones: {system.observations ? system.observations : "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.examinations && consultation.consultation_info.examinations.some(examination => examination.segment || examination.observations || examination.normal !== null) &&
                                                    <tr>
                                                        <th scope="row">Exploración por aparatos y sistemas:</th>
                                                        <td>
                                                            <div style={{ marginBottom: '20px' }}>
                                                                {consultation.consultation_info.examinations.map((examination, index) => (
                                                                    (examination.segment || examination.observations || examination.normal !== null) && (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div style={{ marginRight: '10px' }}>{index + 1}.</div>
                                                                            <div>
                                                                                <span>Aparato o sistema: {examination.segment || "Sin registro"}</span><br />
                                                                                <span>{examination.normal !== null ? (examination.normal ? "Normal" : "Anormal") : "Sin registro"}</span><br />
                                                                                {examination.observations && (
                                                                                    <div>
                                                                                        <span>Observaciones: {examination.observations ? examination.observations : "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.laboratory_results && consultation.consultation_info.laboratory_results.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Laboratorios:</th>
                                                        <td>
                                                            <div style={{marginBottom: '20px'}}>
                                                                {consultation.consultation_info.laboratory_results.map((result, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>Tipo de laboratorio: {result.laboratory || "Sin registro"}</span><br/>
                                                                            {result.result && (
                                                                                <div>
                                                                                    <span>Resultado: {result.result ? result.result : "Sin registro"}</span><br/>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.study_results && consultation.consultation_info.study_results.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Estudios de gabinete:</th>
                                                        <td>
                                                            <div style={{marginBottom: '20px'}}>
                                                                {consultation.consultation_info.study_results.map((result, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>Tipo de estudio: {result.study || "Sin registro"}</span><br/>
                                                                            {result.result && (
                                                                                <div>
                                                                                    <span>Resultado: {result.result ? result.result : "Sin registro"}</span><br/>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.extra_notes &&
                                                    <tr>
                                                        <th scope="row" style={{ width: '200px' }}>Observaciones adicionales de la consulta:</th>
                                                        <td>{consultation.extra_notes}</td>
                                                    </tr>
                                                }
                                                {
                                                    (consultation.diagnosis || (consultation.consultation_info?.diagnoses && consultation.consultation_info.diagnoses.length > 0) || consultation.diagnosis_observations) &&
                                                    <tr>
                                                        <th scope="row">Diagnóstico:</th>
                                                        <td>
                                                            <>
                                                                {consultation.diagnosis && (
                                                                    <div style={{ marginBottom: '10px' }}>
                                                                        Impresión diagnóstica: {consultation.diagnosis}
                                                                    </div>
                                                                )}
                                                                {
                                                                    (consultation.consultation_info?.diagnoses && consultation.consultation_info.diagnoses.length > 0) ? (
                                                                        <div>
                                                                            <div style={{
                                                                                marginBottom: '10px'
                                                                            }}>Diagnóstico diferencial:
                                                                            </div>
                                                                            {consultation.consultation_info?.diagnoses && consultation.consultation_info.diagnoses.length > 0 ? (
                                                                                <div style={{marginBottom: '20px'}}>
                                                                                    {consultation.consultation_info.diagnoses.map((diagnosis, index) => (
                                                                                        <div key={index} style={{
                                                                                            marginBottom: '10px',
                                                                                            display: 'flex'
                                                                                        }}>
                                                                                            {diagnosis.diagnosis ? (
                                                                                                <div
                                                                                                    style={{marginRight: '10px', marginLeft: '30px'}}>{index + 1}.</div>
                                                                                            ) : null}
                                                                                            <div>
                                                                                                <span>{diagnosis.diagnosis ? diagnosis.diagnosis : "Sin registro de diagnósticos"}</span><br/>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ) : (
                                                                                <span>Sin registro de diagnósticos</span>
                                                                            )}
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                {consultation.diagnosis_observations && (
                                                                    <div style={{ marginBottom: '10px' }}>
                                                                        Observaciones: {consultation.diagnosis_observations || 'Sin registro'}
                                                                    </div>
                                                                )}
                                                            </>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.prescriptions && consultation.consultation_info.prescriptions.some(prescription => prescription.prescriptions && prescription.prescriptions.length > 0 || prescription.indications) &&
                                                    <tr>
                                                        <th scope="row">Recetas médicas:</th>
                                                        <td>
                                                            <div style={{ marginBottom: '10px' }}>
                                                                {consultation.consultation_info.prescriptions.map((prescription, index) => (
                                                                    (prescription.prescriptions && prescription.prescriptions.length > 0 || prescription.indications) && (
                                                                        <div key={index} style={{ marginBottom: '30px' }}>
                                                                            <Row>
                                                                                <Col xs={6}>
                                                                                    <b>Receta</b> {consultation.consultation_info.prescriptions.length > 1 ? (
                                                                                    <span># {index + 1}</span>
                                                                                ) : null}
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xs={12}>
                                                                                    {prescription.date && (
                                                                                        <div style={{marginBottom: '10px'}}>
                                                                                            <td>Fecha: {prescription.date ? new Date(prescription.date).toLocaleDateString('es-ES', {timeZone: 'UTC'}) : "Sin registro"}</td>
                                                                                        </div>
                                                                                    )}
                                                                                    {prescription.prescriptions && prescription.prescriptions.length > 0 ? (
                                                                                        prescription.prescriptions.map((presc, prescIndex) => (
                                                                                            <div key={prescIndex} style={{ marginBottom: '10px' }}>
                                                                                                Medicamento: {presc.medication || 'Sin registro'}<br/>
                                                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                Indicaciones: {presc.instruction || 'Sin registro'}<br/>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <div>
                                                                                            <span>Sin detalles de medicamentos</span><br/>
                                                                                        </div>
                                                                                    )}
                                                                                    {prescription.indications && (
                                                                                        <div style={{ marginBottom: '10px' }}>
                                                                                            Notas adicionales:: {prescription.indications || 'Sin registro'}
                                                                                        </div>
                                                                                    )}
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                ))}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.monitoring &&
                                                    <tr>
                                                        <th scope="row" style={{width: '200px'}}>Evolución clínica:</th>
                                                        <td style={{ whiteSpace: 'pre-wrap' }}>{consultation.monitoring}</td>
                                                    </tr>
                                                }
                                                </tbody>
                                                <div>
                                                    <Col sm={6} className="text-sm-end">
                                                        <div className="d-print-none">
                                                            <div className="float-end" style={{
                                                                position: 'absolute',
                                                                bottom: '10px',
                                                                right: '10px'
                                                            }}>
                                                                <Link to={`/admin/patient/consultation/${id}`}
                                                                      className="btn btn-outline-danger">Cancelar</Link>
                                                                <Link
                                                                    to="#"
                                                                    onClick={printRecipe}
                                                                    className="btn btn-success  me-2"
                                                                    style={{ marginLeft: '5px' }}
                                                                >
                                                                    <i className="fa fa-print"/>
                                                                    &nbsp; Imprimir
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminConsultationHistoryPrint)
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    reducerToastMessage: "",
    reducerToastTitle: "",
    loading: false
};

const configRecipeSlice = createSlice({
    name: 'configRecipe',
    initialState,
    reducers: {
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
        },
        cleanApiError(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
        loadingFalse(state) {
            state.reducerToastMessage= "";
            state.reducerToastTitle= "";
            state.loading = false;
        },
    },
});

export const {
    apiError,
    cleanApiError,
    loadingTrue,
    loadingFalse,
} = configRecipeSlice.actions;

export default configRecipeSlice.reducer;
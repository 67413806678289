import { combineReducers } from "redux";

import AdminReducer from "./admin/reducer";
import CalendarReducer from "./calendar/reducer";
import ConsultingOfficeReducer from "./consulting_office/reducer"
import AssistantReducer from "./assistant/reducer"
import DoctorReducer from "./profile/reducer"
import ConsultationReducer from "./consultation/reducer"
import ConfigRecipeReducer from "./config_recipe/reducer";
import LoginReducer from "./auth/login/reducer";
import ProfileReducer from "./auth/profile/reducer";
import ForgotPasswordReducer from "./auth/forgetpwd/reducer";
import AccountReducer from "./auth/register/reducer";

const rootReducer = combineReducers({
    admin: AdminReducer,
    calendar: CalendarReducer,
    consulting_office: ConsultingOfficeReducer,
    assistant: AssistantReducer,
    doctor: DoctorReducer,
    configRecipe: ConfigRecipeReducer,
    consultation: ConsultationReducer,
    login: LoginReducer,
    Profile: ProfileReducer,
    ForgetPassword: ForgotPasswordReducer,
    Account: AccountReducer
});

export default rootReducer;
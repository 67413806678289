import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Assistant } from "../../entities/types";

const initialState = {
    AssistantList: [] as Assistant[],
    assistant: {} as Assistant,
    errorAdminPatientList: "",
    reducerToastTitle: "",
    reducerToastMessage: "",
    loading: false
}

const assistantsSlice = createSlice({
    name: "assistants",
    initialState,
    reducers: {
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
        },
        cleanApiError(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
        adminAssistantsSuccess(state, action) {
            console.log("reducers adminAssistantsSuccess loading false");
            state.AssistantList = action.payload;
            state.loading = false;
        },
        adminAssistantSuccess(state, action: PayloadAction<any>) {
            console.log("adminAssistantSuccess action.payload", action.payload)
            state.assistant = action.payload;
            state.loading = false;
        },
        adminEditAssistantSuccess(state, action: PayloadAction<any>) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Asistente editado";
            state.loading = false;
        },
        adminCreateAssistantSuccess(state) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Asistente creado";
            state.loading = false;
        },
        adminDeleteAssistantSuccess(state) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Asistente eliminado";
            state.loading = false;
        },
    },
});

export const {
    apiError,
    cleanApiError,
    loadingTrue,
    adminAssistantsSuccess,
    adminAssistantSuccess,
    adminEditAssistantSuccess,
    adminCreateAssistantSuccess,
    adminDeleteAssistantSuccess,
} = assistantsSlice.actions;

export default assistantsSlice.reducer;
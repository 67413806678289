import React, {useState} from 'react';
import {Col, Container, Row, Toast, ToastBody, ToastHeader} from 'reactstrap';
import PatientList from "../PatientsList/PatientList";
import {cleanErrors} from "../../slices/admin/thunk";
import {Patient} from "../../entities/types";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import logo from "../../assets/images/logo.png";
interface selectState {
    admin: {
        adminPatientList: Patient[];
        loading: true;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const TermsAndConditions = () => {

    document.title = "Políticas de Privacidad";
    const dispatch = useDispatch<any>();

    return (
        <React.Fragment>
            <section className="section bg-white" id="news">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <img src={logo} alt="" width="200"/>
                                <h4 className="pt-3">Políticas de Privacidad</h4>
                            </div>
                            <div className="text-start mb-5">
                                <p>
                                    <br/><br/>
                                    Farmabits es responsable del uso y protección de sus datos personales. A
                                    continuación, le informamos sobre los siguientes aspectos:
                                    <br/><br/>
                                    ¿Para qué fines utilizaremos sus datos personales?
                                    <br/>Los datos personales que recopilamos de usted se utilizarán para los siguientes
                                    fines necesarios para el servicio que solicita:
                                    <br/><br/>
                                    1. Crear una base de datos que se tratará y pondrá a disposición del cliente final.
                                    <br/>2. Formar parte de un directorio público de profesionales.
                                    <br/>3. Operación, mantenimiento, gestión y administración de nuestros servicios,
                                    incluyendo registros de procesamiento y pagos, y la disponibilidad de atención al
                                    Usuario.
                                    <br/>4. Responder a preguntas y comunicaciones, que conservamos durante el curso
                                    ordinario de los servicios.
                                    <br/>5. Anuncios administrativos sobre características, funcionalidades, términos u
                                    otros aspectos de nuestros servicios; así como ofertas de servicios o productos que
                                    consideramos pueden ser de su interés.
                                    <br/>6. Protección de nuestros servicios, salvaguardar derechos de terceros y
                                    responder a procesos legales.
                                    <br/>7. Cualquier otra finalidad descrita en esta Política o en su contrato de
                                    usuario.
                                    <br/><br/>
                                    También podemos utilizar información no personal para generar informes para
                                    anunciantes actuales o futuros, patrocinadores u otros socios, mostrando tendencias
                                    de uso general de nuestros servicios. Estos informes pueden incluir datos sobre
                                    edad, género, información general geográfica y demográfica del Usuario, pero no
                                    incluirán información personal ni sensible.
                                    <br/><br/>
                                    Fines adicionales
                                    <br/>De manera adicional, utilizaremos su información personal para los siguientes
                                    fines no necesarios para el servicio solicitado, pero que nos permiten y facilitan
                                    brindarle una mejor atención:
                                    <br/><br/>
                                    1. Evaluar la calidad del servicio.
                                    <br/>2. Enviar promociones.
                                    <br/>3. Informar sobre cambios o nuevos servicios relacionados con el contratado o
                                    adquirido por el cliente.
                                    <br/>4. Revisión, investigación, medición y análisis para mantener, administrar,
                                    mejorar y proteger nuestros servicios, incluyendo el análisis de tendencias y
                                    patrones de
                                    uso y la medición de la efectividad del contenido, publicidad, funciones o
                                    servicios.
                                    <br/>5. Crear nuevas características y servicios.
                                    <br/>6. Entregar contenido automatizado basado en cookies, como anuncios
                                    personalizados o resultados de búsqueda.
                                    <br/>7. Otros propósitos descritos en esta política o su contrato.
                                    <br/><br/>
                                    Si no desea que sus datos personales sean tratados para estos fines adicionales,
                                    puede comunicarlo al correo electrónico informes@farmabits.com. La negativa para el
                                    uso de sus datos personales para estos fines no será motivo para negar los servicios
                                    y productos que solicita o contrata con nosotros.
                                    <br/><br/>
                                    ¿Qué datos personales utilizaremos para estos fines?
                                    <br/>Para llevar a cabo las finalidades descritas en este aviso de privacidad,
                                    utilizaremos los siguientes datos personales:
                                    <br/><br/>
                                    - Nombre y apellidos
                                    <br/>- Especialidad médica
                                    <br/>- Correo electrónico
                                    <br/>- Dirección del consultorio
                                    <br/>- Número telefónico
                                    <br/>- Fotografía
                                    <br/><br/>
                                    Farmabits no solicitará ni tratará datos sensibles. Sin embargo, en algunas
                                    jurisdicciones, los datos personales que obtenemos podrían ser clasificados como
                                    sensibles.
                                    <br/><br/>
                                    La información solicitada por Farmabits es opcional, por lo que usted no está
                                    obligado a proporcionar información personal. Sin embargo, la consecuencia única de
                                    no facilitarnos los datos requeridos será la imposibilidad para Farmabits de
                                    realizar los fines necesarios para el servicio que solicita, ya que la información
                                    solicitada es estrictamente necesaria y no excesiva.
                                    <br/><br/>
                                    Farmabits llevará a cabo el tratamiento de los datos personales con los fines
                                    señalados. Por tratamiento se entiende cualquier operación o conjunto de operaciones
                                    efectuadas mediante procedimientos automatizados o manuales aplicados a datos
                                    personales, tales como la recolección, registro, organización, conservación,
                                    modificación, extracción, consulta, utilización, comunicación por transmisión,
                                    difusión o cualquier otra forma que facilite el acceso a estos, cotejo o
                                    interconexión, así como su bloqueo, supresión o destrucción, entre otros.
                                    <br/><br/>
                                    Información de pacientes proporcionada por los doctores
                                    <br/>En caso de que usted, en su calidad de doctor, nos comparta datos de sus
                                    pacientes, manifiesta bajo protesta de decir verdad que cuenta con la autorización
                                    expresa del
                                    paciente para compartir esta información, así como cualquier otro requerimiento
                                    necesario basado en la normativa vigente. Farmabits se compromete a adherirse a las
                                    obligaciones y formas de tratamiento que el doctor asumió mediante el aviso de
                                    privacidad que el paciente tuvo a la vista.
                                    <br/><br/>
                                    Además, Farmabits se compromete a que la información de los pacientes que recibe de
                                    los doctores sea tratada con los más altos estándares de seguridad, proporcionando
                                    los procesos de Acceso, Rectificación, Cancelación y Oposición, así como cualquier
                                    otro que esté tutelado bajo la legislación aplicable, según se señala más adelante.
                                    <br/><br/>
                                    ¿Con quién compartimos su información personal y para qué fines?
                                    <br/>Sus datos personales no serán cedidos, vendidos, compartidos o transferidos,
                                    salvo cuando sea necesario en los casos previstos en la normativa aplicable. No
                                    obstante,
                                    Farmabits se reserva el derecho de compartir sus datos personales con los empleados
                                    de Farmabits, empresas filiales, subsidiarias o pertenecientes al mismo grupo
                                    empresarial, tanto local como internacionalmente.
                                    <br/><br/>
                                    Farmabits podrá compartir sus datos con terceros proveedores de sistemas
                                    informáticos para el almacenamiento de datos, quienes estarán sujetos a los mismos o
                                    mayores estándares de seguridad, y realizarán sus servicios en nombre y por cuenta
                                    de Farmabits, siendo esta última la responsable del manejo de la información.
                                    <br/><br/>
                                    Farmabits utiliza equipos y software desarrollados y proporcionados por terceros
                                    para la prestación de algunos de sus servicios (los "Componentes de Terceros"), a
                                    los cuales usted deberá proporcionar cierta información necesaria para la prestación
                                    de los servicios. Los Componentes de Terceros incluyen, sin limitación, conexión a
                                    Internet, software de sistema operativo, software de comunicaciones mediante
                                    videoconferencia y software para el procesamiento de pagos, los cuales Farmabits
                                    aprovecha para proporcionar servicios como teleconsulta y procesamiento de pagos,
                                    entre otros. Farmabits no almacenará la información que usted facilite a terceros
                                    mediante los Componentes de Terceros. Los terceros que tengan acceso a su
                                    información en virtud de los servicios mencionados no podrán utilizarla de ninguna
                                    otra forma que la indicada por Farmabits. Farmabits exige a los proveedores de
                                    Componentes de Terceros las mismas medidas de protección de la información adoptadas
                                    por Farmabits. Sin embargo, Farmabits no es responsable del manejo que dichos
                                    terceros den a la información que usted les proporcione.
                                    <br/><br/>
                                    Sus datos personales podrían ser entregados a terceros en virtud de una eventual
                                    negociación o formalización de una fusión, adquisición, financiamiento, cesión u
                                    otras transacciones similares. En estos casos, Farmabits entregará únicamente la
                                    información estrictamente necesaria y con los respaldos legales correspondientes
                                    para el manejo de la información personal.
                                    <br/><br/>
                                    ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su
                                    uso?
                                    <br/><br/>
                                    Para ejercer cualquier cambio en sus datos personales deberá presentar la solicitud respectiva al correo
                                    electrónico informes@farmabits.com.
                                    <br/><br/>
                                    Su petición deberá precisar los datos personales en cuestión y enviándonos
                                    una identificación oficial del titular de los datos o de su apoderado. En un plazo
                                    máximo de 20 días hábiles, o menor si la normativa local lo dispone, atenderemos su
                                    solicitud y le informaremos sobre su procedencia a través del correo electrónico del
                                    que provenga la petición.
                                    <br/><br/>
                                    Si no está de acuerdo con las transferencias mencionadas, deberá enviar un correo a
                                    informes@farmabits.com.
                                    <br/><br/>
                                    Si considera que se han vulnerado sus derechos respecto de la protección de datos
                                    personales, tiene derecho a acudir a la autoridad correspondiente para defender su
                                    ejercicio. En México, la autoridad es el Instituto Nacional de Transparencia, Acceso
                                    a la Información y Protección de Datos Personales (INAI), su sitio web es
                                    www.inai.org.mx.
                                    <br/><br/>
                                    ¿Cómo puede revocar su consentimiento para el uso de sus datos personales?
                                    <br/>Usted puede revocar el consentimiento que nos haya otorgado para el tratamiento
                                    de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en
                                    todos
                                    los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que
                                    es posible que por alguna obligación legal requiramos seguir tratando sus datos
                                    personales. Asimismo, debe considerar que, para ciertos fines, la revocación de su
                                    consentimiento implicará que no podamos seguir prestando el servicio que nos
                                    solicitó, o la conclusión de su relación con nosotros.
                                    <br/><br/>
                                    Para revocar su consentimiento, deberá presentar su solicitud al correo electrónico
                                    informes@farmabits.com.
                                    <br/><br/>
                                    ¿Cómo puede limitar el uso o divulgación de su información personal?
                                    <br/>Para limitar el uso y divulgación de su información personal, le ofrecemos los
                                    siguientes medios:
                                    <br/><br/>
                                    1. Solicitarlo directamente a través del correo electrónico informes@farmabits.com o
                                    en las direcciones físicas de nuestras oficinas señaladas al final de esta política
                                    con una misiva dirigida al Departamento de Privacidad.
                                    <br/>2. En México, puede inscribirse en el Registro Público para Evitar Publicidad,
                                    a cargo de la Procuraduría Federal del Consumidor, para que sus datos personales no
                                    sean utilizados para recibir publicidad o promociones de empresas de bienes o
                                    servicios. Farmabits podrá realizar llamadas promocionales incluso si está inscrito
                                    en el portal, siempre y cuando haya dado su consentimiento para tales efectos.
                                    <br/><br/>
                                    Suspensión del servicio por falta de pago
                                    <br/>Farmabits se reserva el derecho de suspender, interrumpir, bloquear y/o
                                    terminar el Servicio si el Usuario no ha pagado el monto adeudado en las fechas
                                    correspondientes. Previo a la suspensión o terminación del Servicio, Farmabits
                                    realizará esfuerzos razonables para notificar por correo electrónico. Si el Usuario
                                    no se pone al día en los pagos, Farmabits no se responsabiliza por la pérdida o
                                    eliminación de la información, entendiendo que el no pago indica que el Usuario no
                                    desea continuar con los Servicios. En este caso, Farmabits no será responsable de
                                    conservar la información del Usuario.
                                    <br/><br/>
                                    El uso de tecnologías de rastreo en nuestro portal de Internet
                                    <br/>Le informamos que en nuestra página de Internet utilizamos cookies, web beacons
                                    y otras tecnologías para monitorear su comportamiento como usuario de Internet y
                                    brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
                                    <br/><br/>
                                    Estas tecnologías podrán deshabilitarse en el apartado de configuraciones de su
                                    navegador de internet.
                                    <br/><br/>
                                    ¿Cómo puede conocer los cambios a este aviso de privacidad?
                                    <br/>Este aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones
                                    derivadas de nuevos requerimientos legales, de nuestras propias necesidades por los
                                    productos o servicios que ofrecemos, de nuestras prácticas de privacidad, de cambios
                                    en nuestro modelo de negocio, o por otras causas.
                                    <br/><br/>
                                    Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir este
                                    aviso de privacidad a través de este mismo medio.
                                    <br/><br/>
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>

                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default TermsAndConditions

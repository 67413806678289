import React, {useState} from "react"
import {Link, useLocation} from "react-router-dom";

import {
    Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody, Table
} from "reactstrap";
import * as yup from "yup";
import {useFormik} from "formik";
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import {cleanErrors, getAdminPatientThunk, patientCreateThunk, getAdminPatientMedicalHistoryThunk} from "../../slices/admin/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {useParams} from "react-router-dom";
import {Patient} from "../../entities/types";
import {isEmpty} from "lodash";
import {index} from "d3-array";
import {Simulate} from "react-dom/test-utils";
import mouseMove = Simulate.mouseMove;
import AdminTabs from "../../Layouts/HorizontalLayout/Tabs";

interface selectState {
    admin: {
        loading: false;
        adminPatient: Patient;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};


const AdminPatientMedicalHistoryDetail = (props: any) => {
    const dispatch: any = useDispatch();
    const {id} = useParams();
    const location = useLocation();

    //meta title
    document.title = "Historial Médico";

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (item) => ({
            loading: item.loading,
            adminPatient: item.adminPatient,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, adminPatient, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);


    React.useEffect(() => {
        if (id) { // Agrega una condición para asegurarte de que 'id' esté definido
            dispatch(getAdminPatientMedicalHistoryThunk(id));
        }
    }, [id]); // Solo dispara la acción cuando 'id' cambie

    const isActiveTab = (tabPath: string) => {
        return location.pathname === tabPath;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            {loading ? (
                                <Spinners/>
                            ) : (
                                <Card>
                                    <AdminTabs id={id as string} isActiveTab={isActiveTab} />
                                    <CardBody className="d-flex justify-content-between align-items-center">
                                        <CardTitle className="mb-0">Historial Médico</CardTitle>
                                        <CardTitle>
                                            <div style={{marginBottom: 10}}>
                                                <Link to={`/admin/patients/medical-history/print/${id}`}
                                                      className="text-decoration-none" style={{color: 'black'}}>
                                                    <span className="me-2" style={{fontSize: '13px'}}>
                                                        <i className="fas fa-print"></i> Imprimir
                                                    </span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to={`/admin/patients/medical-history/edit/${id}`}
                                                      className="btn btn-primary">Editar</Link>
                                            </div>
                                        </CardTitle>
                                    </CardBody>

                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                <tr>
                                                    <th scope="row" style={{width: '300px'}}>Nombre:</th>
                                                    <td>{adminPatient.first_name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{width: '300px'}}>Apellidos:</th>
                                                    <td>{adminPatient.last_name}</td>
                                                </tr>
                                                </tbody>
                                            </Table>

                                            {adminPatient.history_info?.medical_history?.family_pathology_present ||
                                            (adminPatient.history_info?.family_pathologies && adminPatient.history_info.family_pathologies.length > 0) ||
                                            adminPatient.history_info?.medical_history?.family_pathology_notes ? (
                                                <>
                                                    <div style={{
                                                        textAlign: 'left',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        marginTop: '30px',
                                                        marginLeft: '70px',
                                                        marginBottom: '7px'
                                                    }}>
                                                        Antecedentes Heredo Familiares
                                                    </div>

                                                    <Table className="table-nowrap mb-0">
                                                        <tbody>
                                                        <tr>
                                                            <th scope="row" style={{ width: '300px' }}>Antecedentes Heredo Familiares:</th>
                                                            <td>
                                                                {(!adminPatient.history_info?.medical_history?.family_pathology_present &&
                                                                    (!adminPatient.history_info?.family_pathologies || adminPatient.history_info.family_pathologies.length === 0) &&
                                                                    !adminPatient.history_info?.medical_history?.family_pathology_notes) ? (
                                                                    <span>Sin registro</span>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            adminPatient.history_info?.medical_history?.family_pathology_present ? (
                                                                                <div>
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Patología presente: {adminPatient.history_info?.medical_history?.family_pathology_present || "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            adminPatient.history_info?.family_pathologies && adminPatient.history_info.family_pathologies.length > 0 ? (
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info.family_pathologies.map((family, index) => (
                                                                                        <div key={index} style={{
                                                                                            marginBottom: '10px',
                                                                                            marginLeft: '30px',
                                                                                            display: 'flex'
                                                                                        }}>
                                                                                            <div
                                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                                            </div>
                                                                                            <div>
                                                                                                    <span
                                                                                                        style={{marginTop: '10px'}}>Patología: {family.pathology || "Sin registro"}</span><br/>
                                                                                                {family.members && family.members.length > 0 && (
                                                                                                    <div>
                                                                                                        <span>Familiares que lo padecen:</span>
                                                                                                        {family.members.map((member, memberIndex) => (
                                                                                                            (member.member) ? (
                                                                                                                <div
                                                                                                                    key={memberIndex}
                                                                                                                    style={{marginLeft: '30px'}}>
                                                                                                                    <span>{memberIndex + 1}. &nbsp;&nbsp;&nbsp;</span>
                                                                                                                    <span>{member.member || 'Sin registro'}</span>
                                                                                                                </div>
                                                                                                            ) : null
                                                                                                        ))}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            adminPatient.history_info?.medical_history?.family_pathology_notes ? (
                                                                                <div>
                                                                                    <div>
                                                                                        <span>Notas: {adminPatient.history_info.medical_history.family_pathology_notes}</span><br/>
                                                                                    </div>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : null}

                                            {adminPatient.history_info?.medical_history?.pathology_present ||
                                            (adminPatient.history_info?.pathologies && adminPatient.history_info.pathologies.length > 0) ||
                                            adminPatient.history_info?.medical_history?.pathology_notes ||
                                            adminPatient.history_info?.medical_history?.surgery_present ||
                                            (adminPatient.history_info?.surgeries && adminPatient.history_info.surgeries.length > 0) ||
                                            adminPatient.history_info?.medical_history?.surgery_notes ? (
                                                <>
                                                    <div style={{
                                                        textAlign: 'left',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        marginTop: '30px',
                                                        marginLeft: '70px',
                                                        marginBottom: '7px'
                                                    }}>
                                                        Antecedentes Personales Patológicos
                                                    </div>

                                                    <Table className="table-nowrap mb-0">
                                                        <tbody>
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.pathology_present &&
                                                                (!adminPatient.history_info?.pathologies || adminPatient.history_info.pathologies.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.pathology_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row"
                                                                        style={{width: '300px'}}>Patologías:
                                                                    </th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.pathology_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Patología presente: {adminPatient.history_info?.medical_history?.pathology_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.pathologies && adminPatient.history_info.pathologies.length > 0) ? (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        {adminPatient.history_info?.pathologies && adminPatient.history_info.pathologies.length > 0 ? (
                                                                                            <div style={{marginBottom: '10px'}}>
                                                                                                {adminPatient.history_info.pathologies.map((pathology, index) => (
                                                                                                    <div key={index} style={{
                                                                                                        marginBottom: '10px',
                                                                                                        marginLeft: '30px',
                                                                                                        display: 'flex'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                        <span
                                                                                            style={{marginTop: '10px'}}>Patología: {pathology.pathology || "Sin registro"}</span><br/>
                                                                                                            {pathology.treatments && pathology.treatments.length > 0 && (
                                                                                                                <div>
                                                                                                                    <span>Medicamentos y tratamientos:</span>
                                                                                                                    {pathology.treatments.map((treatment, treatmentIndex) => (
                                                                                                                        (treatment.treatment) ? (
                                                                                                                            <div
                                                                                                                                key={treatmentIndex}
                                                                                                                                style={{
                                                                                                                                    marginLeft: '30px',
                                                                                                                                }}>
                                                                                                                                <span>{treatmentIndex + 1}. &nbsp;&nbsp;&nbsp;</span>
                                                                                                                                <span>{treatment.treatment || 'Sin registro'}</span>
                                                                                                                            </div>
                                                                                                                        ) : null
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span>Sin registro de patologías</span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.pathology_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.pathology_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.surgery_present &&
                                                                (!adminPatient.history_info?.surgeries || adminPatient.history_info.surgeries.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.surgery_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Cirugías y hospitalizaciones:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.surgery_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Cirugía u hospitalización presente: {adminPatient.history_info?.medical_history?.surgery_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.surgeries && adminPatient.history_info.surgeries.length > 0) ? (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        {adminPatient.history_info?.surgeries && adminPatient.history_info.surgeries.length > 0 ? (
                                                                                            <div style={{marginBottom: '20px'}}>
                                                                                                {adminPatient.history_info.surgeries.map((surgery, index) => (
                                                                                                    <div key={index} style={{
                                                                                                        marginBottom: '10px',
                                                                                                        marginLeft: 30,
                                                                                                        display: 'flex'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Cirugía u hospitalización: {surgery.surgery ? surgery.surgery : "Sin registro"}</span><br/>
                                                                                                            {
                                                                                                                surgery.date && (
                                                                                                                    <div>
                                                                                                                        <span>Fecha: {surgery.date ? surgery.date : "Sin registro"}</span>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                            {
                                                                                                                surgery.complications && (
                                                                                                                    <div>
                                                                                                                        <span>Complicaciones: {surgery.complications ? surgery.complications : "Sin registro"}</span>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span>Sin registro de cirugías u hospitalizaciones</span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.surgery_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.surgery_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : null}

                                            {adminPatient.history_info?.medical_history?.allergy_present ||
                                            (adminPatient.history_info?.allergies && adminPatient.history_info.allergies.length > 0) ||
                                            adminPatient.history_info?.medical_history?.allergy_notes ||
                                            adminPatient.history_info?.medical_history?.immunization_present ||
                                            (adminPatient.history_info?.medical_history?.up_to_date_immunization && adminPatient.history_info?.medical_history?.up_to_date_immunization === null) ||
                                            (adminPatient.history_info?.immunizations && adminPatient.history_info.immunizations.length > 0) ||
                                            adminPatient.history_info?.medical_history?.immunization_notes ||
                                            adminPatient.history_info?.medical_history?.tobacco_present ||
                                            adminPatient.history_info?.medical_history?.tobacco_suspension_time ||
                                            adminPatient.history_info?.medical_history?.cigarettes_per_day ||
                                            adminPatient.history_info?.medical_history?.smoking_years ||
                                            adminPatient.history_info?.medical_history?.tobacco_notes ||
                                            adminPatient.history_info?.medical_history?.alcohol_present ||
                                            adminPatient.history_info?.medical_history?.alcohol_suspension_time ||
                                            (adminPatient.history_info?.alcohols && adminPatient.history_info.alcohols.length > 0) ||
                                            adminPatient.history_info?.medical_history?.alcohol_consumed_years ||
                                            adminPatient.history_info?.medical_history?.alcohol_notes ||
                                            adminPatient.history_info?.medical_history?.drug_present ||
                                            (adminPatient.history_info?.drugs && adminPatient.history_info.drugs.length > 0) ||
                                            adminPatient.history_info?.medical_history?.drug_notes ||
                                            adminPatient.history_info?.medical_history?.physical_activity_present ||
                                            (adminPatient.history_info?.physical_activities && adminPatient.history_info.physical_activities.length > 0) ||
                                            adminPatient.history_info?.medical_history?.drug_notes ? (
                                                <>
                                                    <div style={{
                                                        textAlign: 'left',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        marginTop: '30px',
                                                        marginLeft: '70px',
                                                        marginBottom: '7px'
                                                    }}>
                                                        Antecedentes Personales No Patológicos
                                                    </div>

                                                    <Table className="table-nowrap mb-0">
                                                        <tbody>
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.allergy_present &&
                                                                (!adminPatient.history_info?.allergies || adminPatient.history_info.allergies.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.allergy_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Alergias:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.allergy_present ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Alergia presente: {adminPatient.history_info?.medical_history?.allergy_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.allergies && adminPatient.history_info.allergies.length > 0) ? (
                                                                                    <div style={{marginBottom: '10px', marginTop: '10px'}}>
                                                                                        {adminPatient.history_info?.allergies && adminPatient.history_info.allergies.length > 0 ? (
                                                                                            <div style={{marginBottom: '20px'}}>
                                                                                                {adminPatient.history_info.allergies.map((allergy, index) => (
                                                                                                    <div key={index} style={{
                                                                                                        marginBottom: '10px',
                                                                                                        marginLeft: 30,
                                                                                                        display: 'flex'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Alergia: {allergy.allergy ? allergy.allergy : "Sin registro"}</span><br/>
                                                                                                            {allergy.reaction && (
                                                                                                                <div>
                                                                                                                    <span>Reacción: {allergy.reaction ? allergy.reaction : "Sin registro"}</span>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span>Sin registro de alergias</span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.allergy_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.allergy_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.immunization_present &&
                                                                (!adminPatient.history_info?.medical_history?.up_to_date_immunization || adminPatient.history_info?.medical_history?.up_to_date_immunization === null) &&
                                                                (!adminPatient.history_info?.immunizations || adminPatient.history_info.immunizations.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.immunization_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Inmunizaciones:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.immunization_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Inmunización presente: {adminPatient.history_info?.medical_history?.immunization_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            <div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    <span>Inmunizaciones al día: {adminPatient.history_info?.medical_history?.up_to_date_immunization === true ? 'Sí' : adminPatient.history_info?.medical_history?.up_to_date_immunization === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                (adminPatient.history_info?.immunizations && adminPatient.history_info.immunizations.length > 0) ? (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        {adminPatient.history_info?.immunizations && adminPatient.history_info.immunizations.length > 0 ? (
                                                                                            <div style={{marginBottom: '20px'}}>
                                                                                                {adminPatient.history_info.immunizations.map((immunization, index) => (
                                                                                                    <div key={index} style={{
                                                                                                        marginBottom: '10px',
                                                                                                        marginLeft: 30,
                                                                                                        display: 'flex'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Inmunización: {immunization.immunization ? immunization.immunization : "Sin registro"}</span><br/>
                                                                                                            {immunization.application_year && (
                                                                                                                <div>
                                                                                                                    <span>Año de aplicación: {immunization.application_year ? immunization.application_year : "Sin registro"}</span>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span>Sin registro de inmunizaciones</span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.immunization_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.immunization_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.tobacco_present &&
                                                                !adminPatient.history_info?.medical_history?.tobacco_suspension_time &&
                                                                !adminPatient.history_info?.medical_history?.cigarettes_per_day &&
                                                                !adminPatient.history_info?.medical_history?.smoking_years &&
                                                                !adminPatient.history_info?.medical_history?.tobacco_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Consumo de tabaco:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.tobacco_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Consumo presente: {adminPatient.history_info?.medical_history?.tobacco_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.tobacco_suspension_time ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>(En caso de) Años de suspensión: {adminPatient.history_info?.medical_history?.tobacco_suspension_time || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.cigarettes_per_day ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>(En caso de) Cigarrillos por día: {adminPatient.history_info?.medical_history?.cigarettes_per_day || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.smoking_years ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>(En caso de) Años de consumo: {adminPatient.history_info?.medical_history?.smoking_years || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.smoking_index ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Índice tabáquico: {adminPatient.history_info?.medical_history?.smoking_index || "Sin registro"} paquete-año</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.tobacco_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.tobacco_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.alcohol_present &&
                                                                !adminPatient.history_info?.medical_history?.alcohol_suspension_time &&
                                                                (!adminPatient.history_info?.alcohols || adminPatient.history_info.alcohols.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.alcohol_consumed_years &&
                                                                !adminPatient.history_info?.medical_history?.alcohol_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Consumo de alcohol:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.alcohol_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Consumo presente: {adminPatient.history_info?.medical_history?.alcohol_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.alcohol_suspension_time ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>(En caso de) Años de suspensión: {adminPatient.history_info?.medical_history?.alcohol_suspension_time || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.alcohols && adminPatient.history_info.alcohols.length > 0) ? (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        {adminPatient.history_info?.alcohols && adminPatient.history_info.alcohols.length > 0 ? (
                                                                                            <div style={{marginBottom: '20px'}}>
                                                                                                {adminPatient.history_info.alcohols.map((alcohol, index) => (
                                                                                                    <div key={index} style={{
                                                                                                        marginBottom: '10px',
                                                                                                        marginLeft: 30,
                                                                                                        display: 'flex'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Tipo de bebida: {alcohol.alcohol ? alcohol.alcohol : "Sin registro"}</span><br/>
                                                                                                            {alcohol.quantity_and_frequency && (
                                                                                                                <div>
                                                                                                                    <span>Cantidad y frecuencia: {alcohol.quantity_and_frequency ? alcohol.quantity_and_frequency : "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span>Sin registro de bebidas alcohólicas</span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.alcohol_consumed_years ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>(En caso de) Años de consumo: {adminPatient.history_info?.medical_history?.alcohol_consumed_years || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.alcohol_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.alcohol_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.drug_present &&
                                                                (!adminPatient.history_info?.drugs || adminPatient.history_info.drugs.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.drug_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Consumo de drogas:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.drug_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Consumo presente: {adminPatient.history_info?.medical_history?.drug_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.drugs && adminPatient.history_info.drugs.length > 0) ? (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        {adminPatient.history_info?.drugs && adminPatient.history_info.drugs.length > 0 ? (
                                                                                            <div style={{marginBottom: '20px'}}>
                                                                                                {adminPatient.history_info.drugs.map((drug, index) => (
                                                                                                    <div key={index} style={{
                                                                                                        marginBottom: '10px',
                                                                                                        marginLeft: 30,
                                                                                                        display: 'flex'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Droga: {drug.drug ? drug.drug : "Sin registro"}</span><br/>
                                                                                                            {drug.dose && (
                                                                                                                <div>
                                                                                                                    <span>Dosis: {drug.dose ? drug.dose : "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            {drug.consumed_frequency && (
                                                                                                                <div>
                                                                                                                    <span>Frecuencia de consumo: {drug.consumed_frequency ? drug.consumed_frequency : "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            {drug.consumed_years && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de consumo: {drug.consumed_years ? drug.consumed_years : "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Suspendido: {drug.suspended !== null ? (drug.suspended ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                            {drug.suspension_years && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de suspensión: {drug.suspension_years ? drug.suspension_years : "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span>Sin registro de drogas</span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.drug_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.drug_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.physical_activity_present &&
                                                                (!adminPatient.history_info?.physical_activities || adminPatient.history_info.physical_activities.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.drug_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Actividades físicas:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.physical_activity_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Actividad presente: {adminPatient.history_info?.medical_history?.physical_activity_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.physical_activities && adminPatient.history_info.physical_activities.length > 0) ? (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        {adminPatient.history_info?.physical_activities && adminPatient.history_info.physical_activities.length > 0 ? (
                                                                                            <div style={{marginBottom: '20px'}}>
                                                                                                {adminPatient.history_info.physical_activities.map((activity, index) => (
                                                                                                    <div key={index} style={{
                                                                                                        marginBottom: '10px',
                                                                                                        marginLeft: 30,
                                                                                                        display: 'flex'
                                                                                                    }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Actividad física: {activity.activity ? activity.activity : "Sin registro"}</span><br/>
                                                                                                            {activity.time != null && activity.time > 0 && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo: {activity.time} min</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            {activity.frequency != null && activity.frequency > 0 && (
                                                                                                                <div>
                                                                                                                    <span>Frecuencia: {activity.frequency} veces por semana</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <span>Sin registro de actividades físicas</span>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.physical_activity_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.physical_activity_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : null}

                                            {adminPatient.history_info?.medical_history?.contraceptive_present ||
                                            (adminPatient.history_info?.medical_history?.intrauterine && adminPatient.history_info?.medical_history?.intrauterine === null) ||
                                            (adminPatient.history_info?.intrauterines && adminPatient.history_info.intrauterines.length > 0) ||
                                            (adminPatient.history_info?.medical_history?.injectable && adminPatient.history_info?.medical_history?.injectable === null) ||
                                            (adminPatient.history_info?.injectables && adminPatient.history_info.injectables.length > 0) ||
                                            (adminPatient.history_info?.medical_history?.barrier_method && adminPatient.history_info?.medical_history?.barrier_method === null) ||
                                            (adminPatient.history_info?.barriers && adminPatient.history_info.barriers.length > 0) ||
                                            (adminPatient.history_info?.medical_history?.natural_method && adminPatient.history_info?.medical_history?.natural_method === null) ||
                                            (adminPatient.history_info?.naturals && adminPatient.history_info.naturals.length > 0) ||
                                            (adminPatient.history_info?.medical_history?.surgical_method && adminPatient.history_info?.medical_history?.surgical_method === null) ||
                                            (adminPatient.history_info?.surgicals && adminPatient.history_info.surgicals.length > 0) ||
                                            (adminPatient.history_info?.medical_history?.oral && adminPatient.history_info?.medical_history?.oral === null) ||
                                            (adminPatient.history_info?.orals && adminPatient.history_info.orals.length > 0) ||
                                            (adminPatient.history_info?.medical_history?.subdermal && adminPatient.history_info?.medical_history?.subdermal === null) ||
                                            (adminPatient.history_info?.subdermals && adminPatient.history_info.subdermals.length > 0) ||
                                            (adminPatient.history_info?.medical_history?.other && adminPatient.history_info?.medical_history?.other === null) ||
                                            (adminPatient.history_info?.others && adminPatient.history_info.others.length > 0) ||
                                            adminPatient.history_info?.medical_history?.method_notes ||
                                            adminPatient.history_info?.medical_history?.menstrual_cycle_present ||
                                            adminPatient.history_info?.medical_history?.cycle_frequency ||
                                            adminPatient.history_info?.medical_history?.dysmenorrhea_present ||
                                            adminPatient.history_info?.medical_history?.menstrual_cycle_notes ||
                                            adminPatient.history_info?.medical_history?.pregnancy_present ||
                                            adminPatient.history_info?.medical_history?.pregnancies ||
                                            adminPatient.history_info?.medical_history?.births ||
                                            adminPatient.history_info?.medical_history?.abortions ||
                                            adminPatient.history_info?.medical_history?.deaths ||
                                            adminPatient.history_info?.medical_history?.cesarean ||
                                            adminPatient.history_info?.medical_history?.alive ||
                                            adminPatient.history_info?.medical_history?.dead ||
                                            (adminPatient.history_info?.pregnancies && adminPatient.history_info.pregnancies.length > 0) ||
                                            adminPatient.history_info?.medical_history?.pregnancy_notes ||
                                            adminPatient.history_info?.medical_history?.cytology_mammogram_present ||
                                            (adminPatient.history_info?.cytologies && adminPatient.history_info.cytologies.length > 0) ||
                                            (adminPatient.history_info?.mammograms && adminPatient.history_info.mammograms.length > 0) ||
                                            adminPatient.history_info?.medical_history?.cytology_mammogram_notes ? (
                                                <>
                                                    <div style={{
                                                        textAlign: 'left',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        marginTop: '30px',
                                                        marginLeft: '70px',
                                                        marginBottom: '7px'
                                                    }}>
                                                        Antecedentes Ginecólogos
                                                    </div>

                                                    <Table className="table-nowrap mb-0">
                                                        <tbody>
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.contraceptive_present &&
                                                                (!adminPatient.history_info?.medical_history?.intrauterine || adminPatient.history_info?.medical_history?.intrauterine === null) &&
                                                                (!adminPatient.history_info?.intrauterines || adminPatient.history_info.intrauterines.length === 0) &&
                                                                (!adminPatient.history_info?.medical_history?.injectable || adminPatient.history_info?.medical_history?.injectable === null) &&
                                                                (!adminPatient.history_info?.injectables || adminPatient.history_info.injectables.length === 0) &&
                                                                (!adminPatient.history_info?.medical_history?.barrier_method || adminPatient.history_info?.medical_history?.barrier_method === null) &&
                                                                (!adminPatient.history_info?.barriers || adminPatient.history_info.barriers.length === 0) &&
                                                                (!adminPatient.history_info?.medical_history?.natural_method || adminPatient.history_info?.medical_history?.natural_method === null) &&
                                                                (!adminPatient.history_info?.naturals || adminPatient.history_info.naturals.length === 0) &&
                                                                (!adminPatient.history_info?.medical_history?.surgical_method || adminPatient.history_info?.medical_history?.surgical_method === null) &&
                                                                (!adminPatient.history_info?.surgicals || adminPatient.history_info.surgicals.length === 0) &&
                                                                (!adminPatient.history_info?.medical_history?.oral || adminPatient.history_info?.medical_history?.oral === null) &&
                                                                (!adminPatient.history_info?.orals || adminPatient.history_info.orals.length === 0) &&
                                                                (!adminPatient.history_info?.medical_history?.subdermal || adminPatient.history_info?.medical_history?.subdermal === null) &&
                                                                (!adminPatient.history_info?.subdermals || adminPatient.history_info.subdermals.length === 0) &&
                                                                (!adminPatient.history_info?.medical_history?.other || adminPatient.history_info?.medical_history?.other === null) &&
                                                                (!adminPatient.history_info?.others || adminPatient.history_info.others.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.method_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Planificación:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.contraceptive_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Planificación presente: {adminPatient.history_info?.medical_history?.contraceptive_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.intrauterine &&
                                                                                    (!adminPatient.history_info?.intrauterines ||
                                                                                        adminPatient.history_info.intrauterines.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Método intrauterino: {adminPatient.history_info?.medical_history?.intrauterine === true ? 'Sí' : adminPatient.history_info?.medical_history?.intrauterine === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.intrauterines && adminPatient.history_info.intrauterines.length > 0 ? (
                                                                                                adminPatient.history_info.intrauterines.map((intrauterine, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Método: {intrauterine.method || "Sin registro"}</span><br/>
                                                                                                            {intrauterine.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {intrauterine.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {intrauterine.actual_method !== null ? (intrauterine.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.injectable &&
                                                                                    (!adminPatient.history_info?.injectables ||
                                                                                        adminPatient.history_info.injectables.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Método inyectable: {adminPatient.history_info?.medical_history?.injectable === true ? 'Sí' : adminPatient.history_info?.medical_history?.injectable === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.injectables && adminPatient.history_info.injectables.length > 0 ? (
                                                                                                adminPatient.history_info.injectables.map((injectable, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Medicamento: {injectable.medicine || "Sin registro"}</span><br/>
                                                                                                            {injectable.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {injectable.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {injectable.actual_method !== null ? (injectable.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.barrier_method &&
                                                                                    (!adminPatient.history_info?.barriers ||
                                                                                        adminPatient.history_info.barriers.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Método de barrera: {adminPatient.history_info?.medical_history?.barrier_method === true ? 'Sí' : adminPatient.history_info?.medical_history?.barrier_method === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.barriers && adminPatient.history_info.barriers.length > 0 ? (
                                                                                                adminPatient.history_info.barriers.map((barrier, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Método: {barrier.method || "Sin registro"}</span><br/>
                                                                                                            {barrier.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {barrier.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {barrier.actual_method !== null ? (barrier.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.natural_method &&
                                                                                    (!adminPatient.history_info?.naturals ||
                                                                                        adminPatient.history_info.naturals.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Método natural: {adminPatient.history_info?.medical_history?.natural_method === true ? 'Sí' : adminPatient.history_info?.medical_history?.natural_method === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.naturals && adminPatient.history_info.naturals.length > 0 ? (
                                                                                                adminPatient.history_info.naturals.map((natural, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Método: {natural.method || "Sin registro"}</span><br/>
                                                                                                            {natural.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {natural.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {natural.actual_method !== null ? (natural.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.surgical_method &&
                                                                                    (!adminPatient.history_info?.surgicals ||
                                                                                        adminPatient.history_info.surgicals.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Método quirúrgico: {adminPatient.history_info?.medical_history?.surgical_method === true ? 'Sí' : adminPatient.history_info?.medical_history?.surgical_method === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.surgicals && adminPatient.history_info.surgicals.length > 0 ? (
                                                                                                adminPatient.history_info.surgicals.map((surgical, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Método: {surgical.method || "Sin registro"}</span><br/>
                                                                                                            {surgical.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {surgical.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {surgical.actual_method !== null ? (surgical.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.oral &&
                                                                                    (!adminPatient.history_info?.orals ||
                                                                                        adminPatient.history_info.orals.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Método oral: {adminPatient.history_info?.medical_history?.oral === true ? 'Sí' : adminPatient.history_info?.medical_history?.oral === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.orals && adminPatient.history_info.orals.length > 0 ? (
                                                                                                adminPatient.history_info.orals.map((oral, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Medicamento: {oral.medicine || "Sin registro"}</span><br/>
                                                                                                            {oral.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {oral.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {oral.actual_method !== null ? (oral.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.subdermal &&
                                                                                    (!adminPatient.history_info?.subdermals ||
                                                                                        adminPatient.history_info.subdermals.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Método subdérmico: {adminPatient.history_info?.medical_history?.subdermal === true ? 'Sí' : adminPatient.history_info?.medical_history?.subdermal === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.subdermals && adminPatient.history_info.subdermals.length > 0 ? (
                                                                                                adminPatient.history_info.subdermals.map((subdermal, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Método: {subdermal.method || "Sin registro"}</span><br/>
                                                                                                            {subdermal.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {subdermal.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {subdermal.actual_method !== null ? (subdermal.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {(!adminPatient.history_info?.medical_history?.other &&
                                                                                    (!adminPatient.history_info?.others ||
                                                                                        adminPatient.history_info.others.length === 0)) ? (
                                                                                    null
                                                                                ) : (
                                                                                    <>
                                                                                        <div style={{
                                                                                            marginBottom: '10px',
                                                                                            marginTop: 30
                                                                                        }}>
                                                                                            <span>Otro método: {adminPatient.history_info?.medical_history?.other === true ? 'Sí' : adminPatient.history_info?.medical_history?.other === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                        </div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            {adminPatient.history_info?.others && adminPatient.history_info.others.length > 0 ? (
                                                                                                adminPatient.history_info.others.map((other, index) => (
                                                                                                    <div key={index}
                                                                                                         style={{
                                                                                                             marginBottom: '10px',
                                                                                                             marginLeft: 30,
                                                                                                             display: 'flex'
                                                                                                         }}>
                                                                                                        <div
                                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span>Método: {other.method || "Sin registro"}</span><br/>
                                                                                                            {other.time && (
                                                                                                                <div>
                                                                                                                    <span>Tiempo de uso: {other.time || "Sin registro"}</span><br/>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <span>Método actual: {other.actual_method !== null ? (other.actual_method ? "Sí" : "No") : "Sin registro"}</span><br/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.method_notes ? (
                                                                                    <div>
                                                                                        <div style={{marginTop: 10}}>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.method_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.menstrual_cycle_present &&
                                                                !adminPatient.history_info?.medical_history?.cycle_frequency &&
                                                                !adminPatient.history_info?.medical_history?.dysmenorrhea_present &&
                                                                !adminPatient.history_info?.medical_history?.menstrual_cycle_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{width: '300px'}}>Ciclo
                                                                        menstrual:
                                                                    </th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.menstrual_cycle_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Ciclo menstrual presente: {adminPatient.history_info?.medical_history?.menstrual_cycle_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.cycle_frequency ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Frecuencia del ciclo: {adminPatient.history_info?.medical_history?.cycle_frequency || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.dysmenorrhea_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Dismenorrea presente: {adminPatient.history_info?.medical_history?.dysmenorrhea_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.menstrual_cycle_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.menstrual_cycle_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.pregnancy_present &&
                                                                !adminPatient.history_info?.medical_history?.pregnancies &&
                                                                !adminPatient.history_info?.medical_history?.births &&
                                                                !adminPatient.history_info?.medical_history?.abortions &&
                                                                !adminPatient.history_info?.medical_history?.deaths &&
                                                                !adminPatient.history_info?.medical_history?.cesarean &&
                                                                !adminPatient.history_info?.medical_history?.alive &&
                                                                !adminPatient.history_info?.medical_history?.dead &&
                                                                !adminPatient.history_info?.medical_history?.pregnancy_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Embarazos:</th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.pregnancy_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Embarazos presente: {adminPatient.history_info?.medical_history?.pregnancy_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            <div>
                                                                                {adminPatient.history_info?.medical_history?.pregnancies > 0 && (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Gestas: {adminPatient.history_info.medical_history.pregnancies}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {adminPatient.history_info?.medical_history?.births > 0 && (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Partos: {adminPatient.history_info?.medical_history?.births || "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {adminPatient.history_info?.medical_history?.abortions > 0 && (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Abortos: {adminPatient.history_info?.medical_history?.abortions || "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {adminPatient.history_info?.medical_history?.deaths > 0 && (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Óbitos: {adminPatient.history_info?.medical_history?.deaths || "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {adminPatient.history_info?.medical_history?.cesarean > 0 && (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Cesáreas: {adminPatient.history_info?.medical_history?.cesarean || "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {adminPatient.history_info?.medical_history?.alive > 0 && (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Vivos: {adminPatient.history_info?.medical_history?.alive || "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {adminPatient.history_info?.medical_history?.dead > 0 && (
                                                                                    <div style={{marginBottom: '10px'}}>
                                                                                        <span>Muertos: {adminPatient.history_info?.medical_history?.dead || "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                {adminPatient.history_info?.pregnancies && adminPatient.history_info.pregnancies.length > 0 ? (
                                                                                    adminPatient.history_info.pregnancies.map((pregnancy, index) => (
                                                                                        <div key={index}
                                                                                             style={{marginBottom: '10px'}}>
                                                                                            <div style={{
                                                                                                marginBottom: '10px',
                                                                                                marginLeft: 30,
                                                                                                display: 'flex'
                                                                                            }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    {pregnancy.due_date && (
                                                                                                        <div>
                                                                                                            <span>Fecha de parto: {new Date(pregnancy.due_date).toLocaleDateString('es-ES')}</span><br/>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {pregnancy.labor != null && pregnancy.labor > 0 && (
                                                                                                        <div>
                                                                                                            <span>Trabajo de parto: {pregnancy.labor} horas</span><br/>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {pregnancy.gestational_age && (
                                                                                                        <div>
                                                                                                            <span>Edad gestacional: {pregnancy.gestational_age}</span><br/>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    <span>Parto múltiple: {pregnancy.multiple_birth !== null ? (pregnancy.multiple_birth ? "Sí" : "No") : ""}</span><br/>
                                                                                                    {pregnancy.pregnancies && pregnancy.pregnancies.length > 0 && (
                                                                                                        <div>
                                                                                                            {pregnancy.pregnancies.map((childPregnancy, index) => (
                                                                                                                <div
                                                                                                                    key={index}
                                                                                                                    style={{
                                                                                                                        marginLeft: 30,
                                                                                                                        display: 'flex'
                                                                                                                    }}>
                                                                                                                    <div
                                                                                                                        style={{marginRight: '10px'}}>{index + 1}.
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        {childPregnancy.weight != null && childPregnancy.weight > 0 && (
                                                                                                                            <div>
                                                                                                                                <span>Peso: {childPregnancy.weight} horas</span><br/>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                        {childPregnancy.gender && (
                                                                                                                            <div>
                                                                                                                                <span>Género: {childPregnancy.gender}</span><br/>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                        {childPregnancy.birth_method && (
                                                                                                                            <div>
                                                                                                                                <span>Tipo de parto: {childPregnancy.birth_method}</span><br/>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                        {childPregnancy.neonate && (
                                                                                                                            <div>
                                                                                                                                <span>Recién nacido: {childPregnancy.neonate}</span>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {pregnancy.pregnancy_observations && (
                                                                                                        <div>
                                                                                                            <br/><span>Observaciones del embarazo: {pregnancy.pregnancy_observations}</span><br/>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                ) : null}
                                                                            </div>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.pregnancy_notes ? (
                                                                                    <div>
                                                                                        <div>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.pregnancy_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.cytology_mammogram_present &&
                                                                (!adminPatient.history_info?.cytologies || adminPatient.history_info.cytologies.length === 0) &&
                                                                (!adminPatient.history_info?.mammograms || adminPatient.history_info.mammograms.length === 0) &&
                                                                !adminPatient.history_info?.medical_history?.cytology_mammogram_notes) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{width: '300px'}}>Citologías
                                                                        y mamografías:
                                                                    </th>
                                                                    <td>
                                                                        <>
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.cytology_mammogram_present ? (
                                                                                    <div>
                                                                                        <div
                                                                                            style={{marginBottom: '10px'}}>
                                                                                            <span>Citología o mamografía presente: {adminPatient.history_info?.medical_history?.cytology_mammogram_present || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.cytologies && adminPatient.history_info.cytologies.length > 0) ? (
                                                                                    <div>
                                                                                        {((!adminPatient.history_info?.cytologies || adminPatient.history_info.cytologies.length === 0)) ? (
                                                                                            <span>No hay registro de cytologías</span>
                                                                                        ) : (
                                                                                            <>
                                                                                                <span>Citologías</span>
                                                                                                <div
                                                                                                    style={{marginBottom: '10px'}}>
                                                                                                    {adminPatient.history_info?.cytologies && adminPatient.history_info.cytologies.length > 0 ? (
                                                                                                        adminPatient.history_info.cytologies.map((cytology, index) => (
                                                                                                            <div key={index}
                                                                                                                 style={{
                                                                                                                     marginBottom: '10px',
                                                                                                                     marginLeft: 30,
                                                                                                                     display: 'flex'
                                                                                                                 }}>
                                                                                                                <div
                                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {cytology.date && (
                                                                                                                        <div>
                                                                                                                            <span>Fecha: {cytology.date ? cytology.date : "Sin registro"}</span><br/>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    {cytology.result && (
                                                                                                                        <div>
                                                                                                                            <span>Resultado: {cytology.result ? cytology.result : "Sin registro"}</span><br/>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    {cytology.observations && (
                                                                                                                        <div>
                                                                                                                            <span>Observaciones: {cytology.observations ? cytology.observations : "Sin registro"}</span><br/>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <span>No hay registro de cytologías</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                (adminPatient.history_info?.mammograms && adminPatient.history_info.mammograms.length > 0) ? (
                                                                                    <div>
                                                                                        {((!adminPatient.history_info?.mammograms ||
                                                                                            adminPatient.history_info.mammograms.length === 0)) ? (
                                                                                            <span>No hay registro de mamografías</span>
                                                                                        ) : (
                                                                                            <>
                                                                                                <span>Mamografías</span>
                                                                                                <div style={{marginBottom: '10px'}}>
                                                                                                    {adminPatient.history_info?.mammograms && adminPatient.history_info.mammograms.length > 0 ? (
                                                                                                        adminPatient.history_info.mammograms.map((mammogram, index) => (
                                                                                                            <div key={index}
                                                                                                                 style={{
                                                                                                                     marginBottom: '10px',
                                                                                                                     marginLeft: 30,
                                                                                                                     display: 'flex'
                                                                                                                 }}>
                                                                                                                <div
                                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {mammogram.date && (
                                                                                                                        <div>
                                                                                                                            <span>Fecha: {mammogram.date ? mammogram.date : "Sin registro"}</span><br/>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    {mammogram.result && (
                                                                                                                        <div>
                                                                                                                            <span>Resultado: {mammogram.result ? mammogram.result : "Sin registro"}</span><br/>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    {mammogram.observations && (
                                                                                                                        <div>
                                                                                                                            <span>Observaciones: {mammogram.observations ? mammogram.observations : "Sin registro"}</span><br/>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <span>No hay registro de mamografías</span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                            {
                                                                                adminPatient.history_info?.medical_history?.cytology_mammogram_notes ? (
                                                                                    <div>
                                                                                        <div style={{marginTop: 10}}>
                                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.cytology_mammogram_notes || "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : null}

                                            {(adminPatient.history_info?.questions && adminPatient.history_info.questions.length > 0) ||
                                            adminPatient.history_info?.medical_history?.general_observations  ? (
                                                <>
                                                    <div style={{
                                                        textAlign: 'left',
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        marginTop: '30px',
                                                        marginLeft: '70px',
                                                        marginBottom: '7px'
                                                    }}>
                                                        Información extra
                                                    </div>

                                                    <Table className="table-nowrap mb-0">
                                                        <tbody>
                                                        {
                                                            ((!adminPatient.history_info?.questions || adminPatient.history_info.questions.length === 0)) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Preguntas personalizadas:</th>
                                                                    <td>
                                                                        <>
                                                                            <div style={{marginBottom: '10px'}}>
                                                                                {adminPatient.history_info?.questions && adminPatient.history_info.questions.length > 0 ? (
                                                                                    <div style={{marginBottom: '20px'}}>
                                                                                        {adminPatient.history_info.questions.map((question, index) => (
                                                                                            <div key={index} style={{
                                                                                                marginBottom: '10px',
                                                                                                marginLeft: 30,
                                                                                                display: 'flex'
                                                                                            }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Pregunta: {question.question ? question.question : "Sin registro"}</span><br/>
                                                                                                    <span>Respuesta: {question.answer ? question.answer : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                ) : (
                                                                                    <span>Sin registro de preguntas personalizadas</span>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        {
                                                            (!adminPatient.history_info?.medical_history?.general_observations) ? null : (
                                                                <tr>
                                                                    <th scope="row" style={{ width: '300px' }}>Observaciones generales:</th>
                                                                    <td>
                                                                        <>
                                                                            <div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    <span>{adminPatient.history_info?.medical_history?.general_observations || "Sin registro"}</span><br/>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminPatientMedicalHistoryDetail)

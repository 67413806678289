import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Toast,
  ToastHeader, ToastBody
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import {registerUserThunk, resetRegisterMsgFlagThunk} from "../../slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.png";
import { createSelector } from 'reselect';
import {cleanErrors} from "../../slices/admin/thunk";
import withRouter from "Components/Common/withRouter";


const Register = (props: any) => {
  const [show, setShow] = useState(false);
  //meta title
  document.title = "Registro | Farmabits";

  const dispatch = useDispatch<any>();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor, ingresa tu Email"),
      first_name: Yup.string().required("Por favor, ingresa tu  Nombre"),
      last_name: Yup.string().required("Por favor, ingresa tus Apellidos"),
      password: Yup.string().required("Por favor, ingresa tu  Contraseña"),
    }),
    onSubmit: (values) => {
      dispatch(registerUserThunk(values, props.router.navigate));
    }
  });

 const selectProperties = createSelector(
    (state: any) => state.Account,
    (item) => ({
      user: item.user,
      registrationError: item.registrationError,
      loading: item.loading,
      reducerToastTitle: item.reducerToastTitle,
      reducerToastMessage: item.reducerToastMessage,
    })
  );

  const {loading, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

  // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
  // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
  const [toast, setToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const closeToast = () => {
    setToast(false);
    dispatch(cleanErrors()); // Limpia los errores cuando el toast se cierra
  };

  useEffect(() => {
    if (reducerToastMessage !== "") {
      console.log("showToastWithMessage=", reducerToastMessage);
      setToast(true);
      setToastTitle(reducerToastTitle);
      setToastMessage(reducerToastMessage);
      setTimeout(() => {
        setToast(false);
        dispatch(resetRegisterMsgFlagThunk())
      }, 3000);
    }
  }, [dispatch, reducerToastTitle, reducerToastMessage]);


  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Registro</h5>
                        <p>Inicia tu periodo de prueba gratis.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                    >


                      <div className="mb-3">
                        <Label className="form-label">Nombre</Label>
                        <Input
                            name="first_name"
                            type="text"
                            placeholder="Ingresar Nombre"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                            invalid={
                              validation.touched.first_name && validation.errors.first_name ? true : false
                            }
                        />
                        {validation.touched.first_name && validation.errors.first_name ? (
                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Apellidos</Label>
                        <Input
                            name="last_name"
                            type="text"
                            placeholder="Ingresar Apellidos"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ""}
                            invalid={
                              validation.touched.last_name && validation.errors.last_name ? true : false
                            }
                        />
                        {validation.touched.last_name && validation.errors.last_name ? (
                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Ingresar email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                        />
                        {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Contraseña</Label>
                        <div className="input-group auth-pass-inputgroup">
                        <Input
                            name="password"
                            type={show ? "text" : "password"}
                            placeholder="Ingresar Contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                        />
                        <button onClick={() => setShow(!show)} className="btn btn-light " type="button"
                                id="password-addon">
                          <i className="mdi mdi-eye-outline"></i></button>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                            className="btn btn-primary btn-block "
                            type="submit"
                        >
                          Registrar
                        </button>
                      </div>
                      <div className="position-fixed top-0 end-0 p-3" style={{zIndex: "1005"}}>
                        <Toast isOpen={toast}>
                          <ToastHeader toggle={closeToast}>
                            {toastTitle}
                          </ToastHeader>
                          <ToastBody>
                            {toastMessage}
                          </ToastBody>
                        </Toast>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Al registrarte aceptas los términos y condiciones de Farmabits{" "}
                          <Link to="#" className="text-primary">
                            Términos y condiciones
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  ¿Ya tienes una cuenta?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Hecho con{" "}
                  <i className="mdi mdi-heart text-danger"/> por Farmabits
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);

import React, { useState } from "react"
import {
    Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody, FormGroup, Table
} from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import {cleanErrors, getAdminConsultationThunk, consultationEditThunk} from "../../slices/consultation/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";
import {getAdminPatientMedicalHistoryThunk} from "../../slices/admin/thunk";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {Link, useLocation, useParams} from "react-router-dom";
import {Consultation, Doctor, Patient} from "../../entities/types";
import {isEmpty} from "lodash";
import {getAdminPatientThunk, patientEditThunk} from "../../slices/admin/thunk";
import {Spanish} from "flatpickr/dist/l10n/es";

interface selectState {
    consultation: {
        loading: false;
        consultation: Consultation;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
    admin: {
        loading: false;
        adminPatient: Patient;
        doctor: Doctor;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};


const AdminConsultationEdit = (props: any) => {
    const dispatch: any = useDispatch();
    const {id} = useParams();

    //meta title
    document.title = "Consulta";

    const selectProperties = createSelector(
        (state: selectState) => state.consultation,
        (state: selectState) => state.admin,
        (item, admin) => ({
            loading: item.loading,
            consultation: item.consultation,
            adminPatient: admin.adminPatient,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, consultation, adminPatient, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    const SystemOptions = [
        { val: "Articular", label: 'Articular' },
        { val: "Cardiovascular", label: 'Cardiovascular' },
        { val: "Circulatorio", label: 'Circulatorio' },
        { val: "Digestivo", label: 'Digestivo' },
        { val: "Endócrino", label: 'Endócrino' },
        { val: "Esquelético", label: 'Esquelético' },
        { val: "Excretor", label: 'Excretor' },
        { val: "Inmunitario", label: 'Inmunitario' },
        { val: "Integumentario", label: 'Integumentario' },
        { val: "Linfático", label: 'Linfático' },
        { val: "Locomotor", label: 'Locomotor' },
        { val: "Muscular", label: 'Muscular' },
        { val: "Nervioso", label: 'Nervioso' },
        { val: "Reproductor", label: 'Reproductor' },
        { val: "Respiratorio", label: 'Respiratorio' },
        { val: "Otro", label: 'Otro' },
    ]

    const SegmentOptions = [
        { val: "Abdomen", label: 'Abdomen' },
        { val: "Cabeza", label: 'Cabeza' },
        { val: "Columna vertebral", label: 'Columna vertebral' },
        { val: "Cuello", label: 'Cuello' },
        { val: "Genitales", label: 'Genitales' },
        { val: "Miembros inferiores", label: 'Miembros inferiores' },
        { val: "Miembros superiores", label: 'Miembros superiores' },
        { val: "Tórax", label: 'Tórax' },
    ]

    const StatusOptions = [
        { val: "pending", label: 'Sin confirmar' },
        { val: "confirmed", label: 'Confirmado' },
        { val: "finished", label: 'Terminado' },
    ]

    const today = new Date().toISOString();

    const formik: any = useFormik({
        initialValues: {
            id: '',
            monitoring: '',
            status: '',
            consultation_reason: '',
            ailment: '',
            vital_sign_notes: '',
            systolic_blood_pressure: null,
            diastolic_blood_pressure: null,
            heart_rate: null,
            blood_oxygen_saturation: null,
            temperature: null,
            respiratory_rate: null,
            glucose: null,
            weight: null,
            height: null,
            body_mass_index: null,
            menstrual_cycle_date: '',
            diagnosis: '',
            diagnosis_observations: '',
            extra_notes: '',
            questions: [{ question: '', answer: '' }],
            systems: [{ system: '', normal: null, observations: '' }],
            examinations: [{ segment: '', normal: null, observations: '' }],
            diagnoses: [{ diagnosis: '' }],
            prescriptions: [{ indications: '', date: today, prescriptions: [{ medication: '', instruction: '' }] }],
            laboratories: [{ laboratories: [{ procedure: '' }] }],
            laboratory_results: [{ laboratory: '', result: '' }],
            study_results: [{ study: '', result: '' }]
        },
        onSubmit: (values: any) => {
            console.log('Basic Information', values);

            const questionErrors = {};
            values.questions.forEach((question, index) => {
                const answerIsEmpty = question.answer.trim() === '';
                const questionIsEmpty = question.question.trim() === '';

                if (!answerIsEmpty || !questionIsEmpty) {
                    if (answerIsEmpty && !questionIsEmpty) {
                        questionErrors[`questions[${index}].answer`] = 'Campo requerido';
                    } else if (questionIsEmpty && !answerIsEmpty) {
                        questionErrors[`questions[${index}].question`] = 'Campo requerido';
                    }
                }
            });

            const systemErrors = {};
            values.systems.forEach((system, index) => {
                const isSystemFilled = system.system && system.system.trim() !== '';
                const isObservationsFilled = system.observations && system.observations.trim() !== '';
                const isNormalChecked = system.normal === true || system.normal === false;

                if (!isSystemFilled && isNormalChecked) {
                    systemErrors[`systems[${index}].system`] = 'Campo requerido';
                }
                if (isSystemFilled && !isNormalChecked) {
                    systemErrors[`systems[${index}].normal`] = 'Seleccione una opción';
                }
                if (isObservationsFilled && !isSystemFilled && !isNormalChecked) {
                    systemErrors[`systems[${index}].system`] = 'Campo requerido';
                    systemErrors[`systems[${index}].normal`] = 'Seleccione una opción';
                }
            });

            const examinationErrors = {};
            values.examinations.forEach((examination, index) => {
                const isSegmentFilled = examination.segment && examination.segment.trim() !== '';
                const isObservationsFilled = examination.observations && examination.observations.trim() !== '';
                const isNormalChecked = examination.normal === true || examination.normal === false;

                if (!isSegmentFilled && isNormalChecked) {
                    examinationErrors[`examinations[${index}].segment`] = 'Campo requerido';
                }
                if (isSegmentFilled && !isNormalChecked) {
                    examinationErrors[`examinations[${index}].normal`] = 'Seleccione una opción';
                }
                if (isObservationsFilled && !isSegmentFilled && !isNormalChecked) {
                    examinationErrors[`examinations[${index}].segment`] = 'Campo requerido';
                    examinationErrors[`examinations[${index}].normal`] = 'Seleccione una opción';
                }
            });

            const labErrors = {};
            values.laboratory_results.forEach((result, index) => {
                const labIsEmpty = result.laboratory && result.laboratory.trim() !== '';
                const resultIsEmpty = result.result && result.result.trim() !== '';

                if (!labIsEmpty && resultIsEmpty) {
                    labErrors[`laboratory_results[${index}].laboratory`] = 'Campo requerido';
                }
            });

            const studyErrors = {};
            values.study_results.forEach((result, index) => {
                const studyIsEmpty = result.study && result.study.trim() !== '';
                const resultIsEmpty = result.result && result.result.trim() !== '';

                if (!studyIsEmpty && resultIsEmpty) {
                    studyErrors[`study_results[${index}].study`] = 'Campo requerido';
                }
            });

            const prescriptionErrors = {};
            values.prescriptions.forEach((prescription, index) => {
                prescription.prescriptions.forEach((prescriptionItem, itemIndex) => {
                    const medicationIsEmpty = prescriptionItem.medication && prescriptionItem.medication.trim() !== '';
                    const instructionIsEmpty = prescriptionItem.instruction && prescriptionItem.instruction.trim() !== '';

                    if (!medicationIsEmpty || !instructionIsEmpty) {
                        if (medicationIsEmpty && !instructionIsEmpty) {
                            prescriptionErrors[`prescriptions[${index}].prescriptions[${itemIndex}].medication`] = 'Campo requerido';
                        } else if (instructionIsEmpty && !medicationIsEmpty) {
                            prescriptionErrors[`prescriptions[${index}].prescriptions[${itemIndex}].instruction`] = 'Campo requerido';
                        }
                    }
                });
            });

            const errors = { ...questionErrors, ...systemErrors, ...examinationErrors, ...labErrors, ...studyErrors, ...prescriptionErrors };

            if (Object.keys(errors).length > 0) {
                formik.setErrors(errors);
                setToastMessage("Por favor, complete todos los campos requeridos.");
                setToastTitle("Campos requeridos");
                setToast(true);
                return;
            }

            dispatch(consultationEditThunk(values, props.router.navigate));
        },
    });

    React.useEffect(() => {
        if (!isEmpty(consultation)) {

            formik.setValues({
                id: consultation.id || '',
                monitoring: consultation.monitoring || '',
                status: consultation.status || '',
                consultation_reason: consultation.consultation_reason || '',
                ailment: consultation.ailment || '',
                vital_sign_notes: consultation.vital_sign_notes || '',
                systolic_blood_pressure: consultation.systolic_blood_pressure || null,
                diastolic_blood_pressure: consultation.diastolic_blood_pressure || null,
                heart_rate: consultation.heart_rate || null,
                blood_oxygen_saturation: consultation.blood_oxygen_saturation || null,
                temperature: consultation.temperature || null,
                respiratory_rate: consultation.respiratory_rate || null,
                glucose: consultation.glucose || null,
                weight: consultation.weight || null,
                height: consultation.height || null,
                menstrual_cycle_date: consultation.menstrual_cycle_date || '',
                diagnosis: consultation.diagnosis || '',
                extra_notes: consultation.extra_notes || '',
                diagnosis_observations: consultation.diagnosis_observations || '',
                questions: (consultation.consultation_info.questions?.length > 0
                        ? consultation.consultation_info.questions.map(question => ({
                            question: question.question,
                            answer: question.answer,
                        }))
                        : [{ question: '', answer: '' }]
                ),
                systems: (consultation.consultation_info.systems?.length > 0
                        ? consultation.consultation_info.systems.map(system => ({
                            system: system.system,
                            normal: system.normal,
                            observations: system.observations,
                        }))
                        : [{ system: '', normal: null, observations: '' }]
                ),
                examinations: (consultation.consultation_info.examinations?.length > 0
                        ? consultation.consultation_info.examinations.map(examination => ({
                            segment: examination.segment,
                            normal: examination.normal,
                            observations: examination.observations,
                        }))
                        : [{ examination: '', normal: null, observations: '' }]
                ),
                diagnoses: (consultation.consultation_info.diagnoses?.length > 0
                        ? consultation.consultation_info.diagnoses.map(diagnosis => ({
                            diagnosis: diagnosis.diagnosis,
                        }))
                        : [{ diagnosis: '' }]
                ),
                prescriptions: (consultation.consultation_info.prescriptions?.length > 0
                        ? consultation.consultation_info.prescriptions.map(prescription => ({
                            indications: prescription.indications,
                            date: prescription.date || today,
                            prescriptions: prescription.prescriptions?.map(presc => ({
                                medication: presc.medication,
                                instruction: presc.instruction,
                            })) || [{ medication: '', instruction: '' }]
                        }))
                        : [{ indications: '', date: today, prescriptions: [{ medication: '', instruction: '' }] }]
                ),
                laboratories: (consultation.consultation_info.laboratories?.length > 0
                        ? consultation.consultation_info.laboratories.map(laboratory => ({
                            laboratories: laboratory.laboratories?.map(lab => ({
                                procedure: lab.procedure,
                            })) || [{ procedure: '' }]
                        }))
                        : [{ laboratories: [{ procedure: '' }] }]
                ),
                laboratory_results: (consultation.consultation_info.laboratory_results?.length > 0
                        ? consultation.consultation_info.laboratory_results.map(result => ({
                            laboratory: result.laboratory,
                            result: result.result,
                        }))
                        : [{ laboratory: '', result: '' }]
                ),
                study_results: (consultation.consultation_info.study_results?.length > 0
                        ? consultation.consultation_info.study_results.map(result => ({
                            study: result.study,
                            result: result.result,
                        }))
                        : [{ study: '', result: '' }]
                ),
            });
        }
    }, [consultation, formik.setValues]);

    React.useEffect(() => {
        if (id) { // Agrega una condición para asegurarte de que 'id' esté definido
            dispatch(getAdminConsultationThunk(id));
        }
    }, [id]);

    React.useEffect(() => {
        // Asegúrate de que 'consultation' y 'consultation.patient_id' existan
        if (consultation && consultation.patient_id) {
            const patientIdAsString = consultation.patient_id.toString();
            dispatch(getAdminPatientMedicalHistoryThunk(patientIdAsString));        }
    }, [consultation, dispatch]);

    const handleQuestionChange = (e, index, field) => {
        const { value } = e.target;
        const newQuestions = formik.values.questions.map((question, i) =>
            i === index ? { ...question, [field]: value } : question
        );
        formik.setFieldValue('questions', newQuestions);
    };

    const addQuestion = () => {
        const newQuestions = [...formik.values.questions, { question: '', answer: '' }];
        formik.setFieldValue('questions', newQuestions);
    };

    const removeQuestion = index => {
        const questions = formik.values.questions;

        if (questions.length === 1) {
            formik.setFieldValue('questions', []);
            return;
        }

        const newQuestions = questions.filter((_, i) => i !== index);
        formik.setFieldValue('questions', newQuestions);
    };

    const handleDiagnosesChange = (e, index, field) => {
        const { value } = e.target;
        const newDiagnoses = formik.values.diagnoses.map((diagnosis, i) =>
            i === index ? { ...diagnosis, [field]: value } : diagnosis
        );
        formik.setFieldValue('diagnoses', newDiagnoses);
    };

    const addDiagnoses = () => {
        const newDiagnoses = [...formik.values.diagnoses, { diagnosis: '' }];
        formik.setFieldValue('diagnoses', newDiagnoses);
    };

    const removeDiagnoses = index => {
        const diagnoses = formik.values.diagnoses;

        if (diagnoses.length === 1) {
            formik.setFieldValue('diagnoses', []);
            return;
        }

        const newDiagnoses = diagnoses.filter((_, i) => i !== index);
        formik.setFieldValue('diagnoses', newDiagnoses);
    };

    const handlePrescriptionsChange = (e, index, field) => {
        const { value } = e.target;
        const newPrescriptions = formik.values.prescriptions.map((diagnosis, i) =>
            i === index ? { ...diagnosis, [field]: value } : diagnosis
        );
        formik.setFieldValue('prescriptions', newPrescriptions);
    };

    const handlePrescriptionDateChange = (date, index, field) => {
        const NewPrescription = formik.values.prescriptions.map((presc, i) =>
            i === index ? { ...presc, [field]: date[0].toISOString() } : presc
        );
        formik.setFieldValue('prescriptions', NewPrescription);
    };

    const handleMedicationChange = (e, index, prescrIndex, field) => {
        const { value } = e.target;
        const newPrescriptions = formik.values.prescriptions.map((prescription, i) =>
            i === index
                ? {
                    ...prescription,
                    prescriptions: prescription.prescriptions.map((prescription, j) =>
                        j === prescrIndex ? { ...prescription, [field]: value } : prescription
                    ),
                }
                : prescription
        );
        formik.setFieldValue('prescriptions', newPrescriptions);
    };

    const addPrescriptions = () => {
        const currentDate = new Date();
        const newPrescriptions = [...formik.values.prescriptions, { indications: '', date: currentDate, prescriptions: [{ medication: '', instruction: '' }] }];
        formik.setFieldValue('prescriptions', newPrescriptions);
    };

    const addMedications = (prescrIndex) => {
        const newPrescriptions = formik.values.prescriptions.map((prescription, i) =>
            i === prescrIndex
                ? {
                    ...prescription,
                    prescriptions: [...prescription.prescriptions, { medication: '', instruction: '' }],
                }
                : prescription
        );
        formik.setFieldValue('prescriptions', newPrescriptions);
    };

    const removePrescriptions = index => {
        const prescriptions = formik.values.prescriptions;

        if (prescriptions.length === 1) {
            formik.setFieldValue('prescriptions', []);
            return;
        }

        const newPrescriptions = prescriptions.filter((_, i) => i !== index);
        formik.setFieldValue('prescriptions', newPrescriptions);
    };

    const removeMedication = (index, prescrIndex) => {
        const newPrescriptions = formik.values.prescriptions.map((prescription, i) =>
            i === index
                ? {
                    ...prescription,
                    prescriptions: prescription.prescriptions.filter((_, j) => j !== prescrIndex),
                }
                : prescription
        );

        formik.setFieldValue('prescriptions', newPrescriptions);
    };

    const handleLabChange = (e, index, labIndex, field) => {
        const { value } = e.target;
        const newLaboratories = formik.values.laboratories.map((laboratory, i) =>
            i === index
                ? {
                    ...laboratory,
                    laboratories: laboratory.laboratories.map((lab, j) =>
                        j === labIndex ? { ...lab, [field]: value } : lab
                    ),
                }
                : laboratory
        );
        formik.setFieldValue('laboratories', newLaboratories);
    };

    const addLaboratories = () => {
        const newLaboratories = [...formik.values.laboratories, { laboratories: [{ procedure: '' }] }];
        formik.setFieldValue('laboratories', newLaboratories);
    };

    const addLabs = (labIndex) => {
        const newLaboratories = formik.values.laboratories.map((laboratory, i) =>
            i === labIndex
                ? {
                    ...laboratory,
                    laboratories: [...laboratory.laboratories, { procedure: '' }],
                }
                : laboratory
        );
        formik.setFieldValue('laboratories', newLaboratories);
    };

    const removeLaboratory = index => {
        const laboratories = formik.values.laboratories;

        if (laboratories.length === 1) {
            formik.setFieldValue('laboratories', []);
            return;
        }

        const newLaboratories = laboratories.filter((_, i) => i !== index);
        formik.setFieldValue('laboratories', newLaboratories);
    };

    const removeLab = (index, labIndex) => {
        const newLaboratories = formik.values.laboratories.map((lab, i) =>
            i === index
                ? {
                    ...lab,
                    laboratories: lab.laboratories.filter((_, j) => j !== labIndex),
                }
                : lab
        );

        formik.setFieldValue('laboratories', newLaboratories);
    };

    const handleSystemChange = (e, index, field) => {
        const { value } = e.target;
        const newSystems = formik.values.systems.map((system, i) =>
            i === index ? { ...system, [field]: value } : system
        );
        formik.setFieldValue('systems', newSystems);
    };

    const handleSystemBoolChange = (e, index, value) => {
        const { checked } = e.target;
        const newSystems = formik.values.systems.map((system, i) =>
            i === index ? { ...system, normal: checked ? value : null } : system
        );
        formik.setFieldValue('systems', newSystems);
    };

    const addSystem = () => {
        const newSystems = [...formik.values.systems, { system: '', observations: '' }];
        formik.setFieldValue('systems', newSystems);
    };

    const removeSystem = index => {
        const systems = formik.values.systems;

        if (systems.length === 1) {
            formik.setFieldValue('systems', []);
            return;
        }

        const newSystems = systems.filter((_, i) => i !== index);
        formik.setFieldValue('systems', newSystems);
    };

    const handleExaminationChange = (e, index, field) => {
        const { value } = e.target;
        const newExaminations = formik.values.examinations.map((examination, i) =>
            i === index ? { ...examination, [field]: value } : examination
        );
        formik.setFieldValue('examinations', newExaminations);
    };

    const handleExaminationBoolChange = (e, index, value) => {
        const { checked } = e.target;
        const newExaminations = formik.values.examinations.map((examination, i) =>
            i === index ? { ...examination, normal: checked ? value : null } : examination
        );
        formik.setFieldValue('examinations', newExaminations);
    };

    const addExamination = () => {
        const newExaminations = [...formik.values.examinations, { examination: '', observations: '' }];
        formik.setFieldValue('examinations', newExaminations);
    };

    const removeExamination = index => {
        const examinations = formik.values.examinations;

        if (examinations.length === 1) {
            formik.setFieldValue('examinations', []);
            return;
        }

        const newExaminations = examinations.filter((_, i) => i !== index);
        formik.setFieldValue('examinations', newExaminations);
    };

    const handleLabResultChange = (e, index, field) => {
        const { value } = e.target;
        const newResults = formik.values.laboratory_results.map((result, i) =>
            i === index ? { ...result, [field]: value } : result
        );
        formik.setFieldValue('laboratory_results', newResults);
    };

    const addLabResult = () => {
        const newResults = [...formik.values.laboratory_results, { laboratory: '', result: '' }];
        formik.setFieldValue('laboratory_results', newResults);
    };

    const removeLabResult = index => {
        const laboratory_results = formik.values.laboratory_results;

        if (laboratory_results.length === 1) {
            formik.setFieldValue('laboratory_results', []);
            return;
        }

        const newResults = laboratory_results.filter((_, i) => i !== index);
        formik.setFieldValue('laboratory_results', newResults);
    };

    const handleStudyResultChange = (e, index, field) => {
        const { value } = e.target;
        const newResults = formik.values.study_results.map((result, i) =>
            i === index ? { ...result, [field]: value } : result
        );
        formik.setFieldValue('study_results', newResults);
    };

    const addStudyResult = () => {
        const newResults = [...formik.values.study_results, { study: '', result: '' }];
        formik.setFieldValue('study_results', newResults);
    };

    const removeStudyResult = index => {
        const study_results = formik.values.study_results;

        if (study_results.length === 1) {
            formik.setFieldValue('study_results', []);
            return;
        }

        const newResults = study_results.filter((_, i) => i !== index);
        formik.setFieldValue('study_results', newResults);
    };

    const [visibility, setVisibility] = useState({
        consultation_reason: false,
        question: false,
        vital_sign: false,
        system: false,
        examination: false,
        laboratory_result: false,
        study_result: false,
        diagnosis: false,
        prescription: false,
        laboratory: false,
        extra_notes: false,
        monitoring: false,
    });

    const toggleSection = (section) => {
        setVisibility(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const location = useLocation();

    const isActiveTab = (tabPath) => {
        return location.pathname === tabPath;
    };

    const [bmi, setBmi] = useState(formik.values.body_mass_index);

    React.useEffect(() => {
        const calculateBMI = () => {
            const { height, weight } = formik.values;
            if (height && weight && height > 0 && weight > 0) {
                const heightMeters = height / 100;
                const bmi = weight / (heightMeters * heightMeters);
                setBmi(bmi.toFixed(2)); // Round to 2 decimal places
            } else {
                setBmi(null);
            }
        };

        calculateBMI();
    }, [formik.values.height, formik.values.weight]);

    const handleInputChange = (event) => {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
        formik.handleChange(event);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row >
                        <Col xs={12}>
                            {loading ? (
                                <Spinners />
                            ) : (
                                <Card>

                                    <div className="tabs">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <Link
                                                    to={`/admin/patients/${consultation.patient ? consultation.patient.id : ''}`}
                                                    className={`nav-link ${isActiveTab(`/admin/patients/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Perfil
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={`/admin/patients/medical-history/${consultation.patient ? consultation.patient.id : ''}`}
                                                    className={`nav-link ${isActiveTab(`/admin/patients/medical-history/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Antecedentes
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={`/admin/patient/consultations/${consultation.patient ? consultation.patient.id : ''}`}
                                                    className={`nav-link ${isActiveTab(`/admin/patient/consultations/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Consultas
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={`/admin/patient/files/${consultation.patient ? consultation.patient.id : ''}`}
                                                    className={`nav-link ${isActiveTab(`/admin/patient/files/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Laboratorio y gabinete
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <CardBody>
                                        <Row>
                                            <Col sm="6">
                                                <CardTitle className="mb-0">
                                                    Consulta Médica <br/>
                                                    Paciente: &nbsp;
                                                    <span className="fw-normal fs-4">
                                                        {consultation.patient ?
                                                            `${consultation.patient.first_name} ${consultation.patient.last_name}` : ""
                                                        }<br/>
                                                    </span>
                                                    Fecha: &nbsp;
                                                    <span className="fw-normal">
                                                        {consultation.patient ?
                                                            `${new Date(consultation.date).toLocaleString('es-ES', {
                                                                year: 'numeric',
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: true
                                                            })}` : ""
                                                        }<br/>
                                                    </span>
                                                    Consultorio: &nbsp;
                                                    <span className="fw-normal">
                                                        {consultation.office ?
                                                            `${consultation.office.name || '-'}` : ""
                                                        }
                                                    </span>
                                                </CardTitle>
                                            </Col>
                                            <Col sm="6">
                                                <FormGroup className="mb-3 mt-3">
                                                    <label htmlFor="status"
                                                           style={{marginLeft: '100px', marginTop: '22px'}}>
                                                        <strong style={{display: 'inline-block'}}>Estatus</strong>
                                                    </label>
                                                    <Input
                                                        type="select"
                                                        id="status"
                                                        name="status"
                                                        value={formik.values.status}
                                                        onChange={(e) => formik.setFieldValue('status', e.target.value)}
                                                        className="custom-select"
                                                        style={{minWidth: '150px'}} // Ajusta el ancho mínimo según tus necesidades
                                                    >
                                                        {StatusOptions.map(option => (
                                                            <option key={option.val} value={option.val}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                    {formik.errors.status && (
                                                        <div className="text-danger">Campo requerido*</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '25px' }}>
                                            <p className="card-title-desc">Recuerda guardar los cambios antes de salir.</p>
                                        </Row>
                                    </CardBody>

                                    <CardTitle style={{marginLeft: 30}} className={`fw-bold fs-5`}>
                                        Antecedentes médicos
                                    </CardTitle>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                {
                                                    (!adminPatient.history_info?.medical_history?.family_pathology_present &&
                                                        (!adminPatient.history_info?.family_pathologies || adminPatient.history_info.family_pathologies.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.family_pathology_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Antecedentes familiares patológicos:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Patología presente: {adminPatient.history_info?.medical_history?.family_pathology_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.family_pathologies && adminPatient.history_info.family_pathologies.length > 0 ? (
                                                                            <div style={{marginBottom: '10px'}}>
                                                                                {adminPatient.history_info.family_pathologies.map((family, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: '30px',
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                        <span
                                                                                            style={{marginTop: '10px'}}>Patología: {family.pathology || "Sin registro"}</span><br/>
                                                                                            {family.members && family.members.length > 0 ? (
                                                                                                <div>
                                                                                                    <span>Familiares que lo padecen:</span>
                                                                                                    {family.members.map((member, memberIndex) => (
                                                                                                        <div
                                                                                                            key={memberIndex}
                                                                                                            style={{
                                                                                                                marginLeft: '30px',
                                                                                                            }}>
                                                                                                            <span>{memberIndex + 1}. &nbsp;&nbsp;&nbsp;</span>
                                                                                                            <span>{member.member || 'Sin registro'}</span>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    <span>No hay familiares registrados que lo padecan</span><br/>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de patologías familiares</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.family_pathology_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.pathology_present &&
                                                        (!adminPatient.history_info?.pathologies || adminPatient.history_info.pathologies.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.pathology_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Antecedentes patológicos:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Patología presente: {adminPatient.history_info?.medical_history?.pathology_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.pathologies && adminPatient.history_info.pathologies.length > 0 ? (
                                                                            <div style={{marginBottom: '10px'}}>
                                                                                {adminPatient.history_info.pathologies.map((pathology, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: '30px',
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                        <span
                                                                                            style={{marginTop: '10px'}}>Patología: {pathology.pathology || "Sin registro"}</span><br/>
                                                                                            {pathology.treatments && pathology.treatments.length > 0 ? (
                                                                                                <div>
                                                                                                    <span>Medicamentos y tratamientos:</span>
                                                                                                    {pathology.treatments.map((treatment, treatmentIndex) => (
                                                                                                        <div
                                                                                                            key={treatmentIndex}
                                                                                                            style={{
                                                                                                                marginLeft: '30px',
                                                                                                            }}>
                                                                                                            <span>{treatmentIndex + 1}. &nbsp;&nbsp;&nbsp;</span>
                                                                                                            <span>{treatment.treatment || 'Sin registro'}</span>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    <span>No hay medicamentos o tratamientos presentes</span><br/>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de patologías</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.pathology_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.allergy_present &&
                                                        (!adminPatient.history_info?.allergies || adminPatient.history_info.allergies.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.allergy_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Alergias:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Alergia presente: {adminPatient.history_info?.medical_history?.allergy_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.allergies && adminPatient.history_info.allergies.length > 0 ? (
                                                                            <div style={{marginBottom: '20px'}}>
                                                                                {adminPatient.history_info.allergies.map((allergy, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: 30,
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>Alergia: {allergy.allergy ? allergy.allergy : "Sin registro"}</span><br/>
                                                                                            <span>Reacción: {allergy.reaction ? allergy.reaction : "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de alergias</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.allergy_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.surgery_present &&
                                                        (!adminPatient.history_info?.surgeries || adminPatient.history_info.surgeries.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.surgery_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Cirugías y hospitalizaciones:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Cirugía u hospitalización presente: {adminPatient.history_info?.medical_history?.surgery_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.surgeries && adminPatient.history_info.surgeries.length > 0 ? (
                                                                            <div style={{marginBottom: '20px'}}>
                                                                                {adminPatient.history_info.surgeries.map((surgery, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: 30,
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>Cirugía u hospitalización: {surgery.surgery ? surgery.surgery : "Sin registro"}</span><br/>
                                                                                            <span>Fecha: {surgery.date ? surgery.date : "Sin registro"}</span><br/>
                                                                                            <span>Complicaciones: {surgery.complications ? surgery.complications : "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de cirugías u hospitalizaciones</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.surgery_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.immunization_present &&
                                                        (!adminPatient.history_info?.medical_history?.up_to_date_immunization || adminPatient.history_info?.medical_history?.up_to_date_immunization === null) &&
                                                        (!adminPatient.history_info?.immunizations || adminPatient.history_info.immunizations.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.immunization_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Inmunizaciones:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Inmunización presente: {adminPatient.history_info?.medical_history?.immunization_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Inmunizaciones al día: {adminPatient.history_info?.medical_history?.up_to_date_immunization === true ? 'Sí' : adminPatient.history_info?.medical_history?.up_to_date_immunization === false ? 'No' : 'Sin registro'}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.immunizations && adminPatient.history_info.immunizations.length > 0 ? (
                                                                            <div style={{marginBottom: '20px'}}>
                                                                                {adminPatient.history_info.immunizations.map((immunization, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: 30,
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>Inmunización: {immunization.immunization ? immunization.immunization : "Sin registro"}</span><br/>
                                                                                            <span>Año de aplicación: {immunization.application_year ? immunization.application_year : "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de inmunizaciones</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.immunization_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.tobacco_present &&
                                                        !adminPatient.history_info?.medical_history?.tobacco_suspension_time &&
                                                        !adminPatient.history_info?.medical_history?.cigarettes_per_day &&
                                                        !adminPatient.history_info?.medical_history?.smoking_years &&
                                                        !adminPatient.history_info?.medical_history?.tobacco_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Consumo de tabaco:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Consumo presente: {adminPatient.history_info?.medical_history?.tobacco_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>(En caso de) Años de suspensión: {adminPatient.history_info?.medical_history?.tobacco_suspension_time || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>(En caso de) Cigarrillos por día: {adminPatient.history_info?.medical_history?.cigarettes_per_day || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>(En caso de) Años de consumo: {adminPatient.history_info?.medical_history?.smoking_years || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.tobacco_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.alcohol_present &&
                                                        !adminPatient.history_info?.medical_history?.alcohol_suspension_time &&
                                                        (!adminPatient.history_info?.alcohols || adminPatient.history_info.alcohols.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.alcohol_consumed_years &&
                                                        !adminPatient.history_info?.medical_history?.alcohol_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Consumo de alcohol:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Consumo presente: {adminPatient.history_info?.medical_history?.alcohol_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>(En caso de) Años de suspensión: {adminPatient.history_info?.medical_history?.alcohol_suspension_time || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.alcohols && adminPatient.history_info.alcohols.length > 0 ? (
                                                                            <div style={{marginBottom: '20px'}}>
                                                                                {adminPatient.history_info.alcohols.map((alcohol, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: 30,
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>Tipo de bebida: {alcohol.alcohol ? alcohol.alcohol : "Sin registro"}</span><br/>
                                                                                            <span>Cantidad y frecuencia: {alcohol.quantity_and_frequency ? alcohol.quantity_and_frequency : "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de bebidas alcohólicas</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>(En caso de) Años de consumo: {adminPatient.history_info?.medical_history?.alcohol_consumed_years || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.alcohol_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.drug_present &&
                                                        (!adminPatient.history_info?.drugs || adminPatient.history_info.drugs.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.drug_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Consumo de drogas:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Consumo presente: {adminPatient.history_info?.medical_history?.drug_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.drugs && adminPatient.history_info.drugs.length > 0 ? (
                                                                            <div style={{marginBottom: '20px'}}>
                                                                                {adminPatient.history_info.drugs.map((drug, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: 30,
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>Droga: {drug.drug ? drug.drug : "Sin registro"}</span><br/>
                                                                                            <span>Dosis: {drug.dose ? drug.dose : "Sin registro"}</span><br/>
                                                                                            <span>Frecuencia de consumo: {drug.consumed_frequency ? drug.consumed_frequency : "Sin registro"}</span><br/>
                                                                                            <span>Tiempo de consumo: {drug.consumed_years ? drug.consumed_years : "Sin registro"}</span><br/>
                                                                                            <span>Suspendido: {drug.suspended !== null ? (drug.suspended ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                            <span>Tiempo de suspensión: {drug.suspension_years ? drug.suspension_years : "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de drogas</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.drug_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.physical_activity_present &&
                                                        (!adminPatient.history_info?.physical_activities || adminPatient.history_info.physical_activities.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.drug_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Actividades físicas:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Actividad presente: {adminPatient.history_info?.medical_history?.physical_activity_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.physical_activities && adminPatient.history_info.physical_activities.length > 0 ? (
                                                                            <div style={{marginBottom: '20px'}}>
                                                                                {adminPatient.history_info.physical_activities.map((activity, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: 30,
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>Actividad física: {activity.activity ? activity.activity : "Sin registro"}</span><br/>
                                                                                            <span>Tiempo: {activity.time ? activity.time : "-"} min</span><br/>
                                                                                            <span>Frecuencia: {activity.frequency ? activity.frequency : "-"} veces por semana</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de actividades físicas</span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.physical_activity_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.contraceptive_present &&
                                                        (!adminPatient.history_info?.medical_history?.intrauterine || adminPatient.history_info?.medical_history?.intrauterine === null) &&
                                                        (!adminPatient.history_info?.intrauterines || adminPatient.history_info.intrauterines.length === 0) &&
                                                        (!adminPatient.history_info?.medical_history?.injectable || adminPatient.history_info?.medical_history?.injectable === null) &&
                                                        (!adminPatient.history_info?.injectables || adminPatient.history_info.injectables.length === 0) &&
                                                        (!adminPatient.history_info?.medical_history?.barrier_method || adminPatient.history_info?.medical_history?.barrier_method === null) &&
                                                        (!adminPatient.history_info?.barriers || adminPatient.history_info.barriers.length === 0) &&
                                                        (!adminPatient.history_info?.medical_history?.natural_method || adminPatient.history_info?.medical_history?.natural_method === null) &&
                                                        (!adminPatient.history_info?.naturals || adminPatient.history_info.naturals.length === 0) &&
                                                        (!adminPatient.history_info?.medical_history?.surgical_method || adminPatient.history_info?.medical_history?.surgical_method === null) &&
                                                        (!adminPatient.history_info?.surgicals || adminPatient.history_info.surgicals.length === 0) &&
                                                        (!adminPatient.history_info?.medical_history?.oral || adminPatient.history_info?.medical_history?.oral === null) &&
                                                        (!adminPatient.history_info?.orals || adminPatient.history_info.orals.length === 0) &&
                                                        (!adminPatient.history_info?.medical_history?.subdermal || adminPatient.history_info?.medical_history?.subdermal === null) &&
                                                        (!adminPatient.history_info?.subdermals || adminPatient.history_info.subdermals.length === 0) &&
                                                        (!adminPatient.history_info?.medical_history?.other || adminPatient.history_info?.medical_history?.other === null) &&
                                                        (!adminPatient.history_info?.others || adminPatient.history_info.others.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.method_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Planificación:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Planificación presente: {adminPatient.history_info?.medical_history?.contraceptive_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.intrauterine &&
                                                                            (!adminPatient.history_info?.intrauterines ||
                                                                                adminPatient.history_info.intrauterines.length === 0)) ? (
                                                                            <span>No hay información de métodos intrauterinos</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Método intrauterino: {adminPatient.history_info?.medical_history?.intrauterine === true ? 'Sí' : adminPatient.history_info?.medical_history?.intrauterine === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.intrauterines && adminPatient.history_info.intrauterines.length > 0 ? (
                                                                                        adminPatient.history_info.intrauterines.map((intrauterine, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Método: {intrauterine.method ? intrauterine.method : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {intrauterine.time ? intrauterine.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {intrauterine.actual_method !== null ? (intrauterine.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de métodos intrauterinos</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.injectable &&
                                                                            (!adminPatient.history_info?.injectables ||
                                                                                adminPatient.history_info.injectables.length === 0)) ? (
                                                                            <span>No hay información de métodos inyectables</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Método inyectable: {adminPatient.history_info?.medical_history?.injectable === true ? 'Sí' : adminPatient.history_info?.medical_history?.injectable === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.injectables && adminPatient.history_info.injectables.length > 0 ? (
                                                                                        adminPatient.history_info.injectables.map((injectable, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Medicamento: {injectable.medicine ? injectable.medicine : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {injectable.time ? injectable.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {injectable.actual_method !== null ? (injectable.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de métodos inyectables</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.barrier_method &&
                                                                            (!adminPatient.history_info?.barriers ||
                                                                                adminPatient.history_info.barriers.length === 0)) ? (
                                                                            <span>No hay información de métodos de barrera</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Método intrauterino: {adminPatient.history_info?.medical_history?.barrier_method === true ? 'Sí' : adminPatient.history_info?.medical_history?.barrier_method === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.barriers && adminPatient.history_info.barriers.length > 0 ? (
                                                                                        adminPatient.history_info.barriers.map((barrier, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Método: {barrier.method ? barrier.method : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {barrier.time ? barrier.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {barrier.actual_method !== null ? (barrier.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de métodos de barrera</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.natural_method &&
                                                                            (!adminPatient.history_info?.naturals ||
                                                                                adminPatient.history_info.naturals.length === 0)) ? (
                                                                            <span>No hay información de métodos naturales</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Método natural: {adminPatient.history_info?.medical_history?.natural_method === true ? 'Sí' : adminPatient.history_info?.medical_history?.natural_method === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.naturals && adminPatient.history_info.naturals.length > 0 ? (
                                                                                        adminPatient.history_info.naturals.map((natural, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Método: {natural.method ? natural.method : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {natural.time ? natural.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {natural.actual_method !== null ? (natural.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de métodos naturales</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.surgical_method &&
                                                                            (!adminPatient.history_info?.surgicals ||
                                                                                adminPatient.history_info.surgicals.length === 0)) ? (
                                                                            <span>No hay información de métodos quirúrgicos</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Método quirúrgico: {adminPatient.history_info?.medical_history?.surgical_method === true ? 'Sí' : adminPatient.history_info?.medical_history?.surgical_method === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.surgicals && adminPatient.history_info.surgicals.length > 0 ? (
                                                                                        adminPatient.history_info.surgicals.map((surgical, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Método: {surgical.method ? surgical.method : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {surgical.time ? surgical.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {surgical.actual_method !== null ? (surgical.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de métodos quirúrgicos</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.oral &&
                                                                            (!adminPatient.history_info?.orals ||
                                                                                adminPatient.history_info.orals.length === 0)) ? (
                                                                            <span>No hay información de métodos orales</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Método oral: {adminPatient.history_info?.medical_history?.oral === true ? 'Sí' : adminPatient.history_info?.medical_history?.oral === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.orals && adminPatient.history_info.orals.length > 0 ? (
                                                                                        adminPatient.history_info.orals.map((oral, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Medicamento: {oral.medicine ? oral.medicine : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {oral.time ? oral.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {oral.actual_method !== null ? (oral.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de métodos orales</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.subdermal &&
                                                                            (!adminPatient.history_info?.subdermals ||
                                                                                adminPatient.history_info.subdermals.length === 0)) ? (
                                                                            <span>No hay información de métodos subdérmicos</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Método subdérmico: {adminPatient.history_info?.medical_history?.subdermal === true ? 'Sí' : adminPatient.history_info?.medical_history?.subdermal === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.subdermals && adminPatient.history_info.subdermals.length > 0 ? (
                                                                                        adminPatient.history_info.subdermals.map((subdermal, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Método: {subdermal.method ? subdermal.method : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {subdermal.time ? subdermal.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {subdermal.actual_method !== null ? (subdermal.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de métodos subdérmicos</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {(!adminPatient.history_info?.medical_history?.other &&
                                                                            (!adminPatient.history_info?.others ||
                                                                                adminPatient.history_info.others.length === 0)) ? (
                                                                            <span>No hay información de otros métodos</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{
                                                                                    marginBottom: '10px',
                                                                                    marginTop: 30
                                                                                }}>
                                                                                    <span>Otro método: {adminPatient.history_info?.medical_history?.other === true ? 'Sí' : adminPatient.history_info?.medical_history?.other === false ? 'No' : 'Sin registro'}</span><br/>
                                                                                </div>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.others && adminPatient.history_info.others.length > 0 ? (
                                                                                        adminPatient.history_info.others.map((other, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Método: {other.method ? other.method : "Sin registro"}</span><br/>
                                                                                                    <span>Tiempo de uso: {other.time ? other.time : "Sin registro"}</span><br/>
                                                                                                    <span>Método actual: {other.actual_method !== null ? (other.actual_method ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de otros métodos</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.method_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.menstrual_cycle_present &&
                                                        !adminPatient.history_info?.medical_history?.cycle_frequency &&
                                                        !adminPatient.history_info?.medical_history?.dysmenorrhea_present &&
                                                        !adminPatient.history_info?.medical_history?.menstrual_cycle_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Ciclo menstrual:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Ciclo menstrual presente: {adminPatient.history_info?.medical_history?.menstrual_cycle_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Frecuencia del ciclo: {adminPatient.history_info?.medical_history?.cycle_frequency || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Dismenorrea presente: {adminPatient.history_info?.medical_history?.dysmenorrhea_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.menstrual_cycle_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.pregnancy_present &&
                                                        !adminPatient.history_info?.medical_history?.pregnancies &&
                                                        !adminPatient.history_info?.medical_history?.births &&
                                                        !adminPatient.history_info?.medical_history?.abortions &&
                                                        !adminPatient.history_info?.medical_history?.deaths &&
                                                        !adminPatient.history_info?.medical_history?.cesarean &&
                                                        !adminPatient.history_info?.medical_history?.alive &&
                                                        !adminPatient.history_info?.medical_history?.dead &&
                                                        !adminPatient.history_info?.medical_history?.pregnancy_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Embarazos:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Embarazos presente: {adminPatient.history_info?.medical_history?.pregnancy_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Gestas: {adminPatient.history_info?.medical_history?.pregnancies || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Partos: {adminPatient.history_info?.medical_history?.births || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Abortos: {adminPatient.history_info?.medical_history?.abortions || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Óbitos: {adminPatient.history_info?.medical_history?.deaths || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Cesáreas: {adminPatient.history_info?.medical_history?.cesarean || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Vivos: {adminPatient.history_info?.medical_history?.alive || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Muertos: {adminPatient.history_info?.medical_history?.dead || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {((!adminPatient.history_info?.pregnancies ||
                                                                            adminPatient.history_info.pregnancies.length === 0)) ? (
                                                                            <span>No hay información de embarazos</span>
                                                                        ) : (
                                                                            <>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.pregnancies && adminPatient.history_info.pregnancies.length > 0 ? (
                                                                                        adminPatient.history_info.pregnancies.map((pregnancy, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Fecha de parto: {pregnancy.due_date ? new Date(pregnancy.due_date).toLocaleDateString('es-ES') : "Sin registro"}</span><br/>
                                                                                                    <span>Trabajo de parto: {pregnancy.labor ? pregnancy.labor : "-"} horas</span><br/>
                                                                                                    <span>Edad gestacional: {pregnancy.gestational_age ? pregnancy.gestational_age : "Sin registro"}</span><br/>
                                                                                                    <span>Parto múltiple: {pregnancy.multiple_birth !== null ? (pregnancy.multiple_birth ? "Si" : "No") : "Sin registro"}</span><br/>
                                                                                                    {((!pregnancy.pregnancies ||
                                                                                                        pregnancy.pregnancies.length === 0)) ? (
                                                                                                        <span>No hay información de embarazos múltiples</span>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <div>
                                                                                                                {pregnancy.pregnancies && pregnancy.pregnancies.length > 0 ? (
                                                                                                                    pregnancy.pregnancies.map((pregnancy, index) => (
                                                                                                                        <div
                                                                                                                            key={index}
                                                                                                                            style={{
                                                                                                                                marginLeft: 30,
                                                                                                                                display: 'flex'
                                                                                                                            }}>
                                                                                                                            <div
                                                                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <span>Peso: {pregnancy.weight ? pregnancy.weight : "-"} horas</span><br/>
                                                                                                                                <span>Género: {pregnancy.gender ? pregnancy.gender : "Sin registro"}</span><br/>
                                                                                                                                <span>Tipo de parto: {pregnancy.birth_method ? pregnancy.birth_method : "Sin registro"}</span><br/>
                                                                                                                                <span>Recién nacido: {pregnancy.neonate ? pregnancy.neonate : "Sin registro"}</span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    ))
                                                                                                                ) : (
                                                                                                                    <span>No hay registro de embarazos múltiples</span>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}
                                                                                                    <br/><span>Observaciones del embarazo: {pregnancy.pregnancy_observations ? pregnancy.pregnancy_observations : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de embarazos</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.pregnancy_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.cytology_mammogram_present &&
                                                        (!adminPatient.history_info?.cytologies || adminPatient.history_info.cytologies.length === 0) &&
                                                        (!adminPatient.history_info?.mammograms || adminPatient.history_info.mammograms.length === 0) &&
                                                        !adminPatient.history_info?.medical_history?.cytology_mammogram_notes) ? null : (
                                                        <tr>
                                                            <th scope="row">Citologías y mamografías:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>Citología o mamografía presente: {adminPatient.history_info?.medical_history?.cytology_mammogram_present || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        {((!adminPatient.history_info?.cytologies ||
                                                                            adminPatient.history_info.cytologies.length === 0)) ? (
                                                                            <span>No hay registro de cytologías</span>
                                                                        ) : (
                                                                            <>
                                                                                <span>Citologías</span>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.cytologies && adminPatient.history_info.cytologies.length > 0 ? (
                                                                                        adminPatient.history_info.cytologies.map((cytology, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Fecha: {cytology.date ? cytology.date : "Sin registro"}</span><br/>
                                                                                                    <span>Resultado: {cytology.result ? cytology.result : "Sin registro"}</span><br/>
                                                                                                    <span>Observaciones: {cytology.observations ? cytology.observations : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de cytologías</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {((!adminPatient.history_info?.mammograms ||
                                                                            adminPatient.history_info.mammograms.length === 0)) ? (
                                                                            <span>No hay registro de mamografías</span>
                                                                        ) : (
                                                                            <>
                                                                                <span>Mamografías</span>
                                                                                <div style={{marginBottom: '10px'}}>
                                                                                    {adminPatient.history_info?.mammograms && adminPatient.history_info.mammograms.length > 0 ? (
                                                                                        adminPatient.history_info.mammograms.map((mammogram, index) => (
                                                                                            <div key={index}
                                                                                                 style={{
                                                                                                     marginBottom: '10px',
                                                                                                     marginLeft: 30,
                                                                                                     display: 'flex'
                                                                                                 }}>
                                                                                                <div
                                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span>Fecha: {mammogram.date ? mammogram.date : "Sin registro"}</span><br/>
                                                                                                    <span>Resultado: {mammogram.result ? mammogram.result : "Sin registro"}</span><br/>
                                                                                                    <span>Observaciones: {mammogram.observations ? mammogram.observations : "Sin registro"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    ) : (
                                                                                        <span>No hay registro de mamografías</span>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div style={{marginTop: 10}}>
                                                                            <span>Notas: {adminPatient.history_info?.medical_history?.cytology_mammogram_notes || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    ((!adminPatient.history_info?.questions || adminPatient.history_info.questions.length === 0)) ? null : (
                                                        <tr>
                                                            <th scope="row">Preguntas personalizadas:</th>
                                                            <td>
                                                                <>
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.history_info?.questions && adminPatient.history_info.questions.length > 0 ? (
                                                                            <div style={{marginBottom: '20px'}}>
                                                                                {adminPatient.history_info.questions.map((question, index) => (
                                                                                    <div key={index} style={{
                                                                                        marginBottom: '10px',
                                                                                        marginLeft: 30,
                                                                                        display: 'flex'
                                                                                    }}>
                                                                                        <div
                                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                                        </div>
                                                                                        <div>
                                                                                            <span>Pregunta: {question.question ? question.question : "Sin registro"}</span><br/>
                                                                                            <span>Respuesta: {question.answer ? question.answer : "Sin registro"}</span><br/>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <span>Sin registro de preguntas personalizadas</span>
                                                                        )}
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    (!adminPatient.history_info?.medical_history?.general_observations) ? null : (
                                                        <tr>
                                                            <th scope="row">Observaciones generales:</th>
                                                            <td>
                                                                <>
                                                                    <div>
                                                                        <div style={{marginBottom: '10px'}}>
                                                                            <span>{adminPatient.history_info?.medical_history?.general_observations || "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>

                                    <CardBody>
                                        <p className="card-title-desc">A continuación ingresa la información de la
                                            consulta.</p>
                                        <Form onSubmit={formik.handleSubmit} autoComplete="off">

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('vital_sign')}
                                                       style={{cursor: 'pointer'}}>
                                                Signos vitales {visibility.vital_sign ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.vital_sign && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3">
                                                                <Label htmlFor="vital_sign_notes">Signos vitales
                                                                    (observaciones)</Label>
                                                                <Input
                                                                    id="vital_sign_notes"
                                                                    name="vital_sign_notes"
                                                                    type="text"
                                                                    placeholder="Observaciones"
                                                                    value={formik.values.vital_sign_notes}
                                                                    onChange={formik.handleChange}
                                                                    invalid={
                                                                        formik.touched.vital_sign_notes && formik.errors.vital_sign_notes ? true : false
                                                                    }
                                                                />
                                                                {formik.errors.vital_sign_notes && formik.touched.vital_sign_notes ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.vital_sign_notes}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="systolic_blood_pressure">Presión
                                                                    arterial (PA)</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="systolic_blood_pressure"
                                                                        name="systolic_blood_pressure"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={formik.values.systolic_blood_pressure === null ? '' : formik.values.systolic_blood_pressure}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('systolic_blood_pressure', value === '' ? 0 : parseInt(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.systolic_blood_pressure && formik.errors.systolic_blood_pressure}
                                                                        style={{marginRight: '5px', width: '80px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>/</span>
                                                                    <Input
                                                                        id="diastolic_blood_pressure"
                                                                        name="diastolic_blood_pressure"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={formik.values.diastolic_blood_pressure === null ? '' : formik.values.diastolic_blood_pressure}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('diastolic_blood_pressure', value === '' ? 0 : parseInt(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.diastolic_blood_pressure && formik.errors.diastolic_blood_pressure}
                                                                        style={{
                                                                            marginLeft: '5px',
                                                                            marginRight: '5px',
                                                                            width: '80px'
                                                                        }}
                                                                        min="0"
                                                                    />
                                                                    <span>mmHg</span>
                                                                </div>
                                                                <div>
                                                                    {formik.errors.systolic_blood_pressure && formik.touched.systolic_blood_pressure && (
                                                                        <FormFeedback>{formik.errors.systolic_blood_pressure}</FormFeedback>
                                                                    )}
                                                                    {formik.errors.diastolic_blood_pressure && formik.touched.diastolic_blood_pressure && (
                                                                        <FormFeedback>{formik.errors.diastolic_blood_pressure}</FormFeedback>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="heart_rate">Frecuencia cardiaca</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="heart_rate"
                                                                        name="heart_rate"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={formik.values.heart_rate === null ? '' : formik.values.heart_rate}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('heart_rate', value === '' ? 0 : parseInt(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.heart_rate && formik.errors.heart_rate}
                                                                        style={{width: '80px', marginRight: '5px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>lpm</span>
                                                                </div>
                                                                {formik.errors.heart_rate && formik.touched.heart_rate && (
                                                                    <FormFeedback>{formik.errors.heart_rate}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="blood_oxygen_saturation">Saturación de
                                                                    oxígeno</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="blood_oxygen_saturation"
                                                                        name="blood_oxygen_saturation"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={formik.values.blood_oxygen_saturation === null ? '' : formik.values.blood_oxygen_saturation}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('blood_oxygen_saturation', value === '' ? 0 : parseInt(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.blood_oxygen_saturation && formik.errors.blood_oxygen_saturation}
                                                                        style={{width: '80px', marginRight: '5px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>lpm</span>
                                                                </div>
                                                                {formik.errors.blood_oxygen_saturation && formik.touched.blood_oxygen_saturation && (
                                                                    <FormFeedback>{formik.errors.blood_oxygen_saturation}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="temperature">Temperatura</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="temperature"
                                                                        name="temperature"
                                                                        type="text"
                                                                        placeholder="0.00"
                                                                        value={formik.values.temperature === null ? '' : formik.values.temperature}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('temperature', value === '' ? 0 : parseFloat(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.temperature && formik.errors.temperature}
                                                                        style={{width: '80px', marginRight: '5px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>ºC</span>
                                                                </div>
                                                                {formik.errors.temperature && formik.touched.temperature && (
                                                                    <FormFeedback>{formik.errors.temperature}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="respiratory_rate">Frecuencia
                                                                    respiratoria</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="respiratory_rate"
                                                                        name="respiratory_rate"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={formik.values.respiratory_rate === null ? '' : formik.values.respiratory_rate}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('respiratory_rate', value === '' ? 0 : parseInt(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.respiratory_rate && formik.errors.respiratory_rate}
                                                                        style={{width: '80px', marginRight: '5px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>rpm</span>
                                                                </div>
                                                                {formik.errors.respiratory_rate && formik.touched.respiratory_rate && (
                                                                    <FormFeedback>{formik.errors.respiratory_rate}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="glucose">Glucosa</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="glucose"
                                                                        name="glucose"
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={formik.values.glucose === null ? '' : formik.values.glucose}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('glucose', value === '' ? 0 : parseInt(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.glucose && formik.errors.glucose}
                                                                        style={{width: '80px', marginRight: '5px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>mg / dl</span>
                                                                </div>
                                                                {formik.errors.glucose && formik.touched.glucose && (
                                                                    <FormFeedback>{formik.errors.glucose}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="height">Altura</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="height"
                                                                        name="height"
                                                                        type="text"
                                                                        placeholder="0.00"
                                                                        value={formik.values.height === null ? '' : formik.values.height}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('height', value === '' ? 0 : parseFloat(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.height && formik.errors.height}
                                                                        style={{width: '80px', marginRight: '5px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>cm</span>
                                                                </div>
                                                                {formik.errors.height && formik.touched.height && (
                                                                    <FormFeedback>{formik.errors.height}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="weight">Peso</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="weight"
                                                                        name="weight"
                                                                        type="text"
                                                                        placeholder="0.00"
                                                                        value={formik.values.weight === null ? '' : formik.values.weight}
                                                                        onBlur={(e) => {
                                                                            const value = e.target.value.trim();
                                                                            formik.setFieldValue('weight', value === '' ? 0 : parseFloat(value));
                                                                        }}
                                                                        onChange={formik.handleChange}
                                                                        invalid={formik.touched.weight && formik.errors.weight}
                                                                        style={{width: '80px', marginRight: '5px'}}
                                                                        min="0"
                                                                    />
                                                                    <span>Kg</span>
                                                                </div>
                                                                {formik.errors.weight && formik.touched.weight && (
                                                                    <FormFeedback>{formik.errors.weight}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="body_mass_index">Índice de Masa Corporal (IMC)</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        id="body_mass_index"
                                                                        name="body_mass_index"
                                                                        type="text"
                                                                        value={bmi === null ? '' : bmi}
                                                                        readOnly
                                                                        style={{ width: '80px', marginRight: '5px' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="menstrual_cycle_date">Fecha último ciclo
                                                                    menstrual</Label>
                                                                <Input
                                                                    id="menstrual_cycle_date"
                                                                    name="menstrual_cycle_date"
                                                                    type="text"
                                                                    placeholder="Fecha"
                                                                    value={formik.values.menstrual_cycle_date}
                                                                    onChange={formik.handleChange}
                                                                    invalid={
                                                                        formik.touched.menstrual_cycle_date && formik.errors.menstrual_cycle_date ? true : false
                                                                    }
                                                                />
                                                                {formik.errors.menstrual_cycle_date && formik.touched.menstrual_cycle_date ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.menstrual_cycle_date}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('consultation_reason')}
                                                       style={{cursor: 'pointer'}}>
                                                Motivo de consulta {visibility.consultation_reason ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.consultation_reason && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3">
                                                                <Label htmlFor="consultation_reason">Motivo de
                                                                    consulta</Label>
                                                                <Input
                                                                    id="consultation_reason"
                                                                    name="consultation_reason"
                                                                    type="text"
                                                                    placeholder="Razón de visita del paciente"
                                                                    value={formik.values.consultation_reason}
                                                                    onChange={formik.handleChange}
                                                                    invalid={
                                                                        formik.touched.consultation_reason && formik.errors.consultation_reason ? true : false
                                                                    }
                                                                />
                                                                {formik.errors.consultation_reason && formik.touched.consultation_reason ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.consultation_reason}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('question')}
                                                       style={{cursor: 'pointer'}}>
                                                Preguntas personalizadas {visibility.question ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.question && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.questions?.map((question, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`questions[${index}].question`}>Pregunta</Label>
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Input
                                                                                    id={`question-${index}`}
                                                                                    name={`questions[${index}].question`}
                                                                                    type="text"
                                                                                    placeholder="Pregunta"
                                                                                    value={question.question}
                                                                                    onChange={e => handleQuestionChange(e, index, 'question')}
                                                                                    className="flex-grow-1"
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.errors[`questions[${index}].question`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`questions[${index}].answer`}>Respuesta</Label>
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Input
                                                                                    id={`answer-${index}`}
                                                                                    name={`questions[${index}].answer`}
                                                                                    type="text"
                                                                                    placeholder="Respuesta"
                                                                                    value={question.answer}
                                                                                    onChange={e => handleQuestionChange(e, index, 'answer')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.errors[`questions[${index}].answer`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Button type="button"
                                                                                        onClick={() => removeQuestion(index)}>Eliminar</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addQuestion}>
                                                        Agregar otra pregunta
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5" onClick={() => toggleSection('system')}
                                                       style={{cursor: 'pointer'}}>
                                                Interrogatorio por aparato o sistema {visibility.system ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.system && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.systems?.map((system, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`systems[${index}].system`}>Aparato
                                                                                    o sistema</Label>
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <select
                                                                                    id={`system-${index}`}
                                                                                    name={`systems[${index}].system`}
                                                                                    value={system.system}
                                                                                    onChange={e => handleSystemChange(e, index, 'system')}
                                                                                    className="custom-select mb-3"
                                                                                >
                                                                                    <option value="">Selecciona...
                                                                                    </option>
                                                                                    {SystemOptions.map(option => (
                                                                                        <option key={option.val}
                                                                                                value={option.val}>
                                                                                            {option.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {formik.errors[`systems[${index}].system`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <input
                                                                                    id={`normal-${index}`}
                                                                                    name={`systems[${index}].normal`}
                                                                                    type="checkbox"
                                                                                    checked={system.normal === true}
                                                                                    onChange={(e) => handleSystemBoolChange(e, index, true)} // Update system.normal to true when checked
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                <label htmlFor={`normal-${index}`}
                                                                                       style={{
                                                                                           marginLeft: 5,
                                                                                           marginRight: 20
                                                                                       }}>Normal</label>
                                                                                <input
                                                                                    id={`anormal-${index}`}
                                                                                    name={`systems[${index}].normal`}
                                                                                    type="checkbox"
                                                                                    checked={system.normal === false}
                                                                                    onChange={(e) => handleSystemBoolChange(e, index, false)} // Update system.normal to false when checked
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                <label htmlFor={`anormal-${index}`}
                                                                                       style={{marginLeft: 5}}>Anormal</label>
                                                                                {formik.errors[`systems[${index}].normal`] && (
                                                                                    <div
                                                                                        className="text-danger">Seleccione
                                                                                        una opción</div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`systems[${index}].observations`}>Observaciones</Label>
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Input
                                                                                    id={`observations-${index}`}
                                                                                    name={`questions[${index}].observations`}
                                                                                    type="text"
                                                                                    placeholder="Observaciones"
                                                                                    value={system.observations}
                                                                                    onChange={e => handleSystemChange(e, index, 'observations')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Button type="button"
                                                                                        onClick={() => removeSystem(index)}>Eliminar</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addSystem}>
                                                        Agregar otro aparato o sistema
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('examination')}
                                                       style={{cursor: 'pointer'}}>
                                                Exploración por aparatos y sistemas {visibility.examination ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.examination && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.examinations?.map((examination, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`examinations[${index}].segment`}>Aparato o sistema</Label>
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <select
                                                                                    id={`segment-${index}`}
                                                                                    name={`examinations[${index}].segment`}
                                                                                    value={examination.segment}
                                                                                    onChange={e => handleExaminationChange(e, index, 'segment')}
                                                                                    className="custom-select mb-3"
                                                                                >
                                                                                    <option value="">Selecciona...
                                                                                    </option>
                                                                                    {SegmentOptions.map(option => (
                                                                                        <option key={option.val}
                                                                                                value={option.val}>
                                                                                            {option.label}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {formik.errors[`examinations[${index}].segment`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </Col>
                                                                            <Col sm="4">
                                                                                <input
                                                                                    id={`normal-${index}`}
                                                                                    name={`examinations[${index}].normal`}
                                                                                    type="checkbox"
                                                                                    checked={examination.normal === true}
                                                                                    onChange={(e) => handleExaminationBoolChange(e, index, true)}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                <label htmlFor={`normal-${index}`}
                                                                                       style={{
                                                                                           marginLeft: 5,
                                                                                           marginRight: 20
                                                                                       }}>Normal</label>
                                                                                <input
                                                                                    id={`anormal-${index}`}
                                                                                    name={`examinations[${index}].normal`}
                                                                                    type="checkbox"
                                                                                    checked={examination.normal === false}
                                                                                    onChange={(e) => handleExaminationBoolChange(e, index, false)} // Update system.normal to false when checked
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                <label htmlFor={`anormal-${index}`}
                                                                                       style={{marginLeft: 5}}>Anormal</label>
                                                                                {formik.errors[`examinations[${index}].normal`] && (
                                                                                    <div
                                                                                        className="text-danger">Seleccione
                                                                                        una opción</div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`examinations[${index}].observations`}>Observaciones</Label>
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Input
                                                                                    id={`observations-${index}`}
                                                                                    name={`examinations[${index}].observations`}
                                                                                    type="text"
                                                                                    placeholder="Observaciones"
                                                                                    value={examination.observations}
                                                                                    onChange={e => handleExaminationChange(e, index, 'observations')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Button type="button"
                                                                                        onClick={() => removeExamination(index)}>Eliminar</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addExamination}>
                                                        Agregar otra exploración segmentaria
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('laboratory_result')}
                                                       style={{cursor: 'pointer'}}>
                                                Laboratorios {visibility.laboratory_result ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.laboratory_result && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.laboratory_results?.map((result, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`laboratory_results[${index}].laboratory`}>Laboratorio</Label>
                                                                            </Col>
                                                                            <Col sm="9">
                                                                                <Input
                                                                                    id={`laboratory-${index}`}
                                                                                    name={`laboratory_results[${index}].laboratory`}
                                                                                    type="text"
                                                                                    placeholder="Tipo de laboratorio"
                                                                                    value={result.laboratory}
                                                                                    onChange={e => handleLabResultChange(e, index, 'laboratory')}
                                                                                    className="flex-grow-1"
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.errors[`laboratory_results[${index}].laboratory`] && (
                                                                                    <div
                                                                                        className="text-danger">Campo requerido*</div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`laboratory_results[${index}].result`}>Resultado</Label>
                                                                            </Col>
                                                                            <Col sm="9">
                                                                                <Input
                                                                                    id={`result-${index}`}
                                                                                    name={`laboratory_results[${index}].result`}
                                                                                    type="text"
                                                                                    placeholder="Resultado"
                                                                                    value={result.result}
                                                                                    onChange={e => handleLabResultChange(e, index, 'result')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Button type="button"
                                                                                        onClick={() => removeLabResult(index)}>Eliminar</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addLabResult}>
                                                        Agregar otro laboratorio
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('study_result')}
                                                       style={{cursor: 'pointer'}}>
                                                Estudios de gabinete {visibility.study_result ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.study_result && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.study_results?.map((result, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`study_results[${index}].study`}>Estudio</Label>
                                                                            </Col>
                                                                            <Col sm="9">
                                                                                <Input
                                                                                    id={`study-${index}`}
                                                                                    name={`study_results[${index}].study`}
                                                                                    type="text"
                                                                                    placeholder="Estudio"
                                                                                    value={result.study}
                                                                                    onChange={e => handleStudyResultChange(e, index, 'study')}
                                                                                    className="flex-grow-1"
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.errors[`study_results[${index}].study`] && (
                                                                                    <div
                                                                                        className="text-danger">Campo requerido*</div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`study_results[${index}].result`}>Resultado</Label>
                                                                            </Col>
                                                                            <Col sm="9">
                                                                                <Input
                                                                                    id={`result-${index}`}
                                                                                    name={`study_results[${index}].result`}
                                                                                    type="text"
                                                                                    placeholder="Resultado"
                                                                                    value={result.result}
                                                                                    onChange={e => handleStudyResultChange(e, index, 'result')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Button type="button"
                                                                                        onClick={() => removeStudyResult(index)}>Eliminar</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addStudyResult}>
                                                        Agregar otro estudio
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('extra_notes')}
                                                       style={{cursor: 'pointer'}}>
                                                Observaciones adicionales de la
                                                consulta {visibility.extra_notes ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.extra_notes && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3">
                                                                <Label htmlFor="extra_notes">Observaciones
                                                                    adicionales</Label>
                                                                <Input
                                                                    id="extra_notes"
                                                                    name="extra_notes"
                                                                    type="text"
                                                                    placeholder="Observaciones adicionales"
                                                                    value={formik.values.extra_notes}
                                                                    onChange={formik.handleChange}
                                                                    invalid={
                                                                        formik.touched.extra_notes && formik.errors.extra_notes ? true : false
                                                                    }
                                                                />
                                                                {formik.errors.extra_notes && formik.touched.extra_notes ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.extra_notes}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('diagnosis')}
                                                       style={{cursor: 'pointer'}}>
                                                Diagnóstico {visibility.diagnosis ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.diagnosis && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3">
                                                                <Label htmlFor="diagnosis">Impresión diagnóstica</Label>
                                                                <Input
                                                                    id="diagnosis"
                                                                    name="diagnosis"
                                                                    type="text"
                                                                    placeholder="Impresión diagnóstica"
                                                                    value={formik.values.diagnosis}
                                                                    onChange={formik.handleChange}
                                                                    invalid={
                                                                        formik.touched.diagnosis && formik.errors.diagnosis ? true : false
                                                                    }
                                                                />
                                                                {formik.errors.diagnosis && formik.touched.diagnosis ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.diagnosis}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.diagnoses?.map((diagnosis, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                                <Label
                                                                                    htmlFor={`diagnoses[${index}].diagnosis`}>Diagnóstico diferencial</Label>
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Input
                                                                                    id={`diagnosis-${index}`}
                                                                                    name={`diagnoses[${index}].diagnosis`}
                                                                                    type="text"
                                                                                    placeholder="Diagnosis"
                                                                                    value={diagnosis.diagnosis}
                                                                                    onChange={e => handleDiagnosesChange(e, index, 'diagnosis')}
                                                                                    className="flex-grow-1"
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="2" className="text-end">
                                                                            </Col>
                                                                            <Col sm="6">
                                                                                <Button type="button"
                                                                                        onClick={() => removeDiagnoses(index)}>Eliminar</Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addDiagnoses}>
                                                        Agregar otro diagnóstico
                                                    </Button>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3">
                                                                <Label
                                                                    htmlFor="diagnosis_observations">Observaciones</Label>
                                                                <Input
                                                                    id="diagnosis_observations"
                                                                    name="diagnosis_observations"
                                                                    type="text"
                                                                    placeholder="Observaciones"
                                                                    value={formik.values.diagnosis_observations}
                                                                    onChange={formik.handleChange}
                                                                    invalid={
                                                                        formik.touched.diagnosis_observations && formik.errors.diagnosis_observations ? true : false
                                                                    }
                                                                />
                                                                {formik.errors.diagnosis_observations && formik.touched.diagnosis_observations ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.diagnosis_observations}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('prescription')}
                                                       style={{cursor: 'pointer'}}>
                                                Recetas médicas {visibility.prescription ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.prescription && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.prescriptions?.map((prescription, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Row>
                                                                            <Col sm="12">
                                                                                <Label
                                                                                    htmlFor={`prescriptions[${index}].indications`}>Receta
                                                                                    médica #{index + 1}</Label>
                                                                            </Col>
                                                                            <Col sm="12">
                                                                                <div className="col-md-4" style={{marginLeft: 20}}>
                                                                                    <label
                                                                                        htmlFor={`medication-${index}-`}>Fecha:</label>
                                                                                    <Flatpickr
                                                                                        id={`date-${index}`}
                                                                                        name={`date-${index}`}
                                                                                        className="form-control d-block"
                                                                                        value={prescription.date || today}
                                                                                        onChange={(date) => handlePrescriptionDateChange(date, index, 'date')}
                                                                                        options={{
                                                                                            altInput: true,
                                                                                            altFormat: "j F Y",
                                                                                            dateFormat: "Y-m-d",
                                                                                            locale: Spanish,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div style={{
                                                                                    marginLeft: 20,
                                                                                    marginTop: 10
                                                                                }}>
                                                                                    {prescription.prescriptions.map((prescription, prescIndex) => (
                                                                                        <div key={prescIndex}
                                                                                             className="mb-12">
                                                                                            <div
                                                                                                style={{marginTop: 10}}>
                                                                                                <label
                                                                                                    htmlFor={`medication-${index}-${prescIndex}`}>Medicamento:</label>
                                                                                                <Input
                                                                                                    id={`medication-${index}-${prescIndex}`}
                                                                                                    name={`prescriptions[${index}].prescriptions[${prescIndex}].medication`}
                                                                                                    type="text"
                                                                                                    placeholder="Medicamento"
                                                                                                    value={prescription.medication}
                                                                                                    onChange={(e) => handleMedicationChange(e, index, prescIndex, 'medication')}
                                                                                                    className="flex-grow-1"
                                                                                                    style={{marginRight: 10}}
                                                                                                />
                                                                                                {formik.errors[`prescriptions[${index}].prescriptions[${prescIndex}].instruction`] && (
                                                                                                    <div
                                                                                                        className="text-danger">Campo
                                                                                                        requerido*</div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div
                                                                                                style={{marginTop: 10}}>
                                                                                                <label
                                                                                                    htmlFor={`instruction-${index}-${prescIndex}`}>Indicación:</label>
                                                                                                <Input
                                                                                                    id={`instruction-${index}-${prescIndex}`}
                                                                                                    name={`prescriptions[${index}].prescriptions[${prescIndex}].instruction`}
                                                                                                    type="text"
                                                                                                    placeholder="Indicación"
                                                                                                    value={prescription.instruction}
                                                                                                    onChange={(e) => handleMedicationChange(e, index, prescIndex, 'instruction')}
                                                                                                    className="flex-grow-1"
                                                                                                    style={{marginRight: 10}}
                                                                                                />
                                                                                                {formik.errors[`prescriptions[${index}].prescriptions[${prescIndex}].medication`] && (
                                                                                                    <div
                                                                                                        className="text-danger">Campo
                                                                                                        requerido*</div>
                                                                                                )}
                                                                                            </div>
                                                                                            <Row>
                                                                                                <Col sm="12"
                                                                                                     className="text-end"
                                                                                                     style={{marginTop: 10}}>
                                                                                                    <Button
                                                                                                        type="button"
                                                                                                        onClick={() => removeMedication(index, prescIndex)}>
                                                                                                        Eliminar
                                                                                                        medicamento
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    ))}
                                                                                    <Row>
                                                                                        <Col sm="12"
                                                                                             className="text-end">
                                                                                            <Button type="button"
                                                                                                    color="light"
                                                                                                    className="btn-rounded mt-2"
                                                                                                    onClick={() => addMedications(index)}>
                                                                                                Agregar medicamento
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                                <div style={{marginLeft: 20}}>
                                                                                    <label
                                                                                        htmlFor={`medication-${index}-`}>Otras
                                                                                        indicaciones:</label>
                                                                                    <Input
                                                                                        id={`indications-${index}`}
                                                                                        name={`prescriptions[${index}].indications`}
                                                                                        type="text"
                                                                                        placeholder="Otras indicaciones"
                                                                                        value={prescription.indications}
                                                                                        onChange={e => handlePrescriptionsChange(e, index, 'indications')}
                                                                                        className="flex-grow-1"
                                                                                        onBlur={formik.handleBlur}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col sm="12" style={{marginTop: 10}}>
                                                                                <Button type="button"
                                                                                        onClick={() => removePrescriptions(index)}>Eliminar
                                                                                    receta médica
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded"
                                                            onClick={addPrescriptions}>
                                                        Agregar otra receta médica
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('monitoring')}
                                                       style={{cursor: 'pointer'}}>
                                                Evolución clínica {visibility.monitoring ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.monitoring && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3">
                                                                <Label htmlFor="monitoring">Evolución del
                                                                    paciente</Label>
                                                                <textarea
                                                                    id="monitoring"
                                                                    name="monitoring"
                                                                    placeholder="Evolución del paciente"
                                                                    value={formik.values.monitoring}
                                                                    onChange={handleInputChange}
                                                                    className={`form-control ${formik.touched.monitoring && formik.errors.monitoring ? 'is-invalid' : ''}`}
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        resize: 'none'
                                                                    }}
                                                                />
                                                                {formik.errors.monitoring && formik.touched.monitoring ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.monitoring}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                            }

                                            {
                                                loading ? <Spinners/>
                                                    :
                                                    <Row>
                                                        <div className="d-flex flex-wrap justify-content-end gap-2">
                                                            <Link to={`/admin/patient/consultation/${id}`}
                                                                  className="btn btn-outline-danger">Cancelar</Link>
                                                            <Button type="submit" color="success"> Guardar </Button>
                                                            <div className="position-fixed top-0 end-0 p-3"
                                                                 style={{zIndex: "1005"}}>
                                                                <Toast isOpen={toast}>
                                                                    <ToastHeader toggle={closeToast}>
                                                                        {toastTitle}
                                                                    </ToastHeader>
                                                                    <ToastBody>
                                                                        {toastMessage}
                                                                    </ToastBody>
                                                                </Toast>
                                                            </div>
                                                        </div>
                                                    </Row>
                                            }
                                        </Form>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminConsultationEdit)
import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

//Import Components
import Accordian from "./accordian"

const FAQs = () => {
  const [activeTab, setactiveTab] = useState("1")

  return (
    <React.Fragment>
      <section className="section" id="faqs">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">FAQs</div>
                <h4>Preguntas Frecuentes</h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="vertical-nav">
                <Row>
                  <Col lg="2" sm="4">
                    <Nav pills className="flex-column">
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <i className="bx bx-help-circle nav-icon d-block mb-2"/>
                        <p className="font-weight-bold mb-0">
                          Preguntas
                        </p>
                      </NavLink>

                    </Nav>
                  </Col>
                  <Col lg="10" sm="8">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1" id="buy">
                            <Accordian
                              question1="¿Cumplen las Normas de FarmabitsCaydeem"
                              answer1="Sí, Farmabits ha sido desarrollado para cumplir con todas las regulaciones y normativas de FarmabitsCaydeem, asegurando que el manejo de información médica y la emisión de recetas se realice de manera legal y segura."
                              question2="¿Qué es Farmabits?"
                              answer2="Farmabits es un software diseñado específicamente para doctores que permite llevar un registro detallado de sus pacientes, gestionar recetas y cumplir con las normativas de FarmabitsCaydeem."
                              question3="¿Qué funcionalidades ofrece Farmabits?"
                              answer3="Farmabits ofrece funcionalidades como registro de pacientes, historial médico, gestión de citas, emisión de recetas electrónicas, y reportes de seguimiento, todo cumpliendo con las normativas de FarmabitsCaydeem."
                              question4="¿Cómo asegura Farmabits la privacidad de la información de mis pacientes?"
                              answer4="Farmabits utiliza protocolos de seguridad avanzados y cifrado de datos para garantizar que toda la información de los pacientes esté protegida y cumpla con las regulaciones de privacidad de FarmabitsCaydeem."
                              question5="¿Puedo acceder a Farmabits desde diferentes dispositivos?"
                              answer5="Sí, Farmabits es una plataforma basada en la nube, lo que permite acceder al software desde cualquier dispositivo con conexión a Internet, ya sea una computadora, tableta o teléfono inteligente."
                            />
                          </TabPane>


                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default FAQs

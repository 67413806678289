import {
    adminConsultingOfficesSuccess,
    loadingTrue,
    apiError,
    cleanApiError,
    adminCreateOfficeSuccess,
    adminDeleteOfficeSuccess,
    adminConsultingOfficeSuccess,
    adminEditOfficeSuccess
} from "./reducer";
import { APIClient } from "../../helpers/api_helper";

const api = new APIClient();

export const cleanErrors = () => {
    return cleanApiError();
}

export const getAdminConsultingOfficesThunk = () =>async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/consulting-offices`, null);
        console.log("without error, response", response);
        console.log("without error, response.consulting_offices", response.consulting_offices);
        dispatch(adminConsultingOfficesSuccess(response.consulting_offices));
    } catch (error) {
        console.error("Error in getAdminConsultingOfficesThunk:", error);
        dispatch(apiError(error));
    }
}
export const getAdminConsultingOfficeThunk = (id: string | undefined) =>async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/consulting-office/${id}`, null);
        console.log("without error, response", response);
        console.log("without error, response.consulting_office", response.consulting_office);
        dispatch(adminConsultingOfficeSuccess(response.consulting_office));
    } catch (error) {
        console.error("Error in getAdminConsultingOfficesThunk:", error);
        dispatch(apiError(error));
    }
}

export const consultingOfficeCreateThunk = (consultingOffice: any, history: any) => async (dispatch: any) => {
    console.log("in consultingOfficeCreateThunk, user", consultingOffice);
    dispatch(loadingTrue());
    try {
        const response = await api.Post(`/doctor/consulting-office`, {
            name: consultingOffice.name,
            state: consultingOffice.state.val,
            city: consultingOffice.city,
            address: consultingOffice.address,
            phone: consultingOffice.phone,
        });

        console.log("without error, consultingOfficeCreateThunk response", response);
        dispatch(adminCreateOfficeSuccess());
        history('/admin/consulting-offices');
    } catch (error) {
        console.error("Error in consultingOfficeCreateThunk:", error);
        dispatch(apiError(error));
    }
}
export const consultingOfficeUpdateThunk = (consultingOffice: any, history: any) => async (dispatch: any) => {
    console.log("in consultingOfficeCreateThunk, user", consultingOffice);
    dispatch(loadingTrue());
    try {
        const response = await api.Put(`/doctor/consulting-office`, {
            id: consultingOffice.id,
            name: consultingOffice.name,
            state: consultingOffice.state.val,
            city: consultingOffice.city,
            address: consultingOffice.address,
            phone: consultingOffice.phone,
        });

        console.log("without error, consultingOfficeCreateThunk response", response);
        dispatch(adminEditOfficeSuccess(response));
        history('/admin/consulting-offices');
    } catch (error) {
        console.error("Error in consultingOfficeCreateThunk:", error);
        dispatch(apiError(error));
    }
}

export const deleteOffice = (id: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Delete(`/doctor/consulting-office/${id}`, null);
        dispatch(adminDeleteOfficeSuccess(response));
        dispatch(getAdminConsultingOfficesThunk());
    } catch (error) {
        console.error("Error in deleteEvent:", error);
        dispatch(apiError(error));
    }
}
import React, {useState} from "react"
import { Link } from "react-router-dom";

import {
    Card, CardBody, CardTitle, Col, Container, Row, Table
} from "reactstrap";
import {cleanErrors, getAdminProfileThunk} from "../../slices/profile/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {Doctor} from "../../entities/types";

interface selectState {
    doctor: {
        loading: false;
        adminProfile: Doctor;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminProfileDetail = (props: any) => {
    const dispatch: any = useDispatch();

    document.title = "Perfil";

    const selectProperties = createSelector(
        (state: selectState) => state.doctor,
        (item) => ({
            loading: item.loading,
            adminProfile: item.adminProfile,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, adminProfile, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    React.useEffect(() => {
        dispatch(getAdminProfileThunk());
    }, []);

    const getGenderLabel = (gender) => {
        switch (gender) {
            case 'male':
                return 'Masculino';
            case 'female':
                return 'Femenino';
            default:
                return 'Sin registro';
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            {loading ? (
                                <Spinners/>
                            ) : (
                                <Card>
                                    <CardBody className="d-flex justify-content-between align-items-center">
                                        <CardTitle className="mb-0">Perfil</CardTitle>
                                        <Link to={`/admin/profile/edit`} className="btn btn-primary">Editar</Link>
                                    </CardBody>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Título Académico:</th>
                                                    <td>{adminProfile.academic_title || 'Sin registro'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Nombre:</th>
                                                    <td>{adminProfile.first_name || 'Sin registro'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Apellidos:</th>
                                                    <td>{adminProfile.last_name || 'Sin registro'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Especialidad:</th>
                                                    <td>{adminProfile.specialty || 'Sin registro'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Cédula Profesional:</th>
                                                    <td>{adminProfile.professional_id_card || 'Sin registro'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Email:</th>
                                                    <td>{adminProfile.email || 'Sin registro'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Género:</th>
                                                    <td>{getGenderLabel(adminProfile.gender)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Títulos Universitarios</th>
                                                    <td>
                                                        {adminProfile.info?.educations && adminProfile.info.educations.length > 0 ? (
                                                            <div style={{marginBottom: '20px'}}>
                                                                {adminProfile.info.educations.map((education, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>Universidad: {education.school_name ? education.school_name : "Sin registro"}</span><br/>
                                                                            <span>Título: {education.title ? education.title : "Sin registro"}</span><br/>
                                                                            <span>Fecha de inicio: {education.admission_date ? education.admission_date : "Sin registro"}</span><br/>
                                                                            <span>Fecha de graduación: {education.graduation_date ? education.graduation_date : "Sin registro"}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <span>No hay títulos registrados</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Sub-especialidades</th>
                                                    <td>
                                                        {adminProfile.info?.subspecialties && adminProfile.info.subspecialties.length > 0 ? (
                                                            <div style={{marginBottom: '20px'}}>
                                                                {adminProfile.info.subspecialties.map((subspecialty, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>{subspecialty.subspecialty} </span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <span>No hay sub-espacialidades registradas</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Cursos</th>
                                                    <td>
                                                        {adminProfile.info?.courses && adminProfile.info.courses.length > 0 ? (
                                                            <div style={{marginBottom: '20px'}}>
                                                                {adminProfile.info.courses.map((course, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '20px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>Curso: {course.course ? course.course : "Sin registro"}</span><br/>
                                                                            <span>Fecha de inicio: {course.start_date ? course.start_date : "Sin registro"}</span><br/>
                                                                            <span>Fecha de término: {course.end_date ? course.end_date : "Sin registro"}</span><br/>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <span>No hay cursos registrados</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Entrenamientos</th>
                                                    <td>
                                                        {adminProfile.info?.trainings && adminProfile.info.trainings.length > 0 ? (
                                                            <div style={{marginBottom: '20px'}}>
                                                                {adminProfile.info.trainings.map((course, index) => (
                                                                    <div key={index} style={{
                                                                        marginBottom: '10px',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div
                                                                            style={{marginRight: '10px'}}>{index + 1}.
                                                                        </div>
                                                                        <div>
                                                                            <span>{course.training}</span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <span>No hay entrenamientos registrados</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminProfileDetail)
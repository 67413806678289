import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    user: "",
    reducerToastMessage: "",
    reducerToastTitle: "",
    loading: false,
    isUserLogout: false,
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess(state, action) {
            console.log("reducers loginSuccess loading false");
            state.user = action.payload
            state.loading = false;
        },
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
            state.isUserLogout = true;
            console.log("reducers apiError");
        },
        resetLoginFlag(state) {
            console.log("reducers resetLoginFlag loading false");
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
            state.loading = false;
        },
        logoutUserSuccess(state, action) {
            state.isUserLogout = true;
            state.loading = false;
        },
        cleanErrors(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
    }
});
export const {
    loginSuccess,
    apiError,
    resetLoginFlag,
    logoutUserSuccess,
    loadingTrue,
} = loginSlice.actions;
export default loginSlice.reducer;
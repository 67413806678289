import React, {useState} from "react";
import {Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Spinner} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import {Link, useLocation} from "react-router-dom";
import withRouter from "Components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import {userForgetPasswordThunk, userResetPasswordThunk} from "../../slices/thunk";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import { createSelector } from 'reselect';

const RecoverPasswordPage = (props) => {
    const [show, setShow] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    //meta title
    document.title = "Reestablecer contraseña | Farmabits";

    const dispatch = useDispatch<any>();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    console.log("Token from URL:", token);


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Escribe tu nueva contraseña"),
        }),
        onSubmit: (values) => {
            dispatch(userResetPasswordThunk(token, values.password, () => {
                setShowSuccessMessage(true);
            }));
        }
    });

    const selectProperties = createSelector(
        (state: any) => state.ForgetPassword,
        (forgetPassword) => ({
            forgetError: forgetPassword.forgetError,
            forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
        })
    );

    const { forgetError, forgetSuccessMsg } = useSelector(selectProperties);

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary-subtlebg-soft-primary">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Reestablece tu contraseña</h5>
                                                <p>Escribe tu nueva contraseña.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        {loading ? (
                                            <div className="text-center">
                                                <Spinner color="primary" />
                                            </div>
                                        ) : (
                                            <>
                                                {forgetError && forgetError ? (
                                                    <Alert color="danger" style={{ marginTop: "13px" }}>
                                                        {forgetError}
                                                    </Alert>
                                                ) : null}
                                                {showSuccessMessage && (
                                                    <Alert color="success" style={{ marginTop: "13px" }}>
                                                        Contraseña reestablecida
                                                    </Alert>
                                                )}
                                                {!showSuccessMessage && (
                                                    <>
                                                        <Form
                                                            className="form-horizontal"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                validation.handleSubmit();
                                                                return false;
                                                            }}
                                                        >
                                                            <div className="mb-3">
                                                                <Label className="form-label">Nueva contraseña</Label>
                                                                <div className="d-flex align-items-center">
                                                                    <Input
                                                                        name="password"
                                                                        className="form-control"
                                                                        placeholder="Contraseña"
                                                                        type={show ? "text" : "password"}
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.password || ""}
                                                                        invalid={
                                                                            validation.touched.password && validation.errors.password ? true : false
                                                                        }
                                                                        style={{ flex: 1 }} // Hace que el Input ocupe todo el espacio disponible
                                                                    />
                                                                    <button
                                                                        onClick={() => setShow(!show)}
                                                                        className="btn btn-light ms-2" // Añade margen a la izquierda para separar el botón del campo de entrada
                                                                        type="button"
                                                                        id="password-addon"
                                                                    >
                                                                        <i className="mdi mdi-eye-outline"></i>
                                                                    </button>
                                                                </div>
                                                                {validation.touched.password && validation.errors.password ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.password}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                            <Row className="mb-3">
                                                                <Col className="text-end">
                                                                    <button
                                                                        className="btn btn-primary w-md"
                                                                        type="submit"
                                                                    >
                                                                        Enviar
                                                                    </button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>
                                    © {new Date().getFullYear()} Crafted with{" "}
                                    <i className="mdi mdi-heart text-danger" /> by Farmabits
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(RecoverPasswordPage);
import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

import logolight from "../../../assets/images/logo_white.png";

const Features = () => {
  const footerLinks = [
    {
      title: "Información",
      links: [
        { title: "Términos y Condiciones", link: "http://localhost:3000/terms-and-conditions" },
        { title: "Políticas de Privacidad", link: "http://localhost:3000/policy-privacy" },
      ],
    },/*
    {
      title: "Redes Sociales",
      links: [
        { title: "Youtube", link: "https://www.youtube.com/" },
      ],
    },*/
  ]

  return (
    <React.Fragment>
      <footer className="landing-footer">
        <Container>
          <Row>
            <Col lg="6">
              <div className="mb-4">
                <img src={logolight} alt="" width="200" />
              </div>

              <p className="mb-2">
                {new Date().getFullYear()} © Powered by Caydeem
              </p>
            </Col>
            {footerLinks.map((footerLink, key) => (
              <Col lg="3" sm="6" key={key}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">{footerLink.title}</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {footerLink.links.map((Flink, key) => (
                      <li key={key}>
                        <Link to={Flink.link}>{Flink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}

          </Row>

        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Features

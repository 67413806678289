import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ConsultingOffice, Event, Patient} from "../../entities/types";

const initialState = {
    events: [] as Event[],
    patients: [] as Patient[],
    consultingOfficeList: [] as ConsultingOffice[],
    reducerToastMessage: "",
    reducerToastTitle: "",
    loading: false
};

const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
        },
        cleanApiError(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
        adminCalendarEventsSuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminCalendarEventsSuccess loading false");
            state.events = action.payload;
            state.loading = false;
        },
        adminSearchPatientsSuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminCalendarEventsSuccess loading false");
            state.patients = action.payload;
            state.loading = false;
        },
        adminDeleteEventSuccess(state) {
            console.log("reducers adminDeleteEventSuccess loading false");
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Cita eliminada";
            state.loading = false;
        },
        appointmentCreateSuccess(state) {
            console.log("reducers appointmentCreateSuccess loading false");
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Consulta agendada";
            state.loading = false;
        },
        adminConsultingOfficesSuccess(state, action: PayloadAction<any>) {
            state.consultingOfficeList = action.payload;
            state.loading = false;
        },
    },
});
export const {
    apiError,
    cleanApiError,
    loadingTrue,
    adminCalendarEventsSuccess,
    adminSearchPatientsSuccess,
    adminDeleteEventSuccess,
    appointmentCreateSuccess,
    adminConsultingOfficesSuccess,
} = patientsSlice.actions;

export default patientsSlice.reducer;
import React, { useState } from "react"
import {
    Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody, FormGroup
} from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import {cleanErrors, getAdminPatientMedicalHistoryThunk, patientCreateThunk, patientMedicalHistoryEditThunk} from "../../slices/admin/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';


import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {Link, useParams} from "react-router-dom";
import {Patient} from "../../entities/types";
import {isEmpty} from "lodash";
import {Spanish} from "flatpickr/dist/l10n/es";
import AdminTabs from "../../Layouts/HorizontalLayout/Tabs";

interface selectState {
    admin: {
        loading: false;
        adminPatient: Patient;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};


const AdminPatientEdit = (props: any) => {
    const dispatch: any = useDispatch();
    const { id } = useParams();

    //meta title
    document.title = "Paciente";

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (item) => ({
            loading: item.loading,
            adminPatient: item.adminPatient,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, adminPatient, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    const PresentThreeOptions = [
        { val: 'Si', label: 'Si' },
        { val: 'Niega', label: 'Niega' },
        { val: 'No se conocen', label: 'No se conocen' },
    ]

    const PresentTwoOptions = [
        { val: 'Si', label: 'Si' },
        { val: 'Niega', label: 'Niega' },
    ]

    const PresentSuspendedOptions = [
        { val: 'Si', label: 'Si' },
        { val: 'Niega', label: 'Niega' },
        { val: 'Suspendido', label: 'Suspendido' },
    ]

    const TimeOptions = [
        { val: 'Menos de un año', label: 'Menos de un año' },
        { val: '1 a 5 años', label: '1 a 5 años' },
        { val: '6 a 10 años', label: '6 a 10 años' },
        { val: '11 a 20 años', label: '11 a 20 años' },
        { val: 'Más de 20 años', label: 'Más de 20 años' },
        { val: 'Otro', label: 'Otro' },
    ]

    const CycleFrequencyOptions = [
        { val: 'Normal', label: 'Normal' },
        { val: 'Anormal', label: 'Anormal' },
    ]

    const GestationalOptions = [
        { val: 'Pretérmino', label: 'Pretérmino' },
        { val: 'Término', label: 'Término' },
        { val: 'Postérmino', label: 'Postérmino' },
    ]

    const GenderOptions = [
        { val: 'Masculino', label: 'Masculino' },
        { val: 'Femenino', label: 'Femenino' },
    ]

    const BirthOptions = [
        { val: 'Vaginal', label: 'Vaginal' },
        { val: 'Cesárea', label: 'Cesárea' },
    ]

    const NeonateOptions = [
        { val: 'Vivo', label: 'Vivo' },
        { val: 'Aborto', label: 'Aborto' },
        { val: 'Óbito', label: 'Óbito' },
    ]

    const ResultOptions = [
        { val: 'Normal', label: 'Normal' },
        { val: 'Anormal', label: 'Anormal' },
    ]

    //Basic Information
    const formik: any = useFormik({
        initialValues: {
            id: '',
            medical_history: {
                family_pathology_present: '',
                family_pathology_notes: '',
                pathology_present: '',
                pathology_notes: '',
                allergy_present: '',
                allergy_notes: '',
                surgery_present: '',
                surgery_notes: '',
                immunization_present: '',
                up_to_date_immunization: null,
                immunization_notes: '',
                tobacco_present: '',
                tobacco_suspension_time: '',
                cigarettes_per_day: null,
                smoking_years: null,
                smoking_index: null,
                tobacco_notes: '',
                alcohol_present: '',
                alcohol_suspension_time: '',
                alcohol_consumed_years: '',
                alcohol_notes: '',
                drug_present: '',
                drug_notes: '',
                physical_activity_present: '',
                physical_activity_notes: '',
                contraceptive_present: '',
                intrauterine: null,
                injectable: null,
                barrier_method: null,
                natural_method: null,
                surgical_method: null,
                oral: null,
                subdermal: null,
                other: null,
                method_notes: '',
                menstrual_cycle_present: '',
                cycle_frequency: '',
                dysmenorrhea_present: '',
                menstrual_cycle_notes: '',
                pregnancy_present: '',
                pregnancies: null,
                births: null,
                abortions: null,
                deaths: null,
                cesarean: null,
                alive: null,
                dead: null,
                pregnancy_notes: '',
                cytology_mammogram_present: '',
                cytology_mammogram_notes: '',
                general_observations: '',
            },
            family_pathologies: [{ pathology: '', members: [{ member: '' }] }],
            pathologies: [{ pathology: '', treatments: [{ treatment: '' }] }],
            allergies: [{ allergy: '', reaction: '' }],
            surgeries: [{ surgery: '', date: '', complications: '' }],
            immunizations: [{ immunization: '', application_year: '' }],
            alcohols: [{ alcohol: '', quantity_and_frequency: '' }],
            drugs: [{ drug: '', dose: '', consumed_frequency: '', consumed_years: '', suspended: null, suspension_years: '' }],
            physical_activities: [{ activity: '', time: 0, frequency: 0 }],
            intrauterines: [{ method: '', time: '', actual_method: null }],
            injectables: [{ medicine: '', time: '', actual_method: null }],
            barriers: [{ method: '', time: '', actual_method: null }],
            naturals: [{ method: '', time: '', actual_method: null }],
            surgicals: [{ method: '', time: '', actual_method: null }],
            orals: [{ medicine: '', time: '', actual_method: null }],
            subdermals: [{ method: '', time: '', actual_method: null }],
            others: [{ method: '', time: '', actual_method: null }],
            pregnancies: [{ due_date: null, labor: 0, gestational_age: '', multiple_birth: null, pregnancy_observations: '', pregnancies: [{ weight: 0, gender: '', birth_method: '', neonate: '' }] }],
            cytologies: [{ date: '', result: '', observations: '' }],
            mammograms: [{ date: '', result: '', observations: '' }],
            questions: [{ question: '', answer: '' }],
        },
        onSubmit: (values: any) => {
            console.log('Basic Information', values);

            const familyErrors = {};
            values.family_pathologies.forEach((family, index) => {
                const isPathologyFilled = family.pathology && family.pathology.trim() !== '';
                const isMemberFilled = family.members.some(member => member.member.trim() !== '');

                if (isMemberFilled && !isPathologyFilled) {
                    familyErrors[`family_pathologies[${index}].pathology`] = 'Campo requerido';
                }
            });

            const pathologyErrors = {};
            values.pathologies.forEach((pathology, index) => {
                const isPathologyFilled = pathology.pathology && pathology.pathology.trim() !== '';
                const isTreatmentFilled = pathology.treatments.some(treatment => treatment.treatment.trim() !== '');

                if (isTreatmentFilled && !isPathologyFilled) {
                    pathologyErrors[`pathologies[${index}].pathology`] = 'Campo requerido';
                }
            });

            const allergyErrors = {};
            values.allergies.forEach((allergy, index) => {
                const isAllergyFilled = allergy.allergy && allergy.allergy.trim() !== '';
                const isReactionFilled = allergy.reaction && allergy.reaction.trim() !== '';

                if (!isAllergyFilled && isReactionFilled) {
                    allergyErrors[`allergies[${index}].allergy`] = 'Campo requerido';
                }
            });

            const surgeryErrors = {};
            values.surgeries.forEach((surgery, index) => {
                const isSurgeryFilled = surgery.surgery && surgery.surgery.trim() !== '';
                const isDateFilled = surgery.date && surgery.date.trim() !== '';
                const isComplicationsFilled = surgery.complications && surgery.complications.trim() !== '';

                if (!isSurgeryFilled && (isDateFilled || isComplicationsFilled)) {
                    surgeryErrors[`surgeries[${index}].surgery`] = 'Campo requerido';
                }
            });

            const immunizationErrors = {};
            values.immunizations.forEach((immunization, index) => {
                const isImmunizationFilled = immunization.immunization && immunization.immunization.trim() !== '';
                const isDateFilled = immunization.application_year && immunization.application_year.trim() !== '';

                if (!isImmunizationFilled && isDateFilled) {
                    immunizationErrors[`immunizations[${index}].immunization`] = 'Campo requerido';
                }
            });

            const alcoholErrors = {};
            values.alcohols.forEach((alcohol, index) => {
                const isAlcoholFilled = alcohol.alcohol && alcohol.alcohol.trim() !== '';
                const isQFFilled = alcohol.quantity_and_frequency && alcohol.quantity_and_frequency.trim() !== '';

                if (!isAlcoholFilled && isQFFilled) {
                    alcoholErrors[`alcohols[${index}].alcohol`] = 'Campo requerido';
                }
            });

            const drugErrors = {};
            values.drugs.forEach((drug, index) => {
                const isDrugFilled = drug.drug && drug.drug.trim() !== '';
                const isDoseFilled = drug.dose && drug.dose.trim() !== '';
                const isFrequencyFilled = drug.consumed_frequency && drug.consumed_frequency.trim() !== '';
                const isConsumedYearsFilled = drug.consumed_years && drug.consumed_years.trim() !== '';
                const isSuspendedChecked = drug.suspended === true || drug.suspended === false;
                const isSuspensionYearsFilled = drug.suspension_years && drug.suspension_years.trim() !== '';

                if (!isDrugFilled && (isDoseFilled || isFrequencyFilled || isConsumedYearsFilled || isSuspendedChecked || isSuspensionYearsFilled)) {
                    drugErrors[`drugs[${index}].drug`] = 'Campo requerido';
                }

                if (!isSuspendedChecked && (isDoseFilled || isFrequencyFilled || isConsumedYearsFilled || isDrugFilled || isSuspensionYearsFilled)) {
                    drugErrors[`drugs[${index}].suspended`] = 'Campo requerido';
                }

            });

            const activityErrors = {};
            values.physical_activities.forEach((activity, index) => {
                const isActivityFilled = activity.activity && activity.activity.trim() !== '';
                const isTimeFilled = activity.time != null && activity.time !== 0;
                const isFrequencyFilled = activity.frequency != null && activity.frequency !== 0;

                if (!isActivityFilled && (isTimeFilled || isFrequencyFilled)) {
                    activityErrors[`physical_activities[${index}].activity`] = 'Campo requerido';
                }
            });

            const intrauterineErrors = {};
            values.intrauterines.forEach((method, index) => {
                const isMethodFilled = method.method && method.method.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    intrauterineErrors[`intrauterines[${index}].method`] = 'Campo requerido';
                }

            });

            const injectableErrors = {};
            values.injectables.forEach((method, index) => {
                const isMethodFilled = method.medicine && method.medicine.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    injectableErrors[`injectables[${index}].medicine`] = 'Campo requerido';
                }

            });

            const barrierErrors = {};
            values.barriers.forEach((method, index) => {
                const isMethodFilled = method.method && method.method.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    barrierErrors[`barriers[${index}].method`] = 'Campo requerido';
                }

            });

            const naturalErrors = {};
            values.naturals.forEach((method, index) => {
                const isMethodFilled = method.method && method.method.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    naturalErrors[`naturals[${index}].method`] = 'Campo requerido';
                }

            });

            const surgicalErrors = {};
            values.surgicals.forEach((method, index) => {
                const isMethodFilled = method.method && method.method.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    surgicalErrors[`surgicals[${index}].method`] = 'Campo requerido';
                }

            });

            const oralErrors = {};
            values.orals.forEach((method, index) => {
                const isMethodFilled = method.medicine && method.medicine.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    oralErrors[`orals[${index}].medicine`] = 'Campo requerido';
                }

            });

            const subdermalErrors = {};
            values.subdermals.forEach((method, index) => {
                const isMethodFilled = method.method && method.method.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    subdermalErrors[`subdermals[${index}].method`] = 'Campo requerido';
                }

            });

            const otherErrors = {};
            values.others.forEach((method, index) => {
                const isMethodFilled = method.method && method.method.trim() !== '';
                const isTimeFilled = method.time && method.time.trim() !== '';
                const isActualChecked = method.actual_method === true || method.actual_method === false;

                if (!isMethodFilled && (isTimeFilled || isActualChecked)) {
                    otherErrors[`others[${index}].method`] = 'Campo requerido';
                }

            });

            const questionErrors = {};
            values.questions.forEach((question, index) => {
                const isQuestionFilled = question.question && question.question.trim() !== '';
                const isAnswerFilled = question.answer && question.answer.trim() !== '';

                if (!isQuestionFilled && isAnswerFilled) {
                    questionErrors[`questions[${index}].question`] = 'Campo requerido';
                }

                if (isQuestionFilled && !isAnswerFilled) {
                    questionErrors[`questions[${index}].answer`] = 'Campo requerido';
                }

            });

            const errors = { ...familyErrors, ...pathologyErrors, ...allergyErrors, ...surgeryErrors, ...immunizationErrors, ...alcoholErrors, ...drugErrors, ...activityErrors, ...intrauterineErrors, ...injectableErrors, ...barrierErrors, ...naturalErrors, ...surgicalErrors, ...oralErrors, ...subdermalErrors, ...otherErrors, ...questionErrors };

            if (Object.keys(errors).length > 0) {
                formik.setErrors(errors);
                setToastMessage("Por favor, complete todos los campos requeridos.");
                setToastTitle("Campos requeridos");
                setToast(true);
                return;
            }


            dispatch(patientMedicalHistoryEditThunk(values, props.router.navigate));
        },
    });

    React.useEffect(() => {
        if (!isEmpty(adminPatient)) {

            formik.setValues({
                id: adminPatient.id || '',
                medical_history: {
                    family_pathology_present: adminPatient.history_info.medical_history?.family_pathology_present || '',
                    family_pathology_notes: adminPatient.history_info.medical_history?.family_pathology_notes || '',
                    pathology_present: adminPatient.history_info.medical_history?.pathology_present || '',
                    pathology_notes: adminPatient.history_info.medical_history?.pathology_notes || '',
                    allergy_present: adminPatient.history_info.medical_history?.allergy_present || '',
                    allergy_notes: adminPatient.history_info.medical_history?.allergy_notes || '',
                    surgery_present: adminPatient.history_info.medical_history?.surgery_present || '',
                    surgery_notes: adminPatient.history_info.medical_history?.surgery_notes || '',
                    immunization_present: adminPatient.history_info.medical_history?.immunization_present || '',
                    up_to_date_immunization: adminPatient.history_info.medical_history?.up_to_date_immunization ?? null,
                    immunization_notes: adminPatient.history_info.medical_history?.immunization_notes || '',
                    tobacco_present: adminPatient.history_info.medical_history?.tobacco_present || '',
                    tobacco_suspension_time: adminPatient.history_info.medical_history?.tobacco_suspension_time || '',
                    cigarettes_per_day: adminPatient.history_info.medical_history?.cigarettes_per_day || null,
                    smoking_years: adminPatient.history_info.medical_history?.smoking_years || null,
                    tobacco_notes: adminPatient.history_info.medical_history?.tobacco_notes || '',
                    alcohol_present: adminPatient.history_info.medical_history?.alcohol_present || '',
                    alcohol_suspension_time: adminPatient.history_info.medical_history?.alcohol_suspension_time || '',
                    alcohol_consumed_years: adminPatient.history_info.medical_history?.alcohol_consumed_years || '',
                    alcohol_notes: adminPatient.history_info.medical_history?.alcohol_notes || '',
                    drug_present: adminPatient.history_info.medical_history?.drug_present || '',
                    drug_notes: adminPatient.history_info.medical_history?.drug_notes || '',
                    physical_activity_present: adminPatient.history_info.medical_history?.physical_activity_present || '',
                    physical_activity_notes: adminPatient.history_info.medical_history?.physical_activity_notes || '',
                    contraceptive_present: adminPatient.history_info.medical_history?.contraceptive_present || '',
                    intrauterine: adminPatient.history_info.medical_history?.intrauterine ?? null,
                    injectable: adminPatient.history_info.medical_history?.injectable ?? null,
                    barrier_method: adminPatient.history_info.medical_history?.barrier_method ?? null,
                    natural_method: adminPatient.history_info.medical_history?.natural_method ?? null,
                    surgical_method: adminPatient.history_info.medical_history?.surgical_method ?? null,
                    oral: adminPatient.history_info.medical_history?.oral ?? null,
                    subdermal: adminPatient.history_info.medical_history?.subdermal ?? null,
                    other: adminPatient.history_info.medical_history?.other ?? null,
                    method_notes: adminPatient.history_info.medical_history?.method_notes ?? '',
                    menstrual_cycle_present: adminPatient.history_info.medical_history?.menstrual_cycle_present ?? '',
                    cycle_frequency: adminPatient.history_info.medical_history?.cycle_frequency ?? '',
                    dysmenorrhea_present: adminPatient.history_info.medical_history?.dysmenorrhea_present ?? '',
                    menstrual_cycle_notes: adminPatient.history_info.medical_history?.menstrual_cycle_notes ?? '',
                    pregnancy_present: adminPatient.history_info.medical_history?.pregnancy_present ?? '',
                    pregnancies: adminPatient.history_info.medical_history?.pregnancies || null,
                    births: adminPatient.history_info.medical_history?.births || null,
                    abortions: adminPatient.history_info.medical_history?.abortions || null,
                    deaths: adminPatient.history_info.medical_history?.deaths || null,
                    cesarean: adminPatient.history_info.medical_history?.cesarean || null,
                    alive: adminPatient.history_info.medical_history?.alive || null,
                    dead: adminPatient.history_info.medical_history?.dead || null,
                    pregnancy_notes: adminPatient.history_info.medical_history?.pregnancy_notes ?? '',
                    cytology_mammogram_present: adminPatient.history_info.medical_history?.cytology_mammogram_present ?? '',
                    cytology_mammogram_notes: adminPatient.history_info.medical_history?.cytology_mammogram_notes ?? '',
                    general_observations: adminPatient.history_info.medical_history?.general_observations ?? '',
                },
                family_pathologies: (adminPatient.history_info.family_pathologies?.length > 0
                        ? adminPatient.history_info.family_pathologies.map(family => ({
                            pathology: family.pathology,
                            members: family.members?.map(member => ({
                                member: member.member,
                            })) || [{ member: '' }]
                        }))
                        : [{ pathology: '', members: [{ member: '' }] }]
                ),
                pathologies: (adminPatient.history_info.pathologies?.length > 0
                        ? adminPatient.history_info.pathologies.map(pathology => ({
                            pathology: pathology.pathology,
                            treatments: pathology.treatments?.map(treatment => ({
                                treatment: treatment.treatment,
                            })) || [{ treatment: '' }]
                        }))
                        : [{ pathology: '', treatments: [{ treatment: '' }] }]
                ),
                allergies: (adminPatient.history_info.allergies?.length > 0
                        ? adminPatient.history_info.allergies.map(allergy => ({
                            allergy: allergy.allergy,
                            reaction: allergy.reaction,
                        }))
                        : [{ allergy: '', reaction: '' }]
                ),
                surgeries: (adminPatient.history_info.surgeries?.length > 0
                        ? adminPatient.history_info.surgeries.map(surgery => ({
                            surgery: surgery.surgery,
                            date: surgery.date,
                            complications: surgery.complications,
                        }))
                        : [{ surgery: '', date: '', complications: '' }]
                ),
                immunizations: (adminPatient.history_info.immunizations?.length > 0
                        ? adminPatient.history_info.immunizations.map(immunization => ({
                            immunization: immunization.immunization,
                            application_year: immunization.application_year,
                        }))
                        : [{ immunization: '', application_year: '' }]
                ),
                alcohols: (adminPatient.history_info.alcohols?.length > 0
                        ? adminPatient.history_info.alcohols.map(alcohol => ({
                            alcohol: alcohol.alcohol,
                            quantity_and_frequency: alcohol.quantity_and_frequency,
                        }))
                        : [{ alcohol: '', quantity_and_frequency: '' }]
                ),
                drugs: (adminPatient.history_info.drugs?.length > 0
                        ? adminPatient.history_info.drugs.map(drug => ({
                            drug: drug.drug,
                            dose: drug.dose,
                            consumed_frequency: drug.consumed_frequency,
                            consumed_years: drug.consumed_years,
                            suspended: drug.suspended,
                            suspension_years: drug.suspension_years,
                        }))
                        : [{ drug: '', dose: '', consumed_frequency: '', consumed_years: '', suspended: null, suspension_years: '' }]
                ),
                physical_activities: (adminPatient.history_info.physical_activities?.length > 0
                        ? adminPatient.history_info.physical_activities.map(activity => ({
                            activity: activity.activity,
                            time: activity.time,
                            frequency: activity.frequency,
                        }))
                        : [{ activity: '', time: 0, frequency: 0 }]
                ),
                intrauterines: (adminPatient.history_info.intrauterines?.length > 0
                        ? adminPatient.history_info.intrauterines.map(method => ({
                            method: method.method,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ method: '', time: '', actual_method: null }]
                ),
                injectables: (adminPatient.history_info.injectables?.length > 0
                        ? adminPatient.history_info.injectables.map(method => ({
                            medicine: method.medicine,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ medicine: '', time: '', actual_method: null }]
                ),
                barriers: (adminPatient.history_info.barriers?.length > 0
                        ? adminPatient.history_info.barriers.map(method => ({
                            method: method.method,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ method: '', time: '', actual_method: null }]
                ),
                naturals: (adminPatient.history_info.naturals?.length > 0
                        ? adminPatient.history_info.naturals.map(method => ({
                            method: method.method,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ method: '', time: '', actual_method: null }]
                ),
                surgicals: (adminPatient.history_info.surgicals?.length > 0
                        ? adminPatient.history_info.surgicals.map(method => ({
                            method: method.method,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ method: '', time: '', actual_method: null }]
                ),
                orals: (adminPatient.history_info.orals?.length > 0
                        ? adminPatient.history_info.orals.map(method => ({
                            medicine: method.medicine,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ medicine: '', time: '', actual_method: null }]
                ),
                subdermals: (adminPatient.history_info.subdermals?.length > 0
                        ? adminPatient.history_info.subdermals.map(method => ({
                            method: method.method,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ method: '', time: '', actual_method: null }]
                ),
                others: (adminPatient.history_info.others?.length > 0
                        ? adminPatient.history_info.others.map(method => ({
                            method: method.method,
                            time: method.time,
                            actual_method: method.actual_method,
                        }))
                        : [{ method: '', time: '', actual_method: null }]
                ),
                pregnancies: (adminPatient.history_info.pregnancies?.length > 0
                        ? adminPatient.history_info.pregnancies.map(pregnancy => ({
                            due_date: pregnancy.due_date,
                            labor: pregnancy.labor,
                            gestational_age: pregnancy.gestational_age,
                            multiple_birth: pregnancy.multiple_birth,
                            pregnancy_observations: pregnancy.pregnancy_observations,
                            pregnancies: pregnancy.pregnancies?.map(multiple => ({
                                weight: multiple.weight,
                                gender: multiple.gender,
                                birth_method: multiple.birth_method,
                                neonate: multiple.neonate,
                            })) || [{ weight: 0, gender: '', birth_method: '', neonate: '' }]
                        }))
                        : [{ due_date: null, labor: 0, gestational_age: '', multiple_birth: null, pregnancy_observations: '', pregnancies: [{ weight: 0, gender: '', birth_method: '', neonate: '' }] }]
                ),
                cytologies: (adminPatient.history_info.cytologies?.length > 0
                        ? adminPatient.history_info.cytologies.map(cytoloy => ({
                            date: cytoloy.date,
                            result: cytoloy.result,
                            observations: cytoloy.observations,
                        }))
                        : [{ date: '', result: '', observations: '' }]
                ),
                mammograms: (adminPatient.history_info.mammograms?.length > 0
                        ? adminPatient.history_info.mammograms.map(mammogram => ({
                            date: mammogram.date,
                            result: mammogram.result,
                            observations: mammogram.observations,
                        }))
                        : [{ date: '', result: '', observations: '' }]
                ),
                questions: (adminPatient.history_info.questions?.length > 0
                        ? adminPatient.history_info.questions.map(question => ({
                            question: question.question,
                            answer: question.answer,
                        }))
                        : [{ question: '', answer: '' }]
                ),
            });
        }
    }, [adminPatient, formik.setValues]);

    React.useEffect(() => {
        if (id) { // Agrega una condición para asegurarte de que 'id' esté definido
            dispatch(getAdminPatientMedicalHistoryThunk(id));
        }
    }, [id]); // Solo dispara la acción cuando 'id' cambie

    const handleFamilyChange = (e, index, field) => {
        const { value } = e.target;
        const newFamily = formik.values.family_pathologies.map((family, i) =>
            i === index ? { ...family, [field]: value } : family
        );
        formik.setFieldValue('family_pathologies', newFamily);
    };

    const handleFamilyMemberChange = (e, index, memberIndex, field) => {
        const { value } = e.target;
        const newFamily = formik.values.family_pathologies.map((family, i) =>
            i === index
                ? {
                    ...family,
                    members: family.members.map((member, j) =>
                        j === memberIndex ? { ...member, [field]: value } : member
                    ),
                }
                : family
        );
        formik.setFieldValue('family_pathologies', newFamily);
    };

    const addFamily = () => {
        const newFamily = [...formik.values.family_pathologies, { pathology: '', members: [{ member: '' }] }];
        formik.setFieldValue('family_pathologies', newFamily);
    };

    const addFamilyMember = (familyIndex) => {
        const newFamily = formik.values.family_pathologies.map((family, i) =>
            i === familyIndex
                ? {
                    ...family,
                    members: [...family.members, { member: '' }],
                }
                : family
        );
        formik.setFieldValue('family_pathologies', newFamily);
    };

    const removeFamily = index => {
        const family_pathologies = formik.values.family_pathologies;

        if (family_pathologies.length === 1) {
            formik.setFieldValue('family_pathologies', []);
            return;
        }

        const newFamily = family_pathologies.filter((_, i) => i !== index);
        formik.setFieldValue('family_pathologies', newFamily);
    };

    const removeFamilyMember = (index, memberIndex) => {
        const newFamily = formik.values.family_pathologies.map((family, i) =>
            i === index
                ? {
                    ...family,
                    members: family.members.filter((_, j) => j !== memberIndex),
                }
                : family
        );

        formik.setFieldValue('family_pathologies', newFamily);
    };

    const handlePathologyChange = (e, index, field) => {
        const { value } = e.target;
        const newPathology = formik.values.pathologies.map((pathology, i) =>
            i === index ? { ...pathology, [field]: value } : pathology
        );
        formik.setFieldValue('pathologies', newPathology);
    };

    const handlePathologyTreatmentChange = (e, index, treatmentIndex, field) => {
        const { value } = e.target;
        const newPathology = formik.values.pathologies.map((pathology, i) =>
            i === index
                ? {
                    ...pathology,
                    treatments: pathology.treatments.map((treatment, j) =>
                        j === treatmentIndex ? { ...treatment, [field]: value } : treatment
                    ),
                }
                : pathology
        );
        formik.setFieldValue('pathologies', newPathology);
    };

    const addPathology = () => {
        const newPathology = [...formik.values.pathologies, { pathology: '', treatments: [{ treatment: '' }] }];
        formik.setFieldValue('pathologies', newPathology);
    };

    const addPathologyTreatment = (familyIndex) => {
        const newPathology = formik.values.pathologies.map((family, i) =>
            i === familyIndex
                ? {
                    ...family,
                    treatments: [...family.treatments, { treatment: '' }],
                }
                : family
        );
        formik.setFieldValue('pathologies', newPathology);
    };

    const removePathology = index => {
        const pathologies = formik.values.pathologies;

        if (pathologies.length === 1) {
            formik.setFieldValue('pathologies', []);
            return;
        }

        const newPathology = pathologies.filter((_, i) => i !== index);
        formik.setFieldValue('pathologies', newPathology);
    };

    const removePathologyTreatment = (index, treatmentIndex) => {
        const newPathology = formik.values.pathologies.map((pathology, i) =>
            i === index
                ? {
                    ...pathology,
                    treatments: pathology.treatments.filter((_, j) => j !== treatmentIndex),
                }
                : pathology
        );

        formik.setFieldValue('pathologies', newPathology);
    };

    const handleAllergyChange = (e, index, field) => {
        const { value } = e.target;
        const newAllergies = formik.values.allergies.map((allergy, i) =>
            i === index ? { ...allergy, [field]: value } : allergy
        );
        formik.setFieldValue('allergies', newAllergies);
    };

    const addAllergy = () => {
        const newAllergies = [...formik.values.allergies, { allergy: '', reaction: '' }];
        formik.setFieldValue('allergies', newAllergies);
    };

    const removeAllergy = index => {
        const allergies = formik.values.allergies;

        if (allergies.length === 1) {
            formik.setFieldValue('allergies', []);
            return;
        }

        const newAllergies = allergies.filter((_, i) => i !== index);
        formik.setFieldValue('allergies', newAllergies);
    };

    const handleSurgeryChange = (e, index, field) => {
        const { value } = e.target;
        const newSurgery = formik.values.surgeries.map((surgery, i) =>
            i === index ? { ...surgery, [field]: value } : surgery
        );
        formik.setFieldValue('surgeries', newSurgery);
    };

    const addSurgery = () => {
        const newSurgery = [...formik.values.surgeries, { surgery: '', date: '', complications: '' }];
        formik.setFieldValue('surgeries', newSurgery);
    };

    const removeSurgery = index => {
        const surgeries = formik.values.surgeries;

        if (surgeries.length === 1) {
            formik.setFieldValue('surgeries', []);
            return;
        }

        const newSurgery = surgeries.filter((_, i) => i !== index);
        formik.setFieldValue('surgeries', newSurgery);
    };

    const handleImmunizationChange = (e, index, field) => {
        const { value } = e.target;
        const newImmunization = formik.values.immunizations.map((immunization, i) =>
            i === index ? { ...immunization, [field]: value } : immunization
        );
        formik.setFieldValue('immunizations', newImmunization);
    };

    const addImmunization = () => {
        const newImmunization = [...formik.values.immunizations, { immunization: '', application_year: '' }];
        formik.setFieldValue('immunizations', newImmunization);
    };

    const removeImmunization = index => {
        const immunizations = formik.values.immunizations;

        if (immunizations.length === 1) {
            formik.setFieldValue('immunizations', []);
            return;
        }

        const newImmunization = immunizations.filter((_, i) => i !== index);
        formik.setFieldValue('immunizations', newImmunization);
    };

    const handleAlcoholChange = (e, index, field) => {
        const { value } = e.target;
        const newAlcohol = formik.values.alcohols.map((alcohol, i) =>
            i === index ? { ...alcohol, [field]: value } : alcohol
        );
        formik.setFieldValue('alcohols', newAlcohol);
    };

    const addAlcohol = () => {
        const newAlcohol = [...formik.values.alcohols, { alcohol: '', quantity_and_frequency: '' }];
        formik.setFieldValue('alcohols', newAlcohol);
    };

    const removeAlcohol = index => {
        const alcohols = formik.values.alcohols;

        if (alcohols.length === 1) {
            formik.setFieldValue('alcohols', []);
            return;
        }

        const newAlcohol = alcohols.filter((_, i) => i !== index);
        formik.setFieldValue('alcohols', newAlcohol);
    };

    const handleDrugChange = (e, index, field) => {
        const { value } = e.target;
        const newDrug = formik.values.drugs.map((drug, i) =>
            i === index ? { ...drug, [field]: value } : drug
        );
        formik.setFieldValue('drugs', newDrug);
    };

    const handleDrugBoolChange = (e, index, value) => {
        const { checked } = e.target;
        const newDrug = formik.values.drugs.map((drug, i) =>
            i === index ? { ...drug, suspended: checked ? value : null } : drug
        );
        formik.setFieldValue('drugs', newDrug);
    };

    const addDrug = () => {
        const newDrug = [...formik.values.drugs, { drug: '', dose: '', consumed_frequency: '', consumed_years: '', /*suspended. '',*/ suspension_years: '' }];
        formik.setFieldValue('drugs', newDrug);
    };

    const removeDrug = index => {
        const drugs = formik.values.drugs;

        if (drugs.length === 1) {
            formik.setFieldValue('drugs', []);
            return;
        }

        const newDrug = drugs.filter((_, i) => i !== index);
        formik.setFieldValue('drugs', newDrug);
    };

    const handleActivityChange = (e, index, field) => {
        const { value } = e.target;
        const newActivity = formik.values.physical_activities.map((activity, i) =>
            i === index ? { ...activity, [field]: value } : activity
        );
        formik.setFieldValue('physical_activities', newActivity);
    };

    const handleActivityIntChange = (e, index, field) => {
        const { value } = e.target;
        const newValue = value !== '' ? parseFloat(value) : 0;
        const newActivity = formik.values.physical_activities.map((activity, i) =>
            i === index ? { ...activity, [field]: newValue } : activity
        );
        formik.setFieldValue('physical_activities', newActivity);
    };

    const addActivity = () => {
        const newActivity = [...formik.values.physical_activities, { activity: '', time: 0, frequency: 0 }];
        formik.setFieldValue('physical_activities', newActivity);
    };

    const removeActivity = index => {
        const physical_activities = formik.values.physical_activities;

        if (physical_activities.length === 1) {
            formik.setFieldValue('physical_activities', []);
            return;
        }

        const newActivity = physical_activities.filter((_, i) => i !== index);
        formik.setFieldValue('physical_activities', newActivity);
    };

    const handleIntrauterineChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.intrauterines.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('intrauterines', newMethod);
    };

    const addIntrauterine = () => {
        const newMethod = [...formik.values.intrauterines, { method: '', time: '', actual_method: null }];
        formik.setFieldValue('intrauterines', newMethod);
    };

    const removeIntrauterine = index => {
        const intrauterines = formik.values.intrauterines;

        if (intrauterines.length === 1) {
            formik.setFieldValue('intrauterines', []);
            return;
        }

        const newMethod = intrauterines.filter((_, i) => i !== index);
        formik.setFieldValue('intrauterines', newMethod);
    };

    const handleInjectableChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.injectables.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('injectables', newMethod);
    };

    const addInjectable = () => {
        const newMethod = [...formik.values.injectables, { medicine: '', time: '', actual_method: null }];
        formik.setFieldValue('injectables', newMethod);
    };

    const removeInjectable = index => {
        const injectables = formik.values.injectables;

        if (injectables.length === 1) {
            formik.setFieldValue('injectables', []);
            return;
        }

        const newMethod = injectables.filter((_, i) => i !== index);
        formik.setFieldValue('injectables', newMethod);
    };

    const handleBarrierChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.barriers.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('barriers', newMethod);
    };

    const addBarrier = () => {
        const newMethod = [...formik.values.barriers, { method: '', time: '', actual_method: null }];
        formik.setFieldValue('barriers', newMethod);
    };

    const removeBarrier = index => {
        const barriers = formik.values.barriers;

        if (barriers.length === 1) {
            formik.setFieldValue('barriers', []);
            return;
        }

        const newMethod = barriers.filter((_, i) => i !== index);
        formik.setFieldValue('barriers', newMethod);
    };

    const handleNaturalChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.naturals.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('naturals', newMethod);
    };

    const addNatural = () => {
        const newMethod = [...formik.values.naturals, { method: '', time: '', actual_method: null }];
        formik.setFieldValue('naturals', newMethod);
    };

    const removeNatural = index => {
        const naturals = formik.values.naturals;

        if (naturals.length === 1) {
            formik.setFieldValue('naturals', []);
            return;
        }

        const newMethod = naturals.filter((_, i) => i !== index);
        formik.setFieldValue('naturals', newMethod);
    };

    const handleSurgicalChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.surgicals.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('surgicals', newMethod);
    };

    const addSurgical = () => {
        const newMethod = [...formik.values.surgicals, { method: '', time: '', actual_method: null }];
        formik.setFieldValue('surgicals', newMethod);
    };

    const removeSurgical = index => {
        const surgicals = formik.values.surgicals;

        if (surgicals.length === 1) {
            formik.setFieldValue('surgicals', []);
            return;
        }

        const newMethod = surgicals.filter((_, i) => i !== index);
        formik.setFieldValue('surgicals', newMethod);
    };

    const handleOralChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.orals.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('orals', newMethod);
    };

    const addOral = () => {
        const newMethod = [...formik.values.orals, { medicine: '', time: '', actual_method: null }];
        formik.setFieldValue('orals', newMethod);
    };

    const removeOral = index => {
        const orals = formik.values.orals;

        if (orals.length === 1) {
            formik.setFieldValue('orals', []);
            return;
        }

        const newMethod = orals.filter((_, i) => i !== index);
        formik.setFieldValue('orals', newMethod);
    };

    const handleSubdermalChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.subdermals.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('subdermals', newMethod);
    };

    const addSubdermal = () => {
        const newMethod = [...formik.values.subdermals, { method: '', time: '', actual_method: null }];
        formik.setFieldValue('subdermals', newMethod);
    };

    const removeSubdermal = index => {
        const subdermals = formik.values.subdermals;

        if (subdermals.length === 1) {
            formik.setFieldValue('subdermals', []);
            return;
        }

        const newMethod = subdermals.filter((_, i) => i !== index);
        formik.setFieldValue('subdermals', newMethod);
    };

    const handleOtherChange = (e, index, field) => {
        const { value } = e.target;
        const newMethod = formik.values.others.map((method, i) =>
            i === index ? { ...method, [field]: value } : method
        );
        formik.setFieldValue('others', newMethod);
    };

    const addOther = () => {
        const newMethod = [...formik.values.others, { method: '', time: '', actual_method: null }];
        formik.setFieldValue('others', newMethod);
    };

    const removeOther = index => {
        const others = formik.values.others;

        if (others.length === 1) {
            formik.setFieldValue('others', []);
            return;
        }

        const newMethod = others.filter((_, i) => i !== index);
        formik.setFieldValue('others', newMethod);
    };

    const handlePregnancyChange = (e, index, field) => {
        const { value } = e.target;
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === index ? { ...pregnancy, [field]: value } : pregnancy
        );
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const handlePregnancyDateChange = (date, index, field) => {
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === index ? { ...pregnancy, [field]: date[0] } : pregnancy // date[0] porque Flatpickr retorna un array con la fecha seleccionada
        );
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const handlePregnancyIntChange = (e, index, field) => {
        const { value } = e.target;
        const newValue = value !== '' ? parseFloat(value) : 0;
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === index ? { ...pregnancy, [field]: newValue } : pregnancy
        );
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const handlePregnancyBoolChange = (e, index, value) => {
        const { checked } = e.target;
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === index ? { ...pregnancy, multiple_birth: checked ? value : null } : pregnancy
        );
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const handleMultipleChange = (e, index, pregnancyIndex, field) => {
        const { value } = e.target;
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === index
                ? {
                    ...pregnancy,
                    pregnancies: pregnancy.pregnancies.map((multiple, j) =>
                        j === pregnancyIndex ? { ...multiple, [field]: value } : multiple
                    ),
                }
                : pregnancy
        );
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const handleMultipleIntChange = (e, index, pregnancyIndex, field) => {
        const { value } = e.target;
        const newValue = value !== '' ? parseFloat(value) : 0;
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === index
                ? {
                    ...pregnancy,
                    pregnancies: pregnancy.pregnancies.map((multiple, j) =>
                        j === pregnancyIndex ? { ...multiple, [field]: newValue } : multiple
                    ),
                }
                : pregnancy
        );
        formik.setFieldValue('pregnancies', newPregnancy);
    }

    const addPregnancy = () => {
        const newPregnancy = [...formik.values.pregnancies, { due_date: null, labor: 0, gestational_age: '', multiple_birth: null, pregnancy_observations: '', pregnancies: [{ weight: 0, gender: '', birth_method: '', neonate: '' }] }];
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const addMultiple = (multipleIndex) => {
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === multipleIndex
                ? {
                    ...pregnancy,
                    pregnancies: [...pregnancy.pregnancies, { weight: 0, gender: '', birth_method: '', neonate: '' }],
                }
                : pregnancy
        );
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const removePregnancy = index => {
        const pregnancies = formik.values.pregnancies;

        if (pregnancies.length === 1) {
            formik.setFieldValue('pregnancies', []);
            return;
        }

        const newPregnancy = pregnancies.filter((_, i) => i !== index);
        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const removeMultiple = (index, multipleIndex) => {
        const newPregnancy = formik.values.pregnancies.map((pregnancy, i) =>
            i === index
                ? {
                    ...pregnancy,
                    pregnancies: pregnancy.pregnancies.filter((_, j) => j !== multipleIndex),
                }
                : pregnancy
        );

        formik.setFieldValue('pregnancies', newPregnancy);
    };

    const handleCytologyChange = (e, index, field) => {
        const { value } = e.target;
        const newCytologies = formik.values.cytologies.map((cytology, i) =>
            i === index ? { ...cytology, [field]: value } : cytology
        );
        formik.setFieldValue('cytologies', newCytologies);
    };

    const addCytology = () => {
        const newCytologies = [...formik.values.cytologies, { date: '', result: '', observations: '' }];
        formik.setFieldValue('cytologies', newCytologies);
    };

    const removeCytology = index => {
        const cytologies = formik.values.cytologies;

        if (cytologies.length === 1) {
            formik.setFieldValue('cytologies', []);
            return;
        }

        const newCytologies = cytologies.filter((_, i) => i !== index);
        formik.setFieldValue('cytologies', newCytologies);
    };

    const handleMammogramChange = (e, index, field) => {
        const { value } = e.target;
        const newMammograms = formik.values.mammograms.map((cytology, i) =>
            i === index ? { ...cytology, [field]: value } : cytology
        );
        formik.setFieldValue('mammograms', newMammograms);
    };

    const addMammogram = () => {
        const newMammograms = [...formik.values.mammograms, { date: '', result: '', observations: '' }];
        formik.setFieldValue('mammograms', newMammograms);
    };

    const removeMammogram = index => {
        const mammograms = formik.values.mammograms;

        if (mammograms.length === 1) {
            formik.setFieldValue('mammograms', []);
            return;
        }

        const newMammograms = mammograms.filter((_, i) => i !== index);
        formik.setFieldValue('mammograms', newMammograms);
    };

    const handleQuestionChange = (e, index, field) => {
        const { value } = e.target;
        const newQuestions = formik.values.questions.map((question, i) =>
            i === index ? { ...question, [field]: value } : question
        );
        formik.setFieldValue('questions', newQuestions);
    };

    const addQuestion = () => {
        const newQuestions = [...formik.values.questions, { question: '', answer: '' }];
        formik.setFieldValue('questions', newQuestions);
    };

    const removeQuestion = index => {
        const questions = formik.values.questions;

        if (questions.length === 1) {
            formik.setFieldValue('questions', []);
            return;
        }

        const newQuestions = questions.filter((_, i) => i !== index);
        formik.setFieldValue('questions', newQuestions);
    };

    const [visibility, setVisibility] = useState({
        generalFamilyHistory: false,
        familyHistory: false,
        generalPathology: false,
        pathology: false,
        surgery: false,
        generalNotPathology: false,
        allergy: false,
        immunization: false,
        tobacco: false,
        alcohol: false,
        drug: false,
        physical_activity: false,
        generalGynecology: false,
        planning: false,
        menstrual_cycle: false,
        pregnancy: false,
        cytology_mammogram: false,
        generalInfo: false,
        question: false,
        general_observations: false,
    });

    const toggleSection = (section) => {
        setVisibility(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const location = useLocation();


    const isActiveTab = (tabPath: string) => {
        return location.pathname === tabPath;
    };

    const [smokingIndex, setSmokingIndex] = React.useState<number | null>(formik.values.smoking_index);

    React.useEffect(() => {
        const calculateSmokingIndex = () => {
            const { cigarettes_per_day, smoking_years } = formik.values.medical_history;
            if (cigarettes_per_day && smoking_years && cigarettes_per_day > 0 && smoking_years > 0) {
                const index = (cigarettes_per_day * smoking_years) / 20;
                setSmokingIndex(parseFloat(index.toFixed(2)));
            } else {
                setSmokingIndex(null);
            }
        };

        calculateSmokingIndex();
    }, [formik.values.medical_history.cigarettes_per_day, formik.values.medical_history.smoking_years]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row >
                        <Col xs={12}>
                            {loading ? (
                                <Spinners />
                            ) : (
                                <Card>

                                    <AdminTabs id={id as string} isActiveTab={isActiveTab} />

                                    <CardBody>
                                        <CardTitle tag="h4" style={{ fontSize: '20px' }}>Historial médico de {adminPatient ? (`${adminPatient.first_name} ${adminPatient.last_name}`) : ""}
                                        </CardTitle>
                                        <p className="card-title-desc"> Ingresa información correcta y completa para tener un mejor manejo de los antecedentes de tus pacientes.<br/>
                                            Recuerda guardar los cambios antes de salir.</p>
                                        <Form onSubmit={formik.handleSubmit} autoComplete="off">

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('generalFamilyHistory')}
                                                       style={{cursor: 'pointer'}}>
                                                Antecedentes Heredo
                                                Familiares {visibility.generalFamilyHistory ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.generalFamilyHistory && (
                                                <div>
                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('familyHistory')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Antecedentes Heredo
                                                        Familiares {visibility.familyHistory ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.familyHistory && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="family_pathology_present"
                                                                               style={{marginRight: 30}}>Patología
                                                                            presente</Label>
                                                                        <select
                                                                            id="family_pathology_present"
                                                                            name="medical_history.family_pathology_present"
                                                                            value={formik.values.medical_history.family_pathology_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentThreeOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        {formik.values.family_pathologies.map((family, index) => (
                                                                            <div key={index} className="mb-3">
                                                                                <Row>
                                                                                    <Row>
                                                                                        <Col md={8} style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'flex-end'
                                                                                        }}>
                                                                                            <Label
                                                                                                htmlFor={`family_pathologies-${index}-pathology`}
                                                                                                style={{
                                                                                                    marginBottom: '10px',
                                                                                                    marginRight: '30px'
                                                                                                }}>Patología</Label>
                                                                                            <Input
                                                                                                id={`family_pathologies-${index}-pathology`}
                                                                                                name={`family_pathologies[${index}].pathology`}
                                                                                                type="text"
                                                                                                placeholder="Patología"
                                                                                                value={family.pathology}
                                                                                                onChange={(e) => handleFamilyChange(e, index, 'pathology')}
                                                                                                className="flex-grow-1"
                                                                                                style={{marginRight: 30}}
                                                                                            />
                                                                                            <Button type="button"
                                                                                                    onClick={() => removeFamily(index)}>
                                                                                                Eliminar
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Row>
                                                                                {formik.errors[`family_pathologies[${index}].pathology`] && (
                                                                                    <div className="text-danger"
                                                                                         style={{marginLeft: 100}}>Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                                <Row className="mt-3">
                                                                                    <Col md={8}>
                                                                                        <div style={{
                                                                                            marginTop: 10,
                                                                                            marginLeft: 60
                                                                                        }}>
                                                                                            {family.members.map((member, memberIndex) => (
                                                                                                <div key={memberIndex}
                                                                                                     className="mb-3">
                                                                                                    <Row
                                                                                                        className="align-items-center">
                                                                                                        <Col>
                                                                                                            <InputGroup
                                                                                                                className="d-flex justify-content-between align-items-center">
                                                                                                                <Label
                                                                                                                    htmlFor={`family_pathologies-${index}-pathology`}
                                                                                                                    style={{
                                                                                                                        marginBottom: '10px',
                                                                                                                        marginRight: '30px'
                                                                                                                    }}>Familiar</Label>
                                                                                                                <Input
                                                                                                                    id={`members-${index}-member-${memberIndex}`}
                                                                                                                    name={`family_pathologies[${index}].members[${memberIndex}].member`}
                                                                                                                    type="text"
                                                                                                                    placeholder="Familiar"
                                                                                                                    value={member.member}
                                                                                                                    onChange={(e) => handleFamilyMemberChange(e, index, memberIndex, 'member')}
                                                                                                                    className="flex-grow-1"
                                                                                                                    style={{marginRight: 30}}
                                                                                                                />
                                                                                                                <Button
                                                                                                                    type="button"
                                                                                                                    onClick={() => removeFamilyMember(index, memberIndex)}>
                                                                                                                    Eliminar
                                                                                                                    familiar
                                                                                                                </Button>
                                                                                                            </InputGroup>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            ))}
                                                                                            <Button type="button"
                                                                                                    color="light"
                                                                                                    className="btn-rounded mt-2"
                                                                                                    onClick={() => addFamilyMember(index)}>
                                                                                                Agregar familiar
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))}
                                                                        <Button color="light"
                                                                                className="btn-rounded mt-3"
                                                                                onClick={addFamily}>
                                                                            Agregar otra patología
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label
                                                                            htmlFor="family_pathology_notes">Notas</Label>
                                                                        <Input
                                                                            id="family_pathology_notes"
                                                                            name="medical_history.family_pathology_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.family_pathology_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            )}

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('generalPathology')}
                                                       style={{cursor: 'pointer'}}>
                                                Antecedentes Personales Patológicos {visibility.generalPathology ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.generalPathology && (
                                                <div>
                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('pathology')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Patologías {visibility.pathology ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.pathology && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="pathology_present"
                                                                               style={{marginRight: 30}}>Patología
                                                                            presente</Label>
                                                                        <select
                                                                            id="pathology_present"
                                                                            name="medical_history.pathology_present"
                                                                            value={formik.values.medical_history.pathology_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        {formik.values.pathologies.map((pathology, index) => (
                                                                            <div key={index} className="mb-3">
                                                                                <Row>
                                                                                    <Row>
                                                                                        <Col md={8} style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'flex-end'
                                                                                        }}>
                                                                                            <Label
                                                                                                htmlFor={`pathologies-${index}-pathology`}
                                                                                                style={{
                                                                                                    marginBottom: '10px',
                                                                                                    marginRight: '30px'
                                                                                                }}>Patología</Label>
                                                                                            <Input
                                                                                                id={`pathologies-${index}-pathology`}
                                                                                                name={`pathologies[${index}].pathology`}
                                                                                                type="text"
                                                                                                placeholder="Patología"
                                                                                                value={pathology.pathology}
                                                                                                onChange={(e) => handlePathologyChange(e, index, 'pathology')}
                                                                                                className="flex-grow-1"
                                                                                                style={{marginRight: 30}}
                                                                                            />
                                                                                            <Button type="button"
                                                                                                    onClick={() => removePathology(index)}>
                                                                                                Eliminar
                                                                                            </Button>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Row>
                                                                                {formik.errors[`pathologies[${index}].pathology`] && (
                                                                                    <div className="text-danger"
                                                                                         style={{marginLeft: 100}}>Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                                <Row className="mt-3">
                                                                                    <Col md={10}>
                                                                                        <div style={{
                                                                                            marginTop: 10,
                                                                                            marginLeft: 60
                                                                                        }}>
                                                                                            {pathology.treatments.map((treatment, treatmentIndex) => (
                                                                                                <div
                                                                                                    key={treatmentIndex}
                                                                                                    className="mb-3">
                                                                                                    <Row
                                                                                                        className="align-items-center">
                                                                                                        <Col>
                                                                                                            <InputGroup
                                                                                                                className="d-flex justify-content-between align-items-center">
                                                                                                                <Label
                                                                                                                    htmlFor={`pathologies-${index}-treatment`}
                                                                                                                    style={{
                                                                                                                        marginBottom: '10px',
                                                                                                                        marginRight: '30px'
                                                                                                                    }}>Medicamento
                                                                                                                    o
                                                                                                                    tratamiento</Label>
                                                                                                                <Input
                                                                                                                    id={`treatments-${index}-treatment-${treatmentIndex}`}
                                                                                                                    name={`pathologies[${index}].treatments[${treatmentIndex}].treatment`}
                                                                                                                    type="text"
                                                                                                                    placeholder="Medicameto o tratamiento"
                                                                                                                    value={treatment.treatment}
                                                                                                                    onChange={(e) => handlePathologyTreatmentChange(e, index, treatmentIndex, 'treatment')}
                                                                                                                    className="flex-grow-1"
                                                                                                                    style={{marginRight: 30}}
                                                                                                                />
                                                                                                                <Button
                                                                                                                    type="button"
                                                                                                                    onClick={() => removePathologyTreatment(index, treatmentIndex)}>
                                                                                                                    Eliminar
                                                                                                                    tratamiento
                                                                                                                </Button>
                                                                                                            </InputGroup>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            ))}
                                                                                            <Button type="button"
                                                                                                    color="light"
                                                                                                    className="btn-rounded mt-2"
                                                                                                    onClick={() => addPathologyTreatment(index)}>
                                                                                                Agregar tratamiento
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))}
                                                                        <Button color="light"
                                                                                className="btn-rounded mt-3"
                                                                                onClick={addPathology}>
                                                                            Agregar otra patología
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="pathology_notes">Notas</Label>
                                                                        <Input
                                                                            id="pathology_notes"
                                                                            name="medical_history.pathology_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.pathology_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('surgery')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Cirugías y hospitalizaciones {visibility.surgery ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.surgery && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="surgery_present"
                                                                               style={{marginRight: 30}}>Cirugía u
                                                                            hospitalización presente</Label>
                                                                        <select
                                                                            id="surgery_present"
                                                                            name="medical_history.surgery_present"
                                                                            value={formik.values.medical_history.surgery_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="surgery">Cirugía u
                                                                            hospitalización*</Label>
                                                                        {formik.values.surgeries.map((surgery, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`surgery-${index}`}
                                                                                    name={`surgeries[${index}].surgery`}
                                                                                    type="text"
                                                                                    placeholder="Cirugía u hospitalización*"
                                                                                    value={surgery.surgery}
                                                                                    onChange={e => handleSurgeryChange(e, index, 'surgery')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`surgeries[${index}].surgery`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="date">Fecha</Label>
                                                                        {formik.values.surgeries.map((surgery, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`date-${index}`}
                                                                                        name={`surgeries[${index}].date`}
                                                                                        type="text"
                                                                                        placeholder="Fecha"
                                                                                        value={surgery.date}
                                                                                        onChange={e => handleSurgeryChange(e, index, 'date')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="5">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label
                                                                            htmlFor="complications">Complicaciones</Label>
                                                                        {formik.values.surgeries.map((surgery, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`complications-${index}`}
                                                                                        name={`surgeries[${index}].complications`}
                                                                                        type="text"
                                                                                        placeholder="Complicaciones"
                                                                                        value={surgery.complications}
                                                                                        onChange={e => handleSurgeryChange(e, index, 'complications')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                    <Button type="button"
                                                                                            onClick={() => removeSurgery(index)}>Eliminar</Button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addSurgery}>
                                                                Agregar otra cirugía
                                                            </Button>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="surgery_notes">Notas</Label>
                                                                        <Input
                                                                            id="surgery_notes"
                                                                            name="medical_history.surgery_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.surgery_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            )}

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('generalNotPathology')}
                                                       style={{cursor: 'pointer'}}>
                                                Antecedentes Personales No Patológicos {visibility.generalNotPathology ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.generalNotPathology && (
                                                <div>
                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('allergy')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Alergias {visibility.allergy ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.allergy && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="allergy_present"
                                                                               style={{marginRight: 30}}>Alergia
                                                                            presente</Label>
                                                                        <select
                                                                            id="allergy_present"
                                                                            name="medical_history.allergy_present"
                                                                            value={formik.values.medical_history.allergy_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="5">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="allergy">Alergia*</Label>
                                                                        {formik.values.allergies.map((allergy, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`allergy-${index}`}
                                                                                    name={`allergies[${index}].allergy`}
                                                                                    type="text"
                                                                                    placeholder="Alergia*"
                                                                                    value={allergy.allergy}
                                                                                    onChange={e => handleAllergyChange(e, index, 'allergy')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`allergies[${index}].allergy`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="7">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="reaction">Reacción</Label>
                                                                        {formik.values.allergies.map((allergy, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`reaction-${index}`}
                                                                                        name={`allergies[${index}].reaction`}
                                                                                        type="text"
                                                                                        placeholder="Reacción"
                                                                                        value={allergy.reaction}
                                                                                        onChange={e => handleAllergyChange(e, index, 'reaction')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                    <Button type="button"
                                                                                            onClick={() => removeAllergy(index)}>Eliminar</Button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addAllergy}>
                                                                Agregar otra alergia
                                                            </Button>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="allergy_notes">Notas</Label>
                                                                        <Input
                                                                            id="allergy_notes"
                                                                            name="medical_history.allergy_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.allergy_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('immunization')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Inmunizaciones {visibility.immunization ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.immunization && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="immunization_present"
                                                                               style={{marginRight: 30}}>Inmunizaciones
                                                                            presente</Label>
                                                                        <select
                                                                            id="immunization_present"
                                                                            name="medical_history.immunization_present"
                                                                            value={formik.values.medical_history.immunization_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentThreeOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <FormGroup tag="fieldset">
                                                                <span>Inmunización al día</span>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.up_to_date_immunization"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.up_to_date_immunization === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.up_to_date_immunization', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.up_to_date_immunization"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.up_to_date_immunization === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.up_to_date_immunization', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label
                                                                            htmlFor="immunization">Inmunización*</Label>
                                                                        {formik.values.immunizations.map((immunization, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`immunization-${index}`}
                                                                                    name={`immunizations[${index}].immunization`}
                                                                                    type="text"
                                                                                    placeholder="Inmunización*"
                                                                                    value={immunization.immunization}
                                                                                    onChange={e => handleImmunizationChange(e, index, 'immunization')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`immunizations[${index}].immunization`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="reaction">Año de
                                                                            aplicación</Label>
                                                                        {formik.values.immunizations.map((immunization, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`application_year-${index}`}
                                                                                        name={`immunizations[${index}].application_year`}
                                                                                        type="text"
                                                                                        placeholder="Año de aplicación"
                                                                                        value={immunization.application_year}
                                                                                        onChange={e => handleImmunizationChange(e, index, 'application_year')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                    <Button type="button"
                                                                                            onClick={() => removeImmunization(index)}>Eliminar</Button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addImmunization}>
                                                                Agregar otra inmunización
                                                            </Button>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label
                                                                            htmlFor="immunization_notes">Notas</Label>
                                                                        <Input
                                                                            id="immunization_notes"
                                                                            name="medical_history.immunization_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.immunization_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('tobacco')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Consumo de tabaco {visibility.tobacco ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.tobacco && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="tobacco_present"
                                                                               style={{marginRight: 30}}>Consumo
                                                                            presente</Label>
                                                                        <select
                                                                            id="tobacco_present"
                                                                            name="medical_history.tobacco_present"
                                                                            value={formik.values.medical_history.tobacco_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentSuspendedOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="tobacco_suspension_time"
                                                                               style={{marginRight: 30}}>(En caso de)
                                                                            Tiempo de suspensión</Label>
                                                                        <select
                                                                            id="tobacco_suspension_time"
                                                                            name="medical_history.tobacco_suspension_time"
                                                                            value={formik.values.medical_history.tobacco_suspension_time}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {TimeOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="cigarettes_per_day">(En caso de)
                                                                            Cigarrillos por día</Label>
                                                                        <Input
                                                                            id="cigarettes_per_day"
                                                                            name="medical_history.cigarettes_per_day"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.cigarettes_per_day}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="smoking_years">(En caso de) Años
                                                                            de consumo</Label>
                                                                        <Input
                                                                            id="smoking_years"
                                                                            name="medical_history.smoking_years"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.smoking_years}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="smoking_index">Índice tabáquico (paquete-año)</Label>
                                                                        <div className="d-flex align-items-center">
                                                                            <Input
                                                                                id="smoking_index"
                                                                                name="smoking_index"
                                                                                type="text"
                                                                                value={smokingIndex === null ? '' : smokingIndex}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="tobacco_notes">Notas</Label>
                                                                        <Input
                                                                            id="tobacco_notes"
                                                                            name="medical_history.tobacco_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.tobacco_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('alcohol')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Consumo de alcohol {visibility.alcohol ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.alcohol && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="alcohol_present"
                                                                               style={{marginRight: 30}}>Consumo
                                                                            presente</Label>
                                                                        <select
                                                                            id="alcohol_present"
                                                                            name="medical_history.alcohol_present"
                                                                            value={formik.values.medical_history.alcohol_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentSuspendedOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="alcohol_suspension_time"
                                                                               style={{marginRight: 30}}>(En caso de)
                                                                            Tiempo de suspensión</Label>
                                                                        <select
                                                                            id="alcohol_suspension_time"
                                                                            name="medical_history.alcohol_suspension_time"
                                                                            value={formik.values.medical_history.alcohol_suspension_time}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {TimeOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="alcohol">Tipo de bebida*</Label>
                                                                        {formik.values.alcohols.map((alcohol, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`alcohol-${index}`}
                                                                                    name={`alcohols[${index}].alcohol`}
                                                                                    type="text"
                                                                                    placeholder="Tipo de bebida*"
                                                                                    value={alcohol.alcohol}
                                                                                    onChange={e => handleAlcoholChange(e, index, 'alcohol')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`alcohols[${index}].alcohol`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="quantity_and_frequency">Cantidad
                                                                            y frecuencia</Label>
                                                                        {formik.values.alcohols.map((alcohol, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`quantity_and_frequency-${index}`}
                                                                                        name={`alcohols[${index}].quantity_and_frequency`}
                                                                                        type="text"
                                                                                        placeholder="Cantidad y frecuencia"
                                                                                        value={alcohol.quantity_and_frequency}
                                                                                        onChange={e => handleAlcoholChange(e, index, 'quantity_and_frequency')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                    <Button type="button"
                                                                                            onClick={() => removeAlcohol(index)}>Eliminar</Button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addAlcohol}>
                                                                Agregar otra bebida alcohólica
                                                            </Button>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="alcohol_consumed_years"
                                                                               style={{marginRight: 30}}>(En caso de)
                                                                            Tiempo de suspensión</Label>
                                                                        <select
                                                                            id="alcohol_consumed_years"
                                                                            name="medical_history.alcohol_consumed_years"
                                                                            value={formik.values.medical_history.alcohol_consumed_years}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {TimeOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="alcohol_notes">Notas</Label>
                                                                        <Input
                                                                            id="alcohol_notes"
                                                                            name="medical_history.alcohol_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.alcohol_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('drug')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Consumo de drogas {visibility.drug ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.drug && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="drug_present"
                                                                               style={{marginRight: 30}}>Consumo
                                                                            presente</Label>
                                                                        <select
                                                                            id="drug_present"
                                                                            name="medical_history.drug_present"
                                                                            value={formik.values.medical_history.drug_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <div>
                                                                    {formik.values.drugs.map((drug, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <Row>
                                                                                <Col sm="4">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`drug-${index}`}>Droga*</Label>
                                                                                        <Input
                                                                                            id={`drug-${index}`}
                                                                                            name={`drugs[${index}].drug`}
                                                                                            type="text"
                                                                                            placeholder="Droga*"
                                                                                            value={drug.drug}
                                                                                            onChange={e => handleDrugChange(e, index, 'drug')}
                                                                                            className="flex-grow-1"
                                                                                            style={{marginRight: 10}}
                                                                                        />
                                                                                        {formik.errors[`drugs[${index}].drug`] && (
                                                                                            <div
                                                                                                className="text-danger">Campo
                                                                                                requerido*</div>
                                                                                        )}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="6">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`dose-${index}`}>Dosis</Label>
                                                                                        <Input
                                                                                            id={`dose-${index}`}
                                                                                            name={`drugs[${index}].dose`}
                                                                                            type="text"
                                                                                            placeholder="Dosis"
                                                                                            value={drug.dose}
                                                                                            onChange={e => handleDrugChange(e, index, 'dose')}
                                                                                            className="flex-grow-1"
                                                                                            style={{marginRight: 10}}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col sm="4">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`consumed_frequency-${index}`}>Frecuencia
                                                                                            de consumo</Label>
                                                                                        <select
                                                                                            id={`consumed_frequency-${index}`}
                                                                                            name={`drugs[${index}].consumed_frequency`}
                                                                                            value={drug.consumed_frequency}
                                                                                            onChange={e => handleDrugChange(e, index, 'consumed_frequency')}
                                                                                            onBlur={formik.handleBlur}
                                                                                            className="custom-select"
                                                                                            style={{marginRight: 10}}
                                                                                        >
                                                                                            <option
                                                                                                value="">Selecciona...
                                                                                            </option>
                                                                                            {TimeOptions.map(option => (
                                                                                                <option key={option.val}
                                                                                                        value={option.val}>
                                                                                                    {option.label}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="4">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`consumed_years-${index}`}>Tiempo
                                                                                            de consumo</Label>
                                                                                        <select
                                                                                            id={`consumed_years-${index}`}
                                                                                            name={`drugs[${index}].consumed_years`}
                                                                                            value={drug.consumed_years}
                                                                                            onChange={e => handleDrugChange(e, index, 'consumed_years')}
                                                                                            onBlur={formik.handleBlur}
                                                                                            className="custom-select"
                                                                                            style={{marginRight: 10}}
                                                                                        >
                                                                                            <option
                                                                                                value="">Selecciona...
                                                                                            </option>
                                                                                            {TimeOptions.map(option => (
                                                                                                <option key={option.val}
                                                                                                        value={option.val}>
                                                                                                    {option.label}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col sm="4">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`suspended-${index}`}>Suspendido</Label>
                                                                                        <div
                                                                                            className="d-flex align-items-center">
                                                                                            <Input
                                                                                                id={`suspended-yes-${index}`}
                                                                                                name={`drugs[${index}].suspended`}
                                                                                                type="checkbox"
                                                                                                checked={drug.suspended === true}
                                                                                                onChange={(e) => handleDrugBoolChange(e, index, true)}
                                                                                                className="mr-2"
                                                                                                onBlur={formik.handleBlur}
                                                                                                style={{marginRight: 10}}
                                                                                            />
                                                                                            <Label
                                                                                                htmlFor={`suspended-yes-${index}`}
                                                                                                className="mb-0 mr-4"
                                                                                                style={{marginRight: 50}}>Sí</Label>

                                                                                            <Input
                                                                                                id={`suspended-no-${index}`}
                                                                                                name={`drugs[${index}].suspended`}
                                                                                                type="checkbox"
                                                                                                checked={drug.suspended === false}
                                                                                                onChange={(e) => handleDrugBoolChange(e, index, false)}
                                                                                                className="mr-2" // Modificado para mantener consistencia con el anterior
                                                                                                onBlur={formik.handleBlur}
                                                                                                style={{marginRight: 10}}
                                                                                            />
                                                                                            <Label
                                                                                                htmlFor={`suspended-no-${index}`}
                                                                                                className="mb-0">No</Label>
                                                                                        </div>
                                                                                        {formik.errors[`drugs[${index}].suspended`] && (
                                                                                            <div
                                                                                                className="text-danger">Campo
                                                                                                requerido*</div>
                                                                                        )}
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="6">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`suspension_years-${index}`}>Tiempo
                                                                                            de suspendido</Label>
                                                                                        <div
                                                                                            className="d-flex justify-content-between align-items-center">
                                                                                            <Input
                                                                                                id={`suspension_years-${index}`}
                                                                                                name={`drugs[${index}].suspension_years`}
                                                                                                type="text"
                                                                                                placeholder="Tiempo de suspendido"
                                                                                                value={drug.suspension_years}
                                                                                                onChange={e => handleDrugChange(e, index, 'suspension_years')}
                                                                                                className="flex-grow-1"
                                                                                                style={{marginRight: 10}}
                                                                                            />
                                                                                            <Button type="button"
                                                                                                    onClick={() => removeDrug(index)}>Eliminar</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                    ))}
                                                                    <Button color="light" className="btn-rounded mt-3"
                                                                            onClick={addDrug}>
                                                                        Agregar otra droga
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="drug_notes">Notas</Label>
                                                                        <Input
                                                                            id="drug_notes"
                                                                            name="medical_history.drug_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.drug_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('physical_activity')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Activitades físicas {visibility.physical_activity ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.physical_activity && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="physical_activity_present"
                                                                               style={{marginRight: 30}}>Consumo
                                                                            presente</Label>
                                                                        <select
                                                                            id="physical_activity_present"
                                                                            name="medical_history.physical_activity_present"
                                                                            value={formik.values.medical_history.physical_activity_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="activity">Actividad
                                                                            física*</Label>
                                                                        {formik.values.physical_activities.map((activity, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`activity-${index}`}
                                                                                    name={`physical_activities[${index}].activity`}
                                                                                    type="text"
                                                                                    placeholder="Actividad física*"
                                                                                    value={activity.activity}
                                                                                    onChange={e => handleActivityChange(e, index, 'activity')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`physical_activities[${index}].activity`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo (minutos al
                                                                            día)</Label>
                                                                        {formik.values.physical_activities.map((activity, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`physical_activities[${index}].time`}
                                                                                        type="number"
                                                                                        placeholder="0"
                                                                                        value={activity.time}
                                                                                        onChange={e => handleActivityIntChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                        min="0"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="frequency">Frecuencia (veces por
                                                                            semana)</Label>
                                                                        {formik.values.physical_activities.map((activity, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`frequency-${index}`}
                                                                                        name={`physical_activities[${index}].frequency`}
                                                                                        type="number"
                                                                                        placeholder="0"
                                                                                        value={activity.frequency}
                                                                                        onChange={e => handleActivityIntChange(e, index, 'frequency')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                        min="0"
                                                                                    />
                                                                                    <Button type="button"
                                                                                            onClick={() => removeActivity(index)}>Eliminar</Button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addActivity}>
                                                                Agregar otra actividad
                                                            </Button>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label
                                                                            htmlFor="physical_activity_notes">Notas</Label>
                                                                        <Input
                                                                            id="physical_activity_notes"
                                                                            name="medical_history.physical_activity_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.physical_activity_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            )}

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('generalGynecology')}
                                                       style={{cursor: 'pointer'}}>
                                                Antecedentes Ginecólogos {visibility.generalGynecology ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.generalGynecology && (
                                                <div>
                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('planning')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Planificación {visibility.planning ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.planning && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="contraceptive_present"
                                                                               style={{marginRight: 30}}>Planificación
                                                                            presente</Label>
                                                                        <select
                                                                            id="contraceptive_present"
                                                                            name="medical_history.contraceptive_present"
                                                                            value={formik.values.medical_history.contraceptive_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '30px'}}><strong>Método
                                                                    intrauterino</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.intrauterine"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.intrauterine === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.intrauterine', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.intrauterine"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.intrauterine === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.intrauterine', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="method">Método*</Label>
                                                                        {formik.values.intrauterines.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`method-${index}`}
                                                                                    name={`intrauterines[${index}].method`}
                                                                                    type="text"
                                                                                    placeholder="Método*"
                                                                                    value={method.method}
                                                                                    onChange={e => handleIntrauterineChange(e, index, 'method')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`intrauterines[${index}].method`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.intrauterines.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`intrauterines[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleIntrauterineChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.intrauterines.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`intrauterines[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`intrauterines[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`intrauterines[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`intrauterines[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeIntrauterine(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addIntrauterine}
                                                                    style={{marginBottom: 50}}>
                                                                Agregar otro método
                                                            </Button>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '20px'}}><strong>Método
                                                                    inyectable</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.injectable"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.injectable === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.injectable', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.injectable"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.injectable === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.injectable', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="medicine">Medicamento*</Label>
                                                                        {formik.values.injectables.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`medicine-${index}`}
                                                                                    name={`injectables[${index}].medicine`}
                                                                                    type="text"
                                                                                    placeholder="Medicamento*"
                                                                                    value={method.medicine}
                                                                                    onChange={e => handleInjectableChange(e, index, 'medicine')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`injectables[${index}].medicine`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.injectables.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`injectables[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleInjectableChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.injectables.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`injectables[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`injectables[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`injectables[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`injectables[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeInjectable(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addInjectable} style={{marginBottom: 50}}>
                                                                Agregar otro medicamento
                                                            </Button>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '20px'}}><strong>Método de
                                                                    barrera</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.barrier_method"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.barrier_method === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.barrier_method', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.barrier_method"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.barrier_method === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.barrier_method', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="method">Método*</Label>
                                                                        {formik.values.barriers.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`method-${index}`}
                                                                                    name={`barriers[${index}].method`}
                                                                                    type="text"
                                                                                    placeholder="Método*"
                                                                                    value={method.method}
                                                                                    onChange={e => handleBarrierChange(e, index, 'method')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`barriers[${index}].method`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.barriers.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`barriers[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleBarrierChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.barriers.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`barriers[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`barriers[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`barriers[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`barriers[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeBarrier(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addBarrier} style={{marginBottom: 50}}>
                                                                Agregar otro método
                                                            </Button>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '20px'}}><strong>Método
                                                                    natural</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.natural_method"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.natural_method === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.natural_method', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.natural_method"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.natural_method === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.natural_method', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="method">Método*</Label>
                                                                        {formik.values.naturals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`method-${index}`}
                                                                                    name={`naturals[${index}].method`}
                                                                                    type="text"
                                                                                    placeholder="Método*"
                                                                                    value={method.method}
                                                                                    onChange={e => handleNaturalChange(e, index, 'method')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`naturals[${index}].method`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.naturals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`naturals[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleNaturalChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.naturals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`naturals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`naturals[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`naturals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`naturals[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeNatural(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addNatural} style={{marginBottom: 50}}>
                                                                Agregar otro método
                                                            </Button>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '20px'}}><strong>Método
                                                                    quirúrgico</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.surgical_method"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.surgical_method === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.surgical_method', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.surgical_method"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.surgical_method === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.surgical_method', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="method">Método*</Label>
                                                                        {formik.values.surgicals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`method-${index}`}
                                                                                    name={`surgicals[${index}].method`}
                                                                                    type="text"
                                                                                    placeholder="Método*"
                                                                                    value={method.method}
                                                                                    onChange={e => handleSurgicalChange(e, index, 'method')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`surgicals[${index}].method`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.surgicals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`surgicals[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleSurgicalChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.surgicals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`surgicals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`surgicals[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`surgicals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`surgicals[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeSurgical(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addSurgical} style={{marginBottom: 50}}>
                                                                Agregar otro método
                                                            </Button>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '20px'}}><strong>Método
                                                                    oral</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.oral"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.oral === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.oral', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.oral"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.oral === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.oral', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="medicine">Medicamento*</Label>
                                                                        {formik.values.orals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`medicine-${index}`}
                                                                                    name={`orals[${index}].medicine`}
                                                                                    type="text"
                                                                                    placeholder="Medicamento*"
                                                                                    value={method.medicine}
                                                                                    onChange={e => handleOralChange(e, index, 'medicine')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`orals[${index}].medicine`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.orals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`orals[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleOralChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.orals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`orals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`orals[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`orals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`orals[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeOral(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addOral} style={{marginBottom: 50}}>
                                                                Agregar otro medicamento
                                                            </Button>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '20px'}}><strong>Método
                                                                    subdérmico</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.subdermal"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.subdermal === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.subdermal', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.subdermal"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.subdermal === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.subdermal', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="method">Método*</Label>
                                                                        {formik.values.subdermals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`method-${index}`}
                                                                                    name={`subdermals[${index}].method`}
                                                                                    type="text"
                                                                                    placeholder="Método*"
                                                                                    value={method.method}
                                                                                    onChange={e => handleSubdermalChange(e, index, 'method')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`subdermals[${index}].method`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.subdermals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`subdermals[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleSubdermalChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.subdermals.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`subdermals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`subdermals[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`subdermals[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`subdermals[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeSubdermal(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addSubdermal} style={{marginBottom: 50}}>
                                                                Agregar otro método
                                                            </Button>
                                                            <FormGroup tag="fieldset">
                                                                <h5 style={{marginTop: '20px'}}><strong>Otro
                                                                    método</strong></h5>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.other"
                                                                            value="true"
                                                                            checked={formik.values.medical_history.other === true}
                                                                            onChange={() => formik.setFieldValue('medical_history.other', true)}
                                                                        />
                                                                        Sí
                                                                    </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input
                                                                            type="radio"
                                                                            name="medical_history.other"
                                                                            value="false"
                                                                            checked={formik.values.medical_history.other === false}
                                                                            onChange={() => formik.setFieldValue('medical_history.other', false)}
                                                                        />
                                                                        No
                                                                    </Label>
                                                                </FormGroup>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="method">Método*</Label>
                                                                        {formik.values.others.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <Input
                                                                                    id={`method-${index}`}
                                                                                    name={`others[${index}].method`}
                                                                                    type="text"
                                                                                    placeholder="Método*"
                                                                                    value={method.method}
                                                                                    onChange={e => handleOtherChange(e, index, 'method')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`others[${index}].method`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="time">Tiempo de uso</Label>
                                                                        {formik.values.others.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-column">
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center">
                                                                                    <Input
                                                                                        id={`time-${index}`}
                                                                                        name={`others[${index}].time`}
                                                                                        type="text"
                                                                                        placeholder="Tiempo de uso"
                                                                                        value={method.time}
                                                                                        onChange={e => handleOtherChange(e, index, 'time')}
                                                                                        className="flex-grow-1"
                                                                                        style={{marginRight: 10}}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="actual_method">Método
                                                                            actual</Label>
                                                                        {formik.values.others.map((method, index) => (
                                                                            <div key={index}
                                                                                 className="mb-3 d-flex flex-row align-items-center">
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-yes-${index}`}
                                                                                            name={`others[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="true"
                                                                                            checked={method.actual_method === true}
                                                                                            onChange={() => formik.setFieldValue(`others[${index}].actual_method`, true)}
                                                                                        />
                                                                                        Sí
                                                                                    </Label>
                                                                                </div>
                                                                                <div className="mr-3">
                                                                                    <Label check>
                                                                                        <Input
                                                                                            id={`actual_method-no-${index}`}
                                                                                            name={`others[${index}].actual_method`}
                                                                                            type="radio"
                                                                                            value="false"
                                                                                            checked={method.actual_method === false}
                                                                                            onChange={() => formik.setFieldValue(`others[${index}].actual_method`, false)}
                                                                                            style={{marginLeft: 30}}
                                                                                        />
                                                                                        No
                                                                                    </Label>
                                                                                </div>
                                                                                <Button type="button"
                                                                                        onClick={() => removeOther(index)}
                                                                                        style={{marginLeft: 60}}>Eliminar</Button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addOther} style={{marginBottom: 50}}>
                                                                Agregar otro método
                                                            </Button>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="method_notes">Notas</Label>
                                                                        <Input
                                                                            id="method_notes"
                                                                            name="medical_history.method_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.method_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('menstrual_cycle')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Ciclo menstrual {visibility.menstrual_cycle ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.menstrual_cycle && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="menstrual_cycle_present"
                                                                               style={{marginRight: 30}}>Ciclo menstrual
                                                                            presente</Label>
                                                                        <select
                                                                            id="menstrual_cycle_present"
                                                                            name="medical_history.menstrual_cycle_present"
                                                                            value={formik.values.medical_history.menstrual_cycle_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="cycle_frequency"
                                                                               style={{marginRight: 30}}>Frecuencia del
                                                                            ciclo</Label>
                                                                        <select
                                                                            id="cycle_frequency"
                                                                            name="medical_history.cycle_frequency"
                                                                            value={formik.values.medical_history.cycle_frequency}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {CycleFrequencyOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="dysmenorrhea_present"
                                                                               style={{marginRight: 30}}>Presenta
                                                                            dismenorrea</Label>
                                                                        <select
                                                                            id="dysmenorrhea_present"
                                                                            name="medical_history.dysmenorrhea_present"
                                                                            value={formik.values.medical_history.dysmenorrhea_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label
                                                                            htmlFor="menstrual_cycle_notes">Notas</Label>
                                                                        <Input
                                                                            id="menstrual_cycle_notes"
                                                                            name="medical_history.menstrual_cycle_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.menstrual_cycle_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('pregnancy')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Embarazos {visibility.pregnancy ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.pregnancy && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="pregnancy_present"
                                                                               style={{marginRight: 30}}>Embarazo
                                                                            presente</Label>
                                                                        <select
                                                                            id="pregnancy_present"
                                                                            name="medical_history.pregnancy_present"
                                                                            value={formik.values.medical_history.pregnancy_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12" md="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="pregnancies">Gestas</Label>
                                                                        <Input
                                                                            id="pregnancies"
                                                                            name="medical_history.pregnancies"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.pregnancies}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm="12" md="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="births">Partos</Label>
                                                                        <Input
                                                                            id="births"
                                                                            name="medical_history.births"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.births}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm="12" md="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="abortions">Abortos</Label>
                                                                        <Input
                                                                            id="abortions"
                                                                            name="medical_history.abortions"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.abortions}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm="12" md="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="deaths">Óbitos</Label>
                                                                        <Input
                                                                            id="deaths"
                                                                            name="medical_history.deaths"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.deaths}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12" md="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="cesarean">Cesáreas</Label>
                                                                        <Input
                                                                            id="cesarean"
                                                                            name="medical_history.cesarean"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.cesarean}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm="12" md="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="alive">Vivos</Label>
                                                                        <Input
                                                                            id="alive"
                                                                            name="medical_history.alive"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.alive}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm="12" md="3">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="dead">Muertos</Label>
                                                                        <Input
                                                                            id="dead"
                                                                            name="medical_history.dead"
                                                                            type="number"
                                                                            placeholder="0"
                                                                            value={formik.values.medical_history.dead}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        {formik.values.pregnancies.map((pregnancy, index) => (
                                                                            <div key={index} className="mb-3">
                                                                                <Row>
                                                                                    <h5 style={{marginTop: '30px'}}>
                                                                                        <strong>Embarazo</strong></h5>
                                                                                </Row>
                                                                                <Row style={{marginTop: 10}}>
                                                                                    <Col sm="3">
                                                                                        <div className="mb-3">
                                                                                            <Label
                                                                                                htmlFor={`due_date-${index}`}
                                                                                                className="mb-2">Fecha
                                                                                                de
                                                                                                parto</Label>
                                                                                            <Flatpickr
                                                                                                id={`due_date-${index}`}
                                                                                                name={`due_date-${index}`}
                                                                                                className="form-control d-block"
                                                                                                value={pregnancy.due_date}
                                                                                                onChange={(date) => handlePregnancyDateChange(date, index, 'due_date')}
                                                                                                options={{
                                                                                                    altInput: true,
                                                                                                    altFormat: "j F Y",
                                                                                                    dateFormat: "Y-m-d",
                                                                                                    locale: Spanish,
                                                                                                }}
                                                                                            />
                                                                                            {formik.errors[`pregnancies[${index}].due_date`] && (
                                                                                                <div
                                                                                                    className="text-danger">Campo
                                                                                                    requerido*</div>
                                                                                            )}
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col sm="3">
                                                                                        <Label
                                                                                            htmlFor={`pregnancies-${index}-labor`}
                                                                                            style={{
                                                                                                marginBottom: '10px',
                                                                                                marginRight: '30px'
                                                                                            }}>Trabajo de parto
                                                                                            (horas)</Label>
                                                                                        <Input
                                                                                            id={`pregnancies-${index}-labor`}
                                                                                            name={`pregnancies[${index}].labor`}
                                                                                            type="number"
                                                                                            placeholder="Trabajo de parto"
                                                                                            value={pregnancy.labor}
                                                                                            onChange={(e) => handlePregnancyIntChange(e, index, 'labor')}
                                                                                            className="flex-grow-1"
                                                                                            style={{marginRight: 30}}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col sm="3">
                                                                                        <Label
                                                                                            htmlFor={`gestational_age-${index}`}
                                                                                            style={{marginRight: 30}}>Edad
                                                                                            gestacional</Label>
                                                                                        <select
                                                                                            id={`gestational_age-${index}`}
                                                                                            name={`pregnancies[${index}].gestational_age`}
                                                                                            value={pregnancy.gestational_age}
                                                                                            onChange={e => handlePregnancyChange(e, index, 'gestational_age')}
                                                                                            onBlur={formik.handleBlur}
                                                                                            className="custom-select"
                                                                                            style={{
                                                                                                marginRight: 10,
                                                                                                marginTop: 3,
                                                                                                width: 200,
                                                                                                height: 35
                                                                                            }}
                                                                                        >
                                                                                            <option
                                                                                                value="">Selecciona...
                                                                                            </option>
                                                                                            {GestationalOptions.map(option => (
                                                                                                <option key={option.val}
                                                                                                        value={option.val}>
                                                                                                    {option.label}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </Col>
                                                                                    <Col sm="3">
                                                                                        <Label
                                                                                            htmlFor={`multiple_birth-${index}`}>Parto
                                                                                            múltiple</Label>
                                                                                        <div
                                                                                            className="d-flex align-items-center">
                                                                                            <Input
                                                                                                id={`multiple_birth-yes-${index}`}
                                                                                                name={`pregnancies[${index}].multiple_birth`}
                                                                                                type="checkbox"
                                                                                                checked={pregnancy.multiple_birth === true}
                                                                                                onChange={(e) => handlePregnancyBoolChange(e, index, true)}
                                                                                                className="mr-2"
                                                                                                onBlur={formik.handleBlur}
                                                                                                style={{marginRight: 10}}
                                                                                            />
                                                                                            <Label
                                                                                                htmlFor={`multiple_birth-yes-${index}`}
                                                                                                className="mb-0 mr-4"
                                                                                                style={{marginRight: 50}}>Sí</Label>

                                                                                            <Input
                                                                                                id={`multiple_birth-no-${index}`}
                                                                                                name={`pregnancies[${index}].multiple_birth`}
                                                                                                type="checkbox"
                                                                                                checked={pregnancy.multiple_birth === false}
                                                                                                onChange={(e) => handlePregnancyBoolChange(e, index, false)}
                                                                                                className="mr-2" // Modificado para mantener consistencia con el anterior
                                                                                                onBlur={formik.handleBlur}
                                                                                                style={{marginRight: 10}}
                                                                                            />
                                                                                            <Label
                                                                                                htmlFor={`multiple_birth-no-${index}`}
                                                                                                className="mb-0">No</Label>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mt-3">
                                                                                    <Col md={12}>
                                                                                        <div style={{
                                                                                            marginTop: 5,
                                                                                            marginLeft: 60
                                                                                        }}>
                                                                                            {pregnancy.pregnancies.map((multiple, multipleIndex) => (
                                                                                                <div key={multipleIndex}
                                                                                                     className="mb-3">
                                                                                                    <Row>
                                                                                                        <h5 style={{marginTop: '30px'}}>
                                                                                                            <strong>Parto</strong>
                                                                                                        </h5>
                                                                                                    </Row>
                                                                                                    <Row>
                                                                                                        <Col sm="2">
                                                                                                            <Label
                                                                                                                htmlFor={`pregnancies-${index}-weight`}
                                                                                                                style={{
                                                                                                                    marginBottom: '10px',
                                                                                                                    marginRight: '30px'
                                                                                                                }}>
                                                                                                                Peso
                                                                                                                (Kg)
                                                                                                            </Label>
                                                                                                            <Input
                                                                                                                id={`pregnancies-${index}-weight-${multipleIndex}`}
                                                                                                                name={`pregnancies[${index}].pregnancies[${multipleIndex}].weight`}
                                                                                                                type="number"
                                                                                                                placeholder="Peso"
                                                                                                                value={multiple.weight}
                                                                                                                onChange={(e) => handleMultipleIntChange(e, index, multipleIndex, 'weight')}
                                                                                                                className="flex-grow-1"
                                                                                                                style={{marginRight: '30px'}}
                                                                                                            />
                                                                                                        </Col>
                                                                                                        <Col sm="2">
                                                                                                            <Label
                                                                                                                htmlFor={`pregnancies-${index}-gender`}
                                                                                                                style={{
                                                                                                                    marginBottom: '10px',
                                                                                                                    marginRight: '30px'
                                                                                                                }}>
                                                                                                                Género
                                                                                                            </Label>
                                                                                                            <select
                                                                                                                id={`pregnancies-${index}-gender-${multipleIndex}`}
                                                                                                                name={`pregnancies[${index}].pregnancies[${multipleIndex}].gender`}
                                                                                                                value={multiple.gender}
                                                                                                                onChange={(e) => handleMultipleChange(e, index, multipleIndex, 'gender')}
                                                                                                                onBlur={formik.handleBlur}
                                                                                                                className="custom-select"
                                                                                                                style={{
                                                                                                                    marginRight: 10,
                                                                                                                    marginTop: 3,
                                                                                                                    width: 120,
                                                                                                                    height: 35
                                                                                                                }}
                                                                                                            >
                                                                                                                <option
                                                                                                                    value="">Selecciona...
                                                                                                                </option>
                                                                                                                {GenderOptions.map(option => (
                                                                                                                    <option
                                                                                                                        key={option.val}
                                                                                                                        value={option.val}>
                                                                                                                        {option.label}
                                                                                                                    </option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        </Col>
                                                                                                        <Col sm="3"
                                                                                                             style={{flex: '0 0 20%'}}>
                                                                                                            <Label
                                                                                                                htmlFor={`pregnancies-${index}-birth_method`}
                                                                                                                style={{
                                                                                                                    marginBottom: '10px',
                                                                                                                    marginRight: '30px'
                                                                                                                }}>
                                                                                                                Tipo de
                                                                                                                parto
                                                                                                            </Label>
                                                                                                            <select
                                                                                                                id={`pregnancies-${index}-birth_method-${multipleIndex}`}
                                                                                                                name={`pregnancies[${index}].pregnancies[${multipleIndex}].birth_method`}
                                                                                                                value={multiple.birth_method}
                                                                                                                onChange={(e) => handleMultipleChange(e, index, multipleIndex, 'birth_method')}
                                                                                                                onBlur={formik.handleBlur}
                                                                                                                className="custom-select"
                                                                                                                style={{
                                                                                                                    marginRight: 10,
                                                                                                                    marginTop: 3,
                                                                                                                    width: 120,
                                                                                                                    height: 35
                                                                                                                }}
                                                                                                            >
                                                                                                                <option
                                                                                                                    value="">Selecciona...
                                                                                                                </option>
                                                                                                                {BirthOptions.map(option => (
                                                                                                                    <option
                                                                                                                        key={option.val}
                                                                                                                        value={option.val}>
                                                                                                                        {option.label}
                                                                                                                    </option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        </Col>
                                                                                                        <Col sm="3"
                                                                                                             style={{flex: '0 0 20%'}}>
                                                                                                            <Label
                                                                                                                htmlFor={`pregnancies-${index}-neonate`}
                                                                                                                style={{
                                                                                                                    marginBottom: '10px',
                                                                                                                    marginRight: '30px'
                                                                                                                }}>Recién
                                                                                                                nacido</Label>
                                                                                                            <select
                                                                                                                id={`pregnancies-${index}-neonate-${multipleIndex}`}
                                                                                                                name={`pregnancies[${index}].pregnancies[${multipleIndex}].neonate`}
                                                                                                                value={multiple.neonate}
                                                                                                                onChange={(e) => handleMultipleChange(e, index, multipleIndex, 'neonate')}
                                                                                                                onBlur={formik.handleBlur}
                                                                                                                className="custom-select"
                                                                                                                style={{
                                                                                                                    marginRight: 10,
                                                                                                                    marginTop: 3,
                                                                                                                    width: 120,
                                                                                                                    height: 35
                                                                                                                }}
                                                                                                            >
                                                                                                                <option
                                                                                                                    value="">Selecciona...
                                                                                                                </option>
                                                                                                                {NeonateOptions.map(option => (
                                                                                                                    <option
                                                                                                                        key={option.val}
                                                                                                                        value={option.val}>
                                                                                                                        {option.label}
                                                                                                                    </option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        </Col>
                                                                                                        <Col sm="2">
                                                                                                            <Button
                                                                                                                type="button"
                                                                                                                onClick={() => removeMultiple(index, multipleIndex)}>
                                                                                                                Eliminar
                                                                                                                parto
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            ))}
                                                                                            <Button type="button"
                                                                                                    color="light"
                                                                                                    className="btn-rounded mt-2"
                                                                                                    onClick={() => addMultiple(index)}>
                                                                                                Agregar parto
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{marginTop: 50}}>
                                                                                    <Col sm="12">
                                                                                        <Label
                                                                                            htmlFor={`pregnancies-${index}-pregnancy_observations`}
                                                                                            style={{
                                                                                                marginBottom: '10px',
                                                                                                marginRight: '30px'
                                                                                            }}>Observaciones del
                                                                                            parto</Label>
                                                                                        <div style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center'
                                                                                        }}>
                                                                                            <Input
                                                                                                id={`pregnancies-${index}-pregnancy_observations`}
                                                                                                name={`pregnancies[${index}].pregnancy_observations`}
                                                                                                type="text"
                                                                                                placeholder="Observaciones del parto"
                                                                                                value={pregnancy.pregnancy_observations}
                                                                                                onChange={(e) => handlePregnancyChange(e, index, 'pregnancy_observations')}
                                                                                                className="flex-grow-1"
                                                                                                style={{marginRight: '30px'}}
                                                                                            />
                                                                                            <Button type="button"
                                                                                                    onClick={() => removePregnancy(index)}>
                                                                                                Eliminar embarazo
                                                                                            </Button>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))}
                                                                        <Button color="light"
                                                                                className="btn-rounded mt-3"
                                                                                onClick={addPregnancy}>
                                                                            Agregar otro embarazo
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="pregnancy_notes">Notas</Label>
                                                                        <Input
                                                                            id="pregnancy_notes"
                                                                            name="medical_history.pregnancy_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.pregnancy_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('cytology_mammogram')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Citologías y
                                                        mamografías {visibility.cytology_mammogram ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.cytology_mammogram && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="6">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label htmlFor="cytology_mammogram_present"
                                                                               style={{marginRight: 30}}>Citología o
                                                                            mamografía presente</Label>
                                                                        <select
                                                                            id="cytology_mammogram_present"
                                                                            name="medical_history.cytology_mammogram_present"
                                                                            value={formik.values.medical_history.cytology_mammogram_present}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="custom-select"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PresentTwoOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <div>
                                                                    <h5 style={{marginTop: '30px'}}>
                                                                        <strong>Citologías</strong></h5>
                                                                    {formik.values.cytologies.map((cytology, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <Row>
                                                                                <Col sm="4">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`drug-${index}`}>Fecha</Label>
                                                                                        <Input
                                                                                            id={`date-${index}`}
                                                                                            name={`cytologies[${index}].date`}
                                                                                            type="text"
                                                                                            placeholder="Fecha"
                                                                                            value={cytology.date}
                                                                                            onChange={e => handleCytologyChange(e, index, 'date')}
                                                                                            className="flex-grow-1"
                                                                                            style={{marginRight: 10}}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="2">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`result-${index}`}>Resultado</Label>
                                                                                        <select
                                                                                            id={`result-${index}`}
                                                                                            name={`cytologies[${index}].result`}
                                                                                            value={cytology.result}
                                                                                            onChange={e => handleCytologyChange(e, index, 'result')}
                                                                                            onBlur={formik.handleBlur}
                                                                                            className="custom-select"
                                                                                            style={{marginRight: 10}}
                                                                                        >
                                                                                            <option
                                                                                                value="">Selecciona...
                                                                                            </option>
                                                                                            {ResultOptions.map(option => (
                                                                                                <option key={option.val}
                                                                                                        value={option.val}>
                                                                                                    {option.label}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col sm="12">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`observations-${index}`}>Tiempo
                                                                                            de suspendido</Label>
                                                                                        <div
                                                                                            className="d-flex justify-content-between align-items-center">
                                                                                            <Input
                                                                                                id={`observations-${index}`}
                                                                                                name={`cytologies[${index}].observations`}
                                                                                                type="text"
                                                                                                placeholder="Observaciones"
                                                                                                value={cytology.observations}
                                                                                                onChange={e => handleCytologyChange(e, index, 'observations')}
                                                                                                className="flex-grow-1"
                                                                                                style={{marginRight: 10}}
                                                                                            />
                                                                                            <Button type="button"
                                                                                                    onClick={() => removeCytology(index)}>Eliminar</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                    ))}
                                                                    <Button color="light" className="btn-rounded mt-3"
                                                                            onClick={addCytology}>
                                                                        Agregar otra citología
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <div style={{marginTop: 30}}>
                                                                    <h5 style={{marginTop: '30px'}}>
                                                                        <strong>Mamografías</strong></h5>
                                                                    {formik.values.mammograms.map((mammogram, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <Row>
                                                                                <Col sm="4">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`drug-${index}`}>Fecha</Label>
                                                                                        <Input
                                                                                            id={`date-${index}`}
                                                                                            name={`mammograms[${index}].date`}
                                                                                            type="text"
                                                                                            placeholder="Fecha"
                                                                                            value={mammogram.date}
                                                                                            onChange={e => handleMammogramChange(e, index, 'date')}
                                                                                            className="flex-grow-1"
                                                                                            style={{marginRight: 10}}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm="2">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`result-${index}`}>Resultado</Label>
                                                                                        <select
                                                                                            id={`result-${index}`}
                                                                                            name={`mammograms[${index}].result`}
                                                                                            value={mammogram.result}
                                                                                            onChange={e => handleMammogramChange(e, index, 'result')}
                                                                                            onBlur={formik.handleBlur}
                                                                                            className="custom-select"
                                                                                            style={{marginRight: 10}}
                                                                                        >
                                                                                            <option
                                                                                                value="">Selecciona...
                                                                                            </option>
                                                                                            {ResultOptions.map(option => (
                                                                                                <option key={option.val}
                                                                                                        value={option.val}>
                                                                                                    {option.label}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col sm="12">
                                                                                    <div className="mb-3 mt-3">
                                                                                        <Label
                                                                                            htmlFor={`observations-${index}`}>Tiempo
                                                                                            de suspendido</Label>
                                                                                        <div
                                                                                            className="d-flex justify-content-between align-items-center">
                                                                                            <Input
                                                                                                id={`observations-${index}`}
                                                                                                name={`mammograms[${index}].observations`}
                                                                                                type="text"
                                                                                                placeholder="Observaciones"
                                                                                                value={mammogram.observations}
                                                                                                onChange={e => handleMammogramChange(e, index, 'observations')}
                                                                                                className="flex-grow-1"
                                                                                                style={{marginRight: 10}}
                                                                                            />
                                                                                            <Button type="button"
                                                                                                    onClick={() => removeMammogram(index)}>Eliminar</Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </React.Fragment>
                                                                    ))}
                                                                    <Button color="light" className="btn-rounded mt-3"
                                                                            onClick={addMammogram}>
                                                                        Agregar otro mamograma
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Label
                                                                            htmlFor="cytology_mammogram_notes">Notas</Label>
                                                                        <Input
                                                                            id="cytology_mammogram_notes"
                                                                            name="medical_history.cytology_mammogram_notes"
                                                                            type="text"
                                                                            placeholder="Notas"
                                                                            value={formik.values.medical_history.cytology_mammogram_notes}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            )}

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('generalInfo')}
                                                       style={{cursor: 'pointer'}}>
                                                Información extra {visibility.generalInfo ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.generalInfo && (
                                                <div>
                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('question')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Preguntas personalizadas {visibility.question ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.question && (
                                                        <div>
                                                            {formik.values.questions.map((question, index) => (
                                                                <div key={index} className="mb-3">
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    htmlFor={`question-${index}`}>Pregunta</Label>
                                                                                <Input
                                                                                    id={`question-${index}`}
                                                                                    name={`questions[${index}].question`}
                                                                                    type="text"
                                                                                    placeholder="Pregunta"
                                                                                    value={question.question}
                                                                                    onChange={(e) => handleQuestionChange(e, index, 'question')}
                                                                                    className="form-control"
                                                                                />
                                                                                {formik.errors[`questions[${index}].question`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    htmlFor={`answer-${index}`}>Respuesta</Label>
                                                                                <Input
                                                                                    id={`answer-${index}`}
                                                                                    name={`questions[${index}].answer`}
                                                                                    type="text"
                                                                                    placeholder="Respuesta"
                                                                                    value={question.answer}
                                                                                    onChange={(e) => handleQuestionChange(e, index, 'answer')}
                                                                                    className="form-control"
                                                                                />
                                                                                {formik.errors[`questions[${index}].answer`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Button type="button"
                                                                            onClick={() => removeQuestion(index)}>Eliminar</Button>
                                                                </div>
                                                            ))}
                                                            <Button color="light" className="btn-rounded mt-3"
                                                                    onClick={addQuestion}>
                                                                Agregar otra pregunta
                                                            </Button>
                                                        </div>
                                                    )
                                                    }

                                                    <CardTitle tag="h4" className="mt-5"
                                                               onClick={() => toggleSection('general_observations')}
                                                               style={{cursor: 'pointer', marginLeft: '70px'}}>
                                                        Observaciones
                                                        generales {visibility.general_observations ? '▲' : '▼'}
                                                    </CardTitle>
                                                    {visibility.general_observations && (
                                                        <div>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <div className="mb-3 mt-3">
                                                                        <Input
                                                                            id="general_observations"
                                                                            name="medical_history.general_observations"
                                                                            type="text"
                                                                            placeholder="Observaciones generales"
                                                                            value={formik.values.medical_history.general_observations}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            className="flex-grow-1"
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                            )}

                                            {
                                                loading ? <Spinners/>
                                                    :
                                                    <Row>
                                                        <div className="d-flex flex-wrap justify-content-end gap-2">
                                                            <Link to={`/admin/patients/medical-history/${id}`}
                                                                  className="btn btn-outline-danger">Cancelar</Link>
                                                            <Button type="submit" color="success"> Guardar </Button>
                                                            <div className="position-fixed top-0 end-0 p-3"
                                                                 style={{zIndex: "1005"}}>
                                                                <Toast isOpen={toast}>
                                                                    <ToastHeader toggle={closeToast}>
                                                                        {toastTitle}
                                                                    </ToastHeader>
                                                                    <ToastBody>
                                                                        {toastMessage}
                                                                    </ToastBody>
                                                                </Toast>
                                                            </div>
                                                        </div>
                                                    </Row>
                                            }
                                        </Form>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminPatientEdit)

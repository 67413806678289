import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConsultingOffice} from "../../entities/types";

const initialState = {
    adminConsultingOfficeList: [] as ConsultingOffice[],
    adminConsultingOffice: {} as ConsultingOffice,
    reducerToastMessage: "",
    reducerToastTitle: "",
    loading: false,
};

const consultingOfficeSlice = createSlice({
    name: 'consulting_office',
    initialState,
    reducers: {
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
        },
        cleanApiError(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
        adminConsultingOfficesSuccess(state, action: PayloadAction<any>) {
            state.adminConsultingOfficeList = action.payload;
            state.loading = false;
        },
        adminConsultingOfficeSuccess(state, action: PayloadAction<any>) {
            console.log("adminConsultingOfficeSuccess action.payload", action.payload)
            state.adminConsultingOffice = action.payload;
            state.loading = false;
        },
        adminCreateOfficeSuccess(state) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Consultorio creado";
            state.loading = false;
        },
        adminDeleteOfficeSuccess(state) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Consultorio eliminado";
            state.loading = false;
        },
        adminEditOfficeSuccess(state, action: PayloadAction<any>) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Consultorio editado";
            state.loading = false;
        },
    },
});

export const {
    apiError,
    cleanApiError,
    loadingTrue,
    adminConsultingOfficesSuccess,
    adminCreateOfficeSuccess,
    adminDeleteOfficeSuccess,
    adminConsultingOfficeSuccess,
    adminEditOfficeSuccess,
} = consultingOfficeSlice.actions;

export default consultingOfficeSlice.reducer;
import React, { useEffect, useState } from "react";

// Redux
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
  Toast,
  ToastHeader, ToastBody
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import lightlogo from "../../assets/images/logo.png";

//import thunk
import { loginUserThunk, resetLoginMsgFlagThunk } from "slices/auth/login/thunk";

import withRouter from "Components/Common/withRouter";
import { createSelector } from 'reselect';
import {ConsultingOffice} from "../../entities/types";
import {cleanErrors} from "../../slices/admin/thunk";

interface selectState {
  login: {
    user: "",
    isUserLogout: false,
    loading: false;
    reducerToastTitle: "";
    reducerToastMessage: "";
  };
};

const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const dispatch: any = useDispatch();

  //meta title
  document.title = "Login | Farmabits";


  const selectProperties = createSelector(
    (state: selectState) => state.login,
      (item) => ({
        user: item.user,
        loading: item.loading,
        isUserLogout: item.isUserLogout,
        reducerToastTitle: item.reducerToastTitle,
        reducerToastMessage: item.reducerToastMessage,
      })
  );

  // Form validation 
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      dispatch(loginUserThunk(values, props.router.navigate));
    }
  });

  const {loading, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

  // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
  // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
  const [toast, setToast] = useState(false);
  const [toastTitle, setToastTitle] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  const closeToast = () => {
    setToast(false);
    dispatch(cleanErrors()); // Limpia los errores cuando el toast se cierra
  };

  useEffect(() => {
    if (reducerToastMessage !== "") {
      console.log("showToastWithMessage=", reducerToastMessage);
      setToast(true);
      setToastTitle(reducerToastTitle);
      setToastMessage(reducerToastMessage);
      setTimeout(() => {
        setToast(false);
        dispatch(resetLoginMsgFlagThunk())
      }, 3000);
    }
  }, [dispatch, reducerToastTitle, reducerToastMessage]);


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Farmabits.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          <button onClick={() => setShow(!show)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i></button>
                        </div>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Recordar
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                            className="btn btn-primary btn-block "
                            type="submit"
                        >
                          Log In
                        </button>
                        <div className="position-fixed top-0 end-0 p-3" style={{zIndex: "1005"}}>
                          <Toast isOpen={toast}>
                            <ToastHeader toggle={closeToast}>
                              {toastTitle}
                            </ToastHeader>
                            <ToastBody>
                              {toastMessage}
                            </ToastBody>
                          </Toast>
                        </div>
                      </div>


                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1"/> ¿Olvidaste tu contraseña?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  ¿No tienes cuenta?{" "}
                  <Link
                      to="/register"
                      className="fw-medium text-primary"
                  >
                  {" "}
                    Registro{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Hecho con{" "}
                  <i className="mdi mdi-heart text-danger" /> por Farmabits
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

import React, { useState } from "react";
import {
    Row,
    Col,
    Card,
    Form,
    CardBody,
    Container, ToastHeader, ToastBody, Toast,
} from "reactstrap"
import Dropzone from "react-dropzone"
import {useDispatch, useSelector} from "react-redux";

import {Link, useParams} from "react-router-dom"
import withRouter from "Components/Common/withRouter";
import {createSelector} from "reselect";
import {cleanApiError} from "../../slices/config_recipe/reducer";
import Spinners from "Components/Common/Spinner";
import {addPatientFilesThunk} from "../../slices/admin/thunk";


interface selectState {
    configRecipe: {
        loading: false;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

export const cleanErrors = () => {
    return cleanApiError();
}

const AdminPatientFileAdd = (props: any) => {
    const dispatch = useDispatch() as any;
    const { id: patientId } = useParams();
    const [isUploading, setIsUploading] = useState(false);

    //meta title
    document.title = "Agregar archivo"

    const [selectedFiles, setselectedFiles] = useState<any>([])

    function handleAcceptedFiles(files: any) {
        const allowedFiles = files.filter((file: any) =>
            file.type.startsWith('image/') ||
            file.type === 'application/pdf' ||
            file.type === 'video/mp4'
        );

        const formattedFiles = allowedFiles.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );

        // Agregar los nuevos archivos a la lista existente en lugar de reemplazarlos
        setselectedFiles((prevFiles: any[]) => [...prevFiles, ...formattedFiles]);
    }

    const selectProperties = createSelector(
        (state: selectState) => state.configRecipe,
        (item) => ({
            loading: item.loading,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    const finishUploading = () => {
        setIsUploading(false);  // Desactivar el spinner
        props.router.navigate(`/admin/patient/files/${patientId}`);  // Navegar si es necesario
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedFiles.length > 0 && patientId) {
            setIsUploading(true);  // Activar el spinner
            dispatch(addPatientFilesThunk(selectedFiles, patientId, finishUploading))
                .catch(error => {
                    console.error('Error during file upload:', error);
                });
        } else {
            alert('Por favor, selecciona al menos un archivo antes de enviar o verifica el ID del paciente.');
        }
    };

    /**
     * Formats the size
     */
    function formatBytes(bytes:any, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <h6 className="card-title">Archivo</h6>
                                    <div className="card-title-desc">
                                        {" "}
                                        Agregar archivos .png / .jpg / .jpeg / .pdf / .mp4
                                    </div>
                                    <Form onSubmit={handleSubmit}>
                                        <Dropzone
                                            onDrop={(acceptedFiles: any) => {
                                                handleAcceptedFiles(acceptedFiles)
                                            }}
                                            multiple={true}
                                        >
                                            {({getRootProps, getInputProps}: any) => (
                                                <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick mt-2"
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                            <i className="display-4 text-muted bx bxs-cloud-upload"/>
                                                        </div>
                                                        <h4>Drop files here or click to upload.</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                            {selectedFiles.map((f: any, i: any) => {
                                                return (
                                                    <Card
                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                        key={i + "-file"}
                                                    >
                                                        <div className="p-2">
                                                            <Row className="align-items-center">
                                                                <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="80"
                                                                        className="avatar-sm rounded bg-light"
                                                                        alt={f.name}
                                                                        src={f.preview}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <Link
                                                                        to="#"
                                                                        className="text-muted font-weight-bold"
                                                                    >
                                                                        {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                        <strong>{f.formattedSize}</strong>
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                )
                                            })}
                                        </div>
                                        {
                                            isUploading || loading ? <Spinners/> : <div className="text-center mt-4">

                                            <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        disabled={!selectedFiles || selectedFiles.length === 0}>
                                                        Enviar
                                                    </button>

                                                </div>
                                        }
                                        <Toast isOpen={toast}>
                                            <ToastHeader toggle={closeToast}>
                                                {toastTitle}
                                            </ToastHeader>
                                            <ToastBody>
                                                {toastMessage}
                                            </ToastBody>
                                        </Toast>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminPatientFileAdd);

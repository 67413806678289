import React, {useState} from 'react';
import {Col, Container, Row, Toast, ToastBody, ToastHeader} from 'reactstrap';
import PatientList from "../PatientsList/PatientList";
import {cleanErrors} from "../../slices/admin/thunk";
import {Patient} from "../../entities/types";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import logo from "../../assets/images/logo.png";

interface selectState {
    admin: {
        adminPatientList: Patient[];
        loading: true;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const TermsAndConditions = () => {

    document.title = "Términos y Condiciones";
    const dispatch = useDispatch<any>();

    return (
        <React.Fragment>
            <section className="section bg-white" id="news">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <img src={logo} alt="" width="200"/>
                                <h4 className="pt-3">Términos y Condiciones</h4>
                            </div>
                            <div className="text-start mb-5">
                                <p>
                                    Los productos y servicios (en adelante, los "Servicios") se proporcionan a través de
                                    Farmabits, o cualquiera de sus empresas subsidiarias.
                                    <br/><br/>
                                    El uso de nuestros Servicios implica la aceptación de estas condiciones. Por favor,
                                    lea y revise atentamente los Términos y Condiciones de Uso y nuestra Política de
                                    Privacidad antes de utilizar cualquier Servicio de Farmabits. Si no está de acuerdo
                                    con estas condiciones, no continúe utilizando nuestros Servicios.
                                    <br/><br/>
                                    Farmabits brinda distintos Servicios a personas físicas y/o jurídicas (en adelante,
                                    "Usuario"), refiriéndose a cualquier persona que emplee los servicios o productos de
                                    Farmabits.
                                    <br/><br/>
                                    Aceptación
                                    <br/>
                                    Las presentes condiciones regulan el acceso o uso que los Usuarios hagan de los
                                    Servicios que Farmabits pone a disposición. Al utilizar los Servicios, usted acepta
                                    en su totalidad y se obliga a cumplir por completo los Términos y Condiciones de
                                    Uso, así como nuestra Política de Privacidad. Farmabits se reserva el derecho a
                                    modificar estos Términos y Condiciones de Uso cuando lo estime necesario. Por lo
                                    tanto, el Usuario debe leer atentamente los Términos y Condiciones de Uso en cada
                                    ocasión en que se proponga utilizar el Servicio, ya que estos pueden sufrir
                                    modificaciones. Puede ver la versión vigente de estos términos en cualquier momento
                                    a través del hipervínculo en el pie de cada página en este Servicio.
                                    Estos Términos y Condiciones de Uso serán aplicables a todas las marcas, productos y
                                    servicios de Farmabits que no disponen de términos y condiciones de uso
                                    independientes. Estos Términos de Uso se limitan solamente a Farmabits y sus
                                    Servicios. Farmabits no controla ni puede garantizar los Términos de Uso o la
                                    Política de Privacidad de terceros, incluyendo proveedores y/o compañías a las que
                                    pueda tener acceso a través de nuestros Servicios.
                                    <br/><br/>
                                    Condiciones de Uso del Sitio Web
                                    <br/>
                                    Algunos de nuestros servicios son gratuitos, por lo que es responsabilidad del
                                    Usuario verificar si el servicio elegido tiene algún costo. El Usuario se compromete
                                    a no usar los Servicios con fines fraudulentos ni a llevar a cabo conductas que
                                    pudieran dañar la imagen, los intereses y los derechos de Farmabits o de terceros.
                                    Asimismo, el Usuario se compromete a no realizar actos con el objeto de dañar,
                                    inutilizar o sobrecargar cualquier Servicio, realizar ingeniería inversa o impedir
                                    de cualquier forma la normal utilización y funcionamiento del mismo.
                                    <br/>
                                    Se informa al Usuario que, en caso de incumplir los presentes términos de uso y
                                    condiciones, la Política de Privacidad, o cualesquiera otros términos o condiciones
                                    particulares establecidos en los Servicios, Farmabits se reserva el derecho a
                                    limitar, suspender, interrumpir o terminar su acceso a los Servicios. Asimismo,
                                    Farmabits se reserva la posibilidad de ejercer tales medidas en caso de sospecha
                                    razonable de que el Usuario está vulnerando cualquiera de los términos y condiciones
                                    citados. Finalmente, Farmabits se reserva en todo momento la facultad de decidir
                                    sobre la continuidad de los Servicios que presta.
                                    <br/><br/>
                                    Protección de la Privacidad y de los Derechos de Autor
                                    <br/>
                                    Las Políticas de Privacidad de Farmabits explican el tratamiento de los datos
                                    personales y la protección de la privacidad al usar nuestros Servicios. Si utiliza
                                    nuestros Servicios, acepta que Farmabits use dichos datos de conformidad con sus
                                    Políticas de Privacidad.
                                    Respondemos a las notificaciones de presuntas infracciones de los derechos de autor,
                                    propiedad intelectual, abusos de uso o derecho, y se cancelan las cuentas de los
                                    Usuarios que cometen infracciones reiteradas. En caso de considerar que existe una
                                    violación, puede comunicarse con nosotros al correo electrónico
                                    informes@farmabits.com, explicando la infracción y aportando la prueba
                                    correspondiente.
                                    <br/><br/>
                                    Servicios
                                    <br/><br/>
                                    Farmabits es el proveedor de varios Servicios, tales como, pero no limitados a,
                                    proporcionar una herramienta de información sobre salud, productos, información
                                    sobre salud, expedientes electrónicos, directorios médicos, acceso a profesionales
                                    de salud y otros, acceso a citas médicas, teleconsultas, procesamiento de pagos,
                                    calendarios, y el ámbito sanitario. En aquellos casos en los que el Usuario así lo
                                    haya consentido, podrá recibir información y noticias de Farmabits o relacionadas
                                    con la salud y el ámbito sanitario mediante correo electrónico, SMS u otros medios
                                    de comunicación electrónica. Se le recuerda que, con el uso del Servicio, se
                                    entiende su aceptación inmediata al recibo de todo tipo de información comercial o
                                    publicitaria por parte de Farmabits.
                                    <br/><br/>
                                    Algunos de nuestros Servicios permiten subir, enviar, almacenar o recibir contenido.
                                    Si lo hace, seguirá siendo el titular de los derechos de propiedad intelectual y/o
                                    personal que tenga sobre ese contenido. No obstante, en algunas ocasiones Farmabits,
                                    sus directores, subsidiarias, representantes, socios y/o colaboradores podrán usar,
                                    alojar, almacenar, reproducir, modificar, crear, comunicar, ejecutar o mostrar dicho
                                    contenido siempre y cuando se cuente con su consentimiento. Asegúrese de tener los
                                    derechos necesarios sobre cualquier contenido que envíe a nuestros Servicios; en
                                    caso de utilizar información incompleta o que no le pertenezca, se hará bajo su
                                    total responsabilidad, por lo que Farmabits no se hace responsable por la
                                    información que el Usuario decida incluir y/o cualquier uso que se le dé.
                                    <br/><br/>
                                    Para obtener más información sobre cómo Farmabits usa o almacena su información,
                                    consulte las Políticas de Privacidad.
                                    <br/><br/>
                                    Términos de Pago
                                    <br/><br/>
                                    Esta cláusula aplica solamente para médicos y profesionales o proveedores de la
                                    salud que contratan un producto o Servicio de Farmabits.
                                    <br/><br/>
                                    Pago
                                    <br/><br/>
                                    El Usuario deberá pagar el Servicio por adelantado, según el plan que haya
                                    seleccionado y sus características específicas (periodicidad de pago, cantidad de
                                    usuarios, tipo de usuario, extensiones y agregados entre otros). Los periodos de
                                    pago pueden ser mensuales o anuales. El Usuario es responsable de proveer la
                                    información de pago de manera completa, actualizada y exacta, junto con su
                                    información de contacto a Farmabits y notificar de cualquier cambio en dicha
                                    información. La factura se enviará automáticamente, junto con cada cobro, al correo
                                    electrónico que el Usuario indique. De conformidad con lo anterior, el Usuario
                                    consiente y autoriza el recibo y envío de la información de cobro correspondiente a
                                    su servicio.
                                    <br/><br/>
                                    Método de Pago
                                    <br/><br/>
                                    El único método de pago aceptado por Farmabits es mediante el débito automático a
                                    una tarjeta de crédito o débito. Todos los pagos, con la excepción de planes en
                                    México, se deben hacer en dólares estadounidenses (US$). Los cobros a planes en
                                    México se harán en pesos mexicanos, a menos que el Usuario especifique lo contrario.
                                    El Usuario autoriza a Farmabits a realizar el cargo automático de la suscripción
                                    mensual o anual de su tarjeta, por lo que es responsabilidad del Usuario mantener
                                    activa y al día la tarjeta que se utilizará como método de pago. De lo contrario,
                                    será responsabilidad del Usuario la suspensión del Servicio por incumplimiento del
                                    pago y asumirá cualquier daño y perjuicio que se derive de su actuar o
                                    incumplimiento.
                                    <br/><br/>
                                    Cargos Adicionales
                                    <br/><br/>
                                    Mantenimiento y Actualizaciones:
                                    <br/><br/>Farmabits no realiza ningún cobro por mantenimiento ni actualizaciones de
                                    la plataforma.
                                    <br/><br/>
                                    Impuestos
                                    <br/><br/>
                                    Los honorarios de Farmabits no incluyen impuestos de ningún tipo. El Usuario es el
                                    único responsable del pago de cualquier tipo de impuesto asociado con la compra del
                                    Servicio. Farmabits es únicamente responsable por el pago de impuestos derivados de
                                    sus ingresos, propiedad y empleados.
                                    <br/><br/>
                                    Suspensión del Servicio por Falta de Pago
                                    <br/><br/>
                                    Farmabits se reserva el derecho de suspender, interrumpir, bloquear y/o terminar el
                                    Servicio si el Usuario no ha pagado el monto adeudado a Farmabits en las respectivas
                                    fechas de pago. Previo a la suspensión o terminación del Servicio, Farmabits
                                    realizará esfuerzos razonables para notificar por correo electrónico antes del
                                    evento. En caso de que el Usuario no se ponga al día en los pagos correspondientes,
                                    Farmabits no se hace responsable por la pérdida o eliminación de la información, ya
                                    que el no pago correspondiente en la fecha indicada se entenderá que el Usuario no
                                    desea continuar con los Servicios. En dicho caso, no será responsabilidad de
                                    Farmabits conservar la información del Usuario.
                                    <br/><br/>
                                    Cambios de Plan
                                    <br/><br/>
                                    Si el Usuario mejora su plan, es decir, adquiere un plan con más y mejores servicios
                                    a un precio mayor durante un periodo de suscripción activa, se hará un cobro
                                    prorrateado por la cantidad de días que utilizó los servicios del plan anterior y la
                                    cantidad de días que utilizará los servicios del nuevo plan, y se le cobrará lo
                                    correspondiente. El Usuario tendrá acceso al nuevo plan de manera inmediata.
                                    <br/><br/>
                                    Si el Usuario decide no continuar con el plan que tenía y adquirir un plan más
                                    económico durante un periodo de suscripción activa, el Usuario mantendrá su
                                    suscripción por el resto del periodo de pago y cambiará de suscripción a partir del
                                    siguiente periodo de pago. Farmabits no realizará devoluciones de dinero por la
                                    adquisición o cambio a planes más económicos.
                                    <br/><br/>
                                    Reembolsos, Devoluciones y Cancelaciones
                                    <br/><br/>
                                    Dado que el servicio se paga por periodos correspondientes de forma mensual o anual
                                    por adelantado, se le recomienda revisar el tipo de plan que desea adquirir, ya que
                                    no se realizan devoluciones por pagos de servicios previamente prestados. Por lo
                                    tanto, si el Usuario no desea continuar con el servicio, deberá cancelar el servicio
                                    por lo menos 5 días antes del término de su periodo de suscripción activa, ya sea
                                    mensual o anual.
                                    <br/><br/>
                                    Enlaces a Sitios de Terceros
                                    <br/><br/>
                                    En el caso de enlaces hacia otros sitios web, Farmabits no se responsabiliza de la
                                    política de privacidad, ni de los términos y condiciones de uso. El Usuario deberá
                                    conocer dichos términos y políticas de privacidad de los sitios enlazados.
                                    <br/><br/>
                                    Política de Devoluciones
                                    <br/><br/>
                                    Debido a la naturaleza de los Servicios ofrecidos por Farmabits, no se realizan
                                    devoluciones por servicios prestados. Es responsabilidad del Usuario conocer las
                                    características y limitaciones del Servicio que está contratando, así como verificar
                                    que el mismo sea adecuado para sus necesidades antes de proceder con su compra. En
                                    caso de que el Usuario considere que existe un error en el cobro realizado, puede
                                    ponerse en contacto con el equipo de atención al cliente de Farmabits para revisar
                                    el caso y proporcionar una solución adecuada.
                                    <br/><br/>
                                    Requisitos para el Uso del Servicio
                                    <br/><br/>
                                    El Usuario reconoce y acepta que para utilizar el Servicio es necesario disponer de
                                    una conexión a Internet adecuada y de dispositivos compatibles. Farmabits no se hace
                                    responsable de los problemas que puedan surgir debido a una conexión a Internet
                                    deficiente o a dispositivos no compatibles.
                                    <br/><br/>
                                    Limitaciones de Responsabilidad
                                    <br/><br/>
                                    Farmabits no garantiza la disponibilidad continua y sin interrupciones del Servicio.
                                    En consecuencia, Farmabits no será en ningún caso responsable por cualquier daño y/o
                                    perjuicio que pudiera derivarse de la falta de disponibilidad o de continuidad del
                                    funcionamiento del Servicio.
                                    <br/>
                                    En ningún caso, Farmabits será responsable por daños indirectos, especiales,
                                    incidentales, punitivos o consecuentes, incluidos, pero no limitados a, pérdida de
                                    beneficios, ingresos, ahorros, datos, o cualquier otro daño que pudiera resultar de
                                    la utilización del Servicio por parte del Usuario.
                                    <br/><br/>
                                    Jurisdicción y Ley Aplicable
                                    <br/><br/>
                                    Los presentes Términos y Condiciones de Uso se rigen por las leyes vigentes del país
                                    donde se encuentra domiciliada la empresa. Cualquier controversia derivada de estos
                                    términos será sometida a la jurisdicción de los tribunales competentes del lugar de
                                    domicilio de Farmabits.
                                    <br/><br/>
                                    Para cualquier duda o aclaración, puede comunicarse con nosotros a través del correo
                                    electrónico info@farmabits.com
                                    <br/>
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>

                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default TermsAndConditions

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Consultation, Doctor, PaymentIntent} from "../../entities/types";

const initialState = {
    adminProfile: {} as Doctor,
    paymentIntent: {} as PaymentIntent,
    reducerToastMessage: "",
    reducerToastTitle: "",
    loading: false
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
        },
        cleanApiError(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
        adminProfileSuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminProfileSuccess loading false");
            state.adminProfile = action.payload;
            state.loading = false;
        },
        adminPlaceSuccess(state, action:  PayloadAction<{ doctor: Doctor,
            paymentIntent: PaymentIntent }>) {
            console.log("reducers adminPlaceSuccess loading false");
            state.adminProfile = action.payload.doctor;
            state.paymentIntent = action.payload.paymentIntent;
            state.loading = false;
        },
    },
});

export const {
    apiError,
    cleanApiError,
    loadingTrue,
    adminProfileSuccess,
    adminPlaceSuccess,
} = profileSlice.actions;

export default profileSlice.reducer;
import React, {useEffect, useState} from "react"
import {
    Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import * as yup from "yup";
import {useFormik} from "formik";
import Select from "react-select"
import {cleanErrors, consultingOfficeCreateThunk} from "../../slices/consulting_office/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import {addNewEvent, getAdminConsultingOfficesThunk, searchPatients} from "../../slices/calendar/thunk";
import {ConsultingOffice, Patient} from "../../entities/types";
import {Event} from "../../entities/types";
import {addHours, format, parseISO} from "date-fns";

interface selectState {
    calendar: {
        loading: false;
        patients: Patient[];
        consultingOfficeList: ConsultingOffice[];
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminAgendaAppointmentAdd = (props: any) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [time, setTime] = useState(new Date());

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedDateStr = queryParams.get('date');
    const selectedPatientStr = queryParams.get('patient');

    const selectedDate = selectedDateStr ? new Date(selectedDateStr) : new Date();

    const selectedPatientInt = selectedPatientStr ? parseInt(selectedPatientStr) : 0;

    console.log("selectedDate=", selectedDate);
    console.log("selectedPatientInt=", selectedPatientInt);

    //meta title
    document.title = "Crear Cita";

    const selectProperties = createSelector(
        (state: selectState) => state.calendar,
        (item) => ({
            loading: item.loading,
            patients: item.patients,
            consultingOfficeList: item.consultingOfficeList,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {
        loading,
        reducerToastTitle,
        reducerToastMessage,
        patients,
        consultingOfficeList
    } = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [searchName, setSearchName] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    function getTimeStringFromDate(date) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    }

    //Basic Information
    const formik: any = useFormik({
        initialValues: {
            name: {val: '', label: ''},
            patients: {val: '', label: ''},
            office: {val: '', label: ''},
            date: selectedDate,
            time: time,
            consultationType: "",
        },
        validationSchema: yup.object().shape({
            name: yup.object().shape({
                val: yup.string().required('El campo es requerido*'),
                label: yup.string().required('El campo es requerido*'),
            }),
            office: yup.object().shape({
                val: yup.string().required('El campo es requerido*'),
                label: yup.string().required('El campo es requerido*'),
            }),
            date: yup.date().required('El campo es requerido*'),
            time: yup.string().required('El campo es requerido*'),
        }),
        onSubmit: (values: any) => {
            console.log('onSubmit', values);

            const searchParams = new URLSearchParams(window.location.search);
            const sendTrue = searchParams.get('sendTrue') === 'true';
            console.log('sendTrue:', sendTrue);

            const hm = getTimeStringFromDate(values.time);
            console.log('endDate', endDate);
            console.log('time', values.time);
            console.log('hm', hm);

            const event_date = endDate + "T" + hm;
            const gmt_offset = parseInt(getGMT().split('GMT')[1], 10); // Esto representa GMT-6
            console.log('gmt_offset', gmt_offset);

            // Función para ajustar la fecha
            const adjustDateToGMT = (dateStr, offset) => {
                const date = parseISO(dateStr);
                // Debemos sumar el offset si es negativo
                const adjustedDate = addHours(date, -offset);
                return format(adjustedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
            }

            // Ajustar la fecha a su representación en GMT
            const adjustedEventDate = adjustDateToGMT(event_date, gmt_offset);

            console.log("adjustedEventDate= ",adjustedEventDate);

            const newEvent = {
                patient_id: values.name.val,
                event_date: adjustedEventDate,
                duration: 30,
                consulting_office_id: values.office.val,
                force: sendTrue,
            };
            console.log('newEvent', newEvent);

            dispatch(addNewEvent(newEvent, props.router.navigate));
        },
    });

    const handleNameChange = (newValue) => {
        setSearchName(newValue);
    };

    const formatDate = (date) => {
        // Format the date to 'YYYY-MM-DD' for Flatpickr
        return date.toISOString().split('T')[0];
    };

    const [endDate, setEndDate] = useState<any>(new Date());
    const [isFirstTime, setIsFirstTime] = useState(true);

    const endDateChange = (arg: any) => {
        console.log("endDateChange arg", arg);
        const date = arg[0];
        setEndDate(formatDate(date));
    };

    const currentDate = new Date();

    function getGMT() {
        const now = new Date(currentDate);

        // Get the UTC offset in minutes and convert to hours
        const offsetMinutes = -now.getTimezoneOffset(); // getTimezoneOffset returns the offset in minutes from UTC to local time, so it's negative for GMT+X
        const offsetHours = offsetMinutes / 60;

        // Format the offset as GMT+X or GMT-X
        const formattedOffset = `GMT${offsetHours >= 0 ? '+' : ''}${offsetHours}`;
        return formattedOffset;
    }

    useEffect(() => {
        dispatch(searchPatients(searchName));
        if (isFirstTime) {
            dispatch(getAdminConsultingOfficesThunk());
            setIsFirstTime(false);
        }

        setEndDate(formatDate(selectedDate));
    }, [searchName, dispatch]);

    useEffect(() => {
        if (patients.length > 0 && selectedPatientInt !== 0) {
            const selectedPatient = patients.find(p => p.id === selectedPatientInt);
            if (selectedPatient) {
                formik.setFieldValue('name', { val: selectedPatient.id, label: `${selectedPatient.first_name} ${selectedPatient.last_name}` });
            }
        }
    }, [patients, selectedPatientInt]);

    const updateTime = () => {
        const now = new Date();
        setTime(now); // Pasar el objeto Date directamente
    };

    useEffect(() => {
        updateTime(); // Llama inmediatamente a updateTime para establecer la hora inicial

        const intervalId = setInterval(updateTime, 60000); // Actualiza la hora cada minuto

        return () => clearInterval(intervalId);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h4">Agendar nueva consulta
                                    </CardTitle>
                                    <p className="card-title-desc"> Ingresa los datos de la consulta.</p>
                                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col sm="4">
                                                <div className="mb-3">
                                                    <div className="control-label"
                                                         style={{marginBottom: "0.5rem"}}>Nombre del paciente
                                                    </div>
                                                    <Select
                                                        id="name"
                                                        name="name"
                                                        options={patients.map((patient: any) => ({
                                                            val: patient.id,
                                                            label: patient.first_name + " " + patient.last_name
                                                        }))}
                                                        className="select2"
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue('name', selectedOption);
                                                        }}
                                                        onInputChange={handleNameChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.name}
                                                        menuPosition="fixed"
                                                    />
                                                    {formik.errors.name && formik.touched.name && (
                                                        <div className="text-danger" style={{ marginLeft: 0 }}>
                                                            {formik.errors.name.val}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4">
                                                <div className="mb-3">
                                                    <div className="control-label"
                                                         style={{marginBottom: "0.5rem"}}>Consultorio
                                                    </div>
                                                    <Select
                                                        id="office"
                                                        name="office"
                                                        options={consultingOfficeList.map((office: any) => ({
                                                            val: office.id,
                                                            label: office.name
                                                        }))}
                                                        className="select2"
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue('office', selectedOption);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.office}
                                                        menuPosition="fixed"
                                                    />
                                                    {formik.errors.office && formik.touched.office && (
                                                        <div className="text-danger" style={{ marginLeft: 0 }}>
                                                            {formik.errors.office.val}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm="4">
                                                <div className="mb-3">
                                                    <Label htmlFor="date">Fecha</Label>
                                                    <Flatpickr
                                                        className="form-control d-block"
                                                        id="date"
                                                        name="date"
                                                        placeholder="Select date"
                                                        options={{
                                                            mode: "single",
                                                            dateFormat: 'Y-m-d',
                                                        }}
                                                        value={endDate}
                                                        onChange={endDateChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="4">
                                                <div className="mb-3">
                                                    <Label htmlFor="time">Hora</Label>
                                                    <Flatpickr
                                                        className={`form-control ${formik.errors.time && formik.touched.time ? 'is-invalid' : ''}`}
                                                        id="time"
                                                        name="time"
                                                        options={{
                                                            enableTime: true,
                                                            noCalendar: true,
                                                            dateFormat: "H:i",
                                                        }}
                                                        value={time} // Usar directamente el estado time
                                                        onChange={(timeArray) => {
                                                            formik.setFieldValue('time', timeArray[0] || new Date());
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.errors.time && formik.touched.time && (
                                                        <FormFeedback type="invalid">{formik.errors.time}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        {
                                            loading ? <Spinners/>
                                                :
                                                <div className="d-flex flex-wrap justify-content-end gap-2">
                                                    <Button type="submit" color="primary"> Guardar </Button>
                                                    <div className="position-fixed top-0 end-0 p-3"
                                                         style={{zIndex: "1005"}}>
                                                        <Toast isOpen={toast}>
                                                            <ToastHeader toggle={closeToast}>
                                                                {toastTitle}
                                                            </ToastHeader>
                                                            <ToastBody>
                                                                {toastMessage}
                                                            </ToastBody>
                                                        </Toast>
                                                    </div>
                                                </div>
                                        }

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminAgendaAppointmentAdd)
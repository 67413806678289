import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Consultation, Doctor} from "../../entities/types";

const initialState = {
    consultation: {} as Consultation,
    doctor: {} as Doctor,
    reducerToastTitle: "",
    reducerToastMessage: "",
    loading: false
}

const consultationsSlice = createSlice({
    name: "consultations",
    initialState,
    reducers: {
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
        },
        cleanApiError(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
        adminConsultationSuccess(state, action: PayloadAction<{ consultation: Consultation, doctor: Doctor }>) {
            console.log("reducers adminConsultationSuccess loading false");
            state.consultation = action.payload.consultation;
            state.doctor = action.payload.doctor;
            state.loading = false;
        },
        consultationEditSuccess(state) {
            console.log("reducers consultationEditSuccess loading false");
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Consulta editada";
            state.loading = false;
        },
        adminDeleteConsultationSuccess(state) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Consulta eliminada";
            state.loading = false;
        },
    },
});

export const {
    apiError,
    cleanApiError,
    loadingTrue,
    adminConsultationSuccess,
    consultationEditSuccess,
    adminDeleteConsultationSuccess,
} = consultationsSlice.actions;

export default consultationsSlice.reducer;
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import React, {useEffect, useState} from "react";
import esLocale from '@fullcalendar/core/locales/es';

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row, Toast, ToastBody, ToastHeader
} from "reactstrap";
import * as Yup from "yup";
import {useFormik} from "formik";


import DeleteModal from "./DeleteModal";

//redux
import {useSelector, useDispatch} from "react-redux";
import {createSelector} from 'reselect';
import {Event} from "../../entities/types";
import {getEvents} from "../../slices/calendar/thunk";
import {updateEvent} from "../../slices/calendar/thunk";
import {addNewEvent} from "../../slices/calendar/thunk";
import {deleteEvent} from "../../slices/calendar/thunk";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {cleanErrors} from "../../slices/consulting_office/thunk";
import Spinners from "Components/Common/Spinner";

interface selectState {
    calendar: {
        events: Event[];
        loading: true;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};
const AdminAgenda = (props: any) => {
    const navigate = useNavigate();
    //meta title
    document.title = "Agenda";

    const dispatch = useDispatch<any>();

    const selectProperties = createSelector(
        (state: selectState) => state.calendar,
        (item) => ({
            events: item.events,
            loading: item.loading,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const { events, loading, reducerToastTitle, reducerToastMessage } = useSelector(selectProperties);

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [modalCategory, setModalCategory] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState();
    const [selectedDay, setSelectedDay] = useState<any>(0);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    /*
        useEffect(() => {
            new Draggable(document.getElementById("external-events"), {
                itemSelector: ".external-event",
            });
        }, [dispatch]);*/

    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [searchName, setSearchName] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);

            const timer = setTimeout(() => {
                setToast(false);
                setToastTitle('');
                setToastMessage('');
            }, 5000);

            return () => clearTimeout(timer);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    const [event, setEvent] = useState<any>({});

    const [endDate, setendDate] = useState<any>(new Date());
    const endDateChange = (arg: any) => {
        console.log("endDateChange arg", arg);
        const date = arg[0];
        setendDate(formatDate(date));
    };


    // category validation
    const categoryValidation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            title: (event && event.title) || '',
            category: (event && event.category) || '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Your Event Name"),
            category: Yup.string().required("Please Enter Your Billing Name"),
        }),
        onSubmit: (values) => {
            console.log("isEdit", isEdit);
            console.log("selectedDay", selectedDay);
            if (isEdit) {
                console.log("edit event selectedDay", selectedDay);
                const eventItem = {
                    id: event.id,
                    title: values.title,
                    classNames: values.category + " text-white",
                    start: event.start,
                };
                // update event
                dispatch(updateEvent(eventItem));
                categoryValidation.resetForm();
            } else {
                console.log("addNewEvent selectedDay", selectedDay);

            }
            toggle();
        },
    });

    // function to format the date as 'YYYY-MM-DD'
    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Asegurar que el mes tenga dos dígitos
        const day = ('0' + date.getDate()).slice(-2); // Asegurar que el día tenga dos dígitos
        return `${year}-${month}-${day}`;
    }

    // get the current date
    const currentDate = new Date();

    // Calculate the 'from' date (current month - 14 days)
    const fromDate = new Date(currentDate);
    fromDate.setDate(currentDate.getDate() - 45);

    // Calculate the 'to' date (current month + 14 days)
    const toDate = new Date(currentDate);
    toDate.setDate(currentDate.getDate() + 45);

    // Format the 'from' and 'to' dates
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);


    useEffect(() => {
        const timezone = getGMT();

        dispatch(getEvents(formattedFromDate, formattedToDate, timezone));
    }, [dispatch])

    /**
     * Handling the modal state
     */
    const toggle = () => {
        if (modalCategory) {
            setModalCategory(false);
            setEvent(null);
            setIsEdit(false);
        } else {
            setModalCategory(true);
        }
    };


    /**
     * Handling date click on calendar
     */
    const handleDateClick = (arg: any) => {
        console.log("handleDateClick arg", arg);
        const date = arg["date"];
        setendDate(formatDate(date));

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(
            year,
            month,
            day,
            currentHour,
            currentMin,
            currentSec
        );
        const modifiedData = {...arg, date: modifiedDate};

        setSelectedDay(modifiedData);
        toggle();
    };

    /**
     * Handling click on event on calendar
     */
    const handleEventClick = (arg: any) => {
        console.log("handleEventClick arg", arg);
        const event = arg.event;
        setEvent({
            id: event.id,
            title: event.title,
            // title_category: event.title_category,
            start: event.start,
            className: event.classNames,
            category: event.classNames[0],
            event_category: event.classNames[0],
        });
        setDeleteId(event.id)
        setIsEdit(true);
        setendDate(formatDate(new Date(event.start)));
        setModalCategory(true)
        toggle();
    };
    /**
     * Handling click on event on calendar
     */
    const handleDatesSet = (arg) => {
        console.log("handleDatesSet arg", arg);
        const startDate = arg.start;
        const from = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate();
        const endDate = arg.end;
        const to = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate();

        console.log("handleDatesSet from=", from, "to=", to);

        // Format the offset as GMT+X or GMT-X
        const formattedOffset = getGMT();
        console.log("handleDatesSet formattedOffset", formattedOffset);

    };

    function getGMT() {
        const now = new Date(currentDate);

        // Get the UTC offset in minutes and convert to hours
        const offsetMinutes = -now.getTimezoneOffset(); // getTimezoneOffset returns the offset in minutes from UTC to local time, so it's negative for GMT+X
        const offsetHours = offsetMinutes / 60;

        // Format the offset as GMT+X or GMT-X
        const formattedOffset = `GMT${offsetHours >= 0 ? '+' : ''}${offsetHours}`;
        return formattedOffset;
    }

    /**
     * On delete event
     */
    const handleDeleteEvent = () => {
        if (deleteId) {
            dispatch(deleteEvent(deleteId))
                .then(() => {
                    console.log('Evento eliminado exitosamente');
                    dispatch(getEvents(formattedFromDate, formattedToDate, getGMT()));
                })
                .catch(error => {
                    console.error('Error al eliminar el evento:', error);
                });
        }
        setDeleteModal(false);
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteEvent}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid={true}>
                    {/* Render Breadcrumb */}
                    <Row>
                        <Col className="col-12">
                            <Row>
                                <Col className="col-xl-12">
                                    <Card>
                                        <CardBody>
                                            <FullCalendar
                                                plugins={[
                                                    BootstrapTheme,
                                                    dayGridPlugin,
                                                    listPlugin,
                                                    interactionPlugin,
                                                ]}
                                                initialView="dayGridMonth"
                                                slotDuration={"00:15:00"}
                                                handleWindowResize={true}
                                                themeSystem="bootstrap"
                                                headerToolbar={{
                                                    left: "prev,next today",
                                                    center: "title",
                                                    right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                                                }}
                                                events={events}
                                                editable={true}
                                                droppable={false}
                                                selectable={true}
                                                dateClick={handleDateClick}
                                                eventClick={handleEventClick}
                                                datesSet={handleDatesSet}
                                                eventTimeFormat={{
                                                    hour: 'numeric',
                                                    minute: '2-digit',
                                                    meridiem: 'short',
                                                }}
                                                eventStartEditable={false}
                                                eventDurationEditable={true}
                                                locales={[esLocale]}
                                                locale="es"
                                            />
                                        </CardBody>
                                    </Card>

                                    {/* New/Edit event modal */}
                                    <Modal
                                        isOpen={modalCategory}
                                        className={props.className}
                                        centered
                                    >
                                        <ModalHeader toggle={toggle}>
                                            <span className="modal-title" id="modal-title">
                                                {!!isEdit ? `Detalle de la consulta de ${event.title}` : "Agendar Consulta"}
                                            </span>
                                        </ModalHeader>
                                        <ModalBody className="p-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    categoryValidation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <Row>
                                                    <Col className="col-12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="date">Fecha</Label>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                id="orderdate"
                                                                name="date"
                                                                placeholder="Select date"
                                                                options={{
                                                                    mode: "single",
                                                                    dateFormat: 'Y-m-d',
                                                                }}
                                                                value={endDate}
                                                                onChange={endDateChange}
                                                                disabled={isEdit}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-2">
                                                    <Col className="col-6">
                                                        {isEdit &&
                                                            <button type="button" className="btn btn-danger"
                                                                    id="btn-delete-event" onClick={() => {
                                                                toggle();
                                                                setDeleteModal(true)
                                                            }}>Eliminar</button>
                                                        }
                                                    </Col>

                                                    <Col className="col-6 text-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light me-1"
                                                            onClick={toggle}
                                                        >
                                                            Cancelar
                                                        </button>
                                                        {isEdit ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                onClick={() => navigate(`/admin/patient/consultation/${event.id}`)}
                                                            >
                                                                Detalle
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="submit"
                                                                className="btn btn-success"
                                                                id="btn-save-event"
                                                                onClick={() => navigate(`/admin/new-appointment?date=${endDate}`)}
                                                            >
                                                                Agendar
                                                            </button>
                                                        )}
                                                    </Col>
                                                </Row>

                                            </Form>
                                        </ModalBody>
                                    </Modal>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        loading ? <Spinners/>
                            :
                            <div className="d-flex flex-wrap justify-content-end gap-2">
                                <div className="position-fixed top-0 end-0 p-3"
                                     style={{zIndex: "1005"}}>
                                    <Toast isOpen={toast}>
                                        <ToastHeader toggle={closeToast}>
                                            {toastTitle}
                                        </ToastHeader>
                                        <ToastBody>
                                            {toastMessage}
                                        </ToastBody>
                                    </Toast>
                                </div>
                            </div>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};


export default AdminAgenda;
import React, {useState} from "react"
import { Link, useLocation } from "react-router-dom";

import {
    Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody, Table
} from "reactstrap";
import * as yup from "yup";
import {useFormik} from "formik";
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import {cleanErrors, getAdminConsultationThunk} from "../../slices/consultation/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {useParams} from "react-router-dom";
import {Consultation, Doctor} from "../../entities/types";
import {isEmpty} from "lodash";
import {index} from "d3-array";
import {getAdminPatientThunk} from "../../slices/admin/thunk";

interface selectState {
    consultation: {
        loading: false;
        consultation: Consultation;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminConsultationDetail = (props: any) => {
    const dispatch: any = useDispatch();
    const {id} = useParams();
    const location = useLocation();

    //meta title
    document.title = "Consulta";

    const selectProperties = createSelector(
        (state: selectState) => state.consultation,
        (item) => ({
            loading: item.loading,
            consultation: item.consultation,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, consultation, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);


    React.useEffect(() => {
        if (id) { // Agrega una condición para asegurarte de que 'id' esté definido
            dispatch(getAdminConsultationThunk(id));
        }
    }, [id]); // Solo dispara la acción cuando 'id' cambie

    const isActiveTab = (tabPath) => {
        return location.pathname === tabPath;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            {loading ? (
                                <Spinners/>
                            ) : (
                                <Card>

                                    <div className="tabs">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <Link to={`/admin/patients/${consultation.patient ? consultation.patient.id : ''}`}
                                                      className={`nav-link ${isActiveTab(`/admin/patients/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Perfil
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={`/admin/patients/medical-history/${consultation.patient ? consultation.patient.id : ''}`}
                                                      className={`nav-link ${isActiveTab(`/admin/patients/medical-history/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Antecedentes
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={`/admin/patient/consultations/${consultation.patient ? consultation.patient.id : ''}`}
                                                      className={`nav-link ${isActiveTab(`/admin/patient/consultations/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Consultas
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={`/admin/patient/files/${consultation.patient ? consultation.patient.id : ''}`}
                                                      className={`nav-link ${isActiveTab(`/admin/patient/files/${consultation.patient ? consultation.patient.id : ''}`) ? "active" : ""}`}
                                                >
                                                    Laboratorio y gabinete
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <CardBody className="d-flex justify-content-between align-items-center">
                                        <CardTitle className="mb-0">
                                            Detalle de la consulta <br/>
                                            Paciente: &nbsp;
                                            <span className={`fw-normal fs-4`}>
                                            {consultation.patient ?
                                                (`${consultation.patient.first_name} ${consultation.patient.last_name}`) : ""
                                            }<br/>
                                            </span>Fecha: &nbsp;
                                            <span className={`fw-normal`}>
                                            {consultation.patient ?
                                                (`${new Date(consultation.date).toLocaleString('es-ES', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true
                                                })}`) : ""
                                            }<br/>
                                            </span>
                                            Consultorio: &nbsp;
                                            <span className={`fw-normal`}>
                                            {consultation.office ?
                                                (`${consultation.office ? consultation.office.name : '-'}`) : ""
                                            }
                                            </span>
                                        </CardTitle>

                                        <CardTitle>
                                            <div style={{marginBottom: 10}}>
                                                <Link to={`/admin/patient/consultation/print-history/${id}`}
                                                      className="text-decoration-none" style={{color: 'black'}}>
                                                    <span className="me-2" style={{fontSize: '13px'}}>
                                                        <i className="fas fa-print"></i> Imprimir
                                                    </span>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to={`/admin/patients/consultation/edit/${id}`}
                                                      className="btn btn-primary">Editar</Link>
                                            </div>
                                        </CardTitle>
                                    </CardBody>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Estatus:</th>
                                                    <td>{consultation.status === 'pending' ? 'Sin confirmar' :
                                                        consultation.status === 'confirmed' ? 'Confirmado' :
                                                            consultation.status === 'finished' ? 'Terminado' :
                                                                '-'}</td>
                                                </tr>
                                                {
                                                    (consultation.vital_sign_notes ||
                                                        (consultation.systolic_blood_pressure && consultation.systolic_blood_pressure !== 0) ||
                                                        (consultation.heart_rate && consultation.heart_rate !== 0) ||
                                                        (consultation.blood_oxygen_saturation && consultation.blood_oxygen_saturation !== 0) ||
                                                        (consultation.temperature && consultation.temperature !== 0) ||
                                                        (consultation.respiratory_rate && consultation.respiratory_rate !== 0) ||
                                                        (consultation.glucose && consultation.glucose !== 0) ||
                                                        (consultation.height && consultation.height !== 0) ||
                                                        (consultation.weight && consultation.weight !== 0) ||
                                                        (consultation.body_mass_index && consultation.body_mass_index !== 0) ||
                                                        consultation.menstrual_cycle_date) &&
                                                    <tr>
                                                        <th scope="row" style={{width: '200px'}}>Signos vitales:</th>
                                                        <td>
                                                            {consultation.vital_sign_notes && consultation.vital_sign_notes !== "" && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Observaciones:</span>{" "}
                                                                    {`${consultation.vital_sign_notes}`}
                                                                </div>
                                                            )}
                                                            {(consultation.systolic_blood_pressure !== null && consultation.systolic_blood_pressure !== 0 ||
                                                                consultation.diastolic_blood_pressure !== null && consultation.diastolic_blood_pressure !== 0) && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Presión arterial (PA):</span>{" "}
                                                                    {`${consultation.systolic_blood_pressure} / ${consultation.diastolic_blood_pressure !== null && consultation.diastolic_blood_pressure !== 0
                                                                        ? ` ${consultation.diastolic_blood_pressure}`
                                                                        : " -"}`}
                                                                    {" mmHg"}
                                                                </div>
                                                            )}
                                                            {consultation.heart_rate !== null && consultation.heart_rate !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Frecuencia cardiaca (FC):</span>{" "}
                                                                    {`${consultation.heart_rate} lpm`}
                                                                </div>
                                                            )}
                                                            {consultation.blood_oxygen_saturation !== null && consultation.blood_oxygen_saturation !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Saturación de oxígeno (SpO2):</span>{" "}
                                                                    {`${consultation.blood_oxygen_saturation} %`}
                                                                </div>
                                                            )}
                                                            {consultation.temperature !== null && consultation.temperature !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Temperatura:</span>{" "}
                                                                    {`${consultation.temperature} ºC`}
                                                                </div>
                                                            )}
                                                            {consultation.respiratory_rate !== null && consultation.respiratory_rate !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Frecuencia respiratoria:</span>{" "}
                                                                    {`${consultation.respiratory_rate} rpm`}
                                                                </div>
                                                            )}
                                                            {consultation.glucose !== null && consultation.glucose !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Glucosa:</span>{" "}
                                                                    {`${consultation.glucose} mg/dl`}
                                                                </div>
                                                            )}
                                                            {consultation.height !== null && consultation.height !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Altura:</span>{" "}
                                                                    {`${consultation.height} cm`}
                                                                </div>
                                                            )}
                                                            {consultation.weight !== null && consultation.weight !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Peso:</span>{" "}
                                                                    {`${consultation.weight} Kg`}
                                                                </div>
                                                            )}
                                                            {consultation.body_mass_index !== null && consultation.body_mass_index !== 0 && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Índice de masa corporal (IMC):</span>{" "}
                                                                    {`${consultation.body_mass_index}`}
                                                                </div>
                                                            )}
                                                            {consultation.menstrual_cycle_date !== null && consultation.menstrual_cycle_date !== "" && (
                                                                <div style={{marginBottom: '10px'}}>
                                                                    <span>Fecha último ciclo menstrual:</span>{" "}
                                                                    {`${consultation.menstrual_cycle_date}`}
                                                                </div>
                                                            )}
                                                            {!consultation.diastolic_blood_pressure &&
                                                                !consultation.systolic_blood_pressure &&
                                                                !consultation.heart_rate &&
                                                                !consultation.blood_oxygen_saturation &&
                                                                !consultation.temperature &&
                                                                !consultation.respiratory_rate &&
                                                                !consultation.glucose &&
                                                                !consultation.height &&
                                                                !consultation.weight &&
                                                                !consultation.body_mass_index &&
                                                                !consultation.menstrual_cycle_date &&
                                                                !consultation.vital_sign_notes && (
                                                                    <span>Sin registro</span>
                                                                )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_reason &&
                                                    <tr>
                                                        <th scope="row" style={{width: '200px'}}>Motivo de consulta:
                                                        </th>
                                                        <td>{consultation.consultation_reason || 'Sin registro'}</td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.questions && consultation.consultation_info.questions.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Preguntas personalizadas:</th>
                                                        <td>
                                                            {consultation.consultation_info?.questions && consultation.consultation_info.questions.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {consultation.consultation_info.questions.map((question, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>{question.question ? question.question : "Sin registro"}</span><br/>
                                                                                <span>{question.answer ? question.answer : "Sin registro"}</span><br/>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.systems && consultation.consultation_info.systems.some(system => system.system || system.observations || system.normal !== null) &&
                                                    <tr>
                                                        <th scope="row">Interrogatorio por aparatos y sistemas:</th>
                                                        <td>
                                                            {consultation.consultation_info?.systems && consultation.consultation_info.systems.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {consultation.consultation_info.systems.map((system, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>Aparato o sistema: {system.system ? system.system : "Sin registro"}</span><br/>
                                                                                <span>{system.normal !== null ? (system.normal ? "Normal" : "Anormal") : "Sin registro"}</span><br/>
                                                                                {system.observations && (
                                                                                    <div>
                                                                                        <span>Observaciones: {system.observations ? system.observations : "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.examinations && consultation.consultation_info.examinations.some(examination => examination.segment || examination.observations || examination.normal !== null) &&
                                                    <tr>
                                                        <th scope="row">Exploración por aparatos y sistemas:</th>
                                                        <td>
                                                            {consultation.consultation_info?.examinations && consultation.consultation_info.examinations.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {consultation.consultation_info.examinations.map((examination, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>Aparato o sistema: {examination.segment ? examination.segment : "Sin registro"}</span><br/>
                                                                                <span>{examination.normal !== null ? (examination.normal ? "Normal" : "Anormal") : "Sin registro"}</span><br/>
                                                                                {examination.observations && (
                                                                                    <div>
                                                                                        <span>Observaciones: {examination.observations ? examination.observations : "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.laboratory_results && consultation.consultation_info.laboratory_results.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Laboratorios:</th>
                                                        <td>
                                                            {consultation.consultation_info?.laboratory_results && consultation.consultation_info.laboratory_results.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {consultation.consultation_info.laboratory_results.map((result, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>Tipo de laboratorio: {result.laboratory ? result.laboratory : "Sin registro"}</span><br/>
                                                                                {result.result && (
                                                                                    <div>
                                                                                        <span>Resultado: {result.result ? result.result : "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.study_results && consultation.consultation_info.study_results.length > 0 &&
                                                    <tr>
                                                        <th scope="row">Estudios de gabinete:</th>
                                                        <td>
                                                            {consultation.consultation_info?.study_results && consultation.consultation_info.study_results.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {consultation.consultation_info.study_results.map((result, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>Tipo de estudio: {result.study ? result.study : "Sin registro"}</span><br/>
                                                                                {result.result && (
                                                                                    <div>
                                                                                        <span>Resultado: {result.result ? result.result : "Sin registro"}</span><br/>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.extra_notes &&
                                                    <tr>
                                                        <th scope="row" style={{width: '200px'}}>Observaciones
                                                            adicionales
                                                            de la consulta:
                                                        </th>
                                                        <td>{consultation.extra_notes || 'Sin registro'}</td>
                                                    </tr>
                                                }
                                                {
                                                    (consultation.diagnosis || (consultation.consultation_info?.diagnoses && consultation.consultation_info.diagnoses.length > 0) || consultation.diagnosis_observations) &&
                                                    <tr>
                                                        <th scope="row">Diagnóstico:</th>
                                                        <td>
                                                            {consultation.diagnosis || consultation.consultation_info?.diagnoses?.length > 0 || consultation.diagnosis_observations ? (
                                                                <>
                                                                    {consultation.diagnosis && (
                                                                        <div style={{ marginBottom: '10px' }}>
                                                                            Impresión diagnóstica: {consultation.diagnosis}
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        (consultation.consultation_info?.diagnoses && consultation.consultation_info.diagnoses.length > 0) ? (
                                                                            <div>
                                                                                <div style={{
                                                                                    marginBottom: '10px'
                                                                                }}>Diagnóstico diferencial:
                                                                                </div>
                                                                                {consultation.consultation_info?.diagnoses && consultation.consultation_info.diagnoses.length > 0 ? (
                                                                                    <div style={{marginBottom: '20px'}}>
                                                                                        {consultation.consultation_info.diagnoses.map((diagnosis, index) => (
                                                                                            <div key={index} style={{
                                                                                                marginBottom: '10px',
                                                                                                display: 'flex'
                                                                                            }}>
                                                                                                {diagnosis.diagnosis ? (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            marginRight: '10px',
                                                                                                            marginLeft: '30px'
                                                                                                        }}>{index + 1}.</div>
                                                                                                ) : null}
                                                                                                <div>
                                                                                                    <span>{diagnosis.diagnosis ? diagnosis.diagnosis : "Sin registro de diagnósticos"}</span><br/>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                ) : (
                                                                                    <span>Sin registro de diagnósticos</span>
                                                                                )}
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                    {consultation.diagnosis_observations && (
                                                                        <div style={{ marginBottom: '10px' }}>
                                                                            Observaciones: {consultation.diagnosis_observations || 'Sin registro'}
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.consultation_info?.prescriptions && consultation.consultation_info.prescriptions.some(prescription => prescription.prescriptions && prescription.prescriptions.length > 0 || prescription.indications) &&
                                                    <tr>
                                                        <th scope="row">Recetas médicas:</th>
                                                        <td>
                                                            <div>
                                                                {consultation.consultation_info?.prescriptions && consultation.consultation_info.prescriptions.length > 0 ? (
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {consultation.consultation_info.prescriptions.map((prescription, index) => (
                                                                            <div key={index} style={{
                                                                                marginBottom: '30px'
                                                                            }}>
                                                                                <Row>
                                                                                    <Col xs={6}>
                                                                                        <b>Receta</b> {consultation.consultation_info.prescriptions.length > 1 ? (
                                                                                        <span># {index + 1}</span>
                                                                                    ) : null}
                                                                                    </Col>
                                                                                    <Col xs={6} className="text-end">
                                                                                        <Link
                                                                                            to={`/admin/patient/consultation/print-recipe/${id}/${index}`}
                                                                                            className="btn btn-success  me-2"
                                                                                        >
                                                                                            <i className="fa fa-print"/>
                                                                                            &nbsp; Imprimir
                                                                                            Receta</Link>
                                                                                    </Col>
                                                                                </Row>

                                                                                <Row>
                                                                                    <Col xs={12}>
                                                                                        {prescription.date && (
                                                                                            <div style={{marginBottom: '10px'}}>
                                                                                                <td>Fecha: {prescription.date ? new Date(prescription.date).toLocaleDateString('es-ES', {timeZone: 'UTC'}) : "Sin registro"}</td>
                                                                                            </div>
                                                                                        )}
                                                                                        {prescription.prescriptions && prescription.prescriptions.length > 0 ? (
                                                                                            prescription.prescriptions.map((presc, prescIndex) => (
                                                                                                <div key={prescIndex}
                                                                                                     style={{
                                                                                                         marginBottom: '10px'
                                                                                                     }}>
                                                                                                    Medicamento: {presc.medication || 'Sin registro'}<br/>
                                                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                                    Indicaciones: {presc.instruction || 'Sin registro'}<br/>
                                                                                                </div>
                                                                                            ))
                                                                                        ) : (
                                                                                            <div>
                                                                                                <span>Sin detalles de medicamentos</span><br/>
                                                                                            </div>
                                                                                        )}
                                                                                        {prescription.indications && (
                                                                                            <div style={{ marginBottom: '10px' }}>
                                                                                                Notas adicionales:: {prescription.indications || 'Sin registro'}
                                                                                            </div>
                                                                                        )}
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ) : (
                                                                    <span>Sin registro</span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    consultation.monitoring &&
                                                    <tr>
                                                        <th scope="row" style={{width: '200px'}}>Evolución clínica:</th>
                                                        <td style={{ whiteSpace: 'pre-wrap' }}>{consultation.monitoring || 'Sin registro'}</td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminConsultationDetail)
import React, {useEffect, useState} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom";

import { Card, CardBody, CardTitle, Col, Container, Row, Table } from "reactstrap";
import {cleanErrors, getAdminPatientThunk, patientCreateThunk} from "../../slices/admin/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {useParams} from "react-router-dom";
import {ConsultingOffice, Patient} from "../../entities/types";
import {addNewEvent, getAdminConsultingOfficesThunk, searchPatients} from "../../slices/calendar/thunk";
import AdminTabs from "Layouts/HorizontalLayout/Tabs";

interface selectState {
    admin: {
        loading: false;
        adminPatient: Patient;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
    calendar: {
        loading: false;
        patients: Patient[];
        consultingOfficeList: ConsultingOffice[];
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};


const AdminPatientDetail = (props: any) => {
    const dispatch: any = useDispatch();
    const {id} = useParams();
    const location = useLocation();
    const [time, setTime] = useState(new Date());

    //meta title
    document.title = "Paciente";

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (state: selectState) => state.calendar,
        (item, calendar) => ({
            loading: item.loading,
            adminPatient: item.adminPatient,
            reducerToastTitle: item.reducerToastTitle,
            consultingOfficeList: calendar.consultingOfficeList,
            patients: calendar.patients,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, adminPatient, patients, consultingOfficeList, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);


    React.useEffect(() => {
        if (id) {
            dispatch(getAdminPatientThunk(id));
        }
    }, [id]);

    useEffect(() => {
        dispatch(getAdminConsultingOfficesThunk());
    }, [dispatch]);

    const isActiveTab = (tabPath: string) => {
        return location.pathname === tabPath;
    };

    const updateTime = () => {
        const now = new Date();
        setTime(now);
    };

    useEffect(() => {
        updateTime();

        const intervalId = setInterval(updateTime, 60000);

        return () => clearInterval(intervalId);
    }, []);

    const handleSingleOfficeRedirect = (office) => {
        if (id) {
            const patientId = parseInt(id, 10);
            const newEvent = {
                consulting_office_id: office.id,
                patient_id: patientId,
                event_date: time.toISOString(),
                duration: 30,
                force: true
            };
            dispatch(addNewEvent(newEvent, props.router.navigate));
        } else {
            console.error("Error: ID del paciente no proporcionado.");
        }
    };

    React.useEffect(() => {
        dispatch(getAdminConsultingOfficesThunk());
    }, [dispatch]);

    useEffect(() => {
        if (consultingOfficeList.length === 1 && modalOpen) {
            handleSingleOfficeRedirect(consultingOfficeList[0]);
        }
    }, [consultingOfficeList, modalOpen]);


    const calculateAge = (birthday: string | Date): number => {
        const birthDate = typeof birthday === 'string' ? new Date(birthday) : birthday;

        if (!(birthDate instanceof Date) || isNaN(birthDate.getTime())) {
            throw new Error('Invalid date');
        }

        const now = new Date();
        let age = now.getFullYear() - birthDate.getFullYear();
        const monthDifference = now.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            {loading ? (
                                <Spinners/>
                            ) : (
                                <Card>
                                    <AdminTabs id={id as string} isActiveTab={isActiveTab} />
                                    <CardBody className="d-flex justify-content-between align-items-center">
                                        <CardTitle className="mb-0">Información personal</CardTitle>
                                        <div className="d-flex">
                                            <Link
                                                to={`/admin/patients/edit/${id}`}
                                                className="btn btn-primary"
                                                style={{ marginLeft: '10px' }}
                                            >
                                                Editar
                                            </Link>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                <tr>
                                                    <th scope="row" style={{width: '200px'}}>Nombre:</th>
                                                    <td>{adminPatient.first_name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Apellidos:</th>
                                                    <td>{adminPatient.last_name}</td>
                                                </tr>
                                                {
                                                    adminPatient.email &&
                                                    <tr>
                                                        <th scope="row">Email:</th>
                                                        <td>{adminPatient.email}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.birthday &&
                                                    <tr>
                                                        <th scope="row">Fecha de nacimiento:</th>
                                                        <td>{adminPatient.birthday ? new Date(adminPatient.birthday).toLocaleDateString('es-ES', {timeZone: 'UTC'}) : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {adminPatient.birthday && (
                                                    <tr>
                                                        <th scope="row">Edad:</th>
                                                        <td>{calculateAge(adminPatient.birthday)} años</td>
                                                    </tr>
                                                )}
                                                {
                                                    adminPatient.gender &&
                                                    <tr>
                                                        <th scope="row">Género:</th>
                                                        <td>
                                                            {adminPatient.gender === 'male' ? 'Masculino' :
                                                                adminPatient.gender === 'fem' ? 'Femenino' :
                                                                    'Sin registro'}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.birth_place &&
                                                    <tr>
                                                        <th scope="row">Lugar de nacimiento:</th>
                                                        <td>{adminPatient.birth_place ? adminPatient.birth_place : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.ethnic_group &&
                                                    <tr>
                                                        <th scope="row">Grupo étnico:</th>
                                                        <td>
                                                            {adminPatient.ethnic_group === 'afr' ? 'Afrodescenciente (Africano)' :
                                                                adminPatient.ethnic_group === 'ame' ? 'Amerindio (Indígena)' :
                                                                    adminPatient.ethnic_group === 'arb' ? 'Arabigo (Árabe)' :
                                                                        adminPatient.ethnic_group === 'cau' ? 'Caucásico (Blanco)' :
                                                                            adminPatient.ethnic_group === 'lat' ? 'Latino (Latinoamericano)' :
                                                                                adminPatient.ethnic_group === 'mzo' ? 'Mestizo' :
                                                                                    adminPatient.ethnic_group === 'mto' ? 'Mulato' :
                                                                                        adminPatient.ethnic_group === 'otal' ? 'Oriental (Asiático)' :
                                                                                            adminPatient.ethnic_group === 'other' ? 'Otro' :
                                                                                                'Sin registro'}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.marital_status &&
                                                    <tr>
                                                        <th scope="row">Estado civil:</th>
                                                        <td>
                                                            {adminPatient.marital_status === 'sgl' ? 'Soltero(a)' :
                                                                adminPatient.marital_status === 'mry' ? 'Casado(a)' :
                                                                    adminPatient.marital_status === 'wdw' ? 'Viudo(a)' :
                                                                        adminPatient.marital_status === 'sep' ? 'Separado(a)' :
                                                                            adminPatient.marital_status === 'div' ? 'Divorciado(a)' :
                                                                                adminPatient.marital_status === 'prt' ? 'Concubino(a)' :
                                                                                    adminPatient.marital_status === 'other' ? 'Otro' :
                                                                                        'Sin registro'}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.blood_type &&
                                                    <tr>
                                                        <th scope="row">Tipo de sangre:</th>
                                                        <td>
                                                            {adminPatient.blood_type === 'o+' ? 'O+' :
                                                                adminPatient.blood_type === 'o-' ? 'O-' :
                                                                    adminPatient.blood_type === 'a+' ? 'A+' :
                                                                        adminPatient.blood_type === 'a-' ? 'A-' :
                                                                            adminPatient.blood_type === 'b+' ? 'B+' :
                                                                                adminPatient.blood_type === 'b-' ? 'B-' :
                                                                                    adminPatient.blood_type === 'ab+' ? 'AB+' :
                                                                                        adminPatient.blood_type === 'ab-' ? 'AB-' :
                                                                                            adminPatient.blood_type === 'unkn' ? 'Desconocido' :
                                                                                                'Sin registro'}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.state &&
                                                    <tr>
                                                        <th scope="row">Estado de residencia:</th>
                                                        <td>{adminPatient.state ? adminPatient.state : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.city &&
                                                    <tr>
                                                        <th scope="row">Ciudad de residencia:</th>
                                                        <td>{adminPatient.city ? adminPatient.city : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.address &&
                                                    <tr>
                                                        <th scope="row">Domicilio:</th>
                                                        <td>{adminPatient.address ? adminPatient.address : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.occupation &&
                                                    <tr>
                                                        <th scope="row">Ocupación:</th>
                                                        <td>{adminPatient.occupation ? adminPatient.occupation : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.socioeconomic_status &&
                                                    <tr>
                                                        <th scope="row">Nivel Socioeconómico:</th>
                                                        <td>{adminPatient.socioeconomic_status ? adminPatient.socioeconomic_status : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.housing &&
                                                    <tr>
                                                        <th scope="row">Vivienda:</th>
                                                        <td>{adminPatient.housing ? adminPatient.housing : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.disability &&
                                                    <tr>
                                                        <th scope="row">Discapacidad:</th>
                                                        <td>{adminPatient.disability ? adminPatient.disability : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.religion &&
                                                    <tr>
                                                        <th scope="row">Religión:</th>
                                                        <td>{adminPatient.religion ? adminPatient.religion : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    adminPatient.private_note &&
                                                    <tr>
                                                        <th scope="row">Notas privadas:</th>
                                                        <td>{adminPatient.private_note ? adminPatient.private_note : "Sin registro"}</td>
                                                    </tr>
                                                }
                                                {
                                                    (adminPatient.general_info?.phones && adminPatient.general_info.phones.length > 0) &&
                                                    <tr>
                                                        <th scope="row">Teléfonos</th>
                                                        <td>
                                                            {adminPatient.general_info?.phones && adminPatient.general_info.phones.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {adminPatient.general_info.phones.map((phone, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>{phone.phone_type ? `${phone.phone_type}: ` : "Sin registro :"}</span>
                                                                                <span>{phone.phone ? phone.phone : "Sin registro"}</span><br/>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    (adminPatient.general_info?.identifications && adminPatient.general_info.identifications.length > 0) &&
                                                    <tr>
                                                        <th scope="row">Identificaciones</th>
                                                        <td>
                                                            {adminPatient.general_info?.identifications && adminPatient.general_info.identifications.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {adminPatient.general_info.identifications.map((identification, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>{identification.identification_type ? `${identification.identification_type}: ` : "Sin registro :"}</span>
                                                                                <span>{identification.identification ? identification.identification : "Sin registro"}</span><br/>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    (adminPatient.general_info?.insurance_or_plans && adminPatient.general_info.insurance_or_plans.length > 0) &&
                                                    <tr>
                                                        <th scope="row">Seguros y planes médicos</th>
                                                        <td>
                                                            {adminPatient.general_info?.insurance_or_plans && adminPatient.general_info.insurance_or_plans.length > 0 ? (
                                                                <div style={{marginBottom: '20px'}}>
                                                                    {adminPatient.general_info.insurance_or_plans.map((insurance, index) => (
                                                                        <div key={index} style={{
                                                                            marginBottom: '10px',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div
                                                                                style={{marginRight: '10px'}}>{index + 1}.
                                                                            </div>
                                                                            <div>
                                                                                <span>Aseguradora o plan médico: {insurance.name ? insurance.name : "Sin registro :"}</span><br/>
                                                                                <span>Póliza o número de afiliado: {insurance.policy ? insurance.policy : "Sin registro"}</span><br/>
                                                                                {insurance.note && (
                                                                                    <div>
                                                                                        <span>Nota: {insurance.note ? insurance.note : "Sin registro"}</span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <span>Sin registro</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                }
                                                {
                                                    (adminPatient.general_info?.emergency_contacts && adminPatient.general_info.emergency_contacts.length > 0) &&
                                                    <tr>
                                                        <th scope="row">Contactos de emergencia</th>
                                                        <td>
                                                            <div>
                                                                {adminPatient.general_info?.emergency_contacts && adminPatient.general_info.emergency_contacts.length > 0 ? (
                                                                    <div style={{marginBottom: '10px'}}>
                                                                        {adminPatient.general_info.emergency_contacts.map((contact, index) => (
                                                                            <div key={index} style={{
                                                                                marginBottom: '30px',
                                                                                marginLeft: '30px',
                                                                                display: 'flex'
                                                                            }}>
                                                                                <div
                                                                                    style={{marginRight: '10px'}}>{index + 1}.
                                                                                </div>
                                                                                <div>
                                                                                    <span style={{marginTop: '10px'}}>Nombre completo: {contact.full_name ? contact.full_name : "Sin registro"}</span><br/>
                                                                                    {contact.relationship && (
                                                                                        <div>
                                                                                            <span>Parentesco: {contact.relationship}</span>
                                                                                        </div>
                                                                                    )}
                                                                                    {contact.email && (
                                                                                        <div>
                                                                                            <span style={{marginTop: '10px'}}>Email: {contact.email ? contact.email : "Sin registro"}</span>
                                                                                        </div>
                                                                                    )}
                                                                                    {contact.note && (
                                                                                        <div>
                                                                                            <span style={{marginTop: '10px'}}>Notas: {contact.note ? contact.note : "Sin registro"}</span>
                                                                                        </div>
                                                                                    )}
                                                                                    {contact.phones && contact.phones.length > 0 && contact.phones.some(phone => phone.phone_type || phone.phone) && (
                                                                                        <div>
                                                                                            <span>Teléfonos:</span>
                                                                                            {contact.phones.map((phone, phoneIndex) => (
                                                                                                (phone.phone_type || phone.phone) && (
                                                                                                    <div key={phoneIndex} style={{ marginLeft: '20px', marginBottom: '10px' }}>
                                                                                                        <strong>{phoneIndex + 1}.&nbsp;&nbsp;&nbsp;</strong>
                                                                                                        {phone.phone_type && <span>{phone.phone_type}</span>}
                                                                                                        {phone.phone && <span>: {phone.phone}</span>}
                                                                                                    </div>
                                                                                                )
                                                                                            ))}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ) : (
                                                                    <span>Sin registro</span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminPatientDetail)

import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import {cleanErrors, getAdminPlace} from "../../slices/profile/thunk";
import {createSelector} from "reselect";
import {Doctor, PaymentIntent} from "../../entities/types";

const stripePromise = loadStripe('pk_live_51PSgdyLsEYzKQLXRPd2iDCufhvzEurg8BQnCbhas3ww6n3Jt1E4CZJ2kM15LCb7R0t29awbAnRH5ev6ENcsw8e6V001k6Y9fBo');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://farmabits.com/admin",
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      // Redirección manejada por Stripe
    }
  };

  return (
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button disabled={!stripe}>Submit</button>
      </form>
  )
};

interface selectState {
  doctor: {
    loading: false;
    adminProfile: Doctor;
    paymentIntent: PaymentIntent;
    reducerToastTitle: "";
    reducerToastMessage: "";
  };
};

const PagesPricing = () => {
  document.title = "Suscripción | Farmabits";

  const dispatch: any = useDispatch();

  const selectProperties = createSelector(
      (state: selectState) => state.doctor,
      (item) => ({
        loading: item.loading,
        adminProfile: item.adminProfile,
        paymentIntent: item.paymentIntent,
        reducerToastTitle: item.reducerToastTitle,
        reducerToastMessage: item.reducerToastMessage,
      })
  );

  const [clientSecret, setClientSecret] = useState('');
  const {loading, adminProfile, paymentIntent, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

  useEffect(() => {
    dispatch(getAdminPlace());
  }, [dispatch]);

  useEffect(() => {
    if (paymentIntent && paymentIntent.client_secret) {
      setClientSecret(paymentIntent.client_secret);
    }
  }, [paymentIntent]);

  if (loading || !clientSecret) {
    return <div>Cargando...</div>;
  }

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Row className="justify-content-center">
            <React.Fragment>
              <Col xs="12">
                <Card className="plan-box">
                  <CardBody className="p-4">
                    <Row className="justify-content-center">
                      <Col xs="6">

                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5>Anual</h5>
                            <p className="text-muted">Adquirir el servicio anual</p>
                          </div>
                          <div className="flex-shrink-0 ms-3">
                            <i
                                className={"bx bx-walk h1 text-primary"} style={{lineHeight: 1}}
                            />
                          </div>
                        </div>
                        <div className="py-4">
                          <h2>
                            <p className="mb-4">
                  <span className="text-muted me-2">
                              <del>$2,000 MXN</del>
                            </span>{" "}
                            </p>
                            $1,000/{" "}
                            <span className="font-size-13">Por un año</span>
                          </h2>
                        </div>
                        <div>
                          <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm/>
                          </Elements>
                        </div>
                      </Col>
                      <Col xs="6">

                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h2>
                            </h2>
                          </div>
                        </div>
                        <div className="py-4">
                          <h2>
                            Incluye
                          </h2>
                        </div>

                        <div className="plan-features mt-5">
                          <p>
                            <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                            Agenda
                          </p>
                          <p>
                            <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                            Pacientes
                          </p>
                          <p>
                            <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                            Consultorios
                          </p>
                          <p>
                            <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                            Asistentes
                          </p>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
              </Col>

            </React.Fragment>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default PagesPricing

import React, {useState} from 'react';
import {Col, Container, Row, Toast, ToastBody, ToastHeader} from 'reactstrap';
import PatientList from "../PatientsList/PatientList";
import {cleanErrors} from "../../slices/admin/thunk";
import {Patient} from "../../entities/types";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import logo from "../../assets/images/logo.png";
interface selectState {
    admin: {
        adminPatientList: Patient[];
        loading: true;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const Help = () => {

    document.title = "Ayuda";
    const dispatch = useDispatch<any>();

    return (
        <React.Fragment>
            <section className="section bg-white" id="news">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <img src={logo} alt="" width="200"/>
                                <h4 className="pt-3">¿Cómo podemos ayudarte?</h4>
                                <p className="pt-3">
                                    Para cualquier duda o aclaración, favor de ponerte en contacto con nosotros
                                    al correo electrónico informes@farmabits.com
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>

                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Help

import { Navigate } from "react-router-dom"
import AdminPatients from "../pages/Page_Admin_patients/adminPatientIndex";
import AdminConsultingOffices from "../pages/Page_Admin_consulting_offices/adminConsultingOfficeIndex";
import AdminAgenda from "../pages/Page_Admin_Agenda/adminAgendaIndex";
import AdminAssistants from "../pages/Page_Admin_assistants/adminAssistantIndex";
import AdminPatientConsultations from "../pages/Page_Admin_patients/adminPatientConsultationIndex";
import AdminPatientFiles from "../pages/Page_Admin_patients/adminPatientFileIndex";

// Auth
import Login from "../pages/Page_Authentication/login";
import ForgetPasswordPage from "../pages/Page_Authentication/ForgotPassword";
import RecoverPasswordPage from "../pages/Page_Authentication/RecoverPassword";
import Logout from "../pages/Page_Authentication/Logout";
import AdminPatientAdd from "../pages/Page_Admin_patients/adminPatientAdd";
import AdminPatientDetail from "../pages/Page_Admin_patients/adminPatientDetail";
import AdminConsultingOfficeAdd from "../pages/Page_Admin_consulting_offices/adminConsultingOfficeAdd";
import AdminPatientEdit from "../pages/Page_Admin_patients/adminPatientEdit";
import AdminPatientMedicalHistoryDetail from "../pages/Page_Admin_patients/adminPatientMedicalHistoryDetail";
import AdminPatientMedicalHistoryEdit from "../pages/Page_Admin_patients/adminPatientMedicalHistoryEdit";
import AdminAgendaAppointmentAdd from "../pages/Page_Admin_Agenda/adminAgendaAppointmentAdd";
import AdminProfileDetail from "../pages/Page_Admin_profile/adminProfileDetail";
import AdminConsultingOfficeEdit from "../pages/Page_Admin_consulting_offices/adminConsultingOfficeEdit";
import AdminAssistantEdit from "../pages/Page_Admin_assistants/adminAssistantEdit";
import AdminAssistantAdd from "../pages/Page_Admin_assistants/adminAssistantAdd";
import AdminConsultationDetail from "../pages/Page_Admin_patients/adminPatientConsultationDetail";
import AdminConsultationEdit from "../pages/Page_Admin_patients/adminPatientConsultationEdit";
import Register from "../pages/Page_Authentication/Register";
import Landing from "../pages/Page_Home";
import TermsAndConditions from "../pages/Page_Info/termsAndConditions";
import PrivacyPolicy from "../pages/Page_Info/privacyPolicy";
import Help from "../pages/Page_Info/help";
import PagesPricing from "../pages/Utility/pages-pricing";
import AdminProfileEdit from "../pages/Page_Admin_profile/adminProfileEdit";
import AdminProfileSignatureEdit from "../pages/Page_Admin_profile/adminProfileSignatureEdit";
import AdminConfigurationRecipe from "../pages/Page_Admin_profile/adminConfigurationRecipe";
import AdminConsultationPrintRecipe from "../pages/Page_Admin_patients/adminPatientConsultationPrintRecipe";
import AdminConsultationHistoryPrint from "../pages/Page_Admin_patients/adminPatientConsultationHistoryPrint";
import AdminHistoryPrint from "../pages/Page_Admin_patients/adminPatientMedicalHistoryPrint";
import AdminProfileLogoEdit from "../pages/Page_Admin_profile/adminProfileLogoEdit";
import AdminPatientFileAdd from "../pages/Page_Admin_patients/adminPatientFileAdd";

const authProtectedRoutes = [
  { path: "/admin", component: <AdminAgenda /> },
  { path: "/admin/new-appointment", component: <AdminAgendaAppointmentAdd /> },
  { path: "/admin/patients", component: <AdminPatients /> },
  { path: "/admin/patient/files/:id?", component: <AdminPatientFiles /> },
  { path: "/admin/patients/:id?", component: <AdminPatientDetail /> },
  { path: "/admin/patients/medical-history/:id?", component: <AdminPatientMedicalHistoryDetail /> },
  { path: "/admin/patient/consultation/:id?", component: <AdminConsultationDetail /> },
  { path: "/admin/patient/consultation/print-recipe/:id?/:recipe_id?", component: <AdminConsultationPrintRecipe /> },
  { path: "/admin/patient/consultation/print-history/:id?", component: <AdminConsultationHistoryPrint /> },
  { path: "/admin/patients/medical-history/print/:id?", component: <AdminHistoryPrint /> },
  { path: "/admin/patients/edit/:id?", component: <AdminPatientEdit /> },
  { path: "/admin/patients/medical-history/edit/:id?", component: <AdminPatientMedicalHistoryEdit /> },
  { path: "/admin/patients/consultation/edit/:id?", component: <AdminConsultationEdit /> },
  { path: "/admin/new-patient", component: <AdminPatientAdd /> },
  { path: "/admin/consulting-offices", component: <AdminConsultingOffices /> },
  { path: "/admin/patient/consultations/:id?", component: <AdminPatientConsultations /> },
  { path: "/admin/consulting-offices/:id?", component: <AdminConsultingOfficeEdit /> },
  { path: "/admin/new-consulting-office", component: <AdminConsultingOfficeAdd /> },
  { path: "/admin/assistants", component: <AdminAssistants /> },
  { path: "/admin/new-assistant", component: <AdminAssistantAdd /> },
  { path: "/admin/assistants/:id?", component: <AdminAssistantEdit /> },
  { path: "/admin/profile", component: <AdminProfileDetail /> },
  { path: "/admin/profile/edit", component: <AdminProfileEdit /> },
  { path: "/admin/profile/signature/edit", component: <AdminProfileSignatureEdit /> },
  { path: "/admin/profile/logo/edit", component: <AdminProfileLogoEdit /> },
  { path: "/admin/patients/file/add/:id?", component: <AdminPatientFileAdd /> },
  { path: "/admin/configuration/recipe", component: <AdminConfigurationRecipe /> },
  { path: "/admin/subscription", component: <PagesPricing /> },
  { path: "/admin/payments", component: <AdminPatients /> },

  { path: "/", exact: true, component: <Navigate to="/admin" /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/recover-password", component: <RecoverPasswordPage /> },
  { path: "/logout", component: <Logout /> },
  { path: "/register", component: <Register /> },
  { path: "/", component: <Landing /> },
  { path: "/help", component: <Help /> },
  { path: "/terms-and-conditions", component: <TermsAndConditions /> },
  { path: "/policy-privacy", component: <PrivacyPolicy /> },
]
export { authProtectedRoutes, publicRoutes };
              

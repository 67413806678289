import {
    apiError,
    cleanApiError,
    loadingTrue,
    adminConsultationSuccess,
    consultationEditSuccess,
    adminDeleteConsultationSuccess,
} from "./reducer";
import { APIClient } from "../../helpers/api_helper";
import {adminCreateOfficeSuccess, adminDeleteOfficeSuccess} from "../consulting_office/reducer";
import {getAdminConsultingOfficesThunk} from "../consulting_office/thunk";
import {adminPatientSuccess, patientEditSuccess} from "../admin/reducer";
import {
    Consultation,
    ConsultationDiagnosis,
    ConsultationExamination,
    ConsultationInfo,
    ConsultationLaboratory,
    ConsultationLaboratoryNumb,
    ConsultationLaboratoryResult,
    ConsultationPrescription,
    ConsultationPrescriptionNumb,
    ConsultationQuestion, ConsultationStudyResult, ConsultationSystem,
    ConsultingOffice,
    Doctor,
    Education,
    Info
} from "../../entities/types";

const api = new APIClient();
export const cleanErrors = () => {
    return cleanApiError();
}

const defaultInfo: ConsultationInfo = {
    questions: [],
    systems: [],
    examinations: [],
    diagnoses: [],
    prescriptions: [],
    laboratories: [],
    laboratory_results: [],
    study_results: [],
};

// Función de mapeo para convertir el objeto de la API en una instancia de Doctor
const mapToConsultation = (data: any): Consultation => {
    return {
        id: data.id,
        patient_id: data.patient_id,
        doctor_id: data.doctor_id,
        date: data.date,
        duration: data.duration,
        consulting_office_id: data.consulting_office_id,
        monitoring: data.monitoring,
        status: data.status,
        consultation_reason: data.consultation_reason,
        ailment: data.ailment,
        vital_sign_notes: data.vital_sign_notes,
        systolic_blood_pressure: data.systolic_blood_pressure,
        diastolic_blood_pressure: data.diastolic_blood_pressure,
        heart_rate: data.heart_rate,
        blood_oxygen_saturation: data.blood_oxygen_saturation,
        temperature: data.temperature,
        respiratory_rate: data.respiratory_rate,
        glucose: data.glucose,
        weight: data.weight,
        height: data.height,
        body_mass_index: data.body_mass_index,
        menstrual_cycle_date: data.menstrual_cycle_date,
        diagnosis: data.diagnosis,
        diagnosis_observations: data.diagnosis_observations,
        extra_notes: data.extra_notes,
        office: data.office,
        consultation_info: data.consultation_info || defaultInfo,
        patient: data.patient,
    };
};

const mapToDoctor = (data: any): Doctor => {
    return {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        specialty: data.specialty,
        email: data.email,
        password: data.password,
        gender: data.gender,
        academic_title: data.academic_title,
        professional_id_card: data.professional_id_card,
        signature: data.signature,
        logo: data.logo,
        info: data.info,
    }
};

export const getAdminConsultationThunk = (id: string | undefined) =>async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Get(`/doctor/consultation/${id}`, null);
        console.log("without error, response", response);
        console.log("without error, response.consultation", response.consultation);
        const consultation: Consultation = mapToConsultation(response.consultation);
        const doctor: Doctor = mapToDoctor(response.doctor);
        dispatch(adminConsultationSuccess({consultation, doctor}));
    } catch (error) {
        console.error("Error in getAdminConsultationThunk:", error);
        dispatch(apiError(error));
    }
}

export const consultationEditThunk = (consultations: any, history: any) => async (dispatch: any) => {
    console.log("in consultationEditThunk, consultations", consultations);
    dispatch(loadingTrue());
    try {
        let questions = consultations.questions.map((question: ConsultationQuestion) => {
            return {
                question: question.question,
                answer: question.answer,
            }
        });
        let systems = consultations.systems.map((system: ConsultationSystem) => {
            return {
                system: system.system,
                normal: system.normal,
                observations: system.observations,
            }
        });
        let examinations = consultations.examinations.map((examination: ConsultationExamination) => {
            return {
                segment: examination.segment,
                normal: examination.normal,
                observations: examination.observations,
            }
        });
        let diagnoses = consultations.diagnoses.map((diagnosis: ConsultationDiagnosis) => {
            return {
                diagnosis: diagnosis.diagnosis,
            }
        });
        let prescriptions = consultations.prescriptions.map((prescriptionNumb: ConsultationPrescriptionNumb) => {
            return {
                indications: prescriptionNumb.indications,
                date: prescriptionNumb.date,
                prescriptions: prescriptionNumb.prescriptions.map((prescription: ConsultationPrescription) => ({
                    medication: prescription.medication,
                    instruction: prescription.instruction,
                })),
            }
        })
        let laboratories = consultations.laboratories.map((labNumb: ConsultationLaboratoryNumb) => {
            return {
                laboratories: labNumb.laboratories.map((lab: ConsultationLaboratory) => ({
                    procedure: lab.procedure,
                })),
            }
        })
        let laboratory_results = consultations.laboratory_results.map((results: ConsultationLaboratoryResult) => {
            return {
                laboratory: results.laboratory,
                result: results.result,
            }
        });
        let study_results = consultations.study_results.map((results: ConsultationStudyResult) => {
            return {
                study: results.study,
                result: results.result,
            }
        });

        const sanitizeToInt = (val) => {
            if (val === "") {
                return null;
            }
            const myInt = parseInt(val, 10);
            return isNaN(myInt) ? null : myInt;
        };

        const sanitizeToFloat = (val) => {
            if (val === "") {
                return null;
            }
            const myFloat = parseFloat(val);
            return isNaN(myFloat) ? null : myFloat;
        };

        const response = await api.Patch(`/doctor/consultation/history`, {
            id: consultations.id,
            monitoring: consultations.monitoring,
            status: consultations.status,
            consultation_reason: consultations.consultation_reason,
            ailment: consultations.ailment,
            vital_sign_notes: consultations.vital_sign_notes,
            systolic_blood_pressure: sanitizeToInt(consultations.systolic_blood_pressure),
            diastolic_blood_pressure: sanitizeToInt(consultations.diastolic_blood_pressure),
            heart_rate: sanitizeToInt(consultations.heart_rate),
            blood_oxygen_saturation: sanitizeToInt(consultations.blood_oxygen_saturation),
            temperature: sanitizeToFloat(consultations.temperature),
            respiratory_rate: sanitizeToInt(consultations.respiratory_rate),
            glucose: sanitizeToInt(consultations.glucose),
            weight: sanitizeToFloat(consultations.weight),
            height: sanitizeToFloat(consultations.height),
            diagnosis: consultations.diagnosis,
            diagnosis_observations: consultations.diagnosis_observations,
            menstrual_cycle_date: consultations.menstrual_cycle_date,
            extra_notes: consultations.extra_notes,
            questions: questions,
            systems: systems,
            examinations: examinations,
            diagnoses: diagnoses,
            prescriptions: prescriptions,
            laboratories: laboratories,
            laboratory_results: laboratory_results,
            study_results: study_results,
        });

        console.log("without error, consultationEditThunk response", JSON.stringify(response));
        dispatch(consultationEditSuccess());
        history(`/admin/patient/consultation/${response.consultation.id}`);
    } catch (error) {
        console.error("Error in consultationEditThunk:", error);
        dispatch(apiError(error));
    }
}

export const deleteConsultation = (id: string) => async (dispatch: any) => {
    dispatch(loadingTrue());
    try {
        const response = await api.Delete(`/doctor/consultation/${id}`, null);
        dispatch(adminDeleteConsultationSuccess(response));
        dispatch(adminDeleteConsultationSuccess());
    } catch (error) {
        console.error("Error in deleteEvent:", error);
        dispatch(apiError(error));
    }
}
import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody } from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import {cleanErrors, assistantCreateThunk} from "../../slices/assistant/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {Link} from "react-router-dom";

interface selectState {
    assistant: {
        loading: false;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminAssistantAdd = (props: any) => {
    const dispatch: any = useDispatch();
    //meta title
    document.title = "Agregar Asistente";

    const selectProperties = createSelector(
        (state: selectState) => state.assistant,
        (item) => ({
            loading: item.loading,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage!=="") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    //Basic Information
    const formik: any = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
        },
        validationSchema: yup.object().shape({
            first_name: yup.string().required('El campo es requerido'),
            last_name: yup.string().required('El campo es requerido'),
            email: yup.string().required('El campo es requerido'),

        }),
        onSubmit: (values: any) => {
            console.log('Basic Information', values);
            dispatch(assistantCreateThunk(values, props.router.navigate));
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row >
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h4">Información del asistente
                                    </CardTitle>
                                    <p className="card-title-desc">Ingresa la información de tu asistente.</p>
                                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="first_name">Nombre</Label>
                                                    <Input
                                                        id="first_name"
                                                        name="first_name"
                                                        type="text"
                                                        placeholder="Nombre"
                                                        value={formik.values.first_name}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.first_name && formik.errors.first_name ? true : false
                                                        }
                                                    />
                                                    {formik.errors.first_name && formik.touched.first_name ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.first_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="last_name">Apellidos</Label>
                                                    <Input
                                                        id="last_name"
                                                        name="last_name"
                                                        type="text"
                                                        placeholder="Apellidos"
                                                        value={formik.values.last_name}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.last_name && formik.errors.last_name ? true : false
                                                        }
                                                    />
                                                    {formik.errors.last_name && formik.touched.last_name ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.last_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={"6"}>
                                                <div className="mb-3">
                                                    <Label htmlFor="email">Email</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        type="text"
                                                        placeholder="Email"
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.email && formik.errors.email ? true : false
                                                        }
                                                    />
                                                    {formik.errors.email && formik.touched.email ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        {
                                            loading ? <Spinners/>
                                                :
                                                <div className="d-flex flex-wrap justify-content-end gap-2">
                                                    <Link to={`/admin/assistants`}
                                                          className="btn btn-outline-danger">Cancelar</Link>
                                                    <Button type="submit" color="primary"> Guardar  </Button>
                                                    <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                                                        <Toast isOpen={toast}>
                                                            <ToastHeader toggle={closeToast}>
                                                                {toastTitle}
                                                            </ToastHeader>
                                                            <ToastBody>
                                                                {toastMessage}
                                                            </ToastBody>
                                                        </Toast>
                                                    </div>
                                                </div>
                                        }

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminAssistantAdd)
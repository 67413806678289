import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Consultation, Patient} from "../../entities/types";

const initialState = {
    adminPatientList: [] as Patient[],
    adminPatient: {} as Patient,
    patientConsultationList: [] as Consultation[],
    errorAdminPatientList: "",
    reducerToastMessage: "",
    reducerToastTitle: "",
    loading: false
};

const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        apiError(state, action) {
            state.reducerToastTitle = "Error";
            state.reducerToastMessage = action.payload;
            state.loading = false;
        },
        cleanApiError(state) {
            state.reducerToastTitle = "";
            state.reducerToastMessage = "";
        },
        loadingTrue(state) {
            state.loading = true;
        },
        adminPatientsSuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminPatientsSuccess loading false");
            state.adminPatientList = action.payload;
            state.loading = false;
        },
        adminPatientConsultationsSuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminPatientConsultationsSuccess loading false");
            state.patientConsultationList = action.payload;
            state.loading = false;
        },
        adminPatientMedicalHistorySuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminPatientMedicalHistorySuccess loading false");
            state.adminPatient = action.payload;
            state.loading = false;
        },
        adminSearchPatientsSuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminSearchPatientsSuccess loading false");
            state.adminPatientList = action.payload;
            state.loading = false;
        },
        adminPatientSuccess(state, action: PayloadAction<any>) {
            console.log("reducers adminPatientSuccess loading false");
            state.adminPatient = action.payload;
            state.loading = false;
        },
        adminPatientFilesSuccess(state, action: PayloadAction<Patient>) {
            console.log("reducers adminPatientFilesSuccess loading false");
            state.adminPatient = action.payload; // Mantén esta línea si aún necesitas actualizar este estado.
            state.adminPatientList = [action.payload]; // Asumiendo que quieres reemplazar la lista con el paciente recién obtenido.
            state.loading = false;
        },
        patientCreateSuccess(state) {
            console.log("reducers patientCreateSuccess loading false");
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Paciente creado";
            state.loading = false;
        },
        patientEditSuccess(state) {
            console.log("reducers patientEditSuccess loading false");
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Paciente editado";
            state.loading = false;
        },
        patientMedicalHistoryEditSuccess(state) {
            console.log("reducers patientMedicalHistoryEditSuccess loading false");
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Historial médico editado";
            state.loading = false;
        },
        adminDeletePatientSuccess(state) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Paciente eliminado";
            state.loading = false;
        },
        adminDeletePatientFileSuccess(state) {
            state.reducerToastTitle = "Respuesta exitosa";
            state.reducerToastMessage = "Archivo eliminado";
            state.loading = false;
        },
    },
});
export const {
    apiError,
    cleanApiError,
    loadingTrue,
    adminPatientsSuccess,
    adminPatientConsultationsSuccess,
    adminPatientMedicalHistorySuccess,
    adminSearchPatientsSuccess,
    adminPatientSuccess,
    adminPatientFilesSuccess,
    patientCreateSuccess,
    patientEditSuccess,
    patientMedicalHistoryEditSuccess,
    adminDeletePatientSuccess,
    adminDeletePatientFileSuccess,
} = patientsSlice.actions;

export default patientsSlice.reducer;
import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody } from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import {cleanErrors, consultingOfficeCreateThunk} from "../../slices/consulting_office/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";

interface selectState {
    consulting_office: {
        loading: false;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminConsultingOfficeAdd = (props: any) => {
    const dispatch: any = useDispatch();
    //meta title
    document.title = "Agregar Consultorio";

    const selectProperties = createSelector(
        (state: selectState) => state.consulting_office,
        (item) => ({
            loading: item.loading,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage!=="") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    // Contantes para mostrar en las opciones de un box
    const StateOptions = [
        { val: 'Aguascalientes', label: 'Aguascalientes' },
        { val: 'Baja California', label: 'Baja California' },
        { val: 'Baja California Sur', label: 'Baja California Sur' },
        { val: 'Campeche', label: 'Campeche' },
        { val: 'Chiapas', label: 'Chiapas' },
        { val: 'Chihuahua', label: 'Chihuahua' },
        { val: 'Ciudad de México', label: 'Ciudad de México' },
        { val: 'Coahuila', label: 'Coahuila' },
        { val: 'Colima', label: 'Colima' },
        { val: 'Durango', label: 'Durango' },
        { val: 'Estado de México', label: 'Estado de México' },
        { val: 'Guanajuato', label: 'Guanajuato' },
        { val: 'Guerrero', label: 'Guerrero' },
        { val: 'Hidalgo', label: 'Hidalgo' },
        { val: 'Jalisco', label: 'Jalisco' },
        { val: 'Michoacán', label: 'Michoacán' },
        { val: 'Morelos', label: 'Morelos' },
        { val: 'Nayarit', label: 'Nayarit' },
        { val: 'Nuevo León', label: 'Nuevo León' },
        { val: 'Oaxaca', label: 'Oaxaca' },
        { val: 'Puebla', label: 'Puebla' },
        { val: 'Querétaro', label: 'Querétaro' },
        { val: 'Quinana Roo', label: 'Quintana Roo' },
        { val: 'San Luis Potosí', label: 'San Luis Potosí' },
        { val: 'Sinaloa', label: 'Sinaloa' },
        { val: 'Sonora', label: 'Sonora' },
        { val: 'Tabasco', label: 'Tabasco' },
        { val: 'Tamaulipas', label: 'Tamaulipas' },
        { val: 'Tlaxcala', label: 'Tlaxcala' },
        { val: 'Veracruz', label: 'Veracruz' },
        { val: 'Yucatán', label: 'Yucatán' },
        { val: 'Zacatecas', label: 'Zacatecas' },
    ]

    //Basic Information
    const formik: any = useFormik({
        initialValues: {
            name: '',
            state: { val: '', label: '' },
            city: '',
            address: '',
            phone: '',
        },
        validationSchema: yup.object().shape({
            name: yup.string().required('El campo es requerido'),
            state: yup.object().shape({
                val: yup.string().required('El campo es requerido'),
                label: yup.string().required('El campo es requerido'),
            }),
            city: yup.string().required('El campo es requerido'),
            address: yup.string().required('El campo es requerido'),
            phone: yup.string().required('El campo es requerido'),

        }),
        onSubmit: (values: any) => {
            console.log('Basic Information', values);
            dispatch(consultingOfficeCreateThunk(values, props.router.navigate));
        },
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row >
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h4">Información del consultorio
                                    </CardTitle>
                                    <p className="card-title-desc">  Ingresa la información de tu consultorio.</p>
                                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                        <Row>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="first_name">Nombre</Label>
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        placeholder="Nombre"
                                                        value={formik.values.name}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.name && formik.errors.name ? true : false
                                                        }
                                                    />
                                                    {formik.errors.name && formik.touched.name ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={"6"}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name">Teléfono</Label>
                                                    <Input
                                                        id="phone"
                                                        name="phone"
                                                        type="text"
                                                        placeholder="Teléfono"
                                                        value={formik.values.phone}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.phone && formik.errors.phone ? true : false
                                                        }
                                                    />
                                                    {formik.errors.phone && formik.touched.phone ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.phone}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <div className="control-label"
                                                         style={{marginBottom: "0.5rem"}}>Estado
                                                    </div>
                                                    <Select
                                                        id="state"
                                                        name="state"
                                                        options={StateOptions}
                                                        className={`select2 ${formik.touched.state && formik.errors.state ? 'is-invalid' : ''}`}
                                                        onChange={(selectedOption) => {
                                                            formik.setFieldValue('state', selectedOption);
                                                        }}
                                                        onBlur={() => formik.setFieldTouched('state', true)}
                                                        value={formik.values.state}
                                                        menuPosition="fixed"
                                                    />
                                                    {formik.touched.state && formik.errors.state && (
                                                        <FormFeedback type="invalid">{formik.errors.state.val}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={"6"}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name">Ciudad</Label>
                                                    <Input
                                                        id="city"
                                                        name="city"
                                                        type="text"
                                                        placeholder="Ciudad"
                                                        value={formik.values.city}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.city && formik.errors.city ? true : false
                                                        }
                                                    />
                                                    {formik.errors.city && formik.touched.city ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.city}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name">Dirección</Label>
                                                    <Input
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        placeholder="Dirección"
                                                        value={formik.values.address}
                                                        onChange={formik.handleChange}
                                                        invalid={
                                                            formik.touched.address && formik.errors.address ? true : false
                                                        }
                                                    />
                                                    {formik.errors.address && formik.touched.address ? (
                                                        <FormFeedback
                                                            type="invalid">{formik.errors.address}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        {
                                            loading ? <Spinners/>
                                                :
                                                <div className="d-flex flex-wrap justify-content-end gap-2">
                                                    <Button type="submit" color="primary"> Guardar  </Button>
                                                    <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: "1005" }}>
                                                        <Toast isOpen={toast}>
                                                            <ToastHeader toggle={closeToast}>
                                                                {toastTitle}
                                                            </ToastHeader>
                                                            <ToastBody>
                                                                {toastMessage}
                                                            </ToastBody>
                                                        </Toast>
                                                    </div>
                                                </div>
                                        }

                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminConsultingOfficeAdd)
import React, {useState} from "react"
import { Link, useLocation } from "react-router-dom";

import {
    Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody, Table
} from "reactstrap";
import * as yup from "yup";
import {useFormik} from "formik";
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import {cleanErrors, getAdminConsultationThunk} from "../../slices/consultation/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {useParams} from "react-router-dom";
import {Consultation, Doctor} from "../../entities/types";
import {isEmpty, map} from "lodash";
import "../../assets/scss/consultation.scss";

interface selectState {
    consultation: {
        loading: false;
        consultation: Consultation;
        doctor: Doctor,
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminConsultationPrintRecipe = (props: any) => {
    const dispatch: any = useDispatch();
    const { id, recipe_id } = useParams<{ id: string, recipe_id: string }>();

    const location = useLocation();

    //meta title
    document.title = "Consulta";

    const selectProperties = createSelector(
        (state: selectState) => state.consultation,
        (item) => ({
            loading: item.loading,
            consultation: item.consultation,
            doctor: item.doctor,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, consultation, doctor, reducerToastTitle,
        reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);


    React.useEffect(() => {
        if (id) {
            dispatch(getAdminConsultationThunk(id));
        }
        if (!isEmpty(consultation)) {
            printRecipe();
        }
    }, [id]);

    const printRecipe = () => {
        window.print();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {!isEmpty(consultation) && (
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={2}>
                                                <div className="auth-logo mb-4">
                                                    <img src={doctor.logo} alt="logo" height="80"/>
                                                </div>
                                            </Col>
                                            <Col sm={10}>
                                                <Row>
                                                    <Col sm={12}>
                                                        <div>
                                                            <h2 className="text-center fs-2">
                                                                {doctor.academic_title} {doctor.first_name} {doctor.last_name}
                                                            </h2>
                                                            <div className="text-center fs-5">
                                                                {doctor.specialty}
                                                            </div>
                                                            <div className="text-center fs-6">
                                                                {doctor.info.educations.map((education, subIndex) => (
                                                                    <span key={subIndex}>
                                                                 &nbsp;• {education.school_name}
                                                            </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <Row className="pt-5">
                                            <Col sm={6}>
                                                <address>
                                                    <strong>Paciente:</strong>
                                                    <br/>{consultation.patient.first_name} {consultation.patient.last_name}
                                                </address>
                                            </Col>
                                            <Col sm={6} className="text-sm-end">
                                                {consultation?.consultation_info?.prescriptions?.map((prescriptionNumb, index) => (
                                                    // Verifica si el índice actual coincide con el recipe_id convertido a entero
                                                    index === parseInt(recipe_id as string) ? (
                                                        <div key={index}>
                                                            <address>
                                                                <strong>Fecha:</strong>
                                                                <br/>
                                                                {prescriptionNumb.date ?
                                                                    `${new Date(prescriptionNumb.date).toLocaleString('es-ES', {
                                                                        year: 'numeric',
                                                                        month: '2-digit',
                                                                        day: '2-digit',
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: true
                                                                    })}` : "Sin registro"
                                                                }
                                                            </address>
                                                        </div>
                                                    ) : null
                                                ))}
                                            </Col>
                                        </Row>

                                        <hr/>


                                        <Row>
                                            <Col sm={12}>
                                                {consultation?.consultation_info?.prescriptions?.map((prescriptionNumb, index) => (

                                                    <div key={index}>
                                                        {index === parseInt(recipe_id as string) ? (
                                                            <div>
                                                                {prescriptionNumb.prescriptions.map((prescription, subIndex) => (
                                                                    <div key={subIndex}>
                                                                        <p className="text-muted mb-0">{prescription.medication}</p>
                                                                        <p className="text-muted mb-0">{prescription.instruction}</p>
                                                                        <hr/>
                                                                    </div>
                                                                ))}
                                                                <Row>
                                                                    <Col sm={8}>
                                                                        <strong>Notas adicionales:</strong>
                                                                        <p className="text-muted mb-0">{prescriptionNumb.indications}</p>
                                                                    </Col>
                                                                    <Col sm={4}>
                                                                        <img src={doctor.signature} alt="firma"
                                                                             height="80"/>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                ))}
                                                <br/>
                                            </Col>
                                        </Row>
                                        <div className="footer-print">
                                            <Row className="bg-info-subtle"
                                                 style={{paddingTop: '20px', paddingBottom: "20px"}}>
                                                <Col sm={6}>
                                                    {consultation.office.name}
                                                    <br/>
                                                    {consultation.office.address}
                                                    <br/>
                                                    T. {consultation.office.phone}
                                                    <br/>
                                                </Col>
                                                <Col sm={6} className="text-sm-end">
                                                    <div className="d-print-none">
                                                        <div className="float-end">
                                                            <Link
                                                                to="#"
                                                                onClick={printRecipe}
                                                                className="btn btn-success  me-2"
                                                            >
                                                                <i className="fa fa-print"/>
                                                                &nbsp; Imprimir
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
            <style>
                {`
          @media print {
            .footer-print {
              position: fixed;
              bottom: 0;
              width: 100%;
              background-color: white;
            }
          }
        `}
            </style>
        </React.Fragment>
    )
}

export default withRouter(AdminConsultationPrintRecipe)
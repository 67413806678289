
// action
import { profileSuccess, profileError } from "./reducer";
import {APIClient} from "../../../helpers/api_helper";

const api = new APIClient();

export const editProfileThunk = (user: any) => async (dispatch: any) => {
    try {
        const response = await api.Post(`/some_url`, null);

        const data = await response;

        if (data) {
            dispatch(profileSuccess(data));
        }

    } catch (error) {
        dispatch(profileError(error));
    }
};

export const resetProfileFlagThunk = () => async (dispatch: any) => {
    try {
        const response = await api.Post(`/some_url`, null);
        return response;
    } catch (error) {
        return error;
    }
};
import React from "react";

//Import Components
import Navbar from "./Navbar/Navbar"
import Section from "./HeroSection/Section"
import Features from "./Features/features"
import FAQs from "./Faqs/FAQs"
import Footer from "./Footer/footer"

const Landing = () => {

  //meta title
  document.title = "Farmabits";

  return (
    <React.Fragment>
      <Navbar />

      <Section />

      <Features />

      <FAQs />

      <Footer />
    </React.Fragment>
  )
}

export default Landing

import axios from "axios";

const defaultBaseURL = 'https://farmabitsgo.com/api/v1';
const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/v1' : defaultBaseURL;

axios.defaults.baseURL = baseURL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    console.log("error...", error);

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message: any;
    switch (error.response.data.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 400:
        //message = "Invalid form data";
        message = error.response.data.message;
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.response.data.message || error;
    }

    return Promise.reject(message);
  }
);

class APIClient {

  Get = (url: any, params: any) => {
    const tokenString = localStorage.getItem('token');
    console.log("tokenString", tokenString);
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenString}`;
    axios.defaults.headers.common["Device-Id"] = "1";

    let response: any;
    let paramKeys: any = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };

  Post = (url: any, data: any) => {
    const tokenString = localStorage.getItem('token');
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenString}`;
    axios.defaults.headers.common["Device-Id"] = "1";

    return axios.post(url, data);
  };
  PostFile = (url: any, data: any) => {
    const tokenString = localStorage.getItem('token');
    return axios.post(url, data, {
      headers: {
        "Authorization": `Bearer ${tokenString}`,
        "Device-Id": "1",
        "Content-Type": "multipart/form-data"
      }
    });
  };

  Patch = (url: any, data: any) => {
    const tokenString = localStorage.getItem('token');
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenString}`;
    axios.defaults.headers.common["Device-Id"] = "1";

    return axios.patch(url, data);
  };

  Put = (url: any, data: any) => {
    const tokenString = localStorage.getItem('token');
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenString}`;
    axios.defaults.headers.common["Device-Id"] = "1";

    return axios.put(url, data);
  };

  Delete = (url: any, config: any) => {
    const tokenString = localStorage.getItem('token');
    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenString}`;
    axios.defaults.headers.common["Device-Id"] = "1";

    return axios.delete(url, { ...config });
  };
}

export { APIClient };
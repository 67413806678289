import React, {useMemo, useEffect, useState} from "react";
import withRouter from "../../Components/Common/withRouter";

import {Button, Card, CardBody, CardTitle, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

import TableContainer from "../../Components/Common/TableContainer";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from 'reselect';
import {Consultation, ConsultingOffice, Patient, Phone} from "../../entities/types";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import {getAdminPatientConsultationsThunk, getAdminPatientThunk} from "../../slices/admin/thunk";
import {deleteConsultation} from "../../slices/consultation/thunk";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "../Page_Admin_Agenda/DeleteModal";
import {ListUI} from "@ckeditor/ckeditor5-list";
import {deleteOffice} from "../../slices/consulting_office/thunk";
import {addNewEvent} from "../../slices/calendar/thunk";
import Select from "react-select";
import {useFormik} from "formik";
import * as yup from "yup";
import AdminTabs from "../../Layouts/HorizontalLayout/Tabs";

// Este selectState se comunica indirectamente con el state del reducer en /slices/admin/reducer
// y esa unión se hace a través del slices/index.ts, donde une el state "admin" con el archivo del reducer
interface selectState {
    admin: {
        patientConsultationList: Consultation[];
        adminPatient: Patient;
        loading: true;
        message: "";
        error: false;
    };
    calendar: {
        loading: false;
        patients: Patient[];
        consultingOfficeList: ConsultingOffice[];
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};


const PatientConsultationList: React.FC = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const patientsPerPage = 20;
    const location = useLocation();
    const [time, setTime] = useState(new Date());

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (state: selectState) => state.calendar,
        (item, calendar) => ({
            PatientConsultationList: item.patientConsultationList,
            adminPatient: item.adminPatient,
            consultingOfficeList: calendar.consultingOfficeList,
            loading: item.loading,
            error: item.error,
            message: item.message,
        })
    );

    const { PatientConsultationList, consultingOfficeList, adminPatient, loading } = useSelector(selectProperties);

    const columns = useMemo(
        () => [
            {
                header: "Fecha",
                accessorKey: "date",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const consultation = cellProps.row.original.date;
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            {consultation ? (
                                <a href={`/admin/patient/consultation/${cellProps.row.original.id}`}
                                   style={{textDecoration: "none", color: "black"}}>
                                    {new Date(consultation).toLocaleString('es-MX')}
                                </a>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    );
                },
            },
            {
                header: "Consultorio",
                accessorKey: "office",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const officeName = cellProps.row.original.office.name || '-';
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/patient/consultation/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black"
                            }}>
                                {officeName}
                            </a>
                        </div>
                    );
                },
            },
            {
                header: "Estatus",
                accessorKey: "status",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    let statusText = "";
                    switch (cellProps.row.original.status) {
                        case "pending":
                            statusText = "Sin confirmar";
                            break;
                        case "confirmed":
                            statusText = "Confirmado";
                            break;
                        case "finished":
                            statusText = "Terminado";
                            break;
                        default:
                            statusText = cellProps.row.original.status; // fallback to original status if not recognized
                            break;
                    }

                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/patient/consultation/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black"
                            }}>
                                {statusText}
                            </a>
                        </div>
                    );
                },
            },
            {
                header: "",
                accessorKey: "actions",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const handleDeleteClick = () => {
                        setDeleteModal(true); // Mostrar el modal de confirmación de eliminación
                        setDeleteId(cellProps.row.original.id); // Guardar el ID de la consulta a eliminar
                    };

                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => navigate(`/admin/patient/consultation/${cellProps.row.original.id}`)}
                            >
                                Ver consulta
                            </Button>
                            <span
                                style={{ fontSize: '1.5em', paddingLeft: '15px', paddingTop: '25px', cursor: 'pointer' }}
                                id="btn-delete-event"
                                onClick={handleDeleteClick}
                            >
                    <i className="bx bx-trash"></i>
                </span>
                        </div>
                    );
                },
            },
        ],
        [navigate]
    );

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState();
    const [refreshKey, setRefreshKey] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const handleSingleOfficeRedirect = (office) => {
        if (id) {
            const patientId = parseInt(id, 10);
            const newEvent = {
                consulting_office_id: office.id,
                patient_id: patientId,
                event_date: time.toISOString(),
                duration: 30,
                force: true
            };
            dispatch(addNewEvent(newEvent, props.router.navigate));
        } else {
            console.error("Error: ID del paciente no proporcionado.");
        }
    };

    const toggleModal = () => {
        if (consultingOfficeList.length === 1) {
            handleSingleOfficeRedirect(consultingOfficeList[0]);
        } else {
            setModalOpen(!modalOpen);
        }
    };

    const formik: any = useFormik({
        initialValues: {
            name: {val: '', label: ''},
            patients: {val: '', label: ''},
            office: {val: '', label: ''},
            date: time.toISOString(),
            time: time.toISOString(),
        },
        validationSchema: yup.object().shape({
            office: yup.object().shape({
                val: yup.string().required('El campo es requerido*'),
                label: yup.string().required('El campo es requerido*'),
            }),
        }),
        onSubmit: (values: any) => {
            console.log('onSubmit', values);

            const newEvent = {
                consulting_office_id: values.office.val,
            };
            console.log('newEvent', newEvent);

            dispatch(addNewEvent(newEvent, props.router.navigate));
            toggleModal();
        },
    });

    const handleStart = () => {
        if (formik.isValid && formik.dirty) {
            const values = formik.values;

            const patientId = id ? parseInt(id, 10) : undefined;
            const newEvent = {
                consulting_office_id: values.office.val,
                patient_id: patientId,
                event_date: time.toISOString(),
                duration: 30,
                force: true,
            };

            console.log("Datos del evento a enviar:", newEvent);

            dispatch(addNewEvent(newEvent, props.router.navigate));
            toggleModal();
            console.log("Datos enviados:", newEvent);

        } else {
            console.log("Formulario no válido, complete todos los campos requeridos.");
        }
    };

    const handleDeleteConsultation = () => {
        console.log("deleteId", deleteId);
        if (deleteId) {
            dispatch(deleteConsultation(deleteId)).then(() => {
                setRefreshKey(prevKey => prevKey + 1);
            });
        }
        setDeleteModal(false);
    };

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (id) {
            dispatch(getAdminPatientConsultationsThunk(id, currentPage));
        }
    }, [dispatch, id, currentPage, refreshKey])

    React.useEffect(() => {
        if (id) { // Agrega una condición para asegurarte de que 'id' esté definido
            dispatch(getAdminPatientThunk(id));
        }
    }, [id]); // Solo dispara la acción cuando 'id' cambie

    const startRange = (currentPage - 1) * patientsPerPage + 1;
    const endRange = Math.min(currentPage * patientsPerPage, startRange + (PatientConsultationList?.length || 0) - 1);

    const renderPaginationButtons = () => {
        return (
            <div className="d-flex justify-content-center mt-4">
                <Button
                    className="mx-2"
                    color="primary"
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                >
                    {"<"}
                </Button>
                <span className="mx-2" style={{ lineHeight: "38px" }}>
                    {startRange} - {endRange}
                </span>
                <Button
                    className="mx-2"
                    color="primary"
                    disabled={PatientConsultationList?.length < patientsPerPage}
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    {">"}
                </Button>
            </div>
        );
    };

    const isActiveTab = (tabPath: string) => {
        return location.pathname === tabPath;
    };

    const handleClick = (sendFalse: boolean) => {
        navigate(`/admin/new-appointment?patient=${id}&sendFalse=${sendFalse}`);
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteConsultation}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Card>
                <AdminTabs id={id as string} isActiveTab={isActiveTab} />
                <CardBody>
                    <CardTitle tag="h4" className="mb-4 d-flex justify-content-between align-items-center">
                        <span>Citas, padecimiento y evolución clínica de {adminPatient ? (`${adminPatient.first_name} ${adminPatient.last_name}`) : "NA"}</span>
                        <div className="d-flex">
                            <Button
                                type="button"
                                color="success"
                                className="btn-md btn-rounded mr-2"
                                onClick={toggleModal}
                            >
                                Iniciar consulta
                            </Button>
                            <Button type="button" color="success" className="btn-md btn-rounded"
                                    onClick={() => handleClick(false)} style={{ marginLeft: '10px' }}>
                                + Agendar consulta
                            </Button>
                        </div>
                    </CardTitle>
                    {loading ? <Spinners/> :
                        <React.Fragment>
                            <TableContainer
                                columns={columns}
                                data={PatientConsultationList}
                                isGlobalFilter={false}
                                isPagination={false} // Desactiva la paginación interna de TableContainer
                                SearchPlaceholder="Buscar..."
                                isCustomPageSize={false}
                                isAddButton={false}
                                buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                buttonName="Agregar nuevo"
                                tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                theadClass="table-light"
                            />
                            {renderPaginationButtons()} {/* Renderiza los botones de paginación */}
                        </React.Fragment>
                    }
                </CardBody>
            </Card>
            {consultingOfficeList.length > 0 ? (
                modalOpen && (
                    <Modal isOpen={modalOpen} toggle={toggleModal} centered>
                        <ModalHeader toggle={toggleModal}>Consultorio</ModalHeader>
                        <ModalBody>
                            <div className="control-label" style={{ marginBottom: "0.5rem" }}>
                                Seleccione un consultorio
                            </div>
                            <Select
                                id="office"
                                name="office"
                                options={consultingOfficeList.map((office: any) => ({
                                    val: office.id,
                                    label: office.name
                                }))}
                                className="select2"
                                onChange={(selectedOption) => {
                                    formik.setFieldValue('office', selectedOption);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.office}
                                menuPosition="fixed"
                            />
                            <Row className="mt-2">
                                <Col className="col-6">
                                </Col>
                                <Col className="col-6 text-end">
                                    <button
                                        type="button"
                                        className="btn btn-light me-1"
                                        onClick={handleStart}
                                    >
                                        Iniciar
                                    </button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )
            ) : (
                modalOpen && (
                    <Modal isOpen={modalOpen} toggle={toggleModal} centered>
                        <ModalHeader toggle={toggleModal}>Favor de crear un consultorio</ModalHeader>
                    </Modal>
                )
            )}
        </React.Fragment>
    );
};


export default withRouter(PatientConsultationList);

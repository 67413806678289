import {apiError, pwdRecoverSuccess, pwdResetSuccess} from "./reducer"
import {APIClient} from "../../../helpers/api_helper";

const api = new APIClient();

export const userForgetPasswordThunk = (user, history) => async (dispatch) => {
    try {
        const response = await api.Post(`/doctor/password-recover`, { email: user.email });

        if (response) {
            dispatch(pwdRecoverSuccess(
                "Email successfully sent"
            ));
        }
    } catch (forgetError) {
        dispatch(apiError(forgetError));
    }
};

export const userResetPasswordThunk = (token, password, onSuccess) => async (dispatch) => {
    try {
        const response = await api.Post(`/doctor/change-password`, { token, new_password: password });

        if (response) {
            dispatch(pwdResetSuccess("Password successfully reset"));
            if (onSuccess) onSuccess();
        }
    } catch (forgetError) {
        dispatch(apiError(forgetError));
    }
};
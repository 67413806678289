import React, { useState } from "react"
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row, FormFeedback, InputGroup,
    Toast, ToastHeader, ToastBody } from "reactstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select"
import Flatpickr from "react-flatpickr";
import {cleanErrors, getAdminPatientThunk, patientCreateThunk, patientEditThunk} from "../../slices/admin/thunk";
import withRouter from "Components/Common/withRouter";
import {useDispatch, useSelector} from "react-redux";

import {createSelector} from "reselect";
import Spinners from "Components/Common/Spinner";
import {Link, useLocation, useParams} from "react-router-dom";
import {Patient} from "../../entities/types";
import {isEmpty} from "lodash";
import AdminTabs from "../../Layouts/HorizontalLayout/Tabs";
import {Spanish} from "flatpickr/dist/l10n/es";

interface selectState {
    admin: {
        loading: false;
        adminPatient: Patient;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};


const AdminPatientEdit = (props: any) => {
    const dispatch: any = useDispatch();
    const { id } = useParams();

    //meta title
    document.title = "Paciente";

    const selectProperties = createSelector(
        (state: selectState) => state.admin,
        (item) => ({
            loading: item.loading,
            adminPatient: item.adminPatient,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, adminPatient, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    // toastMessage is the variable and setToastMessage is the function(created on the fly) to set the value of the variable
    // then we can use the var with {toastMessage}, and setting the value with setToastMessage("value")
    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    const GenderOptions = [
        { val: 'male', label: 'Masculino' },
        { val: 'fem', label: 'Femenino' },
    ]

    const MaritalStatusOptions = [
        { val: 'sgl', label: 'Soltero(a)' },
        { val: 'mry', label: 'Casado(a)' },
        { val: 'wdw', label: 'Viudo(a)' },
        { val: 'sep', label: 'Separado(a)' },
        { val: 'div', label: 'Divorciado(a)' },
        { val: 'prt', label: 'Concubino(a)' },
        { val: 'other', label: 'Otro' },
    ]

    const BloodTypeOptions = [
        { val: 'o+', label: 'O+' },
        { val: 'o-', label: 'O-' },
        { val: 'a+', label: 'A+' },
        { val: 'a-', label: 'A-' },
        { val: 'b+', label: 'B+' },
        { val: 'b-', label: 'B-' },
        { val: 'ab+', label: 'AB+' },
        { val: 'ab-', label: 'AB-' },
        { val: 'unkn', label: 'Desconocido' },
    ]
    const EthnicGroupOptions = [
        { val: 'afr', label: 'Afrodescenciente (Africano)' },
        { val: 'ame', label: 'Amerindio (Indígena)' },
        { val: 'arb', label: 'Arabigo (Árabe)' },
        { val: 'cau', label: 'Caucásico (Blanco)' },
        { val: 'lat', label: 'Latino (Latinoamericano)' },
        { val: 'mzo', label: 'Mestizo' },
        { val: 'mto', label: 'Mulato' },
        { val: 'otal', label: 'Oriental (Asiático)' },
        { val: 'other', label: 'Otro' },
    ]

    const PhoneOptions = [
        { val: "Celular", label: 'Celular' },
        { val: "Casa", label: 'Casa' },
        { val: "Trabajo", label: 'Trabajo' },
        { val: "Otro", label: 'Otro' },
    ]

    const IdentificationOptions = [
        { val: "Identificación", label: 'Identificación' },
        { val: "Licencia de conducir", label: 'Licencia de conducir' },
        { val: "Número de Seguro Social", label: 'Número de Seguro Social' },
        { val: "Pasaporte", label: 'Pasaporte' },
        { val: "CURP", label: 'CURP' },
        { val: "Otro", label: 'Otro' },
    ]

    //Basic Information
    const formik: any = useFormik({
        initialValues: {
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            birthday: null,
            birth_place: '',
            gender: { val: '', label: '' },
            marital_status: { val: '', label: '' },
            ethnic_group: { val: '', label: '' },
            blood_type: { val: '', label: '' },
            state: '',
            city: '',
            address: '',
            occupation: '',
            private_note: '',
            socioeconomic_status: '',
            housing: '',
            disability: '',
            religion: '',
            phones: [{ phone_type: '', phone: '' }],
            identifications: [{ identification_type: '', identification: '' }],
            insurance_or_plans: [{ name: '', policy: '', note: '' }],
            emergency_contacts: [{ full_name: '', relationship: '', email: '', note: '', phones: [{ phone_type: '', phone: '' }] }],
        },
        validationSchema: yup.object().shape({
            first_name: yup.string().required('El campo es requerido'),
            last_name: yup.string().required('El campo es requerido'),
        }),
        onSubmit: (values: any) => {
            console.log('Basic Information', values);

            const phoneErrors = {};
            values.phones.forEach((phone, index) => {
                const isPhoneTypeFilled = phone.phone_type && phone.phone_type.trim() !== '';
                const isPhoneFilled = phone.phone && phone.phone.trim() !== '';

                if (isPhoneTypeFilled && !isPhoneFilled) {
                    phoneErrors[`phones[${index}].phone`] = 'Campo requerido';
                }
                if (!isPhoneTypeFilled && isPhoneFilled) {
                    phoneErrors[`phones[${index}].phone_type`] = 'Campo requerido';
                }
            });

            const identificationErrors = {};
            values.identifications.forEach((identification, index) => {
                const isIdentificationTypeFilled = identification.identification_type && identification.identification_type.trim() !== '';
                const isIdentificationFilled = identification.identification && identification.identification.trim() !== '';

                if (isIdentificationTypeFilled && !isIdentificationFilled) {
                    identificationErrors[`identifications[${index}].identification`] = 'Campo requerido';
                }
                if (!isIdentificationTypeFilled && isIdentificationFilled) {
                    identificationErrors[`identifications[${index}].identification_type`] = 'Campo requerido';
                }
            });

            const insuranceErrors = {};
            values.insurance_or_plans.forEach((insurance, index) => {
                const isNameFilled = insurance.name && insurance.name.trim() !== '';
                const isPolicyFilled = insurance.policy && insurance.policy.trim() !== '';
                const isNoteFilled = insurance.note && insurance.note.trim() !== '';

                if (!isNameFilled && isPolicyFilled) {
                    insuranceErrors[`insurance_or_plans[${index}].name`] = 'Campo requerido';
                }
                if (isNameFilled && !isPolicyFilled) {
                    insuranceErrors[`insurance_or_plans[${index}].policy`] = 'Campo requerido';
                }
                if (isNoteFilled && !isNameFilled && !isPolicyFilled) {
                    insuranceErrors[`insurance_or_plans[${index}].name`] = 'Campo requerido';
                    insuranceErrors[`insurance_or_plans[${index}].policy`] = 'Campo requerido';
                }
            });

            const contactErrors = {};
            values.emergency_contacts.forEach((contact, index) => {
                const isNameFilled = contact.full_name && contact.full_name.trim() !== '';
                const isRelationshipFilled = contact.relationship && contact.relationship.trim() !== '';
                const isEmailFilled = contact.email && contact.email.trim() !== '';
                const isNoteFilled = contact.note && contact.note.trim() !== '';
                const isPhoneFilled = contact.phones.some(phone => phone.phone.trim() !== '' || phone.phone_type.trim() !== '');

                contact.phones.forEach((phone, phoneIndex) => {
                    if (phone.phone.trim() !== '' && phone.phone_type.trim() === '') {
                        contactErrors[`emergency_contacts[${index}].phones[${phoneIndex}].phone_type`] = 'Campo requerido';
                    }
                    if (phone.phone_type.trim() !== '' && phone.phone.trim() === '') {
                        contactErrors[`emergency_contacts[${index}].phones[${phoneIndex}].phone`] = 'Campo requerido';
                    }
                });

                if ((isNoteFilled || isRelationshipFilled || isEmailFilled || isPhoneFilled) && !isNameFilled) {
                    contactErrors[`emergency_contacts[${index}].full_name`] = 'Campo requerido';
                }
            });

            const errors = { ...phoneErrors, ...identificationErrors, ...insuranceErrors, ...contactErrors };

            if (Object.keys(errors).length > 0) {
                formik.setErrors(errors);
                setToastMessage("Por favor, complete todos los campos requeridos.");
                setToastTitle("Campos requeridos");
                setToast(true);
                return;
            }

            dispatch(patientEditThunk(values, props.router.navigate));
        },
    });

    React.useEffect(() => {
        if (!isEmpty(adminPatient)) {
            const selectedGender = GenderOptions.find(option => option.val === adminPatient.gender);
            const selectedBloodType = BloodTypeOptions.find(option => option.val === adminPatient.blood_type);
            const selectedMaritalStatus = MaritalStatusOptions.find(option => option.val === adminPatient.marital_status);
            const selectedEthnicGroup = EthnicGroupOptions.find(option  => option.val === adminPatient.ethnic_group);

            formik.setValues({
                id: adminPatient.id || '',
                first_name: adminPatient.first_name || '',
                last_name: adminPatient.last_name || '',
                email: adminPatient.email || '',
                birthday: adminPatient.birthday || null,
                birth_place: adminPatient.birth_place || '',
                gender: selectedGender || { val: '', label: '' },
                blood_type: selectedBloodType || { val: '', label: '' },
                state: adminPatient.state || '',
                city: adminPatient.city || '',
                marital_status: selectedMaritalStatus || { val: '', label: '' },
                ethnic_group: selectedEthnicGroup || { val: '', label: '' },
                address: adminPatient.address || '',
                occupation: adminPatient.occupation || '',
                private_note: adminPatient.private_note || '',
                socioeconomic_status: adminPatient.socioeconomic_status || '',
                housing: adminPatient.housing || '',
                disability: adminPatient.disability || '',
                religion: adminPatient.religion || '',
                phones: (adminPatient.general_info.phones?.length > 0
                        ? adminPatient.general_info.phones.map(phone => ({
                            phone_type: phone.phone_type,
                            phone: phone.phone,
                        }))
                        : [{ phone_type: '', phone: '' }]
                ),
                identifications: (adminPatient.general_info.identifications?.length > 0
                        ? adminPatient.general_info.identifications.map(identification => ({
                            identification_type: identification.identification_type,
                            identification: identification.identification,
                        }))
                        : [{ identification_type: '', identification: '' }]
                ),
                insurance_or_plans: (adminPatient.general_info.insurance_or_plans?.length > 0
                        ? adminPatient.general_info.insurance_or_plans.map(insurance => ({
                            name: insurance.name,
                            policy: insurance.policy,
                            note: insurance.note,
                        }))
                        : [{ name: '', policy: '', note: '' }]
                ),
                emergency_contacts: (adminPatient.general_info.emergency_contacts?.length > 0
                        ? adminPatient.general_info.emergency_contacts.map(contact => ({
                            full_name: contact.full_name,
                            relationship: contact.relationship,
                            email: contact.email,
                            note: contact.note,
                            phones: contact.phones?.map(phone => ({
                                phone_type: phone.phone_type,
                                phone: phone.phone,
                            })) || [{ phone_type: '', phone: '' }]
                        }))
                        : [{ full_name: '', relationship: '', email: '', note: '', phones: [{ phone_type: '', phone: '' }] }]
                ),
            });
        }
    }, [adminPatient, formik.setValues]);

    React.useEffect(() => {
        if (id) { // Agrega una condición para asegurarte de que 'id' esté definido
            dispatch(getAdminPatientThunk(id));
        }
    }, [id]); // Solo dispara la acción cuando 'id' cambie

    const handlePhoneChange = (e, index, field) => {
        const { value } = e.target;
        const newPhones = formik.values.phones.map((phone, i) =>
            i === index ? { ...phone, [field]: value } : phone
        );
        formik.setFieldValue('phones', newPhones);
    };

    const addPhone = () => {
        const newPhones = [...formik.values.phones, { phone_type: '', phone: '' }];
        formik.setFieldValue('phones', newPhones);
    };

    const removePhone = index => {
        const phones = formik.values.phones;

        if (phones.length === 1) {
            formik.setFieldValue('phones', []);
            return;
        }

        const newPhones = phones.filter((_, i) => i !== index);
        formik.setFieldValue('phones', newPhones);
    };

    const handleIdentificationChange = (e, index, field) => {
        const { value } = e.target;
        const newIdentifications = formik.values.identifications.map((identification, i) =>
            i === index ? { ...identification, [field]: value } : identification
        );
        formik.setFieldValue('identifications', newIdentifications);
    };

    const addIdentification = () => {
        const newIdentifications = [...formik.values.identifications, { identification_type: '', identification: '' }];
        formik.setFieldValue('identifications', newIdentifications);
    };

    const removeIdentification = index => {
        const identifications = formik.values.identifications;

        if (identifications.length === 1) {
            formik.setFieldValue('identifications', []);
            return;
        }

        const newIdentifications = identifications.filter((_, i) => i !== index);
        formik.setFieldValue('identifications', newIdentifications);
    };

    const handleInsuranceChange = (e, index, field) => {
        const { value } = e.target;
        const newInsurances = formik.values.insurance_or_plans.map((insurance_or_plan, i) =>
            i === index ? { ...insurance_or_plan, [field]: value } : insurance_or_plan
        );
        formik.setFieldValue('insurance_or_plans', newInsurances);
    };

    const addInsurance = () => {
        const newInsurances = [...formik.values.insurance_or_plans, { name: '', policy: '', note: '' }];
        formik.setFieldValue('insurance_or_plans', newInsurances);
    };

    const removeInsurance = index => {
        const insurance_or_plans = formik.values.insurance_or_plans;

        if (insurance_or_plans.length === 1) {
            formik.setFieldValue('insurance_or_plans', []);
            return;
        }

        const newInsurances = insurance_or_plans.filter((_, i) => i !== index);
        formik.setFieldValue('insurance_or_plans', newInsurances);
    };

    const handleContactChange = (e, index, field) => {
        const { value } = e.target;
        const newContacts = formik.values.emergency_contacts.map((contact, i) =>
            i === index ? { ...contact, [field]: value } : contact
        );
        formik.setFieldValue('emergency_contacts', newContacts);
    };

    const handleContactPhoneChange = (e, index, phoneIndex, field) => {
        const { value } = e.target;
        const newContacts = formik.values.emergency_contacts.map((contact, i) =>
            i === index
                ? {
                    ...contact,
                    phones: contact.phones.map((phone, j) =>
                        j === phoneIndex ? { ...phone, [field]: value } : phone
                    ),
                }
                : contact
        );
        formik.setFieldValue('emergency_contacts', newContacts);
    };

    const addContacts = () => {
        const newContacts = [...formik.values.emergency_contacts, { full_name: '', relationship: '', email: '', note: '', phones: [{ phone_type: '', phone: '' }] }];
        formik.setFieldValue('emergency_contacts', newContacts);
    };

    const addContactPhones = (phoneIndex) => {
        const newContacts = formik.values.emergency_contacts.map((contact, i) =>
            i === phoneIndex
                ? {
                    ...contact,
                    phones: [...contact.phones, { phone_type: '', phone: '' }],
                }
                : contact
        );
        formik.setFieldValue('emergency_contacts', newContacts);
    };

    const removeContact = index => {
        const emergency_contacts = formik.values.emergency_contacts;

        if (emergency_contacts.length === 1) {
            formik.setFieldValue('emergency_contacts', []);
            return;
        }

        const newContacts = emergency_contacts.filter((_, i) => i !== index);
        formik.setFieldValue('emergency_contacts', newContacts);
    };

    const removeContactPhone = (index, phonesIndex) => {
        const newContacts = formik.values.emergency_contacts.map((contact, i) =>
            i === index
                ? {
                    ...contact,
                    phones: contact.phones.filter((_, j) => j !== phonesIndex),
                }
                : contact
        );

        formik.setFieldValue('emergency_contacts', newContacts);
    };

    const [visibility, setVisibility] = useState({
        contact: false,
        identification: false,
        insurance: false,
        emergency_contact: false,
    });

    const toggleSection = (section) => {
        setVisibility(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const location = useLocation();


    const isActiveTab = (tabPath: string) => {
        return location.pathname === tabPath;
    };

    const [age, setAge] = useState(0);

    // Función para calcular la edad
    const calculateAge = (birthday: string | Date): number => {
        const birthDate = typeof birthday === 'string' ? new Date(birthday) : birthday;

        if (!(birthDate instanceof Date) || isNaN(birthDate.getTime())) {
            return 0; // Maneja el caso de fecha inválida
        }

        const now = new Date();
        let age = now.getFullYear() - birthDate.getFullYear();
        const monthDifference = now.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    React.useEffect(() => {
        if (formik.values.birthday) {
            setAge(calculateAge(formik.values.birthday));
        }
    }, [formik.values.birthday]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row >
                        <Col xs={12}>
                            {loading ? (
                                <Spinners />
                            ) : (
                                <Card>

                                    <AdminTabs id={id as string} isActiveTab={isActiveTab} />

                                    <CardBody>
                                        <CardTitle tag="h4" style={{fontSize: '20px'}}>Información personal
                                        </CardTitle>
                                        <p className="card-title-desc"> Ingresa información correcta y completa para tener un mejor manejo de los datos de tus pacientes. <br/>
                                            Recuerda guardar los cambios antes de salir.</p>
                                        <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                            <Row>
                                                <Col sm="6">
                                                    <div className="mb-3">
                                                        <Label htmlFor="first_name">Nombre</Label>
                                                        <Input
                                                            id="first_name"
                                                            name="first_name"
                                                            type="text"
                                                            placeholder="Nombre"
                                                            value={formik.values.first_name}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.first_name && formik.errors.first_name ? true : false
                                                            }
                                                        />
                                                        {formik.errors.first_name && formik.touched.first_name ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.first_name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="first_name">Lugar de nacimiento</Label>
                                                        <Input
                                                            id="birth_place"
                                                            name="birth_place"
                                                            type="text"
                                                            placeholder="Lugar de nacimiento"
                                                            value={formik.values.birth_place}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.birth_place && formik.errors.birth_place ? true : false
                                                            }
                                                        />
                                                        {formik.errors.birth_place && formik.touched.birth_place ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.birth_place}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="control-label"
                                                             style={{marginBottom: "0.5rem"}}>Género
                                                        </div>
                                                        <Select
                                                            id="gender"
                                                            name="gender"
                                                            options={GenderOptions}
                                                            className="select2"
                                                            onChange={(selectedOption) => {
                                                                formik.setFieldValue('gender', selectedOption);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.gender}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="control-label"
                                                             style={{marginBottom: "0.5rem"}}>Grupo étnico
                                                        </div>
                                                        <Select
                                                            id="ethnic_group"
                                                            name="ethnic_group"
                                                            options={EthnicGroupOptions}
                                                            className="select2"
                                                            onChange={(selectedOption) => {
                                                                formik.setFieldValue('ethnic_group', selectedOption);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.ethnic_group}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="state">Estado</Label>
                                                        <Input
                                                            id="state"
                                                            name="state"
                                                            type="text"
                                                            placeholder="Estado"
                                                            value={formik.values.state}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.state && formik.errors.state ? true : false
                                                            }
                                                        />
                                                        {formik.errors.state && formik.touched.state ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.state}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="first_name">Domicilio completo</Label>
                                                        <Input
                                                            id="address"
                                                            name="address"
                                                            type="text"
                                                            placeholder="Dirección completa"
                                                            value={formik.values.address}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.address && formik.errors.address ? true : false
                                                            }
                                                        />
                                                        {formik.errors.address && formik.touched.address ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.address}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="socioeconomic_status">Nivel
                                                            socioeconómico</Label>
                                                        <Input
                                                            id="socioeconomic_status"
                                                            name="socioeconomic_status"
                                                            type="text"
                                                            placeholder="Nivel socioeconómico"
                                                            value={formik.values.socioeconomic_status}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.socioeconomic_status && formik.errors.socioeconomic_status ? true : false
                                                            }
                                                        />
                                                        {formik.errors.socioeconomic_status && formik.touched.socioeconomic_status ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.socioeconomic_status}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="housing">Vivienda</Label>
                                                        <Input
                                                            id="housing"
                                                            name="housing"
                                                            type="text"
                                                            placeholder="Tipo de vivienda"
                                                            value={formik.values.housing}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.housing && formik.errors.housing ? true : false
                                                            }
                                                        />
                                                        {formik.errors.housing && formik.touched.housing ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.housing}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="mb-3">
                                                        <Label htmlFor="last_name"> Apellidos </Label>
                                                        <Input
                                                            id="last_name"
                                                            name="last_name"
                                                            type="text"
                                                            placeholder="Apellidos"
                                                            value={formik.values.last_name}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.last_name && formik.errors.last_name ? true : false
                                                            }
                                                        />
                                                        {formik.errors.last_name && formik.touched.last_name ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.last_name}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <Label htmlFor="birthday">Fecha de nacimiento</Label>
                                                        <div className="d-flex">
                                                            <div className="w-50 pr-2">
                                                                <InputGroup>
                                                                    <Flatpickr
                                                                        id="birthday"
                                                                        name="birthday"
                                                                        className="form-control"
                                                                        value={formik.values.birthday}
                                                                        onChange={date => formik.setFieldValue('birthday', date[0])}
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "F j, Y",
                                                                            dateFormat: "Y-m-d",
                                                                            locale: Spanish
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </div>
                                                            <div className="w-50 pl-2 d-flex align-items-center" style={{ marginLeft: "30px" }}>
                                                                {formik.values.birthday && (
                                                                    <>
                                                                        <strong>Edad:</strong> <span
                                                                        className="ml-2" style={{ marginLeft: "5px" }}>{age} años</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="marital_status">Estado civil</Label>
                                                        <Select
                                                            id="marital_status"
                                                            name="marital_status"
                                                            options={MaritalStatusOptions}
                                                            className="select2"
                                                            onChange={(selectedOption) => {
                                                                formik.setFieldValue('marital_status', selectedOption);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.marital_status}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="blood_type">Tipo de sangre</Label>
                                                        <Select
                                                            id="blood_type"
                                                            name="blood_type"
                                                            options={BloodTypeOptions}
                                                            className="select2"
                                                            onChange={(selectedOption) => {
                                                                formik.setFieldValue('blood_type', selectedOption);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.blood_type}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="first_name">Ciudad</Label>
                                                        <Input
                                                            id="city"
                                                            name="city"
                                                            type="text"
                                                            placeholder="Ciudad"
                                                            value={formik.values.city}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.city && formik.errors.city ? true : false
                                                            }
                                                        />
                                                        {formik.errors.city && formik.touched.city ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.city}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="first_name">Ocupación</Label>
                                                        <Input
                                                            name="occupation"
                                                            id="occupation"
                                                            type="text"
                                                            placeholder="Ocupación"
                                                            value={formik.values.occupation}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.occupation && formik.errors.occupation ? true : false
                                                            }
                                                        />
                                                        {formik.errors.occupation && formik.touched.occupation ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.occupation}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="disability">Discapacidad</Label>
                                                        <Input
                                                            id="disability"
                                                            name="disability"
                                                            type="text"
                                                            placeholder="Discapacidad"
                                                            value={formik.values.disability}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.disability && formik.errors.disability ? true : false
                                                            }
                                                        />
                                                        {formik.errors.disability && formik.touched.disability ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.disability}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="religion">Religión</Label>
                                                        <Input
                                                            id="religion"
                                                            name="religion"
                                                            type="text"
                                                            placeholder="Religión"
                                                            value={formik.values.religion}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.religion && formik.errors.religion ? true : false
                                                            }
                                                        />
                                                        {formik.errors.religion && formik.touched.religion ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.religion}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12">
                                                    <div className="mb-3">
                                                        <Label htmlFor="private_note">Notas privadas</Label>
                                                        <Input
                                                            id="private_note"
                                                            name="private_note"
                                                            type="text"
                                                            placeholder="Notas privadas"
                                                            value={formik.values.private_note}
                                                            onChange={formik.handleChange}
                                                            invalid={
                                                                formik.touched.private_note && formik.errors.private_note ? true : false
                                                            }
                                                        />
                                                        {formik.errors.private_note && formik.touched.private_note ? (
                                                            <FormFeedback
                                                                type="invalid">{formik.errors.private_note}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('contact')}
                                                       style={{cursor: 'pointer'}}>
                                                Datos de Contacto {visibility.contact ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.contact && (
                                                <div>
                                                    <Row>
                                                        <Col sm="2">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="phoneType">Tipo de Teléfono</Label>
                                                                {formik.values.phones.map((phone, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <select
                                                                            id={`phone_type-${index}`}
                                                                            name={`phones[${index}].phone_type`}
                                                                            value={phone.phone_type}
                                                                            onChange={e => handlePhoneChange(e, index, 'phone_type')}
                                                                            className="custom-select mb-3"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {PhoneOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {formik.errors[`phones[${index}].phone_type`] && (
                                                                            <div className="text-danger">Campo
                                                                                requerido*</div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                        <Col sm="4">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="phoneNumber">Número de Teléfono</Label>
                                                                {formik.values.phones.map((phone, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <div
                                                                            className="d-flex justify-content-between align-items-center">
                                                                            <Input
                                                                                id={`phoneNumber-${index}`}
                                                                                name={`phones[${index}].phone`}
                                                                                type="text"
                                                                                placeholder="Número de teléfono"
                                                                                value={phone.phone}
                                                                                onChange={e => handlePhoneChange(e, index, 'phone')}
                                                                                className="flex-grow-1"
                                                                                style={{marginRight: 10}}
                                                                            />
                                                                            <Button type="button"
                                                                                    onClick={() => removePhone(index)}>Eliminar</Button>
                                                                        </div>
                                                                        {formik.errors[`phones[${index}].phone`] && (
                                                                            <div className="text-danger">Campo
                                                                                requerido*</div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                        <Col sm="6">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="email">Email</Label>
                                                                <Input
                                                                    id="email"
                                                                    name="email"
                                                                    type="email"
                                                                    placeholder="email"
                                                                    value={formik.values.email}
                                                                    onChange={formik.handleChange}
                                                                    invalid={formik.touched.email && formik.errors.email}
                                                                />
                                                                {formik.errors.email && formik.touched.email ? (
                                                                    <FormFeedback
                                                                        type="invalid">{formik.errors.email}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addPhone}>
                                                        Agregar otro teléfono
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('identification')}
                                                       style={{cursor: 'pointer'}}>
                                                Identificaciones {visibility.identification ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.identification && (
                                                <div>
                                                    <Row>
                                                        <Col sm="4">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="identification_type">Tipo de
                                                                    Identificación</Label>
                                                                {formik.values.identifications.map((identification, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <select
                                                                            id={`identification_type-${index}`}
                                                                            name={`phones[${index}].identification_type`}
                                                                            value={identification.identification_type}
                                                                            onChange={e => handleIdentificationChange(e, index, 'identification_type')}
                                                                            className="custom-select mb-3"
                                                                        >
                                                                            <option value="">Selecciona...</option>
                                                                            {IdentificationOptions.map(option => (
                                                                                <option key={option.val}
                                                                                        value={option.val}>
                                                                                    {option.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {formik.errors[`identifications[${index}].identification_type`] && (
                                                                            <div className="text-danger">Campo
                                                                                requerido*</div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                        <Col sm="8">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="identification">Identificación</Label>
                                                                {formik.values.identifications.map((identification, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <div
                                                                            className="d-flex justify-content-between align-items-center">
                                                                            <Input
                                                                                id={`identification-${index}`}
                                                                                name={`phones[${index}].identification`}
                                                                                type="text"
                                                                                placeholder="Identificación"
                                                                                value={identification.identification}
                                                                                onChange={e => handleIdentificationChange(e, index, 'identification')}
                                                                                className="flex-grow-1"
                                                                                style={{marginRight: 10}}
                                                                            />
                                                                            <Button type="button"
                                                                                    onClick={() => removeIdentification(index)}>Eliminar</Button>
                                                                        </div>
                                                                        {formik.errors[`identifications[${index}].identification`] && (
                                                                            <div className="text-danger">Campo
                                                                                requerido*</div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addIdentification}>
                                                        Agregar otra identificación
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('insurance')}
                                                       style={{cursor: 'pointer'}}>
                                                Seguros y planes médicos {visibility.insurance ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.insurance && (
                                                <div>
                                                    <Row>
                                                        <Col sm="3">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="name">Aseguradora*</Label>
                                                                {formik.values.insurance_or_plans.map((insurance, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Input
                                                                            id={`name-${index}`}
                                                                            name={`insurance_or_plans[${index}].name`}
                                                                            type="text"
                                                                            placeholder="Aseguradora*"
                                                                            value={insurance.name}
                                                                            onChange={e => handleInsuranceChange(e, index, 'name')}
                                                                            className="flex-grow-1"
                                                                            style={{marginRight: 10}}
                                                                        />
                                                                        {formik.errors[`insurance_or_plans[${index}].name`] && (
                                                                            <div className="text-danger">Campo
                                                                                requerido*</div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                        <Col sm="3">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="policy">Póliza*</Label>
                                                                {formik.values.insurance_or_plans.map((insurance, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <Input
                                                                            id={`policy-${index}`}
                                                                            name={`insurance_or_plans[${index}].policy`}
                                                                            type="text"
                                                                            placeholder="Póliza*"
                                                                            value={insurance.policy}
                                                                            onChange={e => handleInsuranceChange(e, index, 'policy')}
                                                                            className="flex-grow-1"
                                                                            style={{marginRight: 10}}
                                                                        />
                                                                        {formik.errors[`insurance_or_plans[${index}].policy`] && (
                                                                            <div className="text-danger">Campo
                                                                                requerido*</div>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                        <Col sm="6">
                                                            <div className="mb-3 mt-3">
                                                                <Label htmlFor="note">Nota</Label>
                                                                {formik.values.insurance_or_plans.map((insurance, index) => (
                                                                    <div key={index}
                                                                         className="mb-3 d-flex flex-column">
                                                                        <div
                                                                            className="d-flex justify-content-between align-items-center">
                                                                            <Input
                                                                                id={`note-${index}`}
                                                                                name={`insurance_or_plans[${index}].note`}
                                                                                type="text"
                                                                                placeholder="Nota"
                                                                                value={insurance.note}
                                                                                onChange={e => handleInsuranceChange(e, index, 'note')}
                                                                                className="flex-grow-1"
                                                                                style={{marginRight: 10}}
                                                                            />
                                                                            <Button type="button"
                                                                                    onClick={() => removeInsurance(index)}>Eliminar</Button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="light" className="btn-rounded mt-3"
                                                            onClick={addInsurance}>
                                                        Agregar otro seguro
                                                    </Button>
                                                </div>
                                            )
                                            }

                                            <CardTitle tag="h4" className="mt-5"
                                                       onClick={() => toggleSection('emergency_contact')}
                                                       style={{cursor: 'pointer'}}>
                                                Contactos de emergencia {visibility.emergency_contact ? '▲' : '▼'}
                                            </CardTitle>
                                            {visibility.emergency_contact && (
                                                <div>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div className="mb-3 mt-3">
                                                                {formik.values.emergency_contacts.map((emergency_contact, contactIndex) => (
                                                                    <div key={contactIndex} className="mb-3">
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <Label
                                                                                    htmlFor={`emergencyContact-${contactIndex}-fullName`}>Nombre
                                                                                    Completo</Label>
                                                                                <Input
                                                                                    id={`emergencyContact-${contactIndex}-fullName`}
                                                                                    name={`emergency_contacts[${contactIndex}].full_name`}
                                                                                    type="text"
                                                                                    placeholder="Nombre Completo"
                                                                                    value={emergency_contact.full_name}
                                                                                    onChange={(e) => handleContactChange(e, contactIndex, 'full_name')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                                {formik.errors[`emergency_contacts[${contactIndex}].full_name`] && (
                                                                                    <div className="text-danger">Campo
                                                                                        requerido*</div>
                                                                                )}
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <Label
                                                                                    htmlFor={`emergencyContact-${contactIndex}-relationship`}>Parentesco</Label>
                                                                                <Input
                                                                                    id={`emergencyContact-${contactIndex}-relationship`}
                                                                                    name={`emergency_contacts[${contactIndex}].relationship`}
                                                                                    type="text"
                                                                                    placeholder="Parentesco"
                                                                                    value={emergency_contact.relationship}
                                                                                    onChange={(e) => handleContactChange(e, contactIndex, 'relationship')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mt-3">
                                                                            <Col md={5}>
                                                                                <Label
                                                                                    htmlFor={`emergencyContact-${contactIndex}-email`}>Email</Label>
                                                                                <Input
                                                                                    id={`emergencyContact-${contactIndex}-email`}
                                                                                    name={`emergency_contacts[${contactIndex}].email`}
                                                                                    type="text"
                                                                                    placeholder="Email"
                                                                                    value={emergency_contact.email}
                                                                                    onChange={(e) => handleContactChange(e, contactIndex, 'email')}
                                                                                    className="flex-grow-1"
                                                                                    style={{marginRight: 10}}
                                                                                />
                                                                            </Col>
                                                                            <Col md={7}>
                                                                                <div style={{marginTop: 10}}>
                                                                                    <Label>Teléfono</Label>
                                                                                    {emergency_contact.phones.map((phone, phoneIndex) => (
                                                                                        <InputGroup
                                                                                            className="d-flex justify-content-between align-items-center">
                                                                                            <div className="col-4">
                                                                                                <select
                                                                                                    id={`phone_type-${phoneIndex}`}
                                                                                                    name={`emergency_contacts[${contactIndex}].phones[${phoneIndex}].phone_type`}
                                                                                                    value={phone.phone_type}
                                                                                                    onChange={e => handleContactPhoneChange(e, contactIndex, phoneIndex, 'phone_type')}
                                                                                                    className="custom-select mb-3"
                                                                                                >
                                                                                                    <option
                                                                                                        value="">Selecciona...
                                                                                                    </option>
                                                                                                    {PhoneOptions.map(option => (
                                                                                                        <option
                                                                                                            key={option.val}
                                                                                                            value={option.val}>
                                                                                                            {option.label}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                                {formik.errors[`emergency_contacts[${contactIndex}].phones[${phoneIndex}].phone_type`] && (
                                                                                                    <div
                                                                                                        className="text-danger mt-1">Campo
                                                                                                        requerido*</div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                <Input
                                                                                                    id={`emergencyContact-${contactIndex}-phone`}
                                                                                                    name={`emergency_contacts[${contactIndex}].phones[${phoneIndex}].phone`}
                                                                                                    type="text"
                                                                                                    placeholder="Teléfono"
                                                                                                    value={phone.phone}
                                                                                                    onChange={(e) => handleContactPhoneChange(e, contactIndex, phoneIndex, 'phone')}
                                                                                                    className="flex-grow-1"
                                                                                                    style={{marginRight: 10}}
                                                                                                />
                                                                                                {formik.errors[`emergency_contacts[${contactIndex}].phones[${phoneIndex}].phone`] && (
                                                                                                    <div
                                                                                                        className="text-danger mt-1">Campo
                                                                                                        requerido*</div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                <Button type="button"
                                                                                                        onClick={() => removeContactPhone(contactIndex, phoneIndex)}>
                                                                                                    Eliminar teléfono
                                                                                                </Button>
                                                                                            </div>
                                                                                        </InputGroup>
                                                                                    ))}
                                                                                    <Button type="button" color="light"
                                                                                            className="btn-rounded mt-2"
                                                                                            onClick={() => addContactPhones(contactIndex)}>
                                                                                        Agregar teléfono
                                                                                    </Button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mt-3">
                                                                            <Col md={12}>
                                                                                <Label
                                                                                    htmlFor={`emergencyContact-${contactIndex}-note`}>Nota</Label>
                                                                                <Input
                                                                                    id={`emergencyContact-${contactIndex}-note`}
                                                                                    name={`emergency_contacts[${contactIndex}].note`}
                                                                                    type="text"
                                                                                    placeholder="Nota"
                                                                                    value={emergency_contact.note}
                                                                                    onChange={(e) => handleContactChange(e, contactIndex, 'note')}
                                                                                    className="w-100"
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                        <Button type="button"
                                                                                onClick={() => removeContact(contactIndex)}
                                                                                className="btn-block mt-3">
                                                                            Eliminar contacto
                                                                        </Button>
                                                                    </div>
                                                                ))}
                                                                <Button color="light" className="btn-rounded mt-3"
                                                                        onClick={addContacts}>
                                                                    Agregar otro contacto
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                            }

                                            {
                                                loading ? <Spinners/>
                                                    :
                                                    <Row>
                                                        <div className="d-flex flex-wrap justify-content-end gap-2">
                                                            <Link to={`/admin/patients/${id}`}
                                                                  className="btn btn-outline-danger">Cancelar</Link>
                                                            <Button type="submit" color="success"> Guardar </Button>
                                                            <div className="position-fixed top-0 end-0 p-3"
                                                                 style={{zIndex: "1005"}}>
                                                                <Toast isOpen={toast}>
                                                                    <ToastHeader toggle={closeToast}>
                                                                        {toastTitle}
                                                                    </ToastHeader>
                                                                    <ToastBody>
                                                                        {toastMessage}
                                                                    </ToastBody>
                                                                </Toast>
                                                            </div>
                                                        </div>
                                                    </Row>
                                            }
                                        </Form>
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(AdminPatientEdit)

import React, {useMemo, useEffect, useState} from "react";
import withRouter from "../../Components/Common/withRouter";

import {Button, Card, CardBody, CardTitle} from "reactstrap";

import TableContainer from "../../Components/Common/TableContainer";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from 'reselect';
import {ConsultingOffice} from "../../entities/types";
import {useNavigate} from 'react-router-dom';
import {deleteOffice, getAdminConsultingOfficesThunk} from "../../slices/consulting_office/thunk";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "../Page_Admin_Agenda/DeleteModal";
import {deleteEvent} from "../../slices/calendar/thunk";

// Este selectState se comunica indirectamente con el state del reducer en /slices/admin/reducer
// y esa unión se hace a través del slices/index.ts, donde une el state "admin" con el archivo del reducer
interface selectState {
    consulting_office: {
        adminConsultingOfficeList: ConsultingOffice[];
        loading: true;
        message: "";
        error: false;
    };
};


const ConsultingOfficeList: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const selectProperties = createSelector(
        (state: selectState) => state.consulting_office,
        (item) => ({
            consultingOfficeList: item.adminConsultingOfficeList,
            loading: item.loading,
            error: item.error,
            message: item.message,
        })
    );

    const {consultingOfficeList, loading} = useSelector(selectProperties);

    const columns = useMemo(
        () => [
            {
                header: "Nombre",
                accessorKey: "name",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/consulting-offices/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black"
                            }}>
                                {cellProps.row.original.name}
                            </a>
                        </div>
                    );
                },
            },
            {
                header: "Ciudad / Estado",
                accessorKey: "state",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const { city, state } = cellProps.row.original;
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/consulting-offices/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black"
                            }}>
                                { `${city}, ${state}` }
                            </a>
                        </div>
                    );
                },
            },
            {
                header: "Dirección",
                accessorKey: "address",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    return (
                        <div className="flex-grow-1" style={{width: "200px"}}>
                            <a href={`/admin/consulting-offices/${cellProps.row.original.id}`} style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                                textDecoration: "none",
                                color: "black",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}>
                                {cellProps.row.original.address}<br/>
                                Tel. {cellProps.row.original.phone}
                            </a>
                        </div>
                    );
                },
            },
            {
                header: "",
                accessorKey: "actions",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps: any) => {
                    const handleDeleteClick = () => {
                        setDeleteModal(true);
                        setDeleteId(cellProps.row.original.id);
                    };

                    return (
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => navigate(`/admin/consulting-offices/${cellProps.row.original.id}`)}
                            >
                                Editar
                            </Button>
                            <span
                                style={{ fontSize: '1.5em', paddingLeft: '15px', paddingTop: '25px', cursor: 'pointer' }}
                                id="btn-delete-event"
                                onClick={handleDeleteClick}
                            >
                                <i className="bx bx-trash"></i>
                            </span>
                        </div>
                    );
                },
            },
        ],
        [navigate]
    );

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState();

    const handleDeleteOffice = () => {
        console.log("deleteId", deleteId);
        if (deleteId) {
            dispatch(deleteOffice(deleteId));
        }
        setDeleteModal(false);
    };

    useEffect(() => {
        dispatch(getAdminConsultingOfficesThunk());
    }, [dispatch])


    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOffice}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Card>
                <CardBody>
                    <CardTitle tag="h4" className="mb-4 d-flex justify-content-between align-items-center">
                        <span>Consultorios</span>
                        <Button type="button" color="success" className="btn-md btn-rounded"
                                onClick={() => navigate('/admin/new-consulting-office')}>
                            + Agregar consultorio
                        </Button>
                    </CardTitle>
                    {
                        loading ? <Spinners/>
                            :
                            <TableContainer
                                columns={columns}
                                data={consultingOfficeList}
                                isGlobalFilter={false}
                                isPagination={false}
                                SearchPlaceholder="Search..."
                                isCustomPageSize={false}
                                isAddButton={false}
                                buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                buttonName="Add new"
                                tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                theadClass="table-light"
                            />
                    }
                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default withRouter(ConsultingOfficeList);

import {
    loadingTrue,
    apiError,
    cleanApiError, loadingFalse,
} from "./reducer";
import {APIClient} from "../../helpers/api_helper";

const api = new APIClient();
export const cleanErrors = () => {
    return cleanApiError();
}


export const configRecipeSignatureUpdateThunk = (files: any, history: any) => async (dispatch: any) => {
    console.log("in adminProfileSignatureUpdateThunk files", files);
    dispatch(loadingTrue());
    try {

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append('files', file, file.name); // Añadimos el nombre del archivo
        });

        const response = await api.PostFile('/doctor/upload/signature', formData);

        console.log("without error, adminProfileSignatureUpdateThunk response", response);
        dispatch(loadingFalse());
        history('/admin/configuration/recipe');
    } catch (error) {
        console.error("Error in adminProfileSignatureUpdateThunk:", error);
        dispatch(apiError(error));
    }
}

export const configRecipeLogoUpdateThunk = (files: any, history: any) => async (dispatch: any) => {
    console.log("in adminProfileLogoUpdateThunk files", files);
    dispatch(loadingTrue());
    try {

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append('files', file, file.name); // Añadimos el nombre del archivo
        });

        const response = await api.PostFile('/doctor/upload/logo', formData);

        console.log("without error, adminProfileLogoUpdateThunk response", response);
        dispatch(loadingFalse());
        history('/admin/configuration/recipe');
    } catch (error) {
        console.error("Error in adminProfileLogoUpdateThunk:", error);
        dispatch(apiError(error));
    }
}

import React, {useState} from "react"
import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, Badge } from "reactstrap";
import { isEmpty, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

// img
import logo from "../../assets/images/logo.png";
import {Doctor} from "../../entities/types";
import {getAdminProfileThunk} from "../../slices/profile/thunk";

interface selectState {
    doctor: {
        loading: false;
        adminProfile: Doctor;
        reducerToastTitle: "";
        reducerToastMessage: "";
    };
};

const AdminConfigurationRecipe = (props: any) => {

    const dispatch: any = useDispatch();

    document.title = "Configuración de receta";

    const selectProperties = createSelector(
        (state: selectState) => state.doctor,
        (item) => ({
            loading: item.loading,
            adminProfile: item.adminProfile,
            reducerToastTitle: item.reducerToastTitle,
            reducerToastMessage: item.reducerToastMessage,
        })
    );

    const {loading, adminProfile, reducerToastTitle, reducerToastMessage} = useSelector(selectProperties);

    const [toast, setToast] = useState(false);
    const [toastTitle, setToastTitle] = useState("");
    const [toastMessage, setToastMessage] = useState("");

    const closeToast = () => {
        setToast(false);
    };

    React.useEffect(() => {
        if (reducerToastMessage !== "") {
            console.log("showToastWithMessage=", toastMessage);
            setToast(true);
            setToastTitle(reducerToastTitle);
            setToastMessage(reducerToastMessage);
        }
        //dispatch(cleanErrors());
    }, [reducerToastTitle, reducerToastMessage]);

    React.useEffect(() => {
        dispatch(getAdminProfileThunk());
    }, []);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {!isEmpty(adminProfile) && (
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={2}>
                                                <Link to={"/admin/profile/logo/edit"}>
                                                <div className="auth-logo mb-4">
                                                    <img src={adminProfile.logo} alt="logo" height="80"/>

                                                    <span style={{padding:"10px", fontSize:"12px"}}
                                                        className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                                                        cambiar logo
                                                    </span>

                                                </div>
                                                </Link>
                                            </Col>
                                            <Col sm={10}>
                                                <Row>
                                                    <Col sm={12}>
                                                        <div>
                                                            <h2 className="text-center fs-2">
                                                                {adminProfile.academic_title} {adminProfile.first_name} {adminProfile.last_name}
                                                            </h2>
                                                            <div className="text-center fs-5">
                                                                {adminProfile.specialty}
                                                            </div>
                                                            <div className="text-center fs-6">
                                                                {adminProfile.info.educations.map((education, subIndex) => (
                                                                    <span key={subIndex}>
                                                                 &nbsp;• {education.school_name}
                                                            </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <Row className="pt-5">
                                            <Col sm={6}>
                                                <address>
                                                    <strong>Paciente:</strong>
                                                    <br/>Alejandro Pérez García
                                                </address>
                                            </Col>
                                            <Col sm={6} className="text-sm-end">
                                                <address>
                                                    <strong>Fecha:</strong>
                                                    <br/>
                                                    24/12/2025, 10:30 a. m.
                                                </address>
                                            </Col>
                                        </Row>

                                        <hr/>


                                        <Row>
                                            <Col sm={12}>

                                                    <div >

                                                            <div>
                                                                    <div >
                                                                        <p className="text-muted mb-0">Paracetamol 500 mg</p>
                                                                        <p className="text-muted mb-0">1 tableta cada 8 horas por 3 días</p>
                                                                        <hr/>
                                                                    </div>
                                                                    <div >
                                                                        <p className="text-muted mb-0">Ibuprofeno 400 mg</p>
                                                                        <p className="text-muted mb-0">1 tableta cada 8 horas por 3 días</p>
                                                                        <hr/>
                                                                    </div>

                                                                <Row>
                                                                    <Col sm={8}>
                                                                        <strong>Notas adicionales:</strong>
                                                                        <p className="text-muted mb-0">Tomar 2 tabletas sólo en caso de mucho dolor</p>
                                                                    </Col>
                                                                    <Col sm={2}>
                                                                        <Link to={"/admin/profile/signature/edit"}>
                                                                            <div className="auth-logo mb-4">
                                                                                <img src={adminProfile.signature} alt="firma" height="80"/>

                                                                                <span style={{padding: "10px", fontSize: "12px"}}
                                                                                      className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                                                                                    cambiar firma
                                                                                </span>
                                                                            </div>
                                                                        </Link>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                    </div>

                                                <br/>
                                            </Col>
                                        </Row>
                                        <div className="footer-print">
                                            <Row className="bg-info-subtle"
                                                 style={{paddingTop: '20px', paddingBottom: "20px"}}>
                                                <Col sm={6}>
                                                    Nombre de Oficina
                                                    <br/>
                                                    Dirección de Oficina
                                                    <br/>
                                                    T. 888 888 8888
                                                    <br/>
                                                </Col>
                                                <Col sm={6} className="text-sm-end">
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
            <style>
                {`
          @media print {
            .footer-print {
              position: fixed;
              bottom: 0;
              width: 100%;
              background-color: white;
            }
          }
        `}
            </style>
        </React.Fragment>
    )
};


export default withRouter(AdminConfigurationRecipe);


